import React, { useCallback, useEffect, useState } from 'react';
import { Typography, Row, Col, Button } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import {
  updateCarrierPackages,
  getCarrierPackageList,
} from 'services/shipment';
import {
  message,
  GreyCardWrapper,
  DataGridPlus,
  HeaderTitle,
  SelectWarehouse,
  CopySpan,
  TablesFilter,
} from 'components/common';
import SelectCarrier from './SelectCarrier';
import { DIMENSION_UNITS as unitEnum } from 'constants/config';
import { onSelectionChange } from 'utils';

const { Text } = Typography;
type SaveChangesButtonProps = {
  loading: boolean;
  onSubmit: () => void;
};
function SaveChangesButton(props: SaveChangesButtonProps): JSX.Element {
  const { onSubmit, loading } = props;
  return (
    <Button
      id="save_change_button"
      loading={loading}
      type="primary"
      onClick={onSubmit}
    >
      <SaveOutlined />
      Save Changes
    </Button>
  );
}

export default function PackageSelection(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [carrier, setCarrier] = useState<CarrierRow>();
  const [warehouse, setWarehouse] = useState<WarehouseRow>();
  const [packages, setPackages] = useState<Array<ShipmentPackageRow>>();
  const [refresh, setRefresh] = useState(false);
  const [selectedRow, setSelectedRow] = React.useState<ShipmentPackageRow[]>(
    []
  );
  const [selected, setSelected] = React.useState<any>({});
  const [allTempList, setAllTempList] = useState<any[]>([])
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const columns = [
    {
      header: 'Package',
      name: 'name',
      userSelect: true,
      defaultFlex: 1,
      // render: (value: any) => {
      //   const { data, rowIndex } = value;
      //   return <span id={`grid_name_row_${rowIndex}`}>{data.name}</span>;
      // },
    },
    {
      header: 'Length',
      name: 'length',
      type: 'number',
      defaultFlex: 1,
      render: (value: any) => {
        const { data } = value;
        return (
          <CopySpan
            inGrid
            value={
              data.length
                ? `${data.length} ${unitEnum[data.linearUnit || 0]}`
                : ''
            }
          />
        );
      },
    },
    {
      header: 'Width',
      name: 'width',
      type: 'number',
      defaultFlex: 1,
      render: (value: any) => {
        const { data } = value;
        return (
          <CopySpan
            inGrid
            value={
              data.width
                ? `${data.width} ${unitEnum[data.linearUnit || 0]}`
                : ''
            }
          />
        );
      },
    },
    {
      header: 'Height',
      name: 'height',
      type: 'number',
      defaultFlex: 1,
      render: (value: any) => {
        const { data } = value;
        return (
          <CopySpan
            inGrid
            value={
              data.height
                ? `${data.height} ${unitEnum[data.linearUnit || 0]}`
                : ''
            }
          />
        );
      },
    },
    {
      header: 'Max Shipping Fee',
      name: 'maxShippingFee',
      type: 'number',
      defaultFlex: 1,
      editable: true,
    },
  ];

  /**
   * Load Data
   */
  const loadData = useCallback(async () => {
    if (!warehouse || !carrier) return;
    setLoading(true);
    const newPackages = await getCarrierPackageList(
      warehouse,
      carrier.carrierNum < 0 ? undefined : carrier
    );
    const data = newPackages.map((i) => {
      return { ...i, id: i.carrierPackageNum || i.shippingCustomPackageNum };
    });
    setPackages(data);
    setSelectedRow(data.filter((item) => item.checked));
    setSelected(() => {
      const temp: { [key: number]: any } = {};
      data.map((i) => {
        if (i.checked) {
          temp[i.id] = i;
        }
        return true;
      });
      return temp;
    })
    setLoading(false);
  }, [warehouse, carrier]);

  /* eslint-disable */
  useEffect(() => {
    if (carrier && warehouse && warehouse.id) {
      setPackages(undefined);
      setSelectedRow([]);
      setSelected({})
      loadData();
    }
  }, [carrier, warehouse?.id, refresh]);

  /* eslint-enable */
  /**
   * handle save changes
   */
  const handleSaveChanges = useCallback(async () => {
    if (!warehouse || !packages) {
      return;
    }
    // setSubmitting(true);
    const selectedNum = selectedRow.map((i) => i.id);
    const pkgs = packages.map((item) => {
      if (selectedNum.indexOf(item.id) > -1) {
        return { ...item, checked: true };
      } else {
        return { ...item, checked: false };
      }
    });
    try {
      await updateCarrierPackages(warehouse, pkgs);
      setSubmitting(false);
      // await loadData(warehouse, carrier);
      setRefresh((prev) => !prev);
      message.success('Saved Changes Successfully!');
    } catch (error) {
      setSubmitting(false);
      console.log(error);
    }
  }, [packages, warehouse, selectedRow]);

  let customProps = {} as any;

  return (
    <>
      <HeaderTitle breadcrumb={['Administration', 'Shipping', 'Package Selection']} />
      <GreyCardWrapper>
        <Row>
          <Col span={16}>
            <Text style={{ fontSize: 16 }}>
              Restrict the list of package types available to users by selecting
              the carrier below and then selecting which package types to show.
            </Text>
          </Col>
        </Row>
      </GreyCardWrapper>
      <GreyCardWrapper style={{ marginTop: 20 }}>
        <Row align="middle" justify="space-between">
          <Col span={12}>
            <Text style={{ marginRight: 5 }}>Warehouse:</Text>
            <SelectWarehouse
              id="warehouse_select"
              width={200}
              value={warehouse}
              onChange={(nextWarehouse) => {
                setWarehouse(nextWarehouse);
                // loadData(nextWarehouse, carrier);
              }}
            />
          </Col>
          <SaveChangesButton
            loading={submitting}
            onSubmit={handleSaveChanges}
          />
        </Row>
        <Row style={{ marginTop: 10 }}>
          {warehouse && (
            <SelectCarrier
              value={carrier}
              onChange={(nextCarrier) => {
                setCarrier(nextCarrier);
                // loadData(undefined, nextCarrier);
              }}
            />
          )}
        </Row>
        <Row style={{ marginTop: -18 }}>
          <Col span={24}>
            {packages && (
              <TablesFilter
                dataSource={packages}
                columns={columns}
                setFilteredData={setFilteredData}
                style={{ marginTop: 10 }}
              />
            )}
            {packages && (
              <DataGridPlus
                autoWith={false}
                style={{ zIndex: 2 }}
                loading={loading}
                dataSource={filteredData}
                idProperty="id"
                columns={columns}
                checkboxColumn
                pagination="local"
                //@ts-ignore
                onEditComplete={({ value, data }) => {
                  console.log(data);
                  if (value && Number(value) > 0 && data.flatRate) {
                    const temp = [...packages];
                    const index = temp.findIndex((item) => item.id === data.id);
                    temp[index].maxShippingFee = value;
                    // data[rowId][columnId] = value;
                    setPackages(temp);
                  }
                }}
                checkboxOnlyRowSelect={true}
                // defaultSelected={() => {
                //   const temp: { [key: number]: any } = {};
                //   packages.map((i) => {
                //     if (i.checked) {
                //       temp[i.id] = i;
                //     }
                //     return true;
                //   });
                //   return temp;
                // }}
                selected={selected}
                onSelectionChange={(props) => onSelectionChange(props, allTempList, setSelected, setSelectedRow, 'id', true, setAllTempList)}
                // onSelectionChange={(props) => {
                //   const { selected, data, unselected } = props;
                //   if (selected === true && !unselected) {
                //     setSelectedRow(packages);
                //   } else {
                //     if (!unselected) {
                //       setSelectedRow(Object.values(selected as any));
                //     } else {
                //       setSelectedRow((prev) => {
                //         return prev.filter(
                //           (item) => item.id !== (data as any).id
                //         );
                //       });
                //     }
                //   }
                // }}
                /* eslint-disable react/jsx-props-no-spreading */
                {...({ customProps } as any)}
              />
            )}
          </Col>
        </Row>
      </GreyCardWrapper>
    </>
  );
}
