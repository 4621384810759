import * as actionTypes from './actionTypes';

export const changeLanguage = (lang: string) => ({
  type: actionTypes.CHANGE_LANGUAGE,
  value: lang,
});

export const switchLanguage = (lang: string) => ({
  type: actionTypes.SWITCH_LANGUAGE,
  value: lang,
});

export const collapseSider = () => ({
  type: actionTypes.COLLAPSE_SIDE_MENU,
});

export const holdCurrentPage = (hold: boolean) => ({
  type: actionTypes.HOLD_CURRENT_PAGE,
  value: hold,
});

export const setAuthenticationResult = (authResult: RegularAuthState) => ({
  type: actionTypes.COMPLETE_AUTHENTICATION,
  state: authResult,
});

export const setUrlPath = (path: string) => ({
  type: actionTypes.SET_URL_PATH,
  value: path,
});

export const setPageHeight = (height: number) => ({
  type: actionTypes.SET_PAGE_HEIGHT,
  value: height,
});

export const setPageShowMode = (mode: number) => ({
  type: actionTypes.SET_PAGE_SHOW_MODE,
  value: mode,
});

export const setPageWidth = (width: number) => ({
  type: actionTypes.SET_PAGE_WIDTH,
  value: width,
});

export const setSiderWidth = (width: number) => ({
  type: actionTypes.SET_SIDER_WIDTH,
  value: width,
});

export const setSignalConnectionGroup = (group: StringKAnyVPair) => ({
  type: actionTypes.SET_SIGNAL_CONNECTION_GROUP,
  value: group,
});

export const setSignalConnectionState = (connection: number) => ({
  type: actionTypes.SET_SIGNAL_CONNECTION_STATE,
  value: connection,
});
