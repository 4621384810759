import React, { useCallback, useEffect, useState } from 'react';
import { Row, Space, Spin, Image, Divider, Col, Card, Descriptions, Empty, Button, Table } from 'antd';
import {
  fetchProduct,
  ProductThumbnailSizeType,
  getProductCustomsDeclaration,
  getBundleDetail,
  getProductLotRule,
  getProductUomList,
} from 'services/product';
import { getErrorImage, getProfileSettingValue } from 'utils';
import EditProductPicturesDialog from './../EditProductPicturesDialog';
import { BoolCell, ButtonIcon, CopySpan, DataGridPlus, FormLabel, LoadingIcon, TablesFilter } from 'components/common';
import SelectWarehouse from 'components/Warehouse/WarehouseSelectBox';
import { fetchWarehouseProductList, getTotalQuantityByWarehouse } from 'services/inventory';
import {
  WEIGHT_UNITS as weightUnitEnum,
  DIMENSION_UNITS_ABBR as unitEnum,
  COMMON_PADDING_SPACE,
  LS_DEFAULT_WAREHOUSE_KEY,
} from 'constants/config';
import { getCountrys } from 'services/address';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { UnderlineButton } from './../style';
import Title from 'antd/lib/typography/Title';
import DescriptionsFilter from 'components/common/Filter/DescriptionsFilter';
import LOTSettingDialog from 'components/Catalog/CatalogView/LOTSettingDialog'

const productStatusKeyValue: { [key: string]: string } = {
  '0': 'Active',
  '1': 'Archived',
};

const conditionEmun: { [key: string]: string } = {
  '0': 'New',
  '1': 'New with tags',
  '2': 'New without tags',
  '3': 'New with defects',
  '4': 'New other',
  '10': 'Open box',
  '11': 'Used - Very Good',
  '12': 'Used - Good',
  '13': 'Used Acceptable',
  '20': 'Used',
  '21': 'Manufacturer refurbished',
  '22': 'Seller refurbished',
  '30': 'Pre-owned',
  '40': 'Refurbished',
  '50': 'Reconditioned',
  '60': 'Remanufactured',
  '70': 'For parts or not working',
  '80': 'Other',
};

// const productCodeTypeKeyValue: { [key: string]: string } = {
//   '1': 'SKU',
//   '2': 'UPC',
//   '3': 'FNSKU',
//   '4': 'EAN',
//   '9': 'Product Code',
//   '200': 'Additional Code'
// };

const productCodeColumns = [
  // {
  //   title: 'Code Type',
  //   dataIndex: 'codeTypeNum',
  //   key: 'codeTypeNum',
  //   render: (text:any) => text ? productCodeTypeKeyValue[`${text}`] : '',
  // },
  {
    title: 'Product Code',
    dataIndex: 'codeValue',
    key: 'codeValue',
  },
  {
    title: 'Case Pack',
    dataIndex: 'casePack',
    key: 'casePack',
  },
  {
    title: 'Code Type',
    dataIndex: 'erpCodeType',
    key: 'erpCodeType',
  },
  {
    title: 'Pack Qty',
    dataIndex: 'packQuantity',
    key: 'packQuantity',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
];

const bundleColumns = [
  {
    name: 'componentSKU',
    header: 'Component SKU',
    defaultFlex: 1,
    minWidth: 220,
    maxWidth: 220,
    userSelect: true,
    sortable: false,
  },
  {
    name: 'componentUPC',
    header: 'Component UPC',
    defaultFlex: 1,
    minWidth: 220,
    maxWidth: 220,
    userSelect: true,
    sortable: false,
  },
  {
    name: 'componentQuantity',
    header: 'Component Qty',
    minWidth: 150,
    maxWidth: 150,
    userSelect: true,
    sortable: false,
  },
  {
    name: 'componentDescription',
    header: 'Component SKU Description',
    defaultFlex: 1,
    userSelect: true,
    sortable: false,
  }
]

const uomColumns = [
  {
    name: 'uom',
    header: 'Case Pack',
    defaultFlex: 1,
    userSelect: true,
    sortable: false,
  },
  {
    name: 'name',
    header: 'Pack Name',
    defaultFlex: 1,
    userSelect: true,
    sortable: false,
  },
  {
    name: 'uomRate',
    header: 'Rate',
    defaultFlex: 1,
    userSelect: true,
    sortable: false,
  }
]

type ProductDetailsDialogProps = {
  visible: boolean;
  onHide: () => void;
  productId: string;
};
export default function ProductDetailsDialog(
  props: ProductDetailsDialogProps
): JSX.Element {
  const [qtyLoading, setQtyLoading] = useState<boolean>(false);
  const [product, setProduct] = useState<any>();
  const [defaultWarehouseCode, setDefaultWarehouseCode] = useState<string>('');
  const [warehouseId, setWarehouseId] = useState<string>('');
  const [count, setCount] = useState<number>(0);
  const [qtyData, setQtyData] = useState<any[]>([]);
  const [defaultQtyData, setDefaultQtyData] = useState<any>(undefined);
  const [productPicturesDialogVisible, setPictureUrlsDialogVisible] =
    useState<boolean>(false);
  const showProductPicturesDialog = useCallback((visible: boolean) => {
    setPictureUrlsDialogVisible(visible);
  }, []);
  const [paramLimit, setParamLimit] = React.useState(10);
  const [paramSkip, setParamSkip] = React.useState(0);
  const [expandRight, setExpandRight] = useState<boolean>(false);
  const [expandDetail, setExpandDetail] = useState<boolean>(false);
  const [expandShipping, setExpandShipping] = useState<boolean>(false);
  const [customDeclaration, setCustomDeclaration] =
    useState<ProductCustomDeclaration>();
  const [countryList, setCountryList] = useState<CountryRow[]>([]);
  const [inventoryInfo, setInventoryInfo] = useState<any[]>([]);
  const { visible, productId } = props;
  const [bundleList, setBundleList] = useState<any[]>([])

  const [basicInfoFilteredLabels, setBasicInfoFilteredLabels] = useState<any[]>([]);
  const [inventoryFilteredLabels, setInventoryFilteredLabels] = useState<any[]>([]);
  const [shippingInfoFilteredLabels, setShippingInfoFilteredLabels] = useState<any[]>([]);
  const [flatFilteredData, setFlatFilteredData] = useState<any[]>([]);
  const [signatureFilteredData, setSignatureFilteredData] = useState<any[]>([]);
  const [warehouseFilteredData, setWarehouseFilteredData] = useState<any[]>([]);
  const [uomList, setUomList] = useState<any[]>([])
  const [lotSetting, setLotSetting] = useState<any>(undefined)
  const [LOTDialogVisible, setLOTDialogVisible] = useState<boolean>(false);
  const [needLoad, setNeedLoad] = useState(true)
  const [lotEnable, setLotEnable] = useState(false);
  const [uomEnable, setUomEnable] = useState(false);
  const [qtyColumns, setQtyColumns] = useState<any[]>([])
  const [inited, setInited] = useState(false)

  const getColumns = () => {
    return [
      {
        name: 'carrierName',
        header: 'Carrier',
        defaultFlex: 1,
        userSelect: true,
        sortable: false,
      },
      {
        name: 'Qualified',
        header: 'Qualified',
        defaultFlex: 1,
        sortable: false,
        render: (value: any) => {
          const { data } = value;
          return <BoolCell value={data.qualified} />;
        },
      },
      {
        name: 'carrierPackageName',
        header: 'Package',
        userSelect: true,
        defaultFlex: 1,
        sortable: false,
      },
      {
        name: 'packageMaxQty',
        header: 'Max Qty',
        defaultFlex: 1,
        sortable: false,
      },
    ];
  };
  const getSignatureColumns = () => {
    return [
      {
        name: 'carrierName',
        header: 'Carrier',
        defaultFlex: 1,
        userSelect: true,
        sortable: false,
      },
      {
        name: 'carrierSignatureName',
        header: 'Signature Option',
        defaultFlex: 1,
        userSelect: true,
        sortable: false,
      },
    ];
  };

  const getInventoryInfo = async (product: any) => {
    const { productId = '' } = product;
    if (!productId) return;
    try {
      const res = await getTotalQuantityByWarehouse(productId);
      if (res.length > 0) {
        const defaultWarehouseId = localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY);
        if (defaultWarehouseId) { // make sure that the default warehouse is the 1st
          const idx = res.findIndex((item: any) => item.warehouseNum === Number(defaultWarehouseId));
          if (idx > -1 && res.length !== 1) {
            const target = res[idx];
            console.log('target = ', target);
            res.splice(idx, 1);
            res.unshift(target);
          }
        }
        setInventoryInfo(res);
      }
    } catch (error) {
      console.log('error = ', error);
    }
  }

  const fetchCountryList = async () => {
    try {
      const res = await getCountrys();
      if (res.isSuccess) {
        setCountryList(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCustomsInfo = useCallback(async () => {
    if (!product.fulfillmentProductNum) return;
    try {
      const res = await getProductCustomsDeclaration(
        product.fulfillmentProductNum
      );
      if (res.isSuccess) {
        setCustomDeclaration(res.data || {});
      }
    } catch (error) {
    }
  }, [product]);

  const getCountryName = (arr: CountryRow[], num: number) => {
    const temp = arr.filter((i) => i.countryNum === num);
    if (temp.length > 0) {
      return temp[0].name;
    } else {
      return '';
    }
  };

  const getQtyData = useCallback(
    async (skip: number, top: number) => {
      try {
        let query: StringKAnyVPair = {
          $skip: skip,
          $top: top,
          $sortBy: 'sku',
          $calculateTotal: false,
          $count: true,
          uniqueCode: product.sku,
        };
        setQtyLoading(true);
        const res = await fetchWarehouseProductList(warehouseId, query);
        setQtyLoading(false);
        if (res && 'object' === typeof res) {
          setCount(res.count);
          setQtyData(res.data);
          if (defaultQtyData === undefined) {
            let sumQty = 0;
            res.data.forEach((item: any) => {
              sumQty += item.quantity;
            })
            setDefaultQtyData(sumQty);
          }
        } else {
          setDefaultQtyData(undefined);
        }
      } catch (error) {
        setQtyLoading(false);
      }
    },
    // eslint-disable-next-line
    [product, warehouseId]
  );

  const getBundleLsit = useCallback(async () => {
    try {
      const res = await getBundleDetail(product.productId)
      if (res) {
        setBundleList(res)
      }
    } catch (error) {
      console.log(error);
    }
  }, [product]);

  const getLotSetting = useCallback(async () => {
    try {
      const res = await getProductLotRule(product.fulfillmentProductNum)
      if(res){
        setLotSetting(res)
      }
    } catch (error) {
      console.log(error)
    }
  },[product])

  const getUomList = useCallback(async()=>{
    try {
      const res = await getProductUomList(product.sku)
      if(res.isSuccess){
        setUomList(res.data)
      }
    } catch (error) {
      
    }
  },[product])

  const getProfileSetting = useCallback(async()=>{
    const res1 = await getProfileSettingValue('EnableLOT', '0')
    const res2 = await getProfileSettingValue('EnableUOM','0')
    setLotEnable(res1 === '1')
    setUomEnable(res2 === '1')
    setInited(true)
  },[])

  useEffect(()=>{
    getProfileSetting()
  },[getProfileSetting])

  const getQtyColumns = useCallback(()=>{
    const cls = [
      {
        header: 'Warehouse',
        name: 'warehouseCode',
        sortable: true,
        userSelect: true,
        defaultFlex: 1,
      },
      {
        header: 'Location',
        name: 'locationCode',
        sortable: true,
        userSelect: true,
        defaultFlex: 1,
      },
      {
        minWidth: 130,
        name: 'lotNumber',
        header: 'LOT#',
        defaultFlex: 1,
        lotColumn: true,
        userSelect: true,
      },
      {
        minWidth: 130,
        name: 'uom',
        header: 'UOM',
        defaultFlex: 1,
        uomColumn: true,
        userSelect: true,
      },
      {
        minWidth: 130,
        name: 'uomRate',
        header: 'UOM Rate',
        defaultFlex: 1,
        uomColumn: true,
        userSelect: true,
      },
      {
        header: 'Qty',
        name: 'quantity',
        sortable: true,
        userSelect: true,
        defaultFlex: 1,
        sort: (a: number, b: number) => a - b,
      },
      {
        minWidth: 130,
        name: 'uomBaseQty',
        header: 'Base Qty',
        defaultFlex: 1,
        uomColumn: true,
        userSelect: true,
      },
      {
        header: 'Zone',
        name: 'ZoneName',
        sortable: true,
        userSelect: true,
        defaultFlex: 1,
      },
    ];
    let temp = [...cls]
    temp = lotEnable ? [...temp] : temp.filter(i=> !i.lotColumn)
    temp = uomEnable ? [...temp] : temp.filter(i => !i.uomColumn)
    setQtyColumns([...temp]);
  },[lotEnable, uomEnable])

  useEffect(()=>{
    getQtyColumns()
  },[lotEnable, uomEnable, getQtyColumns])


  const basicInfoLabels = [
    {
      label: 'SKU',
      value: <CopySpan value={product?.sku} />
    },
    {
      label: 'UPC',
      value: <CopySpan value={product?.upc} />
    },
    {
      label: 'Product Name',
      value: <CopySpan value={product?.productTitle} />
    },
    {
      label: 'Product Size',
      value: <CopySpan
        value={`${product?.productLength || 0} * ${product?.productWidth || 0
          } * ${product?.productHeight || 0}${unitEnum[product?.dimensionUnit || 0]
          } (L*W*H)`}
      />
    },
    {
      label: 'Box Size',
      value: <CopySpan
        value={`${product?.boxLength || 0} * ${product?.boxWidth || 0
          } * ${product?.boxHeight || 0}${unitEnum[product?.dimensionUnit || 0]
          } (L*W*H)`}
      />
    },
    {
      label: 'Weight',
      value: <span>
        <div>
          <CopySpan
            value={`Net Weight: ${product?.netWeight || 0} ${weightUnitEnum[product?.weightUnit || 0]
              }`}
          />
        </div>
        <div>
          <CopySpan
            value={`Gross Weight: ${product?.grossWeight || 0
              } ${weightUnitEnum[product?.weightUnit || 0]}`}
          />
        </div>
      </span>
    },
    // 6
    {
      label: 'EAN',
      value: <CopySpan value={product?.ean} />
    },
    {
      label: 'MPN',
      value: <CopySpan value={product?.mpn} />
    },
    {
      label: 'Condition',
      value: <CopySpan value={conditionEmun[`${product?.condition}`]} />
    },
    {
      label: 'Product Status',
      value: <CopySpan value={productStatusKeyValue[product?.productStatus]} />
    },
    {
      label: 'Brand',
      value: <CopySpan value={product?.brand} />
    },
    {
      label: 'Division Code',
      value: <CopySpan value={product?.divisionCode} />
    },
    {
      label: 'Department Code',
      value: <CopySpan value={product?.departmentCode} />
    },
    {
      label: 'Group Code',
      value: <CopySpan value={product?.groupCode} />
    },
    {
      label: 'Subgroup Code',
      value: <CopySpan value={product?.subGroupCode} />
    },
    {
      label: 'Commodity Description',
      value: <CopySpan value={product?.commodityDescription} />
    },
  ]

  const shippingInfoLabels = [
    {
      label: 'Default Package Name',
      value: <CopySpan value={product?.defaultPackage?.name} />
    },
    {
      label: 'Package Size',
      value: <CopySpan
        value={
          product?.defaultPackage?.shippingCustomPackageNum ===
            1
            ? 'Pacakge size is the same as the box size'
            : `${product?.defaultPackage?.length || 0} * ${product?.defaultPackage?.width || 0
            } * ${product?.defaultPackage?.height || 0}${unitEnum[
            product?.defaultPackage?.linearUnit || 0
            ]
            } (L*W*H)`
        }
      />
    },
    {
      label: 'Package Max Qty',
      value: <CopySpan value={product?.packageMaxQty} />
    },
    {
      label: 'Ship Separately',
      value: <CopySpan
        value={product?.shipSeparately === 1 ? 'Yes' : 'No'}
      />
    },
    {
      label: 'Carrier Declared Value',
      value: <CopySpan value={product?.carrierDeclareValue} />
    },
    {
      label: 'Enable Carrier Declared Value',
      value: <CopySpan
        value={
          product?.enableCarrierDeclareValue ? 'Yes' : 'No'
        }
      />
    },
    {
      label: 'Customs Description',
      value: <CopySpan value={customDeclaration?.description || ''} />
    },
    {
      label: 'Customs Declared Value',
      value: <CopySpan
        value={
          customDeclaration?.declaredValue
            ? `${customDeclaration.declaredValue} ${customDeclaration.currencyCode}`
            : ''
        }
      />
    },
    {
      label: 'HS Code',
      value: <CopySpan value={customDeclaration?.harmonizationCode || ''} />
    },
    {
      label: 'Origin Country',
      value: <CopySpan
        value={customDeclaration?.originCountryNum ? getCountryName(countryList, customDeclaration?.originCountryNum) : ''}
      />
    },
  ]

  useEffect(() => {
    if (product && warehouseId) {
      getInventoryInfo(product);
      getQtyData(0, paramLimit);
    }
  }, [warehouseId, product, getQtyData, paramLimit]);

  useEffect(() => {
    const fetch = async () => {
      if (visible && needLoad) {
        const newProduct = await fetchProduct(
          productId,
          ProductThumbnailSizeType.middle
        );
        setNeedLoad(false)
        setProduct({ ...newProduct, productId: productId, allCodes: newProduct?.allCodes.filter((i:any)=> i.codeTypeNum === 9) });
      }
    };
    fetch();
  }, [productId, visible, needLoad]);

  useEffect(() => {
    if (product) {
      getCustomsInfo();
    }
  }, [product, getCustomsInfo]);

  useEffect(() => {
    fetchCountryList();
  }, []);

  useEffect(() => {
    if (product?.bundleType && product.fulfillmentProductNum) {
      getBundleLsit();
    }
  }, [product, getBundleLsit])

  useEffect(()=>{
    if( product && product.useRule !== 0 && lotEnable && product.fulfillmentProductNum){
      getLotSetting()
    }
  },[product, getLotSetting, lotEnable])

  useEffect(()=>{
    if( product && uomEnable && product.sku){
      getUomList()
    }
  },[uomEnable, getUomList, product])

  /**
  * Modal DescriptionTitle
  * @returns {JSX.Element}
  */
  interface DescriptionTitleProps {
    activeTab: '1' | '2' | '3',
    children: React.ReactNode,
    moreText?: React.ReactNode,
  }
  const DescriptionTitle = (props: DescriptionTitleProps): JSX.Element => {
    const { children, activeTab = '1', moreText = 'More' } = props;
    const handleChangeMoreInfo = () => {
      switch (activeTab) {
        case '1': setExpandDetail(!expandDetail); break;
        case '2': setExpandRight(true); break;
        case '3': setExpandShipping(!expandShipping); break;
        default: break;
      }
    }
    return (
      <div style={{ display: 'inline-block', width: 300 }}>
        <span>{children}</span>
        <UnderlineButton onClick={handleChangeMoreInfo}>
          {moreText}
        </UnderlineButton>
      </div>
    )
  }

  return (
    <Spin indicator={<LoadingIcon size="large" />} spinning={!product}>
      <div
        onClick={() => {
          if (expandRight) setExpandRight(false);
        }}
        style={{
          position: 'relative',
        }}
      >
        {product && (
          <>
            <div>
              <Card>
                <Row justify="space-between">
                  <Col span={16}>
                    <Descriptions
                      title={
                        <Space>
                          <DescriptionTitle
                            activeTab="1"
                            moreText={
                              <span>
                                {expandDetail ? 'Hide' : 'Show'} More Info{' '}
                                {expandDetail ? (
                                  <ButtonIcon className="bi bi-chevron-double-up" />
                                ) : (
                                  <ButtonIcon className="bi bi-chevron-double-down" />
                                )}
                              </span>
                            }
                          >
                            Basic Info
                          </DescriptionTitle>
                          <DescriptionsFilter
                            dataSource={product}
                            labels={basicInfoLabels}
                            setFilteredLabels={setBasicInfoFilteredLabels}
                            inputProps={{ size: 'small' }}
                          />
                        </Space>
                      }
                      size="small"
                      column={2}
                      labelStyle={{
                        display: 'inline-block',
                        width: 170,
                        textAlign: 'right',
                      }}
                    >
                      {(basicInfoFilteredLabels || []).map((item, index: number) => {
                        if (index < 6) return (
                          <Descriptions.Item key={`basicinfo_items_row${index}`} label={<FormLabel noColon>{item.label}</FormLabel>}>
                            {item.value}
                          </Descriptions.Item>
                        )
                        return null;
                      })}
                    </Descriptions>
                  </Col>
                  <Col span={8}>
                    <div
                      style={{
                        marginRight: 150,
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'end',
                        alignItems: 'center',
                      }}
                    >
                      <Image
                        width={100}
                        height={100}
                        // preview={false}
                        src={product?.thumbnailUrl}
                        fallback={getErrorImage()}
                      />
                    </div>
                  </Col>
                </Row>
                {expandDetail && basicInfoFilteredLabels.length > 6 && (
                  <Descriptions
                    size="small"
                    column={3}
                    labelStyle={{
                      display: 'inline-block',
                      width: 170,
                      textAlign: 'right',
                    }}
                  >
                    {(basicInfoFilteredLabels || []).map((item, index: number) => {
                      if (index >= 6) return (
                        <Descriptions.Item key={`basicinfo_items_row${index}`} label={<FormLabel noColon>{item.label}</FormLabel>}>
                          {item.value}
                        </Descriptions.Item>
                      )
                      return null;
                    })}
                  </Descriptions>
                )}
                {
                  expandDetail && product.allCodes.length > 0 && <Table size="small" dataSource={product.allCodes} columns={productCodeColumns} pagination={false} scroll={{ y: 180 }}/>
                }
              </Card>
              {bundleList.length > 0 && (
                <Card
                  style={{
                    marginTop: COMMON_PADDING_SPACE,
                    marginBottom: COMMON_PADDING_SPACE,
                  }}
                >
                  <Title level={5}>{product.bundleType === 2 ? 'Prepack Info' : 'Bundle Info'}</Title>
                  <DataGridPlus
                    style={{ maxHeight: 300 }}
                    idProperty="componentProductNum"
                    columns={bundleColumns}
                    dataSource={bundleList}
                    // loading={loading}
                    pagination={false}
                    {...({} as any)}
                  />
                </Card>
              )}
              <Card
                style={{
                  marginTop: COMMON_PADDING_SPACE,
                  marginBottom: COMMON_PADDING_SPACE,
                  maxHeight: 255,
                  overflow: 'auto',
                }}
              >
                {inventoryInfo.length > 0 ? (
                  <Descriptions
                    title={
                      <Space>
                        <DescriptionTitle
                          activeTab="2"
                          moreText="Warehouses Detail"
                        >
                          Inventory
                        </DescriptionTitle>
                        <DescriptionsFilter
                          dataSource={inventoryInfo}
                          labels={(inventoryInfo || []).map(item => {
                            return { label: item.warehouseCode, value: <CopySpan value={item.quantity} /> }
                          })}
                          setFilteredLabels={setInventoryFilteredLabels}
                        />
                      </Space>
                    }
                    column={3}
                    size="small"
                    labelStyle={{
                      display: 'inline-block',
                      minWidth: 180,
                      textAlign: 'right',
                    }}
                  >
                    {(inventoryFilteredLabels || []).map((label, index: number) => (
                      <Descriptions.Item
                        key={index}
                        label={
                          <FormLabel noColon noWrap>
                            {label.label}
                          </FormLabel>
                        }
                      >
                        {label.value}
                      </Descriptions.Item>
                    ))}
                  </Descriptions>
                ) : (
                  <>
                    <Descriptions
                      title={
                        <DescriptionTitle
                          activeTab="2"
                          moreText="Warehouses Detail"
                        >
                          Inventory
                        </DescriptionTitle>
                      }
                    ></Descriptions>
                    <Empty
                      style={{ margin: 0 }}
                      description="Inventory infomation not found"
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                    ></Empty>
                  </>
                )}
              </Card>
                {lotEnable && (
                  <Card
                    style={{
                      marginTop: COMMON_PADDING_SPACE,
                      marginBottom: COMMON_PADDING_SPACE,
                      maxHeight: 255,
                      overflow: 'auto',
                    }}
                  >
                        <Descriptions
                          title="LOT"
                          extra={<Button type='link' onClick={()=> setLOTDialogVisible(true)} icon={<EditOutlined />}>Edit</Button>}
                        >
                            <Descriptions.Item span={24}  label={<FormLabel noColon>LOT Control</FormLabel>}>
                              {product.lotControl === 1 ? 'Full LOT Control' : 'No LOT Control'}
                            </Descriptions.Item>
                            <Descriptions.Item span={24}  label={<FormLabel noColon>LOT Number Rule</FormLabel>}>
                              {product.useRule === 0 ? 'Use Default Rule' : 'Use SKU Rule'}
                            </Descriptions.Item>
                            {
                              product.useRule !==0 && lotSetting && (<>
                              {
                                lotSetting.rule === 0 ? (<>
                                <Descriptions.Item label={<FormLabel noColon>Prefix</FormLabel>}>
                                  {lotSetting.prefix}
                                </Descriptions.Item>
                                <Descriptions.Item label={<FormLabel noColon>Start Number</FormLabel>}>
                                  {lotSetting.startNumber}
                                </Descriptions.Item>
                                <Descriptions.Item label={<FormLabel noColon>Suffix</FormLabel>}>
                                  {lotSetting.suffix}
                                </Descriptions.Item>
                                <Descriptions.Item label={<FormLabel noColon>Control Total Length</FormLabel>}>
                                  {lotSetting.totalLength || 'No'}
                                </Descriptions.Item>
                                {
                                  lotSetting.totalLength > 0 && <Descriptions.Item label={<FormLabel noColon>Padding Zero</FormLabel>}>
                                  {lotSetting.paddingZero === 0 ? 'Between Prefix and Number' : 'Between Number and Suffix'}
                                </Descriptions.Item>
                                }
                                </>) : (<>
                                  <Descriptions.Item label={<FormLabel noColon>Prefix</FormLabel>}>
                                  {lotSetting.prefix}
                                </Descriptions.Item>
                                <Descriptions.Item label={<FormLabel noColon>Prefix Separator</FormLabel>}>
                                  {lotSetting.prefixSeparator}
                                </Descriptions.Item>
                                <Descriptions.Item label={<FormLabel noColon>Date Format</FormLabel>}>
                                  {lotSetting.dateFormat}
                                </Descriptions.Item>
                                <Descriptions.Item label={<FormLabel noColon>Suffix Separator</FormLabel>}>
                                  {lotSetting.suffixSeparator}
                                </Descriptions.Item>
                                <Descriptions.Item label={<FormLabel noColon>Suffix</FormLabel>}>
                                  {lotSetting.suffix}
                                </Descriptions.Item>
                                <Descriptions.Item label={<FormLabel noColon>Control Total Length</FormLabel>}>
                                  {lotSetting.totalLength || 'No'}
                                </Descriptions.Item>
                                {
                                  lotSetting.totalLength > 0 && <Descriptions.Item label={<FormLabel noColon>Padding Zero</FormLabel>}>
                                  {lotSetting.paddingZero === 0 ? 'Between Prefix and Date' : 'Between Date and Suffix'}
                                </Descriptions.Item>
                                }
                                </>)
                              }

                              </> 
                            )}
                        </Descriptions>
                  </Card>
                )}

                {uomEnable && (
                  <Card
                    style={{
                      marginTop: COMMON_PADDING_SPACE,
                      marginBottom: COMMON_PADDING_SPACE,
                    }}
                  >
                  <Title level={5}>UOM</Title>
                    <DataGridPlus
                      style={{ maxHeight: 200 }}
                      idProperty="productUomNum"
                      columns={uomColumns}
                      dataSource={uomList}
                      pagination={false}
                      {...({} as any)}
                    />
                  </Card>
                )}
            </div>

            <Card>
              <Descriptions
                title={
                  <Space>
                    <DescriptionTitle
                      activeTab="3"
                      moreText={
                        <span>
                          {expandShipping ? 'Hide' : 'Show'} Flat Rate &
                          Signature{' '}
                          {expandShipping ? (
                            <ButtonIcon className="bi bi-chevron-double-up" />
                          ) : (
                            <ButtonIcon className="bi bi-chevron-double-down" />
                          )}
                        </span>
                      }
                    >
                      Shipping Info
                    </DescriptionTitle>
                    <DescriptionsFilter
                      dataSource={product}
                      labels={shippingInfoLabels}
                      setFilteredLabels={setShippingInfoFilteredLabels}
                    />
                  </Space>
                }
              ></Descriptions>
              <Descriptions
                size="small"
                column={3}
                labelStyle={{
                  display: 'inline-block',
                  width: 220,
                  textAlign: 'right',
                }}
              >
                {(shippingInfoFilteredLabels || []).map((label, index: number) => {
                  return (
                    <Descriptions.Item label={<FormLabel noColon>{label.label}</FormLabel>} key={`shippingInfo-label-row${index}`} >
                      {label.value}
                    </Descriptions.Item>
                  )
                })}
              </Descriptions>
              {expandShipping && (
                <>
                  <Divider orientation="left">Flat Rate Option</Divider>
                  <TablesFilter
                    dataSource={product?.productFlatRateOptionDtos || []}
                    columns={getColumns()}
                    setFilteredData={setFlatFilteredData}
                  />
                  <DataGridPlus
                    style={{ maxHeight: 300 }}
                    idProperty="productFlatRateOptionNum"
                    columns={getColumns()}
                    dataSource={flatFilteredData}
                    loading={!product}
                    // pagination="local"
                    pagination={
                      product?.productFlatRateOptionDtos.length > 10
                        ? 'local'
                        : false
                    }
                    {...({} as any)}
                  />
                  <Divider orientation="left">Signature Option</Divider>
                  <TablesFilter
                    dataSource={product?.productCarrierSignatureDetailsDtos || []}
                    columns={getSignatureColumns()}
                    setFilteredData={setSignatureFilteredData}
                  />
                  <DataGridPlus
                    style={{ maxHeight: 300 }}
                    idProperty="productCarrierSignatureNum"
                    columns={getSignatureColumns()}
                    dataSource={signatureFilteredData}
                    loading={!product}
                    pagination={
                      product?.productCarrierSignatureDetailsDtos.length > 10
                        ? 'local'
                        : false
                    }
                    {...({} as any)}
                  />
                </>
              )
              }
            </Card>
          </>
        )
        }
        <div
          onClick={(e) => e.stopPropagation()}
          style={{
            position: 'absolute',
            zIndex: 998,
            top: 0,
            right: 0,
            height: '100vh',
            width: expandRight ? '66%' : 0,
            display: 'flex',
            flexFlow: 'row nowrap',
            backgroundColor: '#fff',
            overflow: 'hidden',
            transition: 'all 0.2s',
            borderRadius: 5,
            boxShadow: expandRight ? 'rgb(0 0 0 / 28%) -2px 0px 40px 0px' : '0 0 12px -1px rgb(0 0 0 / 15%)',
          }}
        >
          {inited && <Card
            title={
              <Row justify="space-between">
                <Col>Warehouses Detail</Col>
                <Col>
                  <CloseOutlined onClick={() => setExpandRight(false)} />
                </Col>
              </Row>
            }
            style={{
              width: '100%',
              borderLeft: 'none',
              overflow: 'auto',
            }}
          >
            {/* <Row align="middle">
            <Col> */}
            <Space align="center">
              <Space align="center">
                <FormLabel>Warehouse</FormLabel>
                <div style={{ width: 200 }}>
                  <SelectWarehouse
                    onlyNormal={false}
                    selectAll
                    onChange={(ws: any, opt: any) => {
                      if (opt) {
                        opt.warehouseId
                          ? setWarehouseId(opt.warehouseId)
                          : setWarehouseId(`${product.databaseNum}-0`);
                      }
                      if (
                        !defaultWarehouseCode &&
                        localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)
                      ) {
                        setDefaultWarehouseCode(opt.label);
                      }
                    }}
                  />
                </div>
              </Space>
              {/* </Col>
            <Col> */}
              <TablesFilter
                dataSource={qtyData}
                columns={qtyColumns}
                setFilteredData={setWarehouseFilteredData}
              />
              {/* </Col>
          </Row> */}
            </Space>
            <DataGridPlus
              style={{ maxHeight: 200 }}
              name="quantity"
              columns={qtyColumns}
              dataSource={warehouseFilteredData}
              loading={qtyLoading}
              pagination={qtyData.length > 10 ? 'local' : false}
              pageSizes={[10, 20, 30, 50, 100, 200]}
              count={count}
              onChange={(limit, skip) => {
                setParamLimit(limit);
                setParamSkip(skip);
                console.log(paramSkip);
                getQtyData(skip, limit);
              }}
              {...({} as any)}
            />
          </Card>}
        </div>
      </div>
      {
        product && (
          <EditProductPicturesDialog
            product={product}
            visible={productPicturesDialogVisible}
            onHide={() => showProductPicturesDialog(false)}
          />
        )
      }
      {product && LOTDialogVisible && (
        <LOTSettingDialog
          onClose={() => {
            setLOTDialogVisible(false);
          }}
          onRefresh={() => {
            setLOTDialogVisible(false);
            setNeedLoad(true)
          }}
          visible={true}
          product={product}
        />
      )}
    </Spin >
  );
}