import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { v4 as uuid } from 'uuid';
import { Button, Menu, Modal, Typography } from 'antd';
import { ExclamationCircleOutlined, MobileOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import 'assets/styles/app.less';
import PIMSVG from 'assets/icons/pim';
import WMSSVG from 'assets/icons/wms';
import ERPSVG from 'assets/icons/erp';
import WMSBGSVG from '../../assets/icons/WMS_bg';
//import HomeSVG from 'assets/icons/home';
//import OfferSVG from 'assets/icons/local_offer';
//import AssigmentSVG from 'assets/icons/assignment';
//import CustomService from 'assets/icons/textsms';
//import Shopping from 'assets/icons/shopping';
import {
  MOBILE_URL_PREFIX,
  PAGE_MODE_FULL_PAGE,
} from 'constants/config';
import { RootState } from 'reducers';
import {
  holdCurrentPage,
  // setPageHeight,
  // setPageWidth,
  // setSiderWidth,
  setUrlPath,
  collapseSider,
} from 'actions/regularActions';
import {
  LabelName,
  LogoContainer,
  LogoHeader,
  // LogoText,
  MenuItem,
  SiderContainer,
  SystemNameContainer
} from './components';
import menuTree, {
  defaultExitConfirmMessage,
  exitConfirmMessage,
  getMenuPath,
} from './menu';
import { useResponsive } from 'hooks';
import { fetchSiteConfig } from 'utils';
import { userProfiles } from 'utils/auth';

const { SubMenu } = Menu;

export type SMenu = {
  key?: string;
  icon?: React.ReactNode;
  name: string;
  route?: string;
  //children?: SMenu[];
  items?: SMenu[];
  visible?: boolean;
};

const makeMenuList = (list: SMenu[]) => {
  const ret: SMenu[] = [];

  list.forEach((e) => {
    const item = { ...e, key: uuid() };

    //if (e.children) {
    if (e.items) {
      //item.children = makeMenuList(e.children);
      item.items = makeMenuList(e.items);
    }

    ret.push(item);
  });

  return ret;
};

const menuItems = makeMenuList(menuTree);

const menuKeyDict: { [key: string]: string[] } = {};

function Sidebar() {
  const { isMobile } = useResponsive();
  const dispatch: Dispatch<any> = useDispatch();
  const history = useHistory();
  const [menuList, setMenuList] = useState<SMenu[]>(menuItems);
  const [currentPath, setCurrentPath] = React.useState(
    history.location.pathname
  ); // variable to store current page path
  const [inited, setInited] = React.useState(false);
  const [openKeys, setOpenKeys] = React.useState<Array<string>>([]);
  const [selectedKeys, setSelectedKeys] = React.useState<Array<string>>([]);
  const [wmsType, setWmsType] = useState(true);
  const siteCfg = fetchSiteConfig();
  const collapsed: boolean = useSelector(
    (state: RootState) => state.regular.siderCollapsed,
    shallowEqual
  );
  const pageShowMode: number = useSelector(
    (state: RootState) => state.regular.pageShowMode,
    shallowEqual
  );
  const pageIsHeld: boolean = useSelector(
    (state: RootState) => state.regular.pageIsHeld
  );
  const urlPath: string = useSelector(
    (state: RootState) => state.regular.urlPath,
    shallowEqual
  );
  const siderbarRef = React.useRef<any>(null);
  const logoTextStyle = collapsed ? { opacity: 0 } : { paddingLeft: 10, paddingTop: 2, minWidth: 100 };
  const menuFoldCtnStyle = {
    color: '#F9F5E7',
    display: collapsed ? 'none' : undefined,
    textAlign: 'right' as 'right',
    width: 'calc(100% - 152px)',
  };
  const menuUnfoldCtnStyle = {
    color: '#F9F5E7',
    display: collapsed ? undefined : 'none',
    textAlign: 'center' as 'center',
    width: '100%',
  };
  /**
   * Check the url to decide whether need to reload page.
   */
  // eslint-disable-next-line
  const checkUrlShowMode = (path: string) => {
    //console.log('=======>', urlPath, path);
    if (urlPath !== path) {
      const currentIsMobile = path.indexOf(MOBILE_URL_PREFIX) > -1;
      const lastIsMobile = urlPath.indexOf(MOBILE_URL_PREFIX) > -1;

      //console.log('c l', currentIsMobile, lastIsMobile);
      if (lastIsMobile !== currentIsMobile) {
        //window.location.reload();
      }
    }
  };

  // eslint-disable-next-line
  const findOpenKeys = (
    menus: SMenu[],
    route: string,
    openKeys: string[]
  ): boolean => {
    for (let i = 0; i < menus.length; i++) {
      if (menus[i].key && 'string' === typeof menus[i].key) {
        const key = menus[i].key as string;

        if (openKeys.indexOf(key) === -1) {
          openKeys.push(key);
        }

        if (route && route === menus[i].route) {
          if (selectedKeys.indexOf(key) === -1) {
            setSelectedKeys([key]);
          }

          if (openKeys.indexOf(key) > -1) {
            openKeys.pop();
          }

          const keys = JSON.parse(JSON.stringify(openKeys));

          menuKeyDict[route] = keys.concat([key]);
          //console.log('cache route', route, keys.concat([key]));

          return true;
          //} else if (menus[i].children) {
        } else if (menus[i].items) {
          //if (findOpenKeys(menus[i].children as SMenu[], route, openKeys)) {
          if (findOpenKeys(menus[i].items as SMenu[], route, openKeys)) {
            return true; // note: false can not be returned from here
          }
        }

        openKeys.pop();
      }
    }

    return false;
  };

  const getPageExitConfirmMessage = (path: string) => {
    const ret = exitConfirmMessage[path] || defaultExitConfirmMessage;

    return ret;
  };

  const handleOpenChange = (keys: any[]) => {
    const rawLength = openKeys.length;
    setOpenKeys(keys as string[]);

    if (keys.length > rawLength) {
      setTimeout(() => {
        scrollLastMenuIntoView(keys);
      }, 500);
    }
  };

  const handleSelectMenuItem = (key: string) => {
    if (key) {
      if (selectedKeys.indexOf(key) === -1) {
        setSelectedKeys([key]);
      }
    }
  };

  /**
   * Do things before routing.
   */
  const readyToRoute = (evt: any, route: any) => {
    if (currentPath === route) {
      evt.preventDefault();
      evt.stopPropagation();
    } else if (pageIsHeld) {
      Modal.confirm({
        title: 'Confirm to exit current page',
        icon: <ExclamationCircleOutlined />,
        content: getPageExitConfirmMessage(currentPath),
        okText: 'Confirm',
        cancelText: 'Cancel',
        onOk(close) {
          close();
          dispatch(holdCurrentPage(false));

          if ('string' === typeof route) {
            history.push(route);
          }
        },
      });
      evt.preventDefault();
      evt.stopPropagation();
    }
  };

  const renderMenu = (menu: SMenu) => {
    //if (menu.children) {
    if (menu.items) {
      return (
        <SubMenu
          key={menu.key}
          icon={menu.icon || ''}
          title={<LabelName>{menu.name}</LabelName>}
        >
          {/*menu.children.map((e) => renderMenu(e))*/}
          {menu.items.map((e) => renderMenu(e))}
        </SubMenu>
      );
    } else {
      return (
        <MenuItem key={menu.key}>
          {menu.icon || ''}
          <span>
            <LabelName className="submenu-item">{menu.name}</LabelName>
          </span>
          {menu.route ? (
            <Link
              to={menu.route}
              onClick={(evt) => readyToRoute(evt, menu.route)}
            />
          ) : (
            ''
          )}
        </MenuItem>
      );
    }
  };

  const scrollLastMenuIntoView = (keys: string[]) => {
    const uls = siderbarRef?.current.querySelectorAll('ul.ant-menu-sub');
    const id = keys[keys.length - 1];

    for (let i = 0; i < uls.length; i++) {
      if (uls[i].id.indexOf(id) > -1) {
        uls[i].scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  };

  // eslint-disable-next-line
  const selectMenuFromCache = (path: string) => {
    const fields = path.split('/').filter((e) => e);
    let isCached = false;

    // note: the url '/' will not be test in cache
    while (fields.length > 0) {
      const url = `/${fields.join('/')}`;
      const cache = menuKeyDict[url];

      if (cache) {
        const keys = JSON.parse(JSON.stringify(cache));
        let needOpen = false;
        const openedIds = [...openKeys];
        const select = keys.pop();

        keys.forEach((key: string) => {
          if (openedIds.indexOf(key) === -1) {
            openedIds.push(key);
            needOpen = true;
          }
        });

        if (needOpen) {
          setOpenKeys(openedIds);
        }

        if (selectedKeys.indexOf(select) === -1) {
          setSelectedKeys([select]);
        }

        isCached = true;

        break;
      }

      fields.pop();
      //console.log('loop', fields);
    }

    return isCached;
  };

  const selectMenuItem = React.useCallback(() => {
    const { pathname } = window.location;
    const path = getMenuPath(history.location.pathname);
    const isCached = selectMenuFromCache(path);

    checkUrlShowMode(pathname);
    dispatch(setUrlPath(pathname));
    setCurrentPath(path);

    if (!isCached) {
      const keys: string[] = [...openKeys];

      findOpenKeys(menuList, path, keys);

      if (keys.length > 0) {
        setOpenKeys(keys);
      }
    }
  }, [
    menuList,
    checkUrlShowMode,
    dispatch,
    findOpenKeys,
    history,
    openKeys,
    selectMenuFromCache,
  ]);

  // eslint-disable-next-line
  // const setSidebarWidth = () => {
  //   const w = window.innerWidth;
  //   let width = DEFAULT_SIDERBAR_WIDTH;

  //   if (collapsed) {
  //     width = SIDEBAR_COLLAPSED_WIDTH;
  //   } else {
  //     if (w < 1200) {
  //       width = 200;
  //     } else if (w < 1600) {
  //       width = 236;
  //     }
  //   }

  //   dispatch(setSiderWidth(width));
  //   dispatch(setPageHeight(window.innerHeight));
  //   dispatch(setPageWidth(window.innerWidth));
  // };


  const isfilterItem = (menu: SMenu) => {
    let flag = false;
    switch (menu.route) {
      case '/sales/white-list': {
        const value = userProfiles.getProfileSettingValue('DuplicateOrder');
        if (value === '1') {
          flag = true;
        }
        break;
      };
      case '/return/restock': {
        const value = userProfiles.getProfileSettingValue('ReturnAutoComplete');
        if (value === '1') {
          flag = true;
        }
        break;
      };
      case '/catalog/lot-Management':
      case '/administration/lot-settings': {
        const value = userProfiles.getProfileSettingValue('EnableLOT');
        if (value !== '1') {
          flag = true;
        }
        break;
      };
      case '/administration/prepack-settings': {
        const value = userProfiles.getProfileSettingValue('EnablePrepack');
        if (value === '0') {
          flag = true;
        }
        break;
      };
      case '/pre-pack': {
        const value = userProfiles.getProfileSettingValue('EnablePrepack');
        if (value === '0') {
          flag = true;
        }
        break;
      };
      default:
        break;
    }

    return flag;
  }

  const filterMenuItems = (menuList: SMenu[]) => {
    const temp: SMenu[] = [];
    menuList.forEach(async item => {
      if (item.items && item.items.length > 0) {
        const subTemp: SMenu[] = [];
        item.items.forEach(async subItem => {
          if (!isfilterItem(subItem)) {
            subTemp.push(subItem);
          }
        });
        item.items = subTemp;
      };

      if (!isfilterItem(item)) temp.push(item);
    })
    if (isMobile) temp.unshift({
      name: 'Smart',
      route: '/smart',
      icon: <MobileOutlined />
    })
    setMenuList(temp);
  }

  React.useEffect(() => {
    if (menuList.length > 0) {
      if (!inited) {
        filterMenuItems(menuList);
        selectMenuItem();
        // window.addEventListener('resize', setSidebarWidth, false);
        setInited(true);
      }

      // setSidebarWidth();

      return history.listen(selectMenuItem);
    }
    // eslint-disable-next-line
  }, [menuList, history, inited, selectMenuItem]);

  const PlatFormButton = ({ color = 'white', children = '', ...other }) => {
    return (
      <Button type="link" style={{ display: 'flex', alignItems: 'center', padding: '4px 4px' }} {...other}>
        {children === 'PIM' && <PIMSVG stroke={color} />}
        {children === 'ERP' && <ERPSVG stroke={color} />}
        {children === 'WMS' && <WMSSVG stroke={color} />}
        <Typography.Text style={{ color, fontSize: 14, marginLeft: 2 }}>{children}</Typography.Text>
      </Button>
    )
  }

  const nameContainerClassName = () => {
    const cls: string[] = [];

    if (collapsed) {
      cls.push('collapse-ctn');
    }

    return cls.join(' ');
  };

  const sidebarClassName = () => {
    const cls: string[] = ['custom-scroll-bar-menu'];

    if (collapsed) {
      cls.push('collapse-sidebar');
    }

    return cls.join(' ');
  };

  return (
    <SiderContainer
      collapsed={collapsed}
      width={230}
      className={sidebarClassName()}
      trigger={null}
      collapsible
      style={{display: pageShowMode === PAGE_MODE_FULL_PAGE ? 'none': 'block'}}
      ref={siderbarRef}
    >
      <LogoContainer>
      <div style={menuUnfoldCtnStyle}>
          <MenuUnfoldOutlined
            onClick={()=> dispatch(collapseSider())}
            style={{cursor: 'pointer'}}
          />
        </div>
        <LogoHeader>
          <a href={process.env.CMC_LINK}>
            <img
              alt="logo"
              //src="https://appdev.digitbridge.com/Azure-AD-B2C-page-templates/assets/images/logo.svg"
              src={siteCfg.logo185}
            />
          </a>
          <div style={logoTextStyle}>
            <div style={{ lineHeight: 2, whiteSpace: 'nowrap' }}>
              <Typography.Text style={{ fontSize: 14, fontWeight: 600, color: '#F9F5E7' }}>{siteCfg.name}</Typography.Text>
            </div>
          </div>
          <div style={menuFoldCtnStyle}>
            <MenuFoldOutlined
              onClick={()=> dispatch(collapseSider())}
              style={{cursor: 'pointer'}}
            />
          </div>
        </LogoHeader>
        <div
          style={
            collapsed
              ? { opacity: 0, height: 0, display: 'none' }
              : {
                // padding: '2px 20px 0px 20px',
                minWidth: 180,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }
          }
        >
          <PlatFormButton href={process.env.PIM_LINK} target="_blank">PIM</PlatFormButton>
          <PlatFormButton href={process.env.ERP_LINK} target="_blank">ERP</PlatFormButton>
          <Link to={'/'}>
            <PlatFormButton
              color={wmsType ? '#E89A26' : 'white'}
              onClick={() => {
                setWmsType(true);
              }}
            >WMS</PlatFormButton>
          </Link>
        </div>
      </LogoContainer>
      <SystemNameContainer style={{backgroundColor: "#4F772D"}} className={nameContainerClassName()}>
        <span className="app-icon-ctn">
          <WMSBGSVG width={40} height={40} />
        </span>
        {!collapsed && <span className="app-name">&nbsp;WMS</span>}
      </SystemNameContainer>
      <Menu
        theme="dark"
        mode="inline"
        onOpenChange={handleOpenChange}
        onSelect={({ key }) => handleSelectMenuItem(key as string)}
        openKeys={openKeys}
        selectedKeys={selectedKeys}
      >
        {menuList.map((e) => renderMenu(e))}
      </Menu>
    </SiderContainer>
  );
}

export default Sidebar;
