import React, { useEffect, useCallback, useState, useRef } from 'react';
import { ModalProps } from 'antd/lib/modal/Modal';
import ModalDialog from '../../../components/common/ModalDialog';
import {
  DeleteOutlined,
  SettingFilled,
  CaretDownOutlined,
  EditOutlined,
  ClearOutlined,
  LeftOutlined,
  StopOutlined,
  // ExclamationCircleOutlined,
  CheckCircleOutlined,
  RightOutlined,
  CloseOutlined,
  SaveOutlined,
  DiffOutlined,
} from '@ant-design/icons';
import {
  CopySpan,
  DataGridPlus,
  FormLabel,
  InputInteger,
  message,
  Locker,
} from '../../../components/common';
import {
  Card,
  Form,
  Row,
  Col,
  Select,
  // Space,
  Tooltip,
  Button,
  Input,
  Divider,
  Dropdown,
  Menu,
  Space,
  Modal,
  Tabs,
  Typography,
  Spin,
} from 'antd';
import {
  receiveReturn,
  getOrderReturnReceiveList,
  fetchReturns,
  closeReturn,
} from 'services/return';
import debounce from 'lodash.debounce';
import {
  getWarehouseIdFromCache,
  convertTimeByUtc,
  getWarehouseCodeFromCache,
} from 'utils';
import EditQtyDialog from './EditDialog';
import { playAudio } from 'utils';
import {
  COMMON_PADDING_SPACE,
  LS_DEFAULT_WAREHOUSE_KEY,
} from 'constants/config';
import theme from 'assets/styles/theme';

type TempLineType = {
  fulfillmentReturnLineNum: number;
  sku: string;
  upc: string;
  productTitle: string;
  receiveWarehouseType: number;
  receiveWarehouseNum: number;
  receiveLocationNum: number;
  receiveQty: number;
};

interface Props extends ModalProps {
  onClose: Function;
  modalReturn: any;
  specialInfo: any;
  defaultWarehouseType: number;
  defaultLocationCode: string;
  receiveDisabled: boolean;
  warehouseId: string;
}

const warehouseTypeKeyValue: StringKVPair = {
  '2': 'Resalable',
  '3': 'Damage',
  '4': 'Inspection',
};
const statusEnum: { [key: string]: string } = {
  '0': 'Pending',
  '1': 'Processing',
  '2': 'Completed',
  '8': 'Abandoned'
};

const ProductDetailDialog = (props: Props) => {
  const {
    modalReturn,
    specialInfo,
    defaultWarehouseType,
    defaultLocationCode,
    warehouseId,
    receiveDisabled
  } = props;
  const [detailDialogIsFullscreen, setDetailDialogIsFullscreen] =
    useState(false);
  // const [loading, setLoading] = useState(false);
  // const [querying, setQuerying] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [queryLoading, setQueryLoading] = useState(false);
  const refreshBtnRef = React.useRef<any>(null);
  const [selected, setSelected] = useState<number>(defaultWarehouseType);
  const [qtyLocker, setQtyLocker] = useState(true);
  const [warehouseLocker, setWarehouseLocker] = useState(true);
  const [editQtyDialogVisible, setEditQtyDialogVisible] = useState(false);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [current, setCurrent] = useState<any>();
  // const [currentProduct, setCurrentProduct] = useState<any>();
  const [filterValue, setFilterValue] = useState<string>();
  const [returnInfo, setReturnInfo] = useState<any>(); //return info
  const [tempLines, setTempLines] = useState<TempLineType[]>([]); //
  const [tempFilterLines, setTempFilterLines] = useState<TempLineType[]>([]);
  const [historyLines, setHistoryLines] = useState<any[]>([]);
  const [filterHistoryLines, setFilterHistoryLines] = useState<any[]>([]);
  const [lines, setLines] = useState<any>([]);
  const [filterlines, setFilterlines] = useState<any>([]);
  const [initing, setIniting] = useState(false);
  const channelOrderIdSearchRef = useRef('');
  const invoiceNoSearchRef = useRef('');
  const returnNoSearchRef = useRef('');
  const [showAll, setShowAll] = useState(true);
  const [showHistory, setShowHistory] = useState(false);

  const [form] = Form.useForm();

  const closeProductDetailDialog = () => {
    if (tempLines.length > 0) {
      Modal.confirm({
        title: 'Do you want to leave this page？',
        okText: 'No',
        cancelText: 'Yes',
        cancelButtonProps: { className: 'hover-danger-button' },
        content: `Changes you made may not be saved.`,
        onCancel() {
          props.onClose();
        },
      });
    } else {
      props.onClose();
    }
  };

  const debounceFn = debounce((val: string) => {
    setFilterValue(val);
  }, 300);

  const detailDialogWidth = useCallback(
    (isFullscreen = detailDialogIsFullscreen) => {
      return isFullscreen
        ? window.innerWidth - 4
        : window.innerWidth > 1280
          ? window.innerWidth * 0.8
          : 1200;
    },
    [detailDialogIsFullscreen]
  );

  const getDialogDom = () => {
    const refreshBtn = refreshBtnRef?.current;

    if (refreshBtn) {
      return refreshBtn.parentNode.parentNode.parentNode.parentNode.parentNode
        .parentNode.parentNode;
    }

    return null;
  };

  const onFullscreen = (isFullscreen: boolean) => {
    const dialog = getDialogDom();

    setDetailDialogIsFullscreen(isFullscreen);

    if (dialog) {
      const width = detailDialogWidth(isFullscreen);

      dialog.style.width = `${width}px`;
    }
  };

  const clearForm = useCallback(() => {
    form.setFieldsValue({ sku: undefined });
    // setCurrentProduct(undefined);
    if (!qtyLocker) {
      form.setFieldsValue({ qty: 1 });
    }
    if (!warehouseLocker) {
      form.setFieldsValue({ warehouseType: `${defaultWarehouseType}` });
    }
  }, [form, qtyLocker, warehouseLocker, defaultWarehouseType]);

  const delReceivingLine = useCallback(
    (l: any) => {
      const index = tempLines.findIndex(
        (i: any) =>
          i.fulfillmentReturnLineNum === l.fulfillmentReturnLineNum &&
          i.receiveLocationNum === l.receiveLocationNum
      );
      if (index > -1) {
        const idx = lines.findIndex(
          (i: any) => i.fulfillmentReturnLineNum === l.fulfillmentReturnLineNum
        );
        const tLine = [...lines];
        tLine[index].receivingQty =
          tLine[idx].receivingQty - tempLines[index].receiveQty;
        const temp = [...tempLines];
        temp.splice(index, 1);
        setTempLines([...temp]);
        setLines([...tLine]);
        setDeleteDialogVisible(false);
        message.success({ content: 'Deleted successfully' });
      }
    },
    [tempLines, lines]
  );

  const editReceivingLine = useCallback(
    (l: any) => {
      const index = tempLines.findIndex(
        (i: any) =>
          i.fulfillmentReturnLineNum === l.fulfillmentReturnLineNum &&
          i.receiveLocationNum === l.receiveLocationNum
      );
      if (index > -1) {
        const idx = lines.findIndex(
          (i: any) => i.fulfillmentReturnLineNum === l.fulfillmentReturnLineNum
        );
        const tLine = [...lines];
        tLine[index].receivingQty =
          tLine[idx].receivingQty - tempLines[index].receiveQty + l.receiveQty;
        const temp = [...tempLines];
        temp[index] = { ...l };
        // temp.splice(index, 1)
        setTempLines([...temp]);
        setLines([...tLine]);
        setEditQtyDialogVisible(false);
        message.success({ content: 'Edited successfully' });
      }
    },
    [tempLines, lines]
  );

  const getTempLineColumns = useCallback(
    () => [
      { name: 'id', type: 'string', header: 'Id', defaultVisible: false },
      {
        name: 'sku',
        header: 'SKU',
        minWidth: 150,
        showColumnMenuTool: false,
        userSelect: true,
        defaultFlex: 1,
      },
      {
        name: 'upc',
        header: 'UPC',
        showColumnMenuTool: false,
        userSelect: true,
        defaultFlex: 1,
      },
      {
        name: 'receiveQty',
        header: 'Receive Qty',
        minWidth: 130,
        maxWidth: 130,
        showColumnMenuTool: false,
        userSelect: true,
        defaultFlex: 1,
        type: 'number',
      },
      {
        name: 'receiveWarehouseType',
        header: 'Marked As',
        minWidth: 70,
        showColumnMenuTool: false,
        sortable: true,
        userSelect: true,
        render: (value: any) => {
          const { data } = value;
          return warehouseTypeKeyValue[data.receiveWarehouseType];
        },
      },
      {
        header: 'Action',
        name: 'action',
        showColumnMenuTool: false,
        defaultLocked: 'end',
        sortable: false,
        maxWidth: 100,
        minWidth: 100,
        defaultFlex: 1,
        render: (value: any) => {
          const { data, rowIndex } = value;
          return (
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key="0">
                    <Button
                      id={`bulk_return_receive_grid_action_row_${rowIndex}_dropdown_details`}
                      type="text"
                      style={{ width: '100%', textAlign: 'left' }}
                      icon={<EditOutlined />}
                      onClick={() => {
                        setCurrent(data);
                        setEditQtyDialogVisible(true);
                      }}
                    >
                      Edit
                    </Button>
                  </Menu.Item>
                  <Menu.Item key="1">
                    <Button
                      id={`bulk_return_receive_grid_action_row_${rowIndex}_dropdown_delete`}
                      type="text"
                      // disabled={data.receiveQty !== 0}
                      style={{ width: '100%', textAlign: 'left' }}
                      className="hover-danger-button-2"
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        setCurrent(data);
                        setDeleteDialogVisible(true);
                      }}
                    >
                      Delete
                    </Button>
                  </Menu.Item>
                </Menu>
              }
              trigger={['click']}
            >
              <Button
                id={`catalog_grid_action_row_${rowIndex}_dropdown`}
                icon={<SettingFilled />}
              >
                <CaretDownOutlined />
              </Button>
            </Dropdown>
          );
        },
      },
    ],
    []
  );

  const getHistoryLineColumns = useCallback(
    () => [
      {
        name: 'returnReceiveNum',
        type: 'string',
        header: 'Id',
        defaultVisible: false,
      },
      {
        name: 'sku',
        header: 'SKU',
        minWidth: 150,
        showColumnMenuTool: false,
        userSelect: true,
        defaultFlex: 1,
      },
      {
        name: 'returnReceiveType',
        header: 'Marked As',
        minWidth: 70,
        showColumnMenuTool: false,
        sortable: true,
        userSelect: true,
        render: (value: any) => {
          const { data } = value;
          return warehouseTypeKeyValue[data.returnReceiveType];
        },
      },
      {
        name: 'receiveQty',
        header: 'Qty',
        minWidth: 130,
        maxWidth: 130,
        showColumnMenuTool: false,
        sortable: true,
        userSelect: true,
        type: 'number',
        defaultFlex: 1,
      },
      {
        name: 'warehouseCode',
        header: 'Warehouse',
        minWidth: 70,
        showColumnMenuTool: false,
        userSelect: true,
        defaultFlex: 1,
      },
    ],
    []
  );

  const fetchHistory = useCallback(async () => {
    //check history
    if (returnInfo?.receiveQty === 0) {
      setShowHistory(false);
      return;
    }
    setShowHistory(true);
    try {
      const res = await getOrderReturnReceiveList(
        getWarehouseIdFromCache(returnInfo.warehouseNum),
        returnInfo.fulfillmentReturnNum
      );
      if (res) {
        setHistoryLines(res);
      }
    } catch (error) { }
  }, [returnInfo]);

  const getTransferByValue = useCallback(
    async (poNumberSearchValue: string, selectSearchType: string) => {
      if (!poNumberSearchValue.trim()) return;
      try {
        const queryparams = {
          $skip: 0,
          $top: 10,
          $sortBy: 'TransferNum desc',
          channelOrderId:
            selectSearchType === 'channelOrderId'
              ? poNumberSearchValue
              : undefined,
          returnNo:
            selectSearchType === 'returnNo' ? poNumberSearchValue : undefined,
          invoice:
            selectSearchType === 'invoiceNo' ? poNumberSearchValue : undefined,
          oper: 0,
        };
        setQueryLoading(true);
        const transferList = await fetchReturns(warehouseId, queryparams);
        setQueryLoading(false);

        if (transferList.data.length === 0) {
          return message.warning({ content: 'No data matched' });
        }
        if (transferList.data[0].status === 2) {
          return message.warning({ content: 'This Return Order is closed' });
        } else {
          setReturnInfo(transferList.data[0]);
          setLines(
            transferList.data[0].items.map((i: any) => {
              return { ...i, receivingQty: 0 };
            })
          );
          //todo fetch history
          fetchHistory();
        }
      } catch (err) {
        setQueryLoading(false);
      }
    },
    [warehouseId, fetchHistory]
  );

  const handleSave = useCallback(async () => {
    try {
      setSaveLoading(true);
      const res = await receiveReturn(
        getWarehouseIdFromCache(returnInfo.warehouseNum),
        returnInfo.fulfillmentReturnNum,
        tempLines
      );
      setSaveLoading(false);
      if (res) {
        // message.success({ content: 'receive successful' });
        // Modal.confirm({
        //   title: 'Received Successfully!',
        //   icon: <CheckCircleOutlined />,
        //   content: '',
        //   okText: 'Next Return',
        //   okType: 'default',
        //   cancelText: 'Close',
        //   autoFocusButton: 'cancel',
        //   onOk() {
        //     setReturnInfo(undefined);
        //     setHistoryLines([]);
        //     setLines([]);
        //     setTempLines([]);
        //   },
        //   onCancel() {
        //     props.onClose();
        //   },
        // });

        Modal.info({
          title: 'Received Successfully!',
          icon: <CheckCircleOutlined />,
          content: '',
          okText: 'Close',
          okType: 'default',
          onOk() {
            props.onClose();
          },
        });

        // todo refresh
      }
    } catch (error) {
      setSaveLoading(false);
    }
  }, [returnInfo, tempLines, props]);

  const queryProduct = useCallback(async () => {
    const params = await form.getFieldsValue();
    if (!params.sku.trim()) {
      return message.warning({ content: 'invaild SKU/UPC' });
    }
    const res = lines.filter(
      (i: any) => i.sku === params.sku.trim() || i.upc === params.sku.trim()
    );
    if (res && res.length > 0) {
      // setCurrentProduct(res[0]);
      const index = lines.findIndex((item: any) => item.sku === res[0].sku);
      if (index > -1) {
        setLines((prev: any) => {
          const temp = prev;
          temp.unshift(temp.splice(index, 1)[0]);
          return [...temp];
        });
      }
    } else {
      message.info({ content: `[${params.sku}] not found` });
      form.setFieldsValue({ sku: undefined });
    }
  }, [form, lines]);

  const addSubmit = useCallback(async () => {
    if (returnInfo.returnQty === returnInfo.receiveQty) return;
    const params = await form.validateFields();
    if (params.errorFields) return;
    if (!params.sku.trim()) {
      return message.warning({ content: 'invaild SKU/UPC' });
    }

    setShowAll(false);

    const filterList = lines.filter(
      (i: any) =>
        i.sku.trim() === params.sku.trim() ||
        (i.upc || '').trim() === params.sku.trim()
    );
    if (filterList.length > 0) {
      // fulfillmentReturnLineNum
      let totalQty = 0;
      filterList.map((i: any) => {
        totalQty = totalQty + i.returnQty - i.receiveQty - i.receivingQty;
        return true;
      });
      if (totalQty < Number(params.qty)) {
        playAudio('error');
        return message.info({ content: 'Cannot exceed Claim Qty' });
      }
      const tempLoc = specialInfo[params.warehouseType]
        ? (specialInfo[params.warehouseType].locations || []).find(
          (i: any) => i.locationCode === defaultLocationCode
        )
        : undefined;

      let remainingQty = Number(params.qty);
      filterList.map((i: any) => {
        if (remainingQty > 0) {
          const qty = i.returnQty - i.receiveQty - i.receivingQty;
          if (qty > 0) {
            //need line
            const index = lines.findIndex(
              (k: any) =>
                i.fulfillmentReturnLineNum === k.fulfillmentReturnLineNum
            );

            const receivingIndex = tempLines.findIndex(
              (item: any) =>
                item.fulfillmentReturnLineNum === i.fulfillmentReturnLineNum &&
                item.receiveWarehouseType === params.warehouseType
            );
            if (receivingIndex > -1) {
              setTempLines((prev) => {
                const temp = [...prev];
                temp[receivingIndex] = {
                  ...temp[receivingIndex],
                  receiveQty:
                    temp[receivingIndex].receiveQty +
                    (qty > remainingQty ? remainingQty : qty),
                };
                temp.unshift(temp.splice(receivingIndex, 1)[0]);
                return [...temp];
              });
            } else {
              setTempLines((prev: any) => {
                return [
                  {
                    fulfillmentReturnLineNum:
                      lines[index].fulfillmentReturnLineNum,
                    sku: lines[index].sku,
                    upc: lines[index].upc,
                    receiveWarehouseType: params.warehouseType,
                    receiveWarehouseNum:
                      specialInfo[params.warehouseType].warehouseNum,
                    receiveLocationNum: specialInfo[params.warehouseType].defaultLocationNum || tempLoc?.locationNum,
                    receiveQty: qty > remainingQty ? remainingQty : qty,
                  },
                  ...prev,
                ];
              });
            }
            setLines((prev: any) => {
              const temp = prev;
              temp[index].receivingQty =
                temp[index].receivingQty +
                (qty > remainingQty ? remainingQty : qty);
              temp.unshift(temp.splice(index, 1)[0]);
              return [...temp];
            });
            remainingQty = qty > remainingQty ? 0 : remainingQty - qty;
          }
        }
        return true;
      });
      playAudio('success');
      clearForm();
    } else {
      playAudio('error');
      message.info({ content: `[${params.sku}] not found` });
      form.setFieldsValue({ sku: undefined });
      // setCurrentProduct(undefined);
    }

    // const index = lines.findIndex(
    //   (i: any) => i.sku === params.sku.trim() || i.upc === params.sku.trim()
    // );

    // if (index > -1) {
    //   //check qty
    //   if (
    //     lines[index].returnQty -
    //     lines[index].receiveQty -
    //     lines[index].receivingQty <
    //     params.qty
    //   ) {
    //     playAudio('error');
    //     return message.info({ content: 'Cannot exceed Claim Qty' });
    //   }
    //   // todo add to lines
    //   const tempLoc = specialInfo[params.warehouseType]
    //     ? (specialInfo[params.warehouseType].locations || []).find(
    //       (i: any) => i.locationCode === defaultLocationCode
    //     )
    //     : undefined;

    //   const receivingIndex = tempLines.findIndex(
    //     (item: any) =>
    //       item.sku === lines[index].sku &&
    //       item.receiveWarehouseType === params.warehouseType
    //   );

    //   if (receivingIndex > -1) {
    //     setTempLines((prev) => {
    //       const temp = [...prev];
    //       temp[receivingIndex] = {
    //         ...temp[receivingIndex],
    //         receiveQty: temp[receivingIndex].receiveQty + Number(params.qty),
    //       };
    //       temp.unshift(temp.splice(receivingIndex, 1)[0]);
    //       return [...temp];
    //     });
    //   } else {
    //     setTempLines((prev: any) => {
    //       return [
    //         {
    //           fulfillmentReturnLineNum: lines[index].fulfillmentReturnLineNum,
    //           sku: lines[index].sku,
    //           upc: lines[index].upc,
    //           receiveWarehouseType: params.warehouseType,
    //           receiveWarehouseNum:
    //             specialInfo[params.warehouseType].warehouseNum,
    //           receiveLocationNum: tempLoc?.locationNum,
    //           receiveQty: Number(params.qty),
    //         },
    //         ...prev,
    //       ];
    //     });
    //   }
    //   playAudio('success');
    //   setLines((prev: any) => {
    //     const temp = prev;
    //     temp[index].receivingQty =
    //       temp[index].receivingQty + Number(params.qty);
    //     temp.unshift(temp.splice(index, 1)[0]);
    //     return [...temp];
    //   });
    //   clearForm();
    // } else {
    //   playAudio('error');
    //   message.info({ content: `[${params.sku}] not found` });
    //   form.setFieldsValue({ sku: undefined });
    //   // setCurrentProduct(undefined);
    // }
  }, [
    form,
    defaultLocationCode,
    lines,
    tempLines,
    specialInfo,
    clearForm,
    returnInfo,
  ]);

  useEffect(() => {
    if (modalReturn && !initing) {
      setReturnInfo(modalReturn);
      setLines(
        modalReturn.items.map((i: any) => {
          return { ...i, receivingQty: 0 };
        })
      );
      //todo fetch history
      setIniting(true);
    }
  }, [modalReturn, initing]);

  useEffect(() => {
    if (filterValue) {
      const temp = lines.filter(
        (i: any) =>
          (i.sku || '')
            .toLowerCase()
            .indexOf((filterValue || '').trim().toLowerCase()) === 0 ||
          (i.upc || '')
            .toLowerCase()
            .indexOf((filterValue || '').trim().toLowerCase()) === 0
      );
      const tempTemp = tempLines.filter(
        (i: any) =>
          (i.sku || '')
            .toLowerCase()
            .indexOf((filterValue || '').trim().toLowerCase()) === 0 ||
          (i.upc || '')
            .toLowerCase()
            .indexOf((filterValue || '').trim().toLowerCase()) === 0
      );
      const tempHistory = historyLines.filter(
        (i: any) =>
          (i.sku || '')
            .toLowerCase()
            .indexOf((filterValue || '').trim().toLowerCase()) === 0 ||
          (i.upc || '')
            .toLowerCase()
            .indexOf((filterValue || '').trim().toLowerCase()) === 0
      );
      setFilterlines([...temp]);
      setTempFilterLines([...tempTemp]);
      setFilterHistoryLines([...tempHistory]);
    } else {
      setFilterlines([...lines]);
      setTempFilterLines([...tempLines]);
      setFilterHistoryLines([...historyLines]);
    }
  }, [filterValue, lines, historyLines, tempLines]);

  const handleSaveAndClose = async () => {
    try {
      setSaveLoading(true);
      const receiveRes = await receiveReturn(
        getWarehouseIdFromCache(returnInfo.warehouseNum),
        returnInfo.fulfillmentReturnNum,
        tempLines
      );
      if (receiveRes) {
        const closeRes = await closeReturn(
          getWarehouseIdFromCache(returnInfo.warehouseNum),
          returnInfo.fulfillmentReturnNum
        );

        if (closeRes) {
          Modal.info({
            title: 'Received and Completed RMA Successfully!',
            icon: <CheckCircleOutlined />,
            content: '',
            okText: 'Close',
            okType: 'default',
            onOk() {
              props.onClose();
            },
          });
        }
      }
      setSaveLoading(false);
    } catch (error) {
      setSaveLoading(false);
    }
  }

  useEffect(() => {
    if (returnInfo) fetchHistory();
  }, [returnInfo, fetchHistory]);

  useEffect(() => {
    if (returnInfo?.status !== 0) return;
    if (filterlines.length <= 0) return;
    if (tempLines.length === 0) return;
    let receivedQty = 0;
    filterlines.forEach((line: any) => {
      if (line.returnQty === line.receiveQty + line.receivingQty) {
        receivedQty++;
      };
    })

    if (receivedQty === filterlines.length) {
      Modal.confirm({
        closable: false,
        maskClosable: false,
        title: 'All items were received, do you want to Complete this RMA?',
        okText: 'Save As Processing',
        cancelText: 'Complete this RMA',
        cancelButtonProps: {
          className: 'hover-danger-button',
        },
        onOk: () => handleSave(),
        onCancel: () => {
          handleSaveAndClose();
        }
      })
    }
    // eslint-disable-next-line
  }, [filterlines, returnInfo, tempLines])

  return (
    <>
      <ModalDialog
        centered
        className="fullscreen-modal"
        closable={false}
        closeButton={true}
        fullscreen={true}
        maskClosable={false}
        onClose={closeProductDetailDialog}
        onFullscreen={onFullscreen}
        title={`Return / RMA Receive (Warehouse: ${getWarehouseCodeFromCache(
          Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
        )})`}
        visible={props.visible}
        destroyOnClose={true}
        width={detailDialogWidth()}
        footer={
          <Row justify="end">
            <Space>
              <Button onClick={closeProductDetailDialog} icon={<CloseOutlined />}>
                Cancel
              </Button>
              <Button
                type="primary"
                disabled={tempLines.length === 0}
                loading={saveLoading}
                onClick={handleSave}
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Space>
          </Row>
        }
      >
        <div style={{ height: '100%', width: '100%' }}>
          {returnInfo && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: '100%',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                // backgroundColor: '#fff',
                backgroundColor: theme['@default-backgroud-color'],
              }}
            >
              <div
                style={{
                  display: 'flex',
                  width: 450,
                  minHeight: 580,
                  height: '100%',
                  // borderRightWidth: 1,
                  borderRadius: `${theme['@border-radius-base']}`,
                  // borderRightColor: ' rgba(0, 0, 0, 0.15)',
                  // borderRightStyle: 'solid',
                  marginRight: COMMON_PADDING_SPACE,
                }}
              >
                <Card size="small" bordered={false}>
                  <Spin
                    spinning={returnInfo.returnQty === returnInfo.receiveQty}
                    indicator={<StopOutlined style={{ fontSize: 20 }} />}
                    tip="All items have been received."
                  >
                    <Tabs
                      onChange={(key) => {
                        if (key === 'item-1') {
                          setFilterValue('');
                        }
                      }}
                      destroyInactiveTabPane={true}
                    >
                      <Tabs.TabPane tab="Add" key="item-1" style={{ overflowX: 'hidden' }}>
                        <Form form={form}>
                          <Form.Item
                            label={
                              <div style={{ width: 110, textAlign: 'left' }}>
                                <FormLabel require>SKU / UPC</FormLabel>
                              </div>
                            }
                            rules={[{ required: true }]}
                          >
                            <Row gutter={8} align="middle">
                              <Col span={20}>
                                <Form.Item
                                  noStyle
                                  name="sku"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please input SKU / UPC!',
                                    },
                                  ]}
                                >
                                  <Input
                                    disabled={receiveDisabled}
                                    tabIndex={0}
                                    onPressEnter={() => {
                                      if (qtyLocker && warehouseLocker) {
                                        addSubmit();
                                      } else {
                                        queryProduct();
                                      }
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.keyCode === 9) {
                                        if (qtyLocker && warehouseLocker) {
                                          addSubmit();
                                        } else {
                                          queryProduct();
                                        }
                                      }
                                    }}
                                  />
                                </Form.Item>
                              </Col>

                              <Col span={4}></Col>
                            </Row>
                          </Form.Item>

                          {/* {currentProduct && (
                          <Form.Item
                            label={
                              <div
                                style={{
                                  width: 110,
                                  textAlign: 'left',
                                  paddingLeft: 7,
                                }}
                              >
                                <FormLabel>Title</FormLabel>
                              </div>
                            }
                          >
                            <CopySpan
                              needToolTip
                              value={currentProduct.productTitle}
                            />
                          </Form.Item>
                        )} */}

                          <Form.Item
                            label={
                              <div style={{ width: 110, textAlign: 'left' }}>
                                <FormLabel require>Quantity</FormLabel>
                              </div>
                            }
                          >
                            <Row gutter={8} align="middle">
                              <Col span={20}>
                                <Form.Item
                                  noStyle
                                  name="qty"
                                  initialValue={1}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please input Quantity!',
                                    },
                                  ]}
                                >
                                  <InputInteger
                                    isTabwork={!qtyLocker}
                                    width={186}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={4}>
                                <Locker
                                  size={16}
                                  value={qtyLocker}
                                  onChange={(value: boolean) => {
                                    setQtyLocker(value);
                                  }}
                                />
                              </Col>
                            </Row>
                          </Form.Item>

                          <Form.Item
                            label={
                              <div style={{ width: 110, textAlign: 'left' }}>
                                <FormLabel require>Marked as</FormLabel>
                              </div>
                            }
                            // extra={`Put it into Warehouse: ${specialInfo[selected] &&
                            //   specialInfo[selected].warehouseCode
                            //   }`}
                            extra={
                              !receiveDisabled ? 
                              specialInfo[selected]&&specialInfo[selected].warehouseCode && specialInfo[selected].defaultLocationCode ? 
                                `Put it into Location(warehouse) : ${specialInfo[selected].defaultLocationCode} (${specialInfo[selected].warehouseCode})`
                                : `Put it into Location(warehouse) : ${defaultLocationCode} (${specialInfo[selected].warehouseCode})`
                              : ''
                              }
                          >
                            <Row gutter={8} align="middle">
                              <Col span={20}>
                                <Form.Item
                                  noStyle
                                  name="warehouseType"
                                  initialValue={`${defaultWarehouseType}`}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please select marked as!',
                                    },
                                  ]}
                                >
                                  <Select
                                    tabIndex={warehouseLocker ? -1 : 0}
                                    onChange={(value: number) => {
                                      setSelected(value);
                                    }}
                                  >
                                    {Object.keys(specialInfo).map((item) => (
                                      <Select.Option key={item} value={item}>
                                        {warehouseTypeKeyValue[item]}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col span={4}>
                                <Locker
                                  size={16}
                                  value={warehouseLocker}
                                  onChange={(value: boolean) => {
                                    setWarehouseLocker(value);
                                  }}
                                />
                              </Col>
                            </Row>
                          </Form.Item>
                          <Row
                            justify="center"
                            style={{ marginTop: 20, marginBottom: 20 }}
                          >
                            <Space>
                              <Button
                                type="primary"
                                onClick={addSubmit}
                                icon={<SaveOutlined />}
                              >
                                Submit
                              </Button>
                              <Button onClick={clearForm}>
                                <ClearOutlined />
                                Clear
                              </Button>
                            </Space>
                          </Row>
                        </Form>
                      </Tabs.TabPane>
                      <Tabs.TabPane tab="Adjustment" key="item-2">
                        <Form.Item
                          label={
                            <div style={{ width: 110, textAlign: 'left' }}>
                              <FormLabel>SKU / UPC</FormLabel>
                            </div>
                          }
                        >
                          <Input
                            allowClear
                            onChange={(e) => {
                              setShowAll(false);
                              debounceFn(e.target.value);
                              // setFilterValue(e.target.value);
                            }}
                          />
                        </Form.Item>
                      </Tabs.TabPane>
                    </Tabs>
                  </Spin>
                  <Divider orientation="left">RMA Return Info</Divider>
                  <Row>
                    {/* <Col span={12}> */}
                    <Form.Item label={<FormLabel>Channel Order ID</FormLabel>}>
                      <CopySpan value={returnInfo?.channelOrderId || ''} />
                    </Form.Item>
                    {/* </Col> */}
                    {/* <Col span={12}> */}
                    <Form.Item label={<FormLabel>RMA #</FormLabel>}>
                      <CopySpan value={returnInfo?.returnNo || ''} />
                    </Form.Item>
                    {/* </Col> */}
                    {/* <Col span={12}> */}
                    <Form.Item label={<FormLabel>Status</FormLabel>}>
                      <CopySpan
                        value={
                          returnInfo ? statusEnum[`${returnInfo?.status}`] : ''
                        }
                      />
                    </Form.Item>
                    {/* </Col> */}
                    {/* <Col span={12}> */}
                    <Form.Item label={<FormLabel>Return Date</FormLabel>}>
                      <CopySpan
                        value={convertTimeByUtc(returnInfo.returnDate, '', '', 'MM/DD/YYYY')}
                      />
                    </Form.Item>
                    {/* </Col> */}
                  </Row>
                  <Divider />
                  <Row justify="center" style={{ marginTop: 16 }}>
                    <Button
                      onClick={() => {
                        if (tempLines.length > 0) {
                          Modal.confirm({
                            title: 'Do you want to leave this page？',
                            okText: 'No',
                            cancelText: 'Yes',
                            cancelButtonProps: {
                              className: 'hover-danger-button',
                            },
                            content: `Changes you made may not be saved.`,
                            onCancel() {
                              setReturnInfo(undefined);
                              setHistoryLines([]);
                              setLines([]);
                              setTempLines([]);
                            },
                          });
                        } else {
                          setReturnInfo(undefined);
                          setHistoryLines([]);
                          setLines([]);
                          setTempLines([]);
                        }
                      }}
                      icon={<DiffOutlined />}
                    >
                      Receive Other Return
                    </Button>
                  </Row>
                </Card>
              </div>
              {!showAll && (
                <div
                  style={{
                    height: '100%',
                    minHeight: 580,
                    flexGrow: 1,
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingBottom: 8,
                    background: '#fff',
                    borderRadius: theme['@border-radius-base'],
                    marginRight: COMMON_PADDING_SPACE,
                  }}
                >
                  <div
                    style={{
                      height: showHistory ? '50%' : '100%',
                      width: '100%',
                      paddingBottom: 8,
                    }}
                  >
                    <Row align="middle" style={{ paddingTop: 8 }}>
                      <Typography.Text strong>Receving Lines</Typography.Text>
                    </Row>
                    <DataGridPlus
                      id="id"
                      boxStyle={{ height: 'calc(100% - 40px)' }}
                      columns={getTempLineColumns()}
                      dataSource={tempFilterLines.map((i: any) => {
                        return {
                          id: `${i.sku}---${i.receiveWarehouseType}`,
                          ...i,
                        };
                      })}
                      autoWith={false}
                      autoHeight={false}
                      style={{
                        height: detailDialogIsFullscreen
                          ? showHistory
                            ? 'auto'
                            : '100%'
                          : showHistory
                            ? 240
                            : 522,
                      }}
                      selected={
                        tempLines.length > 0 ? { [tempLines[0].sku]: true } : {}
                      }
                      pagination={false}
                      {...({} as any)}
                    />
                  </div>
                  {showHistory && (
                    <div
                      style={{
                        height: '50%',
                        width: '100%',
                        paddingBottom: 10,
                      }}
                    >
                      <Row align="middle">
                        <Typography.Text strong>
                          Receive History
                        </Typography.Text>
                      </Row>
                      <DataGridPlus
                        id="id"
                        boxStyle={{ height: 'calc(100% - 40px)' }}
                        columns={getHistoryLineColumns()}
                        dataSource={filterHistoryLines}
                        autoWith={false}
                        autoHeight={false}
                        style={{
                          height: detailDialogIsFullscreen ? 'auto' : 240,
                        }}
                        // selected={lines.length > 0 ? { [lines[0].sku]: true } : {}}
                        pagination={false}
                        {...({} as any)}
                      />
                    </div>
                  )}
                </div>
              )}

              <div
                style={{
                  display: 'flex',
                  width: showAll ? 'calc(100% - 454px)' : 300,
                  height: '100%',
                  minHeight: 580,
                  backgroundColor: '#fff',
                  borderRadius: theme['@border-radius-base'],
                  // borderLeftWidth: 1,
                  // borderLeftColor: ' rgba(0, 0, 0, 0.15)',
                  // borderLeftStyle: 'solid',
                }}
              >
                <Tooltip placement="right" title={showAll ? 'Close' : 'Open'}>
                  <Button
                    icon={showAll ? <RightOutlined /> : <LeftOutlined />}
                    style={{
                      width: 14,
                      height: 200,
                      alignSelf: 'center',
                    }}
                    onClick={() => setShowAll((prev) => !prev)}
                  />
                </Tooltip>

                <Card
                  title="RMA Return Lines"
                  bordered={false}
                  style={{ width: 'calc(100% - 14px)' }}
                  bodyStyle={{
                    height: 'calc(100% - 70px)',
                    minHeight: 520,
                    width: '100%',
                    overflowY: 'auto',
                  }}
                >
                  {filterlines.map((i: any) => (
                    <Card
                      key={i.sku}
                      size="small"
                      type="inner"
                      headStyle={
                        i.returnQty === i.receiveQty + i.receivingQty
                          ? { backgroundColor: theme['@finished-color'] }
                          : {}
                      }
                      title={
                        // showAll ? (
                        //   <Row>
                        //     <Col span={24}>
                        //       <Row>
                        //         SKU:{' '}
                        //         <span style={{ width: 150, marginLeft: 4 }}>
                        //           <CopySpan inGrid value={i.sku || ''} />
                        //         </span>
                        //       </Row>
                        //     </Col>
                        //     <Col span={8}>
                        //       <Row>
                        //         UPC:{' '}
                        //         <span style={{ width: 150, marginLeft: 4 }}>
                        //           <CopySpan inGrid value={i.upc || ''} />
                        //         </span>
                        //       </Row>
                        //     </Col>
                        //     <Col span={8}>
                        //       <Row>
                        //         Title:{' '}
                        //         <span style={{ width: 150, marginLeft: 4 }}>
                        //           <CopySpan
                        //             inGrid
                        //             value={i.productTitle || ''}
                        //           />
                        //         </span>
                        //       </Row>
                        //     </Col>
                        //   </Row>
                        // ) : (
                        <Row>
                          <Col span={24}>
                            <CopySpan inGrid={!showAll} value={i.sku || ''} />
                          </Col>
                        </Row>
                        // )
                      }
                      // bodyStyle={
                      //   i.returnQty === i.receiveQty + i.receivingQty
                      //     ? { width: '100%', backgroundColor: theme['@finished-color'] }
                      //     : { width: '100%' }
                      // }
                      style={{ width: '100%', marginBottom: 16 }}
                    >
                      {showAll ? (
                        <Row gutter={4}>
                          <Col span={8}>
                            <Row>
                              <span>UPC:&nbsp;</span>
                              <span style={{ width: 'calc(100% - 44px)' }}>
                                <CopySpan value={i.upc || ''} />
                              </span>
                            </Row>
                          </Col>
                          <Col span={8}>
                            <Row>
                              <span>Product Name:&nbsp;</span>
                              <span style={{ width: 'calc(100% - 44px)' }}>
                                <CopySpan inGrid value={i.productTitle || ''} />
                              </span>
                            </Row>
                          </Col>
                          <Col span={8}>
                            <span>{`Claim Qty: ${i.returnQty}`}</span>
                          </Col>
                          <Col span={8}>
                            <span>{`Received Qty: ${i.receiveQty}`}</span>
                          </Col>
                          <Col span={8}>
                            <span>{`Receiving Qty: ${i.receivingQty}`}</span>
                          </Col>
                          <Col span={8}>
                            <span>{`Inspection Qty: ${i.inspectionQty}`}</span>
                          </Col>
                          <Col span={8}>
                            <span>{`Resalable Qty: ${i.stockQty}`}</span>
                          </Col>
                          <Col span={8}>
                            <span>{`Damage Qty: ${i.nonStockQty}`}</span>
                          </Col>
                          <Col span={8}>
                            <span>{`Return Date: ${convertTimeByUtc(
                              i.returnDate,
                              '',
                              '',
                              'MM/DD/YYYY'
                            )}`}</span>
                          </Col>
                          <Col span={8}>
                            <span>{`Reason: ${i.reason}`}</span>
                          </Col>
                          <Col span={8}>
                            <Row>
                              <span>Description:&nbsp;</span>
                              <span style={{ width: 'calc(100% - 80px)' }}>
                                <CopySpan inGrid value={i.description || ''} />
                              </span>
                            </Row>
                          </Col>
                        </Row>
                      ) : (
                        <Row>
                          <Col span={24}>
                            <Row>
                              <span>UPC:&nbsp;</span>
                              <span style={{ width: 'calc(100% - 44px)' }}>
                                <CopySpan value={i.upc || ''} />
                              </span>
                            </Row>
                          </Col>

                          <Col span={24}>
                            <span>{`Claim Qty: ${i.returnQty}`}</span>
                          </Col>
                          <Col span={24}>
                            <span>{`Received Qty: ${i.receiveQty}`}</span>
                          </Col>
                          <Col span={24}>
                            <span>{`Receiving Qty: ${i.receivingQty}`}</span>
                          </Col>
                        </Row>
                      )}
                    </Card>
                  ))}
                </Card>
              </div>
            </div>
          )}

          {!returnInfo && (
            <Card
              style={{
                height: '100%',
              }}
              bodyStyle={{
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Form
                style={{ maxWidth: 1200, width: '80%' }}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 12 }}
              >
                <Row justify="center">
                  <Typography.Title level={2}>RMA Receive</Typography.Title>
                </Row>
                <Form.Item label={<FormLabel>Channel Order ID</FormLabel>}>
                  <Input.Search
                    id="form_search_value_input_channelOrderId"
                    allowClear
                    loading={queryLoading}
                    placeholder="Please input search value"
                    onKeyDown={(e) =>
                      e.keyCode === 9 &&
                      getTransferByValue(
                        channelOrderIdSearchRef.current,
                        'channelOrderId'
                      )
                    }
                    onChange={(e) =>
                      (channelOrderIdSearchRef.current = e.target.value)
                    }
                    onSearch={(value) => {
                      getTransferByValue(value.trim(), 'channelOrderId');
                    }}
                  />
                </Form.Item>
                <Form.Item label={<FormLabel>RMA#</FormLabel>}>
                  <Input.Search
                    id="form_search_value_input_returnNo"
                    allowClear
                    loading={queryLoading}
                    placeholder="Please input search value"
                    onKeyDown={(e) =>
                      e.keyCode === 9 &&
                      getTransferByValue(returnNoSearchRef.current, 'returnNo')
                    }
                    onChange={(e) =>
                      (returnNoSearchRef.current = e.target.value)
                    }
                    onSearch={(value) => {
                      getTransferByValue(value.trim(), 'returnNo');
                    }}
                  />
                </Form.Item>
                <Form.Item label={<FormLabel>Invoice#</FormLabel>}>
                  <Input.Search
                    id="form_search_value_input_invoiceNo"
                    allowClear
                    loading={queryLoading}
                    placeholder="Please input search value"
                    onKeyDown={(e) =>
                      e.keyCode === 9 &&
                      getTransferByValue(
                        invoiceNoSearchRef.current,
                        'invoiceNo'
                      )
                    }
                    onChange={(e) =>
                      (invoiceNoSearchRef.current = e.target.value)
                    }
                    onSearch={(value) => {
                      getTransferByValue(value.trim(), 'invoiceNo');
                    }}
                  />
                </Form.Item>
              </Form>
            </Card>
          )}
        </div>
        {current && deleteDialogVisible && (
          <Modal
            title="Are you sure delete this Row?"
            visible
            onOk={() => {
              delReceivingLine(current);
            }}
            onCancel={() => setDeleteDialogVisible(false)}
            okText="Yes"
            cancelText="No"
          >
            <p>{`SKU: ${current.sku}`}</p>
            <p>{`Mark as ${warehouseTypeKeyValue[current.receiveWarehouseType]
              } `}</p>
            `
          </Modal>
        )}
        {current && editQtyDialogVisible && (
          <EditQtyDialog
            visible
            onHide={() => {
              setEditQtyDialogVisible(false);
            }}
            onSave={(data: any) => {
              editReceivingLine(data);
            }}
            data={current}
            lines={lines}
          />
        )}
      </ModalDialog>
    </>
  );
};

export default ProductDetailDialog;
