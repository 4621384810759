import React, { ReactNode } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
// import { Dispatch } from 'redux';
import { Layout } from 'antd';
import styled from 'styled-components';
import HeaderBar from './HeaderBar';
import theme from 'assets/styles/theme';
import {
  // CONTENTAREA_LEFT_PADDING,
  CONTENTAREA_MARGIN,
  PAGE_MODE_FULL_PAGE,
} from 'constants/config';
import { RootState } from 'reducers';

const { Header, Content } = Layout;

const LayoutContainer = styled(Layout) <{$isMode: boolean, $collapse: boolean }>`
  background-color: ${theme['@default-backgroud-color']};
  transition: margin-left 300ms;
  // width: max-content !important;
  height: 100vh;
  margin-left: ${(props) => props.$isMode? '0px' : props.$collapse ? '80px' : '230px'};
`;

const HeaderContainer = styled(Header)`
  width: 100%;
  height: 55px;
  box-shadow: 0 -1px 8px 0 rgba(125, 140, 166, 0.08);
  border: solid 1px #d8dde6;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5px;
  padding-left: 8px;
`;

// const FolderBox = styled.span`
//   padding: 0 18px;
//   margin-bottom: 5px;

//   & .anticon {
//     font-size: 20px;
//     vertical-align: -8px;
//     cursor: pointer;
//   }
// `;

const ContentContainer = styled(Content)`
  margin: ${theme['@section-margin']}px ${theme['@section-margin']}px ${theme['@section-margin']}px 20px;

  & .ant-input-number-handler-wrap {
    opacity: 0.5;
  }

  & .ant-input-number-handler-wrap:hover {
    opacity: 1;
  }
`;

interface Props {
  children: ReactNode;
}

function ContentLayout({ children }: Props) {
  // const dispatch: Dispatch<any> = useDispatch();
  const collapsed: boolean = useSelector(
    (state: RootState) => state.regular.siderCollapsed,
    shallowEqual
  );
  // const screens = Grid.useBreakpoint();
  const pageShowMode: number = useSelector(
    (state: RootState) => state.regular.pageShowMode
  );
  // const sidebarWidth: number = useSelector(
  //   (state: RootState) => state.regular.siderWidth
  // );
  // const contentAreaMarginLeft = React.useMemo(() => {
  //   let marginLeft = sidebarWidth - CONTENTAREA_LEFT_PADDING * 2;

  //   if (pageShowMode === PAGE_MODE_FULL_PAGE) {
  //     marginLeft = 0;
  //   }

  //   return marginLeft;
  // }, [pageShowMode, sidebarWidth]);
  const contentContainerStyle = React.useMemo(() => {
    let style:any = {
      overflowY: "auto",
      padding: "12px 12px 12px 12px",
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
    };

    if (pageShowMode === PAGE_MODE_FULL_PAGE) {
      style = { ...style, marginLeft: CONTENTAREA_MARGIN };
    }

    return style;
  }, [pageShowMode]);
  const headerContainerStyle = React.useMemo(() => {
    let style = {
      width: '100%',
      height: 55,
      boxShadow: "0 -1px 8px 0 rgba(125, 140, 166, 0.08)",
      border: "solid 1px #d8dde6",
      backgroundColor: "#ffffff",
      display: "flex",
      alignItems: "center",
      padding: "5px 20px",
    };

    if (pageShowMode === PAGE_MODE_FULL_PAGE) {
      style = { ...style, display: 'none' };
    }

    return style;
  }, [pageShowMode]);


  return (
    <LayoutContainer $isMode={pageShowMode === PAGE_MODE_FULL_PAGE} $collapse={collapsed} style={{ display:"flex", height: "100%", flexDirection: "column" }}>
      <HeaderContainer style={headerContainerStyle}>
        <HeaderBar />
      </HeaderContainer>
      <ContentContainer style={contentContainerStyle}>
        {children}
      </ContentContainer>
    </LayoutContainer>
  );
}

export default ContentLayout;
