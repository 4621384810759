import React, { useState, useEffect, useCallback } from 'react';
import { Form, Row, Spin, Modal, Button, Space, Input, Select } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import {
  LoadingOutlined,
  SaveOutlined,
  PlusOutlined,
  FileSearchOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import TextEditor from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  message,
  UploadImage,
  FormLabel,
  HelpMessage,
} from 'components/common';
import {
  postSlip,
  previewSlipTemplate,
  getAvailableChannels,
} from 'services/storeSetup';
import { nowTime } from 'utils';

interface AddServiceProps {
  visible: boolean;
  onHide: () => void;
  onRefresh: () => void;
  store: any;
  slip?: any;
  templates: any[];
  v2: boolean;
}

const sizeOptions = [
  {
    id: 'id',
    name: 'ID',
  },
  {
    id: 'code',
    name: 'Name',
  },
  {
    id: 'sizeGroup',
    name: 'Size Group',
  },
  {
    id: 'description',
    name: 'Description',
  },
  {
    id: 'nrfSizeCode',
    name: 'NRF Code',
  },
  {
    id: 'nrfSizeDescription',
    name: 'NRF Name',
  },
  {
    id: 'primary',
    name: 'Primary',
  },
  {
    id: 'secondary',
    name: 'Secondary',
  },
  {
    id: 'category',
    name: 'Categrory',
  },
  {
    id: 'category2',
    name: 'Categrory 2',
  },
  {
    id: 'heading1',
    name: 'Heading 1',
  },
  {
    id: 'heading2',
    name: 'Heading 2',
  },
  {
    id: 'type',
    name: 'Type',
  },
];
const colorOptions = [
  {
    id: 'id',
    name: 'ID',
  },
  {
    id: 'code',
    name: 'Name',
  },
  {
    id: 'description',
    name: 'Description',
  },
  {
    id: 'nrfColorCode',
    name: 'NRF Code',
  },
  {
    id: 'nrfColorDescription',
    name: 'NRF Name',
  },
  {
    id: 'colorGroup',
    name: 'NRF Color Group',
  },
];

// eslint-disable-next-line
export default (props: AddServiceProps) => {
  const { visible, onHide, onRefresh, templates, slip, store, v2 } = props;
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  // const [fileList, setFileList] = useState<any[]>([]);
  //   const [showImg, setShowImg] = useState(false);
  const [mappingArr, setMappingArr] = useState<string[]>([]);
  const [channels, setChannels] = useState<any[]>([]);

  const [form] = Form.useForm();

  //   centralChannelNum: 10001
  // channelAccountNum: 10000
  // channelAccountPackingSlipNum: 1010
  // createBy: "zhen.liang@hurraymart.com"
  // createDate: "2021-11-12T03:25:44.23"
  // isPrintPackingSlip: 1
  // logo: null
  // masterAccountNum: 10001
  // packingSlipFooter: "ssss"
  // packingSlipTemplateName: "Blank PackingSlip"
  // packingSlipTemplateNum: 0
  // profileNum: 10001
  // salesDivision: "testapi"
  const getChannels = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getAvailableChannels();
      setLoading(false);
      if (res.isSuccess) {
        setChannels(res.data);
      }
    } catch (error) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (store && store.channelAccountType === 1 && v2) {
      getChannels();
    }
  }, [getChannels, store, v2]);

  const handleSubmit = async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    const mappings: StringKVPair = {};
    mappingArr.map((i) => {
      if (params[i]) {
        mappings[i] = params[i];
      }
      return true;
    });

    let postchannelName = 'Default';
    if (params.ChannelNum) {
      const t = channels.filter((i) => i.channelNum === params.ChannelNum);
      if (t.length > 0) {
        postchannelName = t[0].channelName;
      }
    }
    const postParams = {
      channelAccountPackingSlipNum: slip
        ? slip.channelAccountPackingSlipNum
        : 0,
      centralChannelNum: store?.centralChannelNum,
      channelAccountNum: store?.channelAccountNum,
      salesDivision: params.salesDivision,
      packingSlipFooter: params.packingSlipFooter,
      logo: params.logo,
      packingSlipTemplateNum: params.packingSlipTemplateNum,
      isPrintPackingSlip: params.isPrintPackingSlip ? 1 : 0,
      mappings: JSON.stringify(mappings),
      ChannelNum:
        v2 && store.channelAccountType === 1 ? params.ChannelNum : undefined,
      Channel:
        v2 && store.channelAccountType === 1 ? postchannelName : undefined,
    };
    try {
      setSubmitLoading(true);
      const res = await postSlip(postParams);
      setSubmitLoading(false);
      if (res.isSuccess) {
        message.success({
          content: slip ? 'Saved successfully' : 'Added successfully',
        });
        onRefresh();
        onHide();
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  const previewPackingSlip = async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    const postParams = {
      ChannelAccountPackingSlipNum: slip
        ? slip.channelAccountPackingSlipNum
        : 0,
      Footer: params.packingSlipFooter,
      Logo: params.logo,
      PackingSlipTemplateNum: params.packingSlipTemplateNum,
      Time: nowTime(),
    };
    try {
      setLoading(true);
      const res = await previewSlipTemplate(postParams);
      setLoading(false);
      const w = window.open('about:blank');
      w?.document.write(res);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    const cancel = form.isFieldsTouched();
    if (!cancel) {
      onHide();
    } else {
      Modal.confirm({
        title: 'Leave / Refresh Page?',
        okText: 'Leave',
        cancelText: 'Cancel',
        content: `Changes you made may not be saved.`,
        onOk() {
          onHide();
        },
      });
    }
  };
  /* eslint-disable */
  useEffect(() => {
    if (slip && slip.mappings && slip.packingSlipTemplateNum) {
      const temp = templates.filter(
        (i) => i.printTemplateNum === slip.packingSlipTemplateNum
      );

      if (temp.length > 0) {
        setMappingArr(JSON.parse(temp[0].mappings));
      }
      const json = JSON.parse(slip.mappings);
      form.setFieldsValue(json);
    }
  }, [slip, templates]);
  /* eslint-enable */
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };
  return (
    <Modal
      title={`${slip ? 'Edit' : 'Add'} Packing Slip Template`}
      centered
      visible={visible}
      maskClosable={false}
      width={900}
      destroyOnClose={true}
      onCancel={handleCancel}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            {false && (
              <Button
                type="default"
                loading={loading}
                onClick={() => previewPackingSlip()}
              >
                <FileSearchOutlined />
                Preview
              </Button>
            )}
            <Button
              key="submit"
              type="primary"
              onClick={handleSubmit}
              loading={submitLoading}
            >
              {slip ? <SaveOutlined /> : <PlusOutlined />}
              {submitLoading
                ? `${slip ? 'Saving' : 'Adding'}`
                : `${slip ? 'Save' : 'Add'}`}
            </Button>
            <Button onClick={handleCancel} icon={<CloseOutlined />}>
              Cancel
            </Button>
          </Space>
        </Row>
      }
    >
      <Spin
        spinning={submitLoading || loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Form {...layout} form={form}>
          {v2 && store && store.channelAccountType === 1 && (
            <Form.Item
              name="ChannelNum"
              initialValue={slip ? slip.channelNum : 0}
              label={<FormLabel>Channel</FormLabel>}
            >
              <Select
                showSearch
                allowClear
                style={{ width: '100%' }}
                optionFilterProp="children"
                filterOption={(input, option) => {
                  if (option) {
                    const { children = '', value = '' } = option;
                    return (
                      (typeof children === 'string' &&
                        children.toLowerCase().indexOf(input.toLowerCase()) >=
                          0) ||
                      (typeof value === 'string' &&
                        value.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                    );
                  }

                  return false;
                }}
              >
                <Select.Option key="default" value={0}>
                  Default
                </Select.Option>
                {channels.map((item) => (
                  <Select.Option key={item.channelNum} value={item.channelNum}>
                    {item.channelName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item
            name="salesDivision"
            initialValue={slip?.salesDivision}
            label={<FormLabel>Sales Division</FormLabel>}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="packingSlipTemplateNum"
            initialValue={slip?.packingSlipTemplateNum}
            label={<FormLabel>Packing Slip Template</FormLabel>}
            rules={[
              { required: true, message: 'Packing Slip Template is required' },
            ]}
          >
            <Select
              showSearch
              optionFilterProp="label"
              onChange={(value) => {
                const temp = templates.filter(
                  (i) => i.printTemplateNum === value
                );
                if (temp.length > 0 && temp[0].mappings) {
                  setMappingArr(JSON.parse(temp[0].mappings));
                }
              }}
            >
              {templates.map((item) => (
                <Select.Option
                  value={item.printTemplateNum}
                  key={item.printTemplateNum}
                  label={item.templateName}
                >
                  {item.templateName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="packingSlipFooter"
            initialValue={slip?.packingSlipFooter || ''}
            label={
              <>
                <FormLabel noColon>Packing Slip Footer</FormLabel>
                <HelpMessage helpId={2} />:
              </>
            }
          >
            {/* <Input.TextArea rows={3} /> */}
            <TextEditor theme="snow" style={{ minHeight: 200 }} />
          </Form.Item>

          {mappingArr.map((i) => (
            <Form.Item
              name={i}
              initialValue={undefined}
              label={<FormLabel noColon>{i}</FormLabel>}
              rules={[{ required: true, message: `${i} is required` }]}
            >
              <Select>
                {(i === 'Size'
                  ? sizeOptions
                  : i === 'Color'
                  ? colorOptions
                  : []
                ).map((k) => (
                  <Select.Option key={k.id} value={k.id}>
                    {k.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          ))}

          <Form.Item
            name="logo"
            initialValue={slip?.logo}
            label={<FormLabel>Store Logo</FormLabel>}
          >
            {/* <Input readOnly={!edited} /> */}
            <UploadImage style={{ marginTop: 5, width: 180 }} />
          </Form.Item>
          <Form.Item
            name="isPrintPackingSlip"
            initialValue={slip ? slip.isPrintPackingSlip : true}
            valuePropName="checked"
            label={<FormLabel noColon> </FormLabel>}
          >
            <Checkbox>Print Packing Slip</Checkbox>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};
