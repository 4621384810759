import React from 'react';
import { useHistory } from 'react-router-dom';
import { isEqualObject } from 'crud-object-diff';
import { Button, Col, Modal, Row, Space, Tooltip } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import copy from 'copy-text-to-clipboard';
import theme from 'assets/styles/theme';
import { ColumnDataType, DataGrid, Loading, ScreenMask, SpaceCell, message, CopySpan, TablesFilter } from 'components/common';
import { /*ButtonIcon,*/ InfoButton, Label } from 'components/common/styles';
import { FormVSpacer } from 'components/common/styles/CustomPropsComponents';
import OrderDetail from 'components/Sales/ScanVerifyPrint/OrderDetailDialog';
import ProductDetail from 'components/Inventory/ProductDetail/ProductDetailsDialog';
import { DATAGRID_CELL_ICON_WIDTH1, LOADING_ICON_SIZE1, } from 'constants/config';
import { pendOrderPickList, preparePrintPickList, queueOrderPickList } from 'services/sales';
import PicklistTagDialog from './PicklistTagDialog';
import ProductSelector from './ProductSelector';
import { queryProductByKeyword } from 'services/product';
import { convertTimeByUtc } from 'utils';
// import DataGridPlus from 'components/common/DataGridPlus/DataGrid';


type Props = {
  getOrderCount?: Function;
  pickListCount?: number;
  queryObject?: StringKAnyVPair;
};

// eslint-disable-next-line
export default (props: Props) => {
  const { useState } = React;
  const ORDER_GRID_HEIGHT = 700;
  const DEFAULT_GRID_PAGE_SIZE = 50;
  const DEFAULT_GRID_PAGE_SORT_ASCEND = -1;
  const history = useHistory();
  const [confirmPrintVisible, setConfirmPrintVisible] = useState(false);
  const [current, setCurrent] = useState<any>();
  const [currentOrderDetailVersion, setCurrentOrderDetailVersion] = useState(0);
  const [currentQueryObject, setCurrentQueryObject] = useState<StringKAnyVPair>(
    {}
  );
  const [detailDialogVisible, setDetailDialogVisible] = useState<boolean>(false);
  const [detailVisible, setDetailVisible] = useState<boolean>(false);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [gridHeight, setGridHeight] = useState(ORDER_GRID_HEIGHT);
  const [gridRef, setGridRef] = useState<any>(null);
  const [gridPageAscend, setGridPageAscend] = useState(-1);
  const [gridPageIndex, setGridPageIndex] = useState(1);
  const [gridPageSize, setGridPageSize] = useState(DEFAULT_GRID_PAGE_SIZE);
  const [gridPageSort, setGridPageSort] = useState('');
  const [inited, setInited] = useState(false);
  const [isAll, setIsAll] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [lockScreen, setLockScreen] = useState(false);
  // const [shownColumns, setShownColumns] = useState<string[]>([]);
  // const [shownColumnsDropdownVisible, setShownColumnsDropdownVisible] =
  //   useState(false);
  const [orderDetailVersion, setOrderDetailVersion] = useState(0);
  const [orderList, setOrderList] = useState<StringKAnyVPair[]>([]);
  const [orderListCount, setOrderListCount] = useState(props.pickListCount);
  const [pickListQueueNum, setPickListQueueNum] = useState(0);
  const [picklistTagDialogVisible, setPicklistTagDialogVisible] =
    useState<boolean>(false);
  const [productSelectorVisible, setProductSelectorVisible] = useState<boolean>(false);
  const [product, setProduct] = useState<any>();
  const [selectedPickNumList, setSelectedPickNumList] = useState<number[]>([]);
  const topRef = React.useRef<any>(null);

  // let mouseIsOnShownColumnDropdownMenu = false;

  // eslint-disable-next-line
  const columns = [
    {
      header: 'Order#',
      headerAlign: 'center',
      minWidth: 216,
      draggable: true,
      name: 'channelOrderId',
      //sortable: false,
      userSelect: true,
      render: (row: any) => {
        const { data } = row;

        return (
          <SpaceCell
            icon={
              <Tooltip
                placement="top"
                title="Copy"
                trigger={['hover', 'click']}
              >
                <CopyOutlined
                  onClick={() => {
                    copy(data.channelOrderId);
                    message.info(
                      `"${data.channelOrderId}" has been copied to clipboard`
                    );
                  }}
                  style={{ color: theme['@info-color'] }}
                />
              </Tooltip>
            }
            iconWidth={DATAGRID_CELL_ICON_WIDTH1}
            onTextClick={() => {
              setCurrent(data);
              setDetailDialogVisible(true);
            }}
            text={data.channelOrderId || 'View'}
            textIsButton
          />
        );
      },
    },
    {
      header: 'Requested Service',
      headerAlign: 'center',
      draggable: true,
      defaultFlex: 1,
      // maxWidth: 150,
      minWidth: 200,
      name: 'requestShippingService',
      //sortable: false,
      render: (value: any) => {
        const { data } = value;
        return <CopySpan inGrid value={data.requestShippingService} />
      },
    },
    {
      header: 'Mapped Service',
      headerAlign: 'center',
      // maxWidth: 150,
      defaultFlex: 1,
      draggable: true,
      minWidth: 150,
      name: 'mappedShippingService',
      //sortable: false,
      render: (value: any) => {
        const { data } = value;
        return <CopySpan inGrid value={data.mappedShippingService} />
      },
    },
    {
      dataType: ColumnDataType.DATE,
      //format: DATE_TIME_NO_SECOND_FORMAT,
      header: 'Order Date',
      headerAlign: 'center',
      minWidth: 190,
      draggable: true,
      name: 'originalOrderDate',
      userSelect: true,
      render: (value: any) => {
        const { data } = value;
        const temp = convertTimeByUtc(data.originalOrderDate, '', '', '')
        return <CopySpan inGrid value={temp} />
      },
    },
    {
      dataType: ColumnDataType.DATE,
      //format: DATE_TIME_NO_SECOND_FORMAT,
      header: 'Enter Date',
      headerAlign: 'center',
      minWidth: 190,
      name: 'enterDate',
      userSelect: true,
      draggable: true,
      render: (value: any) => {
        const { data } = value;
        const temp = convertTimeByUtc(data.enterDate, '', '', '')
        return <CopySpan inGrid value={temp} />
      },
    },
    {
      header: 'SKU',
      headerAlign: 'center',
      minWidth: 220,
      name: 'orderSkus',
      draggable: true,
      //sortable: false,
      render: (row: any) => {
        const { data } = row;
        return (
          <Button
            type='link'
            onClick={() => {
              getProductDetail(data);
            }}
          >
            {data.orderSkus}
          </Button>
        )
      }
    },
    {
      header: 'UPC',
      headerAlign: 'center',
      minWidth: 180,
      name: 'orderUpcs',
      draggable: true,
      sortable: false,
      render(row: any) {
        const { data } = row;
        return <CopySpan inGrid value={data.orderUpcs} />
        // return (
        //   <Popover placement="topLeft" content={data.orderUpcs}>
        //     {data.orderUpcs}
        //   </Popover>
        // );
      },
    },
    /*{
      header: 'Items',
      render(row: any) {
        const { data } = row;
        return (
          <span>${data.totalQty}</span>
        );
      },
    },*/
    {
      header: 'Total Items',
      // maxWidth: 120,
      minWidth: 120,
      name: 'totalLine',
      resizable: false,
      draggable: true,
      //sortable: false,
      textAlign: 'center',
      type: 'number',
    },
    {
      header: 'Total Qty',
      // maxWidth: 100,
      minWidth: 100,
      name: 'totalQty',
      resizable: false,
      draggable: true,
      //sortable: false,
      textAlign: 'center',
      type: 'number',
    },
    {
      header: 'Recipient',
      headerAlign: 'center',
      minWidth: 180,
      draggable: true,
      name: 'shipToName',
      defaultFlex: 1,
      //sortable: false,
      render: (value: any) => {
        const { data } = value;
        return <CopySpan inGrid value={data.shipToName} />
      },
    },
    {
      header: 'Store Account',
      headerAlign: 'center',
      minWidth: 160,
      draggable: true,
      name: 'channelAccountName',
      defaultFlex: 1,
      //sortable: false,
      render: (value: any) => {
        const { data } = value;
        return <CopySpan inGrid value={data.channelAccountName} />
      },
    },
  ];

  const closeOrderDetailDialog = () => {
    setDetailDialogVisible(false);
    setOrderDetailVersion(orderDetailVersion + 1);
  };

  // const columnLabels = columns.filter((e) => e.header).map((e) => e.header);

  // const tableColumns = React.useMemo(() => {
  //   return columns.filter((e) => {
  //     let ret = true;

  //     if (e.header) {
  //       ret = shownColumns.indexOf(e.header) > -1;
  //     }

  //     return ret;
  //   });
  // }, [columns, shownColumns]);

  // const tableColumnsAllShown = React.useMemo(() => {
  //   return shownColumns.length === columnLabels.length;
  // }, [columnLabels, shownColumns]);

  // eslint-disable-next-line
  const fetchPageOrderList = async (
    index: number,
    size: number,
    orderBy?: string,
    ascending?: number
  ) => {
    const params: StringKAnyVPair = {
      pageIndex: index,
      pageSize: size,
      total: gridPageSize,
    };

    if (orderBy && typeof ascending === 'number') {
      if (ascending > DEFAULT_GRID_PAGE_SORT_ASCEND) {
        params['orderby'] = orderBy;
        params['ascending'] = ascending;
      }
    }

    setIsLoading(true);

    try {
      const list = await pendOrderPickList(params, props.queryObject || {});

      setOrderList(list);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const getProductDetail = async (data: any) => {
    const arr = data.orderSkus.split(',');
    const arrLen = arr.length;
    if (arrLen > 1) {
      setCurrent(data);
      setProductSelectorVisible(true)
    }
    if (arrLen === 1) {
      setIsLoading(true);
      try {
        const res = await queryProductByKeyword(arr[0]);
        if (res.length > 0) {
          setProduct(res[0]);
          setDetailVisible(true);
        }
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false);
    }
  }

  const goToPrintPage = () => {
    if (pickListQueueNum > 0) {
      history.push(
        `/sales/print-generated-picklists?pickListNum=${pickListQueueNum}`
      );
      //history.push('/sales/print-generated-picklists');
    }
  };

  // eslint-disable-next-line
  const handlePreparePrint = async () => {
    /*const query = {
        "MasterAccountNum": 0,
        "ProfileNum": 0,
        "WarehouseNum": 10231,
        "WarehouseCode": "WonderFold",
        "MutipleWarehouseOrder": 0,
        "SKU": "",
        "OrderLineQtyType": 0,
        "CustomOrderLineQty": 0,
        "NotFoundProductStatus": 0,
        "OutOfStockStatus": 0,
        "ProductKitStatus": 0,
        "HoldStatus": 0,
        "ChannelAccounts": null,
        "ReqeustServices": null,
        "MappedCarrier": "",
        "MappedServices": null,
        "OrderDateType": 0,
        "LastDayCount": 0,
        "SelectedPickListOrderNums": selectedPickNumList.length > 0 ? selectedPickNumList : null,
    };*/
    const query = {
      ...props.queryObject,
      SelectedPickListOrderNums:
        selectedPickNumList.length > 0 ? selectedPickNumList : null,
    };

    /*setPickListQueueNum(21);
    setConfirmPrintVisible(true);
    return;*/
    setLockScreen(true);

    try {
      const res = await preparePrintPickList(query);

      setLockScreen(false);

      if (res) {
        if (typeof res === 'object' && typeof res.pickListQueueNum) {
          // popup confirm
          setPickListQueueNum(res.pickListQueueNum);
          setConfirmPrintVisible(true);

          const list = orderList.filter(
            (e) => selectedPickNumList.indexOf(e.fulfillmentOrderNum) === -1
          );
          setOrderList(list);
          setSelectedPickNumList([]);
          gridRef?.current.deselectAll();
          fetchPageOrderList(gridPageIndex, gridPageSize);

          if (typeof props.getOrderCount === 'function') {
            const count = await props.getOrderCount(props.queryObject);

            setOrderListCount(count);
          }
        }
      }
    } catch (e) {
      setLockScreen(false);
      //setConfirmPrintVisible(true);
      //setPickListQueueNum(21);
    }
  };

  const handleQueuePickList = async (PickListTag?: string) => {
    const params = {
      pageIndex: 0,
      pageSize: 50,
      total: 100,
    };
    /*const query = {
        "MasterAccountNum": 0,
        "ProfileNum": 0,
        "WarehouseNum": 0,
        "WarehouseCode": "WonderFold",
        "MutipleWarehouseOrder": 0,
        "SKU": "",
        "OrderLineQtyType": 0,
        "CustomOrderLineQty": 0,
        "NotFoundProductStatus": 0,
        "OutOfStockStatus": 0,
        "ProductKitStatus": 0,
        "HoldStatus": 0,
        "ChannelAccounts": null,
        "ReqeustServices": null,
        "MappedCarrier": "",
        "MappedServices": null,
        "OrderDateType": 0,
        "LastDayCount": 0,
        "SelectedPickListOrderNums": null
    };*/
    const query = {
      ...props.queryObject,
      PickListTag,
      //SelectedPickListOrderNums: selectedPickNumList.length > 0 ? selectedPickNumList : null,
      SelectedPickListOrderNums: null,
    };

    setLockScreen(true);

    try {
      const res = await queueOrderPickList(params, query);
      setLockScreen(false);

      handleQueuePickListResult(res);
    } catch (e) {
      setLockScreen(false);
    }
  };

  const handleQueuePickListResult = async (res: StringKAnyVPair) => {
    try {
      if (res && typeof res === 'object' && res.pickListNum) {
        //handlePreparePrint();
        // popup confirm
        setPickListQueueNum(res.pickListNum);
        setConfirmPrintVisible(true);

        const list = orderList.filter(
          (e) => selectedPickNumList.indexOf(e.fulfillmentOrderNum) === -1
        );
        setOrderList(list);
        setSelectedPickNumList([]);
        gridRef?.current.deselectAll();
        fetchPageOrderList(gridPageIndex, gridPageSize);

        if (typeof props.getOrderCount === 'function') {
          const count = await props.getOrderCount(props.queryObject);

          setOrderListCount(count);
        }
      }
    } catch (e) {
      message.error(`Queue pick list result error ${e}`);
    }
  };

  const handleQueueSelectedPickList = async (PickListTag?: string) => {
    if (selectedPickNumList.length === 0) return;

    const params = {
      pageIndex: 0,
      pageSize: 50,
      total: 100,
    };
    /*const query = {
        "MasterAccountNum": 0,
        "ProfileNum": 0,
        "WarehouseNum": 0,
        "WarehouseCode": "WonderFold",
        "MutipleWarehouseOrder": 0,
        "SKU": "",
        "OrderLineQtyType": 0,
        "CustomOrderLineQty": 0,
        "NotFoundProductStatus": 0,
        "OutOfStockStatus": 0,
        "ProductKitStatus": 0,
        "HoldStatus": 0,
        "ChannelAccounts": null,
        "ReqeustServices": null,
        "MappedCarrier": "",
        "MappedServices": null,
        "OrderDateType": 0,
        "LastDayCount": 0,
        "SelectedPickListOrderNums": selectedPickNumList,
    };*/
    const query = {
      ...props.queryObject,
      PickListTag,
      SelectedPickListOrderNums:
        selectedPickNumList.length > 0 ? selectedPickNumList : null,
    };

    setLockScreen(true);

    try {
      const res = await queueOrderPickList(params, query);
      setLockScreen(false);

      handleQueuePickListResult(res);
    } catch (e) {
      setLockScreen(false);
    }
  };

  // const handleResetShownColumns = () => {
  //   if (!tableColumnsAllShown) {
  //     setShownColumns([...columnLabels]);
  //   }
  // };

  // const handleShownColumnsDropdownBlur = () => {
  //   if (!mouseIsOnShownColumnDropdownMenu) {
  //     setShownColumnsDropdownVisible(false);
  //   }
  // };

  // const handleShownColumnsDropdownClick = () => {
  //   setShownColumnsDropdownVisible(!shownColumnsDropdownVisible);
  // };

  // const handleSwitchColumnShownStatus = (checked: boolean, label: string) => {
  //   const idx = shownColumns.indexOf(label);
  //   let cols = [...shownColumns];

  //   if (idx > -1) {
  //     if (!checked) {
  //       cols.splice(idx, 1);
  //     }
  //   } else if (checked) {
  //     cols = [...cols, label];
  //   }

  //   setShownColumns(cols);
  // };

  const onCheckPickList = (checked: any) => {
    const { selected, unselected, data } = checked;
    let list: number[] = [...selectedPickNumList];
    if (selected && JSON.stringify(selected) !== '{}') {
      if (typeof selected === 'object' && Object.keys(selected).length > 0) {
        if (list.length > Object.keys(selected).length) {
          const i = list.indexOf(data.fulfillmentOrderNum);

          if (i > -1) list.splice(i, 1);
        } else {
          if (typeof data === 'object') {
            list.push(data.fulfillmentOrderNum);
          }
        }
      } else if (data && typeof data === 'object') {
        if (Array.isArray(data)) {
          list = [];

          data.forEach((e) => {
            list.push(e.fulfillmentOrderNum);
          });
        } else if (selected === true) {
          if (data && data.fulfillmentOrderNum) {
            if (list.indexOf(data.fulfillmentOrderNum) === -1) {
              list.push(data.fulfillmentOrderNum);
            }
          }
        }
      }

      if (unselected && typeof unselected === 'object') {
        for (let i = list.length - 1; i >= 0; i--) {
          if (list[i] === data.fulfillmentOrderNum) {
            if (unselected[data.channelOrderId]) {
              list.splice(i, 1);
            }
          }
        }
      }
    }else {
      list = []
    }

    setSelectedPickNumList(list);
  };

  const onLimitChange = async (limit: number) => {
    gridRef?.current.deselectAll();
    setGridPageSize(limit);
    await fetchPageOrderList(gridPageIndex, limit, gridPageSort, gridPageAscend);
    //console.log('dd', filteredData);
  };

  const onSkipChange = (skip: number) => {
    const pageIndex = skip / gridPageSize + 1;

    gridRef?.current.deselectAll();
    setGridPageIndex(pageIndex);
    fetchPageOrderList(pageIndex, gridPageSize, gridPageSort, gridPageAscend);
  };

  const onSortChange = async (info: any) => {
    const localSortIds = [
      'channelAccountName',
      'channelOrderId',
      'mappedShippingService',
      'orderSkus',
      'requestShippingService',
      'shipToName',
      'totalLine',
      'totalQty',
    ];
    let [field, ascend] = ['', DEFAULT_GRID_PAGE_SORT_ASCEND];
    let index = gridPageIndex;

    if (info) {
      let { dir, id } = info;

      field = id || field;

      if (localSortIds.indexOf(field) > -1) {
        return;
      }

      if (dir === 1) {
        ascend = 1;
      } else if (dir === -1) {
        ascend = 0;
      }
    }

    if (field && ascend > DEFAULT_GRID_PAGE_SORT_ASCEND) {
      index = 1;
    }

    setGridPageIndex(index);
    setGridPageSort(field);
    setGridPageAscend(ascend);
    await fetchPageOrderList(index, gridPageSize, field, ascend);
  };

  // const columnShownMenu = (
  //   <Menu
  //     onMouseDown={() => (mouseIsOnShownColumnDropdownMenu = true)}
  //     onMouseLeave={() => setShownColumnsDropdownVisible(false)}
  //   >
  //     <Menu.Item key={0}>
  //       <Checkbox
  //         checked={tableColumnsAllShown}
  //         disabled={tableColumnsAllShown}
  //         onChange={handleResetShownColumns}
  //       >
  //         Select All
  //       </Checkbox>
  //     </Menu.Item>
  //     {columnLabels.map((e) => (
  //       <Menu.Item key={e}>
  //         <Checkbox
  //           checked={shownColumns.indexOf(e) > -1}
  //           onChange={(evt) =>
  //             handleSwitchColumnShownStatus(evt.target.checked, e)
  //           }
  //           onClick={(evt) => evt.stopPropagation()}
  //         >
  //           {e}
  //         </Checkbox>
  //       </Menu.Item>
  //     ))}
  //   </Menu>
  // );

  React.useEffect(() => {
    if (!inited) {
      const height =
        window.innerHeight < ORDER_GRID_HEIGHT
          ? window.innerHeight
          : ORDER_GRID_HEIGHT;

      setGridHeight(height - 60);
      // setShownColumns(columns.filter((e) => e.header).map((e) => e.header));
      setInited(true);
    }

    if (props.queryObject) {
      if (!isEqualObject(currentQueryObject, props.queryObject) || currentOrderDetailVersion !== orderDetailVersion) {
        fetchPageOrderList(gridPageIndex, gridPageSize);
        setOrderListCount(props.pickListCount);
        setCurrentQueryObject(props.queryObject);
        setCurrentOrderDetailVersion(orderDetailVersion);
      }
    }
  }, [
    columns,
    currentOrderDetailVersion,
    currentQueryObject,
    fetchPageOrderList,
    inited,
    gridPageIndex,
    gridPageSize,
    orderDetailVersion,
    props,
  ]);

  return (
    <>
      <Col ref={topRef}>
        {/*<Row justify="center">
          <Space>
            <Dropdown
              overlay={columnShownMenu}
              visible={shownColumnsDropdownVisible}
            >
              <Button
                onBlur={handleShownColumnsDropdownBlur}
                onClick={handleShownColumnsDropdownClick}
              >
                <CaretDownOutlined />
                Columns
              </Button>
            </Dropdown>
            <Button
              disabled={tableColumnsAllShown}
              onClick={handleResetShownColumns}
            >
              Reset Layout
            </Button>
          </Space>
        </Row>
        <FormVSpacer />*/}
        <Row align="middle">
          <Col span={9}>
            <Row justify="start">
              <Space>
                <Button
                  disabled={orderList.length === 0}
                  type="primary"
                  onClick={() => {
                    setIsAll(true);
                    setPicklistTagDialogVisible(true);
                  }}
                >
                  Queue All Orders
                </Button>
                <InfoButton
                  disabled={selectedPickNumList.length === 0}
                  type="primary"
                  onClick={() => {
                    setIsAll(false);
                    setPicklistTagDialogVisible(true);
                  }}
                >
                  Queue Selected Orders
                </InfoButton>
                <TablesFilter
                  columns={columns}
                  dataSource={orderList}
                  setFilteredData={setFilteredData}
                />
              </Space>
            </Row>
          </Col>
          <Col span={15}>
            <Row justify="end">
              <Space size={32}>
                <Label>
                  <span className="label-bold">Total: {orderListCount}</span>
                </Label>
              </Space>
            </Row>
          </Col>
          {/* <Col span={7}>
            <Row justify="end">
              <Space>
                <Dropdown
                  overlay={columnShownMenu}
                  visible={shownColumnsDropdownVisible}
                >
                  <Button
                    onBlur={handleShownColumnsDropdownBlur}
                    onClick={handleShownColumnsDropdownClick}
                  >
                    <CaretDownOutlined />
                    Columns
                  </Button>
                </Dropdown>
              </Space>
            </Row>
          </Col> */}
        </Row>
        <FormVSpacer />
        <DataGrid
          checkboxColumn
          checkboxOnlyRowSelect
          showScrollButton
          columns={columns}
          count={orderListCount}
          dataSource={filteredData}
          limit={gridPageSize}
          loading={isLoading}
          onLimitChange={onLimitChange}
          onReady={setGridRef}
          onSelectionChange={onCheckPickList}
          onSkipChange={onSkipChange}
          onSortInfoChange={onSortChange}
          pageSizes={[50, 100, 200, 500]}
          pagination
          idProperty="channelOrderId"
          rowKey="channelOrderId"
          style={{ height: gridHeight }}
          virtualized
          {...({} as any)}
        />
        {/*<FormVSpacer />
        <Row align="middle" justify="end">
          <Space>
            <HelpMessage placement="top" helpId={4} />
            <div>
              <InputNumber />
              <LineOutlined />
              <InputNumber />
            </div>
            <DangerButton>
              Delete
              <ButtonIcon className="bi-trash" />
            </DangerButton>
          </Space>
        </Row>*/}
      </Col>
      <Modal
        centered
        closable={false}
        footer={null}
        title=""
        visible={confirmPrintVisible}
        width={400}
      >
        <Row justify="space-between">
          <Button onClick={() => setConfirmPrintVisible(false)} size="large">
            Close
          </Button>
          <Button onClick={goToPrintPage} size="large" type="primary">
            Go to Print Pick List
          </Button>
        </Row>
      </Modal>
      {lockScreen && (
        <ScreenMask>
          <Loading size={LOADING_ICON_SIZE1} />
        </ScreenMask>
      )}
      {picklistTagDialogVisible && (
        <PicklistTagDialog
         onClose={()=>{
            setPicklistTagDialogVisible(false);
          }}
          onHide={(tagName) => {
            setPicklistTagDialogVisible(false);
            if (isAll) {
              handleQueuePickList(tagName);
            } else {
              handleQueueSelectedPickList(tagName);
            }
          }}
        />
      )}
      {detailDialogVisible && current && (
        <OrderDetail
          allowPopupWindow
          isHistory={false}
          fulfillmentOrderId={current.fulfillmentOrderId}
          fulfillmentOrderNum={current.fulfillmentOrderNum}

          onClose={closeOrderDetailDialog}
        />
      )}
      {
        productSelectorVisible && current && (
          <ProductSelector current={current} setVisible={(flag) => setProductSelectorVisible(flag)} />
        )
      }
      {
        detailVisible && product && (
          <ProductDetail
            visible={detailVisible}
            onHide={() => setDetailVisible(false)}
            productId={product.productId}
          />
        )
      }
    </>
  );
};
