import React, { useState, useCallback } from 'react';
import { Form, Input, Row, Spin, Modal, Button, Space, Select } from 'antd';
import { message } from 'components/common';
import { addWhiteList } from 'services/whiteList';
import { FormLabel } from 'components/common';
import {
  CloseOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons';

interface WhiteListProps {
  visible: boolean;
  channelAccounts: any[];
  onHide: () => void;
  onRefresh: () => void;
}
const WhiteListComponent = (props: WhiteListProps) => {
  const { visible, onHide, onRefresh, channelAccounts } = props;
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const handleCreate = async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    let str: string = (params.ChannelOrderId || '')
      .trim()
      .replace(/[\r\n]/g, ',')
      .replace(/,+/g, ',')
      .replace('/,$/gi', '');
    str =
      str.substring(str.length - 1) === ','
        ? str.substring(0, str.length - 1)
        : str;
    const temp = str ? Array.from(new Set(str.split(','))) : [];
    setLoading(true);
    try {
      const result = await addWhiteList(
        temp.map((i: any) => {
          return {
            ChannelAccountNum: params.ChannelAccountNum,
            ChannelOrderId: i,
          };
        })
      );
      setLoading(false);
      if (!result.error) {
        form.resetFields();
        onHide();
        onRefresh();
        message.success({ content: 'Add White List Successfully！' });
      } else {
        message.error({ content: result.error });
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const handleCancel = useCallback(async () => {
    const cancel = form.isFieldsTouched();
    if (!cancel) {
      onHide();
    } else {
      Modal.confirm({
        title: 'Leave / Refresh Page?',
        okText: 'Leave',
        cancelText: 'Cancel',
        content: `Changes you made may not be saved.`,
        onOk() {
          onHide();
        },
      });
    }
  }, [onHide, form]);

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };
  return (
    <Modal
      title="Add White List"
      centered
      visible={visible}
      maskClosable={false}
      width={600}
      destroyOnClose={true}
      onCancel={handleCancel}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              key="submit"
              type="primary"
              onClick={handleCreate}
              loading={loading}
              icon={loading ? <LoadingOutlined /> : <PlusOutlined />}
            >
              {loading ? 'Adding' : 'Add'}
            </Button>
            <Button onClick={handleCancel} icon={<CloseOutlined />}>
              Cancel
            </Button>
          </Space>
        </Row>
      }
    >
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Form {...layout} form={form} style={{ marginTop: 20 }}>
          <Form.Item
            name="ChannelAccountNum"
            label={<FormLabel>Store Account</FormLabel>}
            rules={[
              { required: true, message: 'Please input channel account!' },
            ]}
          >
            <Select
              showSearch
              allowClear
              placeholder="Please select"
              style={{ width: '100%' }}
              optionFilterProp="label"
            >
              {channelAccounts.map((item) => (
                <Select.Option
                  key={item.channelAccountNum}
                  value={item.channelAccountNum}
                  label={item.channelAccountName}
                >
                  {item.channelAccountName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="ChannelOrderId"
            label={<FormLabel>Channel Order ID(s)</FormLabel>}
            rules={[
              { required: true, message: 'Please input channel order id(s)!' },
            ]}
          >
            <Input.TextArea rows={3} />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default WhiteListComponent;
