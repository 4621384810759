import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Row, Button, Space, Popconfirm } from 'antd';
import { DataGridPlus, message } from 'components/common';
import { getReturnsDetail, delReceiveLine } from 'services/return';
import { getWarehouseIdFromCache, sortByDate } from 'utils';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const warehouseTypeKeyValue: StringKVPair = {
  '2': 'Resalable',
  '3': 'Damage',
  '4': 'Inspection',
};

type Props = {
  onHide: () => void;
  onRefresh: () => void;
  current: any;
  sku?: string;
};
// eslint-disable-next-line
export default (props: Props) => {
  const { onHide, current, onRefresh, sku = '' } = props;
  const [loading, setLoding] = useState(false);
  const [data, setData] = useState([]);
  const [inited, setInited] = useState(false)

  /**
   * handle hide event
   */
  const handleHide = useCallback(() => {
    onRefresh();
    onHide();
  }, [onHide, onRefresh]);

  const getData = useCallback(async () => {
    try {
      setLoding(true);
      const res = await getReturnsDetail(
        getWarehouseIdFromCache(current.warehouseNum),
        current.fulfillmentReturnNum
      );
      setLoding(false);
      if (res) {
        const temp = res
          .filter((item: any) => item.sku === sku)
          .sort((a: any, b: any) => sortByDate(a, b, 'createDate'));
        setData(temp);
      }
    } catch (error) {
      setLoding(false);
    }
  }, [current, sku]);

  const delLine = useCallback(
    async (returnReceiveNum: number) => {
      try {
        const res = await delReceiveLine(
          getWarehouseIdFromCache(current.warehouseNum),
          current.fulfillmentReturnNum,
          returnReceiveNum
        );
        if (res) {
          message.success({ content: 'deleted successfully' });
          getData();
        }
      } catch (error) {}
    },
    [current, getData]
  );

  const columns = [
    {
      name: 'sku',
      header: 'SKU',
      minWidth: 220,
      showColumnMenuTool: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'warehouseCode',
      header: 'Warehouse',
      showColumnMenuTool: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'locationCode',
      header: 'Location',
      showColumnMenuTool: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'returnReceiveType',
      header: 'Receive Type',
      showColumnMenuTool: true,
      userSelect: true,
      defaultFlex: 1,
      render: (value: any) => {
        const { data } = value;
        return warehouseTypeKeyValue[data.returnReceiveType] || '';
      },
    },
    {
      name: 'receiveQty',
      header: 'Qty',
      showColumnMenuTool: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Action',
      name: 'action',
      showColumnMenuTool: false,
      defaultLocked: 'end',
      sortable: false,
      defaultFlex: 1,
      render: (value: any) => {
        const { data, rowIndex } = value;
        return current.status !== 2 ? (
          <Space>
            <Popconfirm
              placement="rightTop"
              title="Are you sure to delete this row?"
              onConfirm={() => delLine(data.returnReceiveNum)}
              okText="Yes"
              cancelText="No"
              okButtonProps={{
                className: 'danger-btn',
                icon: <CheckOutlined />,
              }}
              cancelButtonProps={{ icon: <CloseOutlined /> }}
            >
              <Button
                disabled={rowIndex !== 0}
                type="link"
                className="hover-danger-button-2"
              >
                Delete
              </Button>
            </Popconfirm>
          </Space>
        ) : null;
      },
    },
  ];

  useEffect(() => {
    if (!inited) {
      getData();
      setInited(true)
    }
  }, [getData, inited]);

  return (
    <Modal
      title="Delete"
      centered
      visible={true}
      width={800}
      destroyOnClose={true}
      onCancel={handleHide}
      footer={
        <Row justify="end">
          <Space>
            <Button onClick={handleHide} icon={<CloseOutlined />}>
              Cancel
            </Button>
          </Space>
        </Row>
      }
    >
      <DataGridPlus
        columns={columns}
        loading={loading}
        dataSource={data}
        idProperty="sku"
        pagination="local"
        {...({} as any)}
      />
    </Modal>
  );
};
