import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { DeleteRowOutlined, ExclamationCircleOutlined, SearchOutlined, CopyOutlined } from '@ant-design/icons';
import {
  Button,
  Row,
  Input,
  Modal,
  Space,
  Form,
  DatePicker,
  Col,
  Tooltip,
} from 'antd';
import {
  DataGridPlus,
  HeaderTitle,
  SectionWrapper,
  FormLabel,
  ColumnDataType,
  message,
  GreyCardWrapper,
  SpaceCell,
  TablesFilter,
} from 'components/common';
import { SetDefaultWarehouseDialog } from 'components/common';
// import WarehouseSelectBox from 'components/Inventory/Audit/SelectWarehouse';
import WarehouseSelectBox from '../../Warehouse/WarehouseSelectBox';
import WarehouseLocationSearchBox from 'components/Inventory/Audit/SelectLocation';
// import CloseReceiveDialog from './CloseReceiveDialog';
import {
  COMMON_CARD_WRAPPER_MARGIN,
  COMMON_PADDING_SPACE,
  DEFAULT_DATE_TIME_FORMAT,
  DEFAULT_ISO_TIME_FORMAT,
  LS_DEFAULT_WAREHOUSE_KEY,
  DATAGRID_CELL_ICON_WIDTH1,
} from 'constants/config';
import { fetchAuditBatch, closeAuditBatch } from 'services/audits';
import {
  convertTimeByUtc,
  convertToUtcTime,
  getWarehouseCodeFromCache,
  getWarehouseIdFromCache,
  onSelectionChange,
} from 'utils';
import { useLocation } from 'react-router';
import copy from 'copy-text-to-clipboard';
import theme from 'assets/styles/theme';
import ProductDetailsDialog from '../ProductDetail/ProductDetailsDialog';
import { userProfiles } from 'utils/auth';

const { RangePicker } = DatePicker;

export default function PoList() {
  const hash = useLocation();
  const searchParams = new URLSearchParams(hash.search);
  const startTimeStr = searchParams.get('endTime');
  const paramsWarehouse = searchParams.get('warehouse');
  const [poLineList, setPoLineList] = useState();
  const [loading, setLoading] = useState(false);
  const [init, setInit] = useState(false);
  const [selectedRow, setSelectedRow] = React.useState<any[]>([]);
  const [warehouseNum, setWarehouseNum] = React.useState(
    paramsWarehouse ? Number(paramsWarehouse) :
    0
  );
  const [locationNum, setLocationNum] = React.useState(-1);
  const [dialogClosable, setDialogClosable] = React.useState(true);
  const [defaultWarehouseVisible, setDefaultWarehouseVisible] =
    React.useState(false);

  const [selected, setSelected] = React.useState<any>({});
  const [gridHeight, setGridHeight] = useState<number>(490);
  const [allTempList, setAllTempList] = useState<any[]>([])
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [editingProduct, setEditingProduct] = React.useState<ProductRow>({
    id: 0,
    productId: '',
  });
  const enableLOT = userProfiles.getProfileSettingValue("EnableLOT");
  const enableUOM = userProfiles.getProfileSettingValue("EnableUOM");
  const [productDetailsDialogVisible, setProductDetailsDialogVisible] =
    useState<boolean>(false);
  const openSetDefaultWarehouseDialog = (config: StringKAnyVPair) => {
    setDialogClosable(
      typeof config.closable === 'boolean' ? config.closable : true
    );
    setDefaultWarehouseVisible(true);
  };

  const showProductDetailsDialog = useCallback((visible: boolean) => {
    setProductDetailsDialogVisible(visible);
  }, []);

  const onClickSkuCell = useCallback((data: StringKAnyVPair) => {
    const selObj = window.getSelection();
    let open = selObj && selObj.toString() ? false : true;

    if (open) {
      setEditingProduct({
        id: data.fulfillmentProductNum,
        productId: data.productId,
      });
      showProductDetailsDialog(true);
    }
  },[showProductDetailsDialog]);


  React.useEffect(() => {
    if (
      !localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY) ||
      !getWarehouseCodeFromCache(
        Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
      )
    ) {
      openSetDefaultWarehouseDialog({ closable: false });
    }
  }, []);

  const [form] = Form.useForm();

  const onSelectLocationOption = (option: StringKAnyVPair) => {
    if (option) {
      setLocationNum(option.id);
    } else {
      setLocationNum(-1);
    }
  };
  const onSelectWarehouse = (id: number) => {
    setWarehouseNum(id);
    setLocationNum(-1);
  };
  /**
   * handle search event
   */
  const handleSearch = useCallback(async (fromDashboard: boolean = false) => {
    try {
      // {vendorCode: undefined, poNumber: undefined, sku: undefined, enterDate: Array(2)}
      const formValues = form.getFieldsValue();
      if (formValues.createDate && !formValues.createDate[0] && formValues.createDate[1] && fromDashboard === false) {
        message.error('Please fill or clear the adjust date!');
        return;
      }
      const params = fromDashboard ? {
        locationNum: locationNum > 0 ? locationNum : undefined,
        endDate: startTimeStr ? startTimeStr : undefined
      } : {
        sku: `${formValues.sku || ''}`.trim(),
        startDate: formValues.createDate
          ? //? moment(formValues.createDate[0]).format('YYYY-MM-DD')
          convertToUtcTime(
            formValues.createDate[0].format(DEFAULT_ISO_TIME_FORMAT)
          )
          : undefined,
        locationNum: locationNum > 0 ? locationNum : undefined,
        endDate: formValues.createDate
          ? //? moment(formValues.createDate[1]).format('YYYY-MM-DD')
          convertToUtcTime(
            formValues.createDate[1].format(DEFAULT_ISO_TIME_FORMAT)
          )
          : undefined,
      }

      const res = await fetchAuditBatch(getWarehouseIdFromCache(warehouseNum), params);
      setLoading(false);
      if (res.isSuccess) {
        setSelected({});
        setSelectedRow([]);
        const temp = res.data.map((i:any)=>{return {
          ...i,
          warehoueCode: getWarehouseCodeFromCache(i.warehouseNum)
        }})
        setPoLineList(temp.sort((a: any, b: any) => {
          const aTimeStamp = new Date(a.createDate).getTime();
          const bTimeStamp = new Date(b.createDate).getTime();
          return bTimeStamp - aTimeStamp;
        }));
      }
    } catch (error) {
      console.log(error);
    }
  }, [form, warehouseNum, locationNum, startTimeStr]);

  const postCloseAudit = useCallback(async () => {
    let checkWarehouse: boolean = true;
    if(selectedRow.length === 0) message.info('please select row') 
    selectedRow.map((i:any) => {
      if(i.warehouseNum !== selectedRow[0].warehouseNum){
        checkWarehouse = false;
      }
      return true
    })
    if(!checkWarehouse) return message.info('Batch close is only allowed within the same warehouse')
    Modal.confirm({
      title: 'Do you want to close selected items?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      onOk: () => {
        return new Promise(async (resolve, reject) => {
          try {
            const res = await closeAuditBatch(
              getWarehouseIdFromCache(
                // Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
                warehouseNum
              ),
              selectedRow.map((i) => i.auditNum)
            );
            if (res.isSuccess) {
              message.success({ content: 'Closed Audit Successfully. ' });
              setSelected({});
              setSelectedRow([])
              handleSearch();
              resolve('success');
            } else {
              resolve('fail');
            }
          } catch (error) {
            reject(error);
          }
        }).catch((err) => console.log('err', err));
      },
      onCancel() { },
    });
  }, [selectedRow, handleSearch, warehouseNum]);

  /**
   * handle datagrid change event
   */
  //   const handleDataGridChange = useCallback(
  //     async (limit: number, skip: number) => {},
  //     []
  //   );

  const columns = useMemo(()=>{
    const cls =[
      {
        header: 'SKU',
        name: 'sku',
        defaultFlex: 1,
        minWidth: 220,
        userSelect: true,
        sortable: true,
        render: (value: any) => {
          const { data } = value;
  
          return (
            <SpaceCell
              icon={
                <Tooltip
                  placement="top"
                  title="Copy"
                  trigger={['hover', 'click']}
                >
                  <CopyOutlined
                    onClick={() => {
                      copy(data.sku);
                      message.info(`"${data.sku}" has been copied to clipboard`);
                    }}
                    style={{ color: theme['@info-color'] }}
                  />
                </Tooltip>
              }
              iconWidth={DATAGRID_CELL_ICON_WIDTH1}
              onTextClick={() => onClickSkuCell(data)}
              text={data.sku}
              textIsButton
            />
          );
        },
      },
      {
        minWidth: 130,
        name: 'lotNumber',
        header: 'LOT#',
        defaultFlex: 1,
        lotColumn: true,
        userSelect: true,
      },
      {
        header: 'Warehouse',
        name: 'warehoueCode',
        defaultFlex: 1,
        userSelect: true,
        sortable: true,
      },
      {
        header: 'Location',
        name: 'locationCode',
        defaultFlex: 1,
        userSelect: true,
        sortable: true,
      },
      {
        header: 'Adjust Qty',
        name: 'adjustQty',
        defaultFlex: 1,
        type: 'number',
      },
      {
        minWidth: 130,
        name: 'uom',
        header: 'UOM',
        defaultFlex: 1,
        uomColumn: true,
        userSelect: true,
      },
      {
        minWidth: 130,
        name: 'uomRate',
        header: 'UOM Rate',
        defaultFlex: 1,
        uomColumn: true,
        userSelect: true,
      },
      {
        minWidth: 130,
        name: 'uomBaseQty',
        header: 'Base Qty',
        defaultFlex: 1,
        uomColumn: true,
        userSelect: true,
      },
      {
        header: 'Adjust Date',
        name: 'createDate',
        defaultFlex: 1,
        sortable: true,
        userSelect: true,
        dataType: ColumnDataType.DATE,
      },
      {
        header: 'Email',
        name: 'createBy',
        defaultFlex: 1,
        sortable: true,
        userSelect: true,
        dataType: 'string',
      },
    ];

    let temp = [...cls]
    temp = enableLOT === '1' ? [...temp] : temp.filter(i => !i.lotColumn)
    temp = enableUOM === '1' ? [...temp] : temp.filter(i => !i.uomColumn)
    return temp;
  },[enableLOT, enableUOM, onClickSkuCell])


  useEffect(() => {
    if (startTimeStr && !init) {
      handleSearch(true);
      setInit(true)
    }
  }, [startTimeStr, handleSearch, init])

  return (
    <>
      <HeaderTitle breadcrumb={['Inventory', `Audit Batch`]} />
      <Form form={form} id="search_form">
        <SectionWrapper>
          <Row>
            <Col
              xl={6}
              md={12}
              sm={24}
              style={{ alignContent: 'center', padding: COMMON_PADDING_SPACE }}
            >
              <FormLabel>Warehouse</FormLabel>
              <WarehouseSelectBox
                selectAll
                onlyNormal={false}
                value={warehouseNum}
                onChange={onSelectWarehouse}
                style={{}}
              />
            </Col>
            <Col
              xl={6}
              md={12}
              sm={24}
              style={{ alignContent: 'center', padding: COMMON_PADDING_SPACE }}
            >
              <FormLabel>Location</FormLabel>
              <WarehouseLocationSearchBox
                id="warehouse_location_search_box"
                warehouseNum={warehouseNum}
                // onChange={onSelectLocation}
                onChangeOption={onSelectLocationOption}
                value={locationNum}
              />
            </Col>
            <Col
              xl={6}
              md={12}
              sm={24}
              style={{ alignContent: 'center', padding: COMMON_PADDING_SPACE }}
            >
              <FormLabel>SKU</FormLabel>
              <Form.Item noStyle name="sku">
                <Input id="sku_enter_input" />
              </Form.Item>
            </Col>
            <Col
              xl={6}
              md={12}
              sm={24}
              style={{ alignContent: 'center', padding: COMMON_PADDING_SPACE }}
            >
              <FormLabel>Adjust Date</FormLabel>
              <Form.Item
                noStyle
                initialValue={
                  startTimeStr ? [moment(0), moment(convertTimeByUtc(startTimeStr))]
                    : [moment().add(-1, 'months').startOf('days'), moment().endOf('days'),]
                }
                name="createDate"
              >
                <RangePicker
                  id="adjust_date_range_picker"
                  style={{ width: '100%' }}
                  showTime
                  ranges={{
                    Today: [moment().startOf('day'), moment().endOf('day')],
                    Yesterday: [
                      moment().add(-1, 'day').startOf('day'),
                      moment().add(-1, 'day').endOf('day'),
                    ],
                    '2 days ago': [
                      moment().add(-2, 'day').startOf('day'),
                      moment().endOf('day'),
                    ],
                    '3 days ago': [
                      moment().add(-3, 'day').startOf('day'),
                      moment().endOf('day'),
                    ],
                    'This Week': [
                      moment().startOf('week'),
                      moment().endOf('week'),
                    ],
                    'Last Week': [
                      moment().add(-1, 'week').startOf('week'),
                      moment().add(-1, 'week').endOf('week'),
                    ],
                    'This Month': [
                      moment().startOf('month'),
                      moment().endOf('month'),
                    ],
                  }}
                  format={DEFAULT_DATE_TIME_FORMAT}
                // disabledDate={rangePickerDisabledDate}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="center" style={{ marginTop: COMMON_PADDING_SPACE }}>
            <Space>
              <Button
                id="search_btn"
                loading={loading}
                type="primary"
                onClick={() => handleSearch()}
              >
                <SearchOutlined />
                <span>Search</span>
              </Button>
              <Button
                id="close_audit_btn"
                type="primary"
                disabled={selectedRow.length === 0}
                onClick={postCloseAudit}
                icon={<DeleteRowOutlined />}
              >
                Close Audit
              </Button>
            </Space>
          </Row>
        </SectionWrapper>
      </Form>
      {
        poLineList && (
          <GreyCardWrapper style={{ marginTop: COMMON_CARD_WRAPPER_MARGIN }}>
            <TablesFilter
              dataSource={poLineList}
              columns={columns}
              setFilteredData={setFilteredData}
            />
            <DataGridPlus
              name="ListpoReceive"
              autoWith={false}
              idProperty="auditNum"
              pageSizes={[10, 20, 50, 100, 200, 500]}
              style={{ height: gridHeight }}
              // selected={
              //   selectedRow && selectedRow.length > 0
              //     ? Object.assign(
              //         {},
              //         ...selectedRow.map((i) => {
              //           return { [i.poReceiveNum]: true };
              //         })
              //       )
              //     : {}
              // }
              onSortInfoChange={() => {
                setSelected({});
                setSelectedRow([])
              }}
              onChange={(limit, skip) => {
                setSelected({});
                setSelectedRow([])
                setGridHeight(limit * 40 + 90)
              }}
              checkboxOnlyRowSelect={true}
              checkboxColumn
              selected={selected}
              onSelectionChange={(props) => onSelectionChange(props, allTempList, setSelected, setSelectedRow, 'auditNum', true, setAllTempList)}
              columns={columns}
              //   count={0}
              dataSource={filteredData}
              //   onChange={handleDataGridChange}
              pagination="local"
              loading={loading}
              /* eslint-disable react/jsx-props-no-spreading */
              {...({} as any)}
            />
          </GreyCardWrapper>
        )
      }
      {editingProduct && productDetailsDialogVisible && (
        <ProductDetailsDialog
          productId={editingProduct.productId}
          visible={productDetailsDialogVisible}
          onHide={() => showProductDetailsDialog(false)}
        />
      )}
      {
        <SetDefaultWarehouseDialog
          closable={dialogClosable}
          close={() => {
            setDefaultWarehouseVisible(false);
          }}
          visible={defaultWarehouseVisible}
        />
      }
    </>
  );
}
