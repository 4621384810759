import React, { useState, useCallback, useEffect } from 'react';
import { message, Card, Space, Tooltip, Input, Tabs, Row, Col } from 'antd';
import { ColumnDataType, CopySpan, DataGridPlus, FormLabel, ModalDialog, SpaceCell } from 'components/common';
import { TableHeader } from 'components/Catalog/CatalogView/style';
import { CopyOutlined, SearchOutlined } from '@ant-design/icons';
import copy from 'copy-text-to-clipboard';
import { convertTimeByUtc, getWarehouseIdFromCache } from 'utils';
import { fetchPickingStatus } from 'services/smart';
import theme from 'assets/styles/theme';
import { COMMON_PADDING_SPACE, DATAGRID_CELL_ICON_WIDTH1, LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';
import { queryProductByKeyword } from 'services/product';
import { fetchInventories } from 'services/inventory';

import ProductDetail from 'components/Inventory/ProductDetail/ProductDetailsDialog';
import LocationInfoModal from 'components/Inventory/Add/searchLocation/LocationInfoModal';
import {
  orderStatusKeyValue,
} from 'constants/enums'
const { TabPane } = Tabs;

/**
 * Type defintion for the dialog properties.
 * @param {boolean|unefined} closable - default value is false, it means can not be closed.
 */
type Props = {
  onClose: Function;
  visible: boolean;
  selectedPick: any;
};

const PickingStatusDialog = (props: Props) => {
  const { onClose, visible, selectedPick } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<'sku' | 'order'>('sku');
  const [skuGroup, setSkuGroup] = useState<any>({});
  const [orderGroup, setOrderGroup] = useState<any>({});
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchOrderValue, setSearchOrderValue] = useState<string>('');
  const [product, setProduct] = useState<any>();
  const [detailVisible, setDetailVisible] = useState<boolean>(false);

  const [showLocationInfo, setShowLocationInfo] = useState<boolean>(false);
  const [currentLocation, setCurrentLocation] = useState<any>();

  const [detailDialogIsFullscreen, setDetailDialogIsFullscreen] = useState(false);
  const refreshBtnRef = React.useRef<any>(null);
  const debouncedSearchTerm: string = useDebounce<string>(searchValue, 500);
  const debouncedSearchOrderTerm: string = useDebounce<string>(searchOrderValue, 500);

  const warehouseId = getWarehouseIdFromCache(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)))

  const handleCancel = () => {
    onClose();
  }

  const detailDialogWidth = useCallback(
    (isFullscreen = detailDialogIsFullscreen) => {
      return isFullscreen
        ? window.innerWidth - 4
        : window.innerWidth > 1280
          ? window.innerWidth * 0.8
          : 800;
    },
    [detailDialogIsFullscreen]
  );

  const getDialogDom = () => {
    const refreshBtn = refreshBtnRef?.current;

    if (refreshBtn) {
      return refreshBtn.parentNode.parentNode.parentNode.parentNode.parentNode
        .parentNode.parentNode;
    }

    return null;
  };

  const onFullscreen = (isFullscreen: boolean) => {
    const dialog = getDialogDom();

    setDetailDialogIsFullscreen(isFullscreen);

    if (dialog) {
      const width = detailDialogWidth(isFullscreen);

      dialog.style.width = `${width}px`;
    }
  };

  const getPickingStatus = useCallback(async () => {
    try {
      setLoading(true);
      setSkuGroup({});
      setOrderGroup({});
      const { pickListNum = '' } = selectedPick;
      const { data = {}, isSuccess, message: resMsg = '' } = await fetchPickingStatus(pickListNum, debouncedSearchTerm, debouncedSearchOrderTerm);
      if (isSuccess) {
        const { groupByOrder = {}, groupBySku = {} } = data;
        if (groupBySku) {
          groupBySku.data = (groupBySku.data || []).map((item: any) => {
            return {
              ...item,
              shippedQty: (item.orderList || []).filter((order: any) => order.orderStatus === '1').length,
            }
          })
          setSkuGroup(groupBySku);
        }
        setOrderGroup(groupByOrder);
      } else {
        message.error({ content: resMsg || 'Get Picking Status failed' });
      }
    } catch (error) {
      console.log(error)
    }
    setLoading(false);
  }, [selectedPick, debouncedSearchTerm, debouncedSearchOrderTerm])

  const skuColumns = [
    {
      header: 'SKU',
      name: 'sku',
      userSelect: true,
      defaultFlex: 1,
      sortable: false,
      minWidth: 200,
      render: (row: any) => {
        const { data } = row;
        return (
          <SpaceCell
            icon={
              <Tooltip
                placement="top"
                title="Copy"
                trigger={['hover', 'click']}
              >
                <CopyOutlined
                  onClick={() => {
                    copy(data.sku);
                    message.info(`"${data.sku}" has been copied to clipboard`);
                  }}
                  style={{ color: theme['@info-color'] }}
                />
              </Tooltip>
            }
            iconWidth={DATAGRID_CELL_ICON_WIDTH1}
            onTextClick={() => onClickSKU(data)}
            text={data.sku}
            textIsButton
          />
        )
      }
    },
    {
      header: 'UPC',
      name: 'upc',
      userSelect: true,
      defaultFlex: 1,
      sortable: false,
      minWidth: 200,
    },
    {
      header: 'Product Name',
      name: 'itemTitle',
      userSelect: true,
      defaultFlex: 1,
      sortable: false,
    },
    {
      header: 'Pick Qty',
      name: 'pickQty',
      userSelect: true,
      defaultFlex: 1,
      sortable: false,
    },
    {
      header: 'Picked Qty',
      name: 'pickedQty',
      userSelect: true,
      defaultFlex: 1,
      sortable: false,
    },
    {
      header: 'Shipped Qty',
      name: 'shippedQty',
      userSelect: true,
      defaultFlex: 1,
      sortable: false,
    },
    {
      header: 'Pick Status',
      name: 'pickStatus',
      userSelect: true,
      defaultFlex: 1,
      sortable: false,
    },
    {
      header: 'Print Date',
      name: 'createDate',
      userSelect: true,
      defaultFlex: 1,
      sortable: false,
      render: (row: any) => {
        const { data } = row;
        return (
          <CopySpan inGrid value={convertTimeByUtc(data.createDate)}></CopySpan>
        )
      }
    }
  ]

  const orderColumns = [
    {
      header: 'Order#',
      name: 'channelOrderId',
      userSelect: true,
      defaultFlex: 1,
      sortable: false,
    },
    {
      header: 'Order Fulfillment Status',
      name: 'orderStatus',
      userSelect: true,
      defaultFlex: 1,
      sortable: false,
      render: ((row: any) => {
        const { data } = row;
        return orderStatusKeyValue[data.orderStatus];
      })
    },
    {
      header: 'Pick Status',
      name: 'pickStatus',
      userSelect: true,
      defaultFlex: 1,
      sortable: false,
    },
    {
      header: 'Warehouse',
      name: 'warehouseCode',
      userSelect: true,
      defaultFlex: 1,
      sortable: false,
    },
    {
      header: 'EnterDate',
      name: 'enterDate',
      userSelect: true,
      defaultFlex: 1,
      sortable: false,
      dataType: ColumnDataType.DATE,
    }
  ]

  const onClickSKU = async (data: any) => {
    try {
      const { sku = '' } = data;
      const res = await queryProductByKeyword(sku);
      if (res.length > 0) {
        setProduct(res[0]);
        setDetailVisible(true);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const onClickLocation = async (locationCode: string) => {
    try {
      const res = await fetchInventories(warehouseId, locationCode) || [];
      // console.log(res);
      if (res.length > 0) {
        setCurrentLocation(res[0]);
        setShowLocationInfo(true)
      }
    } catch (error) {
      console.log(error);
    }
  }

  const skuSubGrid = (row: any) => {
    const { data } = row;
    const orderListColumns = [
      {
        header: 'Order#',
        name: 'channelOrderId',
        userSelect: true,
        defaultFlex: 1,
        sortable: false,
      },
      {
        header: 'Store Account',
        name: 'channelAccountName',
        userSelect: true,
        defaultFlex: 1,
        sortable: false,
      },
      {
        header: 'Status',
        name: 'orderStatus',
        userSelect: true,
        defaultFlex: 1,
        sortable: false,
        render: (row: any) => {
          const { data } = row;
          return orderStatusKeyValue[data.orderStatus];
        }
      },
    ];

    const pickListColumns = [
      {
        header: 'SKU',
        name: 'sku',
        userSelect: true,
        defaultFlex: 1,
        sortable: false,
      },
      {
        header: 'UPC',
        name: 'upc',
        userSelect: true,
        defaultFlex: 1,
        sortable: false,
      },
      {
        header: 'Location',
        name: 'locationCode',
        userSelect: true,
        defaultFlex: 1,
        sortable: false,
        render: (row: any) => {
          const { data } = row;
          return (
            <SpaceCell
              icon={
                <Tooltip
                  placement="top"
                  title="Copy"
                  trigger={['hover', 'click']}
                >
                  <CopyOutlined
                    onClick={() => {
                      copy(data.locationCode);
                      message.info(`"${data.locationCode}" has been copied to clipboard`);
                    }}
                    style={{ color: theme['@info-color'] }}
                  />
                </Tooltip>
              }
              iconWidth={DATAGRID_CELL_ICON_WIDTH1}
              onTextClick={() => {
                // setCurrentLocation(data.locationCode);
                // setShowLocationInfo(true);
                onClickLocation(data.locationCode);
              }}
              text={data.locationCode}
              textIsButton
            />
          )
        }
      },
      {
        header: 'Pick By',
        name: 'pickBy',
        userSelect: true,
        defaultFlex: 1,
        sortable: false,
      },
      {
        header: 'Pick Date',
        name: 'pickDate',
        userSelect: true,
        defaultFlex: 1,
        sortable: false,
        dataType: ColumnDataType.DATE,
      },
    ]
    return (
      <div style={{ maxHeight: 700, overflow: 'auto' }}>
        <Card title="Order List">
          <DataGridPlus
            idProperty="channelOrderId"
            columns={orderListColumns}
            dataSource={data.orderList || []}
            loading={loading}
            pagination="local"
            pageSizes={[5, 10]}
            {...({} as any)}
          />
        </Card>
        <Card title="Pick List">
          <DataGridPlus
            idProperty="pickingNum"
            columns={pickListColumns}
            dataSource={data.pickingList || []}
            loading={loading}
            pagination="local"
            pageSizes={[5, 10]}
            {...({} as any)}
          />
        </Card>
      </div>
    )
  }

  const orderSubGrid = (row: any) => {
    const { data } = row;
    const subGridColumns = [
      {
        header: 'SKU',
        name: 'sku',
        userSelect: true,
        defaultFlex: 1,
        sortable: false,
      },
      {
        header: 'UPC',
        name: 'upc',
        userSelect: true,
        defaultFlex: 1,
        sortable: false,
      },
      {
        header: 'Status',
        name: 'pickStatus',
        userSelect: true,
        defaultFlex: 1,
        sortable: false,
      }
    ]
    return (
      <div style={{ maxHeight: 700, overflow: 'auto' }}>
        <Card title="Pick List">
          <DataGridPlus
            idProperty="pickingNum"
            columns={subGridColumns}
            dataSource={data.picklist || []}
            loading={loading}
            pagination="local"
            pageSizes={[5, 10]}
            {...({} as any)}
          />
        </Card>
      </div>
    )
  }


  useEffect(() => {
    getPickingStatus()
  }, [debouncedSearchTerm, getPickingStatus, debouncedSearchOrderTerm])

  return (
    <ModalDialog
      centered
      title={`#${selectedPick.pickListNum} by ${selectedPick.createBy} on ${convertTimeByUtc(selectedPick.createDate)}`}
      closable={false}
      closeButton={true}
      footer={null}
      fullscreen={true}
      maskClosable={false}
      onClose={handleCancel}
      onFullscreen={onFullscreen}
      visible={visible}
      dialogSize="medium"
    >
      <Card>
        <TableHeader style={{ marginBottom: COMMON_PADDING_SPACE }}>
          {
            activeTab === 'sku' ? (
              <Row style={{ width: '100%' }}>
                <Col span={6}><FormLabel>Total Orders</FormLabel>{skuGroup.totalOrderQty}</Col>
                <Col span={6}><FormLabel>Hold Orders</FormLabel>{skuGroup.holdOrderQty}</Col>
                <Col span={6}><FormLabel>Partially Shipped Orders</FormLabel>{skuGroup.partiallyShippedOrderQty}</Col>
                <Col span={6}><FormLabel>Pending Orders</FormLabel>{skuGroup.shippedOrderQty}</Col>
                <Col span={6}><FormLabel>Shipped Orders</FormLabel>{skuGroup.holdOrderQty}</Col>
                <Col span={6}><FormLabel>Pending Pick SKU</FormLabel>{skuGroup.pendingPickSkuQty}</Col>
                <Col span={6}><FormLabel>Picking SKU</FormLabel>{skuGroup.pickingSkuQty}</Col>
                <Col span={6}><FormLabel>Picked SKU</FormLabel>{skuGroup.pickedSkuQty}</Col>
              </Row>
            ) : (
              <Row style={{ width: '100%' }}>
                <Col span={6}><FormLabel>Total Orders</FormLabel>{orderGroup.totalOrderQty}</Col>
                <Col span={6}><FormLabel>Hold Orders</FormLabel>{orderGroup.holdOrderQty}</Col>
                <Col span={6}><FormLabel>Partially Shipped Orders</FormLabel>{orderGroup.partiallyShippedOrderQty}</Col>
                <Col span={6}><FormLabel>Pending Orders</FormLabel>{orderGroup.pendingOrderQty}</Col>
                <Col span={6}><FormLabel>Pending Pick Orders</FormLabel>{orderGroup.pendingPickOrderQty}</Col>
                <Col span={6}><FormLabel>Picked Orders</FormLabel>{orderGroup.pickedOrderQty}</Col>
                <Col span={6}><FormLabel>Picking Orders</FormLabel>{orderGroup.pickingOrderQty}</Col>
                <Col span={6}><FormLabel>Shipped Orders</FormLabel>{orderGroup.shippedOrderQty}</Col>
              </Row>
            )
          }
        </TableHeader>
        <Space>
          <Input
            prefix={<SearchOutlined />}
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
            placeholder="input SKU"
            allowClear
            style={{ marginBottom: COMMON_PADDING_SPACE }}
          />
          <Input
            prefix={<SearchOutlined />}
            value={searchOrderValue}
            onChange={e => setSearchOrderValue(e.target.value)}
            placeholder="input Order#"
            allowClear
            style={{ marginBottom: 10 }}
          />
        </Space>
        <Tabs
          activeKey={activeTab}
          size="small"
          type="card"
          onChange={(value) => setActiveTab(value as 'sku' | 'order')}
        >
          <TabPane tab="Group By Single SKU" key="sku">
            <DataGridPlus
              idProperty="sku"
              columns={skuColumns}
              dataSource={skuGroup.data || []}
              loading={loading}
              renderDetailsGrid={skuSubGrid}
              rowExpandHeight={700}
              pagination="local"
              style={{ height: 400 }}
              {...({} as any)}
            />
          </TabPane>
          <TabPane tab="Group By Order" key="order">
            <DataGridPlus
              idProperty="channelOrderId"
              columns={orderColumns}
              dataSource={orderGroup.data || []}
              loading={loading}
              renderDetailsGrid={orderSubGrid}
              rowExpandHeight={400}
              style={{ height: 400 }}
              pagination="local"
              {...({} as any)}
            />
          </TabPane>
        </Tabs>
      </Card>
      {
        detailVisible && product && (
          <ProductDetail
            visible={detailVisible}
            onHide={() => setDetailVisible(false)}
            productId={product.productId}
          />
        )
      }
      {
        showLocationInfo && (
          <LocationInfoModal
            visible={showLocationInfo}
            onHide={() => setShowLocationInfo(false)}
            current={currentLocation}
            warehouseId={warehouseId}
          />
        )
      }
    </ModalDialog>
  );
};

export default PickingStatusDialog;

// T is a generic type for value parameter, our case this will be string
function useDebounce<T>(value: T, delay: number): T {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

