import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Row, Select, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import { playAudio } from 'utils';
import SKUFinishDialog from './SKUFinishDialog';
import SmartTable from './SmartTable';
import OrderFinishDialog from './OrderFinishDialog';
import SKUFailDialog from './SKUFailDialog';
import { pickingByOrder2 } from 'services/smart';
import {
  SmartSpace,
  SmartFormLabel,
  SmartLabel,
  SmartRow,
  SmartText,
  SmartScanner,
  SmartInputNumber,
  SmartBlock,
  SmartButton,
  GreenColor,
  YellowColor,
  SmartBackButton,
  // SmartPickAll,
  SmartInput,
  SmartSpin,
} from '../../../SmartComponent';
import { useTranslation } from 'react-i18next';

type ScanQtyProps = {
  order: SmartPickOrder;
  product: SmartPickOrderItem;
  location: WarehouseLocationRow;
  onChangeOrder: (nextOrder: string | undefined) => void;
  onChangeSKU: (nextProduct: SmartPickOrderItem) => void;
  onBack: (
    step: 'scanOrder' | 'scanSKU' | 'scanLocation' | 'scanQty',
    nextLocation?: SmartLocationRow
  ) => void;
  onFinish: (scannedQty: number) => Promise<void>;
  pickedQty: number;
  uomEnable: boolean;
  lotEnable: boolean;
  lotNumbers: string[];
  lotControl: number;
  defaultLotNumber?: string;
};
/**
 * Scan Qty
 * @param {ScanQtyProps} props
 * @returns {JSX.Element}
 */
export default function ScanQty(props: ScanQtyProps): JSX.Element {
  const {
    onBack,
    product,
    onFinish,
    location,
    onChangeSKU,
    onChangeOrder,
    order,
    // pickedQty,
    uomEnable,
    lotEnable,
    lotNumbers,
    lotControl,
    defaultLotNumber
  } = props;
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const [pickQty, setPickQty] = useState(0);
  const [scannedQty, setScannedQty] = useState(0);
  const [qtyDetail, setQtyDetail] = useState<{lotNumber?: string, qty: number}[]>([])
  const [scannedAt, setScannedAt] = useState<Date | undefined>();
  const [selectedLot, setSelectedLot] = useState<string | undefined>()
  const [status, setStatus] = useState<'default' | 'success' | 'fail'>(
    'default'
  );
  const failSKURef = useRef('');
  const [finish, setFinish] = useState<'default' | 'allFinished' | 'finished'>(
    'default'
  );

  React.useEffect(()=>{
    if(lotEnable && lotControl === 1 && lotNumbers &&lotNumbers.length > 0 ){
      setSelectedLot(lotNumbers[0])
      
    }
  },[selectedLot, lotNumbers, lotControl, lotEnable])

  useEffect(() => {
    const {fulfillmentOrderLineNum } = product;
    const orderSKU = order.items.find((item) => {
      if (item.fulfillmentOrderLineNum === fulfillmentOrderLineNum) {
        return item;
      }
      return undefined;
    });
    if (orderSKU) {
      setPickQty(orderSKU.pickQty);
      setScannedQty(orderSKU.pickedQty);
    }
  }, [order, product]);
  /**
   * Post qty
   */
  const handleSubmit = useCallback(
    async (qty: number, detail:any[], isAuto?: boolean) => {
      setSubmitting(true);
      try {
        const params = detail.map(i=>{return {
          FulfillmentOrderNum: order.fulfillmentOrderNum,
          LocationCode: location.locationCode || '',
          FulfillmentOrderLineNum: product.fulfillmentOrderLineNum,
          Title: product.title,
          SkuOrUpc: product.sku || product.upc,
          PickQty: product.pickQty,
          PickedQty: i.qty,
          FulfillmentProductNum: product.fulfillmentProductNum,
          LotNumber: i.lotNumber,
          UOM: product.uom,
          UomRate: product.uomRate,
        }})
        const res = await pickingByOrder2(params);
        if (res.isSuccess) {
          setSubmitting(false);
          setStatus('default');
          if (!isAuto) {
            onBack('scanLocation');
          } else {
            const { items } = order;
            let orderFinished = true;
            items.forEach((item) => {
              if (item.pickQty > item.pickedQty) {
                orderFinished = false;
              }
            });
            setFinish(orderFinished ? 'allFinished' : 'finished');
          }
        }
      } catch (error) {
        setStatus('default');
        setScannedQty((prev) => prev - 1);
        onFinish(scannedQty);
        setSubmitting(false);
      }
    },
    [product, location, onBack, order, onFinish, scannedQty]
  );

  const handleSkip = useCallback(
    async (qty: number, detail:any[]) => {
      setSubmitting(true);
      try {
        const params = detail.map(i=>{return {
          FulfillmentOrderNum: order.fulfillmentOrderNum,
          LocationCode: location.locationCode || '',
          FulfillmentOrderLineNum: product.fulfillmentOrderLineNum,
          Title: product.title,
          SkuOrUpc: product.sku || product.upc,
          PickQty: product.pickQty,
          PickedQty: i.qty,
          FulfillmentProductNum: product.fulfillmentProductNum,
          LotNumber:i.lotNumber||'',
          UOM: product.uom,
          UomRate: product.uomRate,
        }})
        const res = await pickingByOrder2(params);
        if (res.isSuccess) {
          setSubmitting(false);
          setStatus('default');
          onBack('scanSKU');
        }
      } catch (error) {
        setStatus('default');
        setSubmitting(false);
      }
    },
    [product, location, onBack, order]
  );

  const handleComplete = useCallback(
    async (qty: number, detail:any[]) => {
      setSubmitting(true);
      try {
        const params = detail.map(i=>{return {
          FulfillmentOrderNum: order.fulfillmentOrderNum,
          LocationCode: location.locationCode || '',
          FulfillmentOrderLineNum: product.fulfillmentOrderLineNum,
          Title: product.title,
          SkuOrUpc: product.sku || product.upc,
          PickQty: product.pickQty,
          PickedQty: i.qty,
          FulfillmentProductNum: product.fulfillmentProductNum,
          LotNumber:i.lotNumber||'',
          UOM: product.uom,
          UomRate: product.uomRate,
        }})
        const res = await pickingByOrder2(params);
        if (res.isSuccess) {
          setSubmitting(false);
          setStatus('default');
          onBack('scanOrder');
        }
      } catch (error) {
        setStatus('default');
        setSubmitting(false);
      }
    },
    [product, location, onBack, order]
  );



  /**
   * hanle finish status
   */
  const handleFinishStatus = useCallback(
    async (nextScannedQty: number, detail:any[]) => {
      onFinish(nextScannedQty);

      //Whether the SKUS has been scanned
      if (pickQty > 0 && nextScannedQty === pickQty) {
        handleSubmit(nextScannedQty, detail, true);
      }
    },
    [pickQty, handleSubmit, onFinish]
  );

  const addQtyDetail = useCallback((qty:number, lotNumber: string)=>{
    const temp = [...qtyDetail]
    const index = temp.findIndex(i => i.lotNumber === lotNumber)
    if(index > -1){
      temp[index] = {
        ...temp[index],
        qty: temp[index].qty + qty
      }
      setQtyDetail([...temp])
      return [...temp]
    } else {
      setQtyDetail([{lotNumber, qty},...temp])
      return [{lotNumber, qty},...temp]
    }
  },[qtyDetail])
  /**
   * handle scan sku  event
   */
  const handleScannerChange = useCallback(
    async (nextValue: string) => {
      if (
        nextValue === product.sku ||
        (nextValue === product.upc && product.upc) ||
        (product.productAllCode||[]).indexOf(nextValue) > -1
      ) {
        playAudio('success');
        setStatus('success');
        const nextScannedQty =
          scannedQty + 1 >= pickQty ? pickQty : scannedQty + 1;

        setScannedQty(nextScannedQty);
        const detail = addQtyDetail(1, defaultLotNumber || selectedLot ||'')
        handleFinishStatus(nextScannedQty, detail);
      } else {
        playAudio('error');
        failSKURef.current = nextValue;
        setStatus('fail');
      }
      setScannedAt(new Date());
    },
    [scannedQty, product, handleFinishStatus, pickQty, selectedLot, addQtyDetail, defaultLotNumber]
  );
  /**
   * handle finish event
   */
  const handleSKUFinish = useCallback(
    (nextSKU: SmartPickOrderItem) => {
      // onBack('scanLocation');
      onChangeSKU(nextSKU);
    },
    [onChangeSKU]
  );
  const scannedAtStr = dayjs(scannedAt).format('hh:mm:ss A');
  const copywrite = t("smart.pick.skipLocation");
  const successTip = t("smart.pick.successTip", { value : scannedAtStr});
  const failTip = t("smart.pick.failedTip", { value : scannedAtStr});

  return (
    <>
      {finish === 'finished' && (
        <SKUFinishDialog
          uomEnable={uomEnable}
          order={order}
          product={product}
          onSubmit={handleSKUFinish}
          onSkipOrder={() => {
            onBack('scanOrder');
          }}
        />
      )}
      {finish === 'allFinished' && (
        <OrderFinishDialog
          order={order}
          onSubmit={(value) => onChangeOrder(value)}
        />
      )}
      {status === 'fail' && (
        <SKUFailDialog
          failSKU={failSKURef.current}
          sku={product.sku || ''}
          upc={product.upc || ''}
          productAllCode={product.productAllCode || []}
          onSubmit={(ok) => {
            if (ok) {
              handleScannerChange(product.sku || '');
            } else {
              setStatus('default');
            }
            setTimeout(() => {
              document.getElementById('scanner')?.focus();
            });
          }}
        />
      )}
      <SmartSpin loading={submitting} />
      <SmartSpace>
        <SmartRow style={{ marginTop: 10 }}>
          <SmartLabel>
            <SmartFormLabel>{t("common.order")}</SmartFormLabel>
          </SmartLabel>
          <SmartText>{order.fulfillmentOrderNum}</SmartText>
        </SmartRow>
        <SmartRow style={{ marginTop: 10 }}>
          <SmartLabel>
            <SmartFormLabel>
              {t("smart.pick.pickSku")}
            </SmartFormLabel>
          </SmartLabel>
          <SmartText>{product.sku}</SmartText>
        </SmartRow>
        <SmartRow style={{ marginTop: 10 }}>
          <SmartLabel>
            <SmartFormLabel>
              {t("smart.pick.pickUpc")}
            </SmartFormLabel>
          </SmartLabel>
          <SmartText>{product.upc}</SmartText>
        </SmartRow>
        {uomEnable && <SmartRow style={{ marginTop: 10 }}>
          <SmartLabel>
            <SmartFormLabel>
            {t("common.uom")}
            </SmartFormLabel>
          </SmartLabel>
          <SmartText>{product.uom}</SmartText>
        </SmartRow>}
        <SmartRow style={{ marginTop: 10, justifyContent: 'space-between' }}>
          <SmartRow>
            <SmartLabel>
              <SmartFormLabel>
                {t("common.location")}
              </SmartFormLabel>
            </SmartLabel>
            <SmartText>{location?.locationCode}</SmartText>
          </SmartRow>
          {/* {scannedQty === 0 && ( */}
          <Button
            size="small"
            onClick={() => {
              onBack('scanLocation');
            }}
          >
            {t("smart.pick.changeLocation")}
          </Button>
          {/* )} */}
        </SmartRow>
        <SmartRow style={{ marginTop: 10 }}>
          <SmartLabel>
            <SmartFormLabel>
              {t("common.title")}
            </SmartFormLabel>
          </SmartLabel>
          <SmartText>{product ? product.title : ''}</SmartText>
        </SmartRow>
        <SmartRow style={{ marginTop: 10 }}>
          <SmartLabel>
            <SmartFormLabel>
              {t("smart.pick.pickQty")}
            </SmartFormLabel>
          </SmartLabel>
          <SmartInputNumber
            value={pickQty}
            readOnly
            style={{ width: 95, backgroundColor: 'rgb(255,253,128)' }}
          />
        </SmartRow>
        <SmartRow style={{ marginTop: 10 }}>
          <SmartLabel>
            <SmartFormLabel>
              {t("smart.pick.awaitPickQty")}
            </SmartFormLabel>
          </SmartLabel>
          <SmartInputNumber
            value={pickQty - scannedQty}
            readOnly
            style={{ width: 95, backgroundColor: 'rgb(255,253,128)' }}
          />
        </SmartRow>
        {lotEnable && <SmartRow style={{ marginTop: 10 }}>
          <SmartLabel>
            <SmartFormLabel>
            {t("common.lotNumber")}
            </SmartFormLabel>
          </SmartLabel>
          {defaultLotNumber ? <Typography.Text>{defaultLotNumber}</Typography.Text> : <Select
            // disabled={lotControl === 0} 
            allowClear={lotControl === 0}
            style={{width: 190}} 
            defaultValue={lotControl === 1 && lotNumbers.length > 0 ? lotNumbers[0]: undefined}
            onChange={setSelectedLot}
          >
            {lotNumbers.map(i => <Select.Option key={i} value={i}>{i}</Select.Option>)}
          </Select>}
        </SmartRow>}
        <SmartRow style={{ marginTop: 10 }}>
          <SmartLabel>
            <SmartFormLabel>
              {t("smart.pick.scan")}
            </SmartFormLabel>
          </SmartLabel>
          <SmartScanner
            id="scanner"
            style={{ width: 190 }}
            // disabled={lotEnable && !selectedLot && lotNumbers.length !==0}
            onChangeValue={handleScannerChange}
          />
          {status === 'success' && (
            <SmartRow style={{ color: GreenColor }}>{successTip}</SmartRow>
          )}
          {status === 'fail' && (
            <SmartRow style={{ color: YellowColor }}>{failTip}</SmartRow>
          )}
        </SmartRow>

        {qtyDetail.map(i => 
          <SmartRow key={`${i.lotNumber}-key`} style={{ marginTop: 10, justifyContent: 'space-between' }}>
          <Row align="middle">
            <SmartLabel>
              <SmartFormLabel>
                {t("smart.pick.scannedQty")}
              </SmartFormLabel>
            </SmartLabel>
            <SmartInputNumber
              value={i.qty}
              readOnly
              style={{ width: 95, backgroundColor: 'rgb(208,248,157)' }}
            />
          </Row>
          {lotEnable && <Row align="middle">
            <SmartLabel>
              <SmartFormLabel>
                {t("common.lotNumber")}
              </SmartFormLabel>
            </SmartLabel>
            <Tooltip
              title={i.lotNumber}
              trigger="click"
            >
            <SmartInput              
              value={i.lotNumber}
              readOnly
              style={{ width: 150, backgroundColor: 'rgb(208,248,157)' }}
            />
            </Tooltip>
          </Row>}
        </SmartRow>
          
        )}
      </SmartSpace>

      <SmartTable showLot={false} showUom={uomEnable} dataSource={order.items} />
      <div style={{ marginTop: 20 }}>
      <SmartBackButton>
          <SmartBlock>
            {scannedQty > 0 && (
              <SmartButton
                backgroundColor="red"
                color="write"
                loading={submitting}
                style={{ width: 'auto' }}
                onClick={() => {
                  // onBack('scanLocation');
                  handleSubmit(scannedQty, qtyDetail);
                }}
              >
                {copywrite}
              </SmartButton>
            )}

            {pickQty !== scannedQty && (
              <SmartButton
                type="default"
                backgroundColor="red"
                color='#fff'
                onClick={() => {
                  if(scannedQty > 0){
                    handleSkip(scannedQty, qtyDetail)
                  }
                  onBack('scanSKU');
                }}
              >
              <Typography.Text
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  whiteSpace: 'normal',
                  width: 230,
                  color: '#fff'
                }}
              >
                {t("smart.pick.chooseAnotherSku")}
                </Typography.Text>
              </SmartButton>
            )}
            <SmartButton
              type="default"
              backgroundColor="red"
              onClick={() => {
                handleComplete(scannedQty, qtyDetail)
              }}
            >
              <Typography.Text
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  whiteSpace: 'normal',
                  width: 230,
                  color: '#fff'
                }}
              >
                {t("smart.pick.chooseAnotherOrder")}
              </Typography.Text>
            </SmartButton>
          </SmartBlock>
        </SmartBackButton>
      </div>
    </>
  );
}
