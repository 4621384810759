import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Modal,
  Row,
  Button,
  Space,
  Card,
  Col,
  Descriptions,
  InputNumber,
  Input,
  message,
  Tooltip,
  Typography,
} from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  DiffOutlined,
  EditOutlined,
  LeftOutlined,
  PrinterOutlined,
  RightOutlined,
  FilePdfOutlined,
  FilterOutlined,
  ExclamationCircleOutlined,
  WarningOutlined
} from '@ant-design/icons';
import {
  DataGridPlus,
  CopySpan,
  FormLabel,
  ModalDialog,
  TablesFilter,
} from 'components/common';
import {
  COMMON_PADDING_SPACE,
  DATE_TIME_NO_SECOND_FORMAT,
  LS_DEFAULT_WAREHOUSE_KEY,
} from 'constants/config';
import {
  downloadRemoteReturn,
  fetchReturns,
  syncReturnTrackingNumberToERP,
  // delReturn,
  abandonReturn,
  // refeshReturn,
  commitQtytoErp,
} from 'services/return';
import { GridIconBox } from 'components/common/styles';
import PrintModal from './PrintModal';
import CloseDetailModal from './CloseDetailModal';
import EditAddressDialog from './EditAddressDialog';
import { convertTimeByUtc, formatAddress, getWarehouseIdFromCache, isTestEnv } from 'utils';
import debounce from 'lodash.debounce';
import styled from 'styled-components';
import { userProfiles } from 'utils/auth';

export const UnderlineButton = styled.div`
  display: inline-block;
  color: #1976d2;
  text-decoration: underline;
  font-weight: 400;
  font-size: 14px;
  padding: 0px 15px;

  &:hover {
    cursor: pointer;
  }
`;

const statusEnum: { [key: string]: string } = {
  '0': 'Pending',
  '1': 'Processing',
  '2': 'Completed',
  '8': 'Abandoned'
};

type Props = {
  dialogData: any;
  isCreate: boolean;
  warehouseId: string;
  warehouseCode: string;
  onHide: () => void;
  onRefresh: () => void;
  changeCurrent?: (obj: any) => void;
  noDownLoad?: boolean;
  onReceive: Function;
  onRestock: Function;
  onComplete: Function;
  onReceivePlus: Function;
  onShippingLabel?: Function;
  // onExpressReceive?: Function;
  onExpressReceiveWithNoReturnQty?: Function;
  // onRefreshRma: Function;
  // onDeleteRma: Function;
};
// eslint-disable-next-line
export default (props: Props) => {
  const {
    onHide,
    onRefresh,
    dialogData,
    noDownLoad = false,
    warehouseId,
    warehouseCode,
    onReceive,
    onRestock,
    onComplete,
    onReceivePlus,
    onShippingLabel,
    // onExpressReceive,
    onExpressReceiveWithNoReturnQty,
    // onRefreshRma,
    // onDeleteRma,
  } = props;
  const [lines, setLines] = useState<any[]>([]);
  const [downloadAble, setDownloadAble] = useState(false);
  const [expressAble, setExpressAble] = useState(false);
  const [downLoading, setDownloading] = useState(false);
  const [isNoDownload, setIsNoDownload] = useState<boolean>(noDownLoad);
  const [data, setData] = useState(dialogData);
  const [deleteSku, setDeleteSku] = useState<string>('');
  const [printModalVisible, setPrintModalVisible] = useState<boolean>(false);
  const [closeDetailDialogVisible, setCloseDetailDialogVisible] =
    useState<boolean>(false);
  const [editAddressDialogVisible, setEditAddressDialogVisible] =
    useState<boolean>(false);
  const [expandRight, setExpandRight] = useState<boolean>(false);
  const [filterList, setFilterList] = useState<any[]>([]);
  const [filterValue, setFilterValue] = useState<string>('');
  const [syncLoading, setSyncLoading] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const tempInputRef = useRef<string>('');
  const [isCreate, setIsCreate] = useState(props.isCreate)
  const enableExpress = userProfiles.getProfileSettingValue('EnableExpressRMAReturn');

  const debounceFn = debounce((val: string) => {
    setFilterValue(val);
  }, 300);

  /**
   * handle hide event
   */
  const handleHide = useCallback(() => {
    onHide();
  }, [onHide]);

  const handleDownload = useCallback(async () => {
    const targets = lines.filter((i) => i.returnQty);
    const params = targets.map((item) => {
      return {
        TransUuid: item.transUuid,
        WarehouseCode: warehouseCode,
        InvoiceNumber: data.invoiceNo,
        InvoiceItemUuid: item.invoiceItemsUuid,
        Sku: item.sku,
        ReturnQty: item.returnQty,
        Reason: item.reason,
      };
    });
    try {
      setDownloading(true);
      const res = await downloadRemoteReturn(warehouseId, params);
      setDownloading(false);
      if (res && res.isSuccess) {
        message.success({ content: isCreate ? 'Create successfully' : 'Download successfully' });
        setData(res.data);
        setIsNoDownload(true);
        setIsCreate(false)
      }
    } catch (error) {
      // message.error({ content: error })
      setDownloading(false);
    }
    // onRefresh();
  }, [data, lines, warehouseId, warehouseCode, isCreate]);

  const refreshData = useCallback(async () => {
    try {
      const query = {
        $top: 10,
        $skip: 0,
        $sortBy: 'FulfillmentReturnNum desc',
        returnNo: data.returnNo,
        oper: 0,
      };
      const res = await fetchReturns(warehouseId, query);
      if (res.data.length > 0) {
        setData(res.data[0]);
      }
    } catch (error) {
      console.log(error)
    }
  }, [data.returnNo, warehouseId]);

  useEffect(() => {
    if(isCreate){
      setLines(data.invoiceItems || [])
    } else {
      setLines(data.items || []);
    }
    
  }, [data, isCreate]);

  const syncToERP = useCallback(async () => {
    try {
      setSyncLoading(true);
      const res = await syncReturnTrackingNumberToERP(
        data.fulfillmentReturnNum
      );
      setSyncLoading(false);
      if (res.isSuccess) {
        message.success({ content: 'Synced successfully' });
      }
    } catch (error) {
      setSyncLoading(false);
    }
  }, [data]);

  const commitReceivedQty = useCallback(async () => {
    try {
      const res = await commitQtytoErp(getWarehouseIdFromCache(data.warehouseNum), data.fulfillmentReturnNum)
      if(res){
        message.success('Commit successfully')
      }
    } catch (error) {
      
    }
  },[data])

  const getTotalShipQty = useCallback(() => {
    let temp = 0;
    lines.map((i) => {
      temp = temp + i.shipQty;
      return true;
    });
    return temp;
  }, [lines]);

  // const deleteReturn = useCallback(async (data: any, closeModal?: boolean) => {
  //   try {
  //     const res = await delReturn(getWarehouseIdFromCache(data.warehouseNum), data.fulfillmentReturnNum)
  //     if (res.isSuccess) {
  //       message.success('deleted successfully')
  //       onHide()
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }, [onHide])
  const handleAbandonReturn = useCallback(async (data: any) => {
    try {
      const res = await abandonReturn(getWarehouseIdFromCache(data.warehouseNum), data.fulfillmentReturnNum)
      if (res.isSuccess) {
        message.success('Now you can edit this RMA in ERP');
        onRefresh();
        onHide();
      }
    } catch (error) {
      console.log(error)
    }
  }, [onHide, onRefresh])

  // const refreshRMAReturn = useCallback(async (data: any) => {
  //   try {
  //     const res = await refeshReturn(getWarehouseIdFromCache(data.warehouseNum), data.fulfillmentReturnNum)
  //     if (res.isSuccess) {
  //       message.success('refresh successfully')
  //       refreshData()
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }, [refreshData])



  const createColumns = [
      {
        header: 'SKU',
        name: 'sku',
        minWidth: 220,
        resizable: true,
        draggable: true,
        sortable: true,
        userSelect: true,
        defaultFlex: 1,
      },
      {
        header: 'UPC',
        name: 'upc',
        minWidth: 220,
        resizable: true,
        draggable: true,
        sortable: true,
        userSelect: true,
        defaultFlex: 1,
      },
      {
        header: 'Shiped Qty',
        name: 'shipQty',
        resizable: true,
        draggable: true,
        sortable: true,
        userSelect: true,
        defaultFlex: 1,
      },
      {
        header: 'Max Claim Qty',
        name: 'maxClaimQty',
        resizable: true,
        draggable: true,
        sortable: true,
        userSelect: true,
        defaultFlex: 1,
      },
      {
        header: 'Claim Qty',
        name: 'returnQty',
        resizable: true,
        draggable: true,
        sortable: true,
        defaultFlex: 1,
        render: (value: any) => {
          const { data, rowIndex } = value;
          return data.transUuid || isNoDownload ? (
            <span>{data.returnQty}</span>
          ) : (
            <InputNumber
              id={`grid_return_qty_row${rowIndex}_input`}
              value={data.returnQty}
              disabled={data.isparentbundle || data.maxClaimQty === 0}
              min={0}
              max={data.maxClaimQty}
              onChange={(qty) =>
                setLines((prev) => {
                  const temp = prev;
                  const index = prev.findIndex(
                    (item) => item.invoiceItemsUuid === data.invoiceItemsUuid
                  );
                  if (index > -1) {
                    temp[index] = {
                      ...temp[index],
                      returnQty: qty,
                    };
                    return [...temp];
                  } else {
                    return prev;
                  }
                })
              }
            />
          );
        },
      },
      {
        header: 'Receive Qty',
        name: 'receiveQty',
        resizable: true,
        draggable: true,
        sortable: true,
        userSelect: true,
        display: isNoDownload ? true : false,
      },
      {
        header: 'Resalable Qty',
        name: 'stockQty',
        resizable: true,
        draggable: true,
        sortable: true,
        userSelect: true,
        display: isNoDownload ? true : false,
      },
      {
        header: 'Inspection Qty',
        name: 'inspectionQty',
        resizable: true,
        draggable: true,
        sortable: true,
        userSelect: true,
        display: isNoDownload ? true : false,
      },
      {
        header: 'Damage Qty',
        name: 'nonStockQty',
        resizable: true,
        draggable: true,
        sortable: true,
        userSelect: true,
        display: isNoDownload ? true : false,
      },
      {
        header: 'Reason',
        name: 'reason',
        resizable: true,
        draggable: true,
        sortable: true,
        userSelect: true,
        defaultFlex: 1,
        render: (value: any) => {
          const { data, rowIndex } = value;
          return data.transUuid || isNoDownload ? (
            <span>{data.reason}</span>
          ) : (
            <>
              {data.reason || ''}
              <GridIconBox>
                <EditOutlined
                  id={`grid_return_qty_reason${rowIndex}_input`}
                  onClick={() => {
                    tempInputRef.current = data.reason || '';
                    Modal.confirm({
                      title: 'please input reason.',
                      content: (
                        <Input
                          defaultValue={tempInputRef.current}
                          onChange={({ target: { value } }) => {
                            tempInputRef.current = value;
                          }}
                        />
                      ),
                      icon: null,
                      onOk: async () => {
                        setLines((prev) => {
                          const temp = prev;
                          const index = prev.findIndex(
                            (item) =>
                              item.invoiceItemsUuid === data.invoiceItemsUuid
                          );
                          if (index > -1) {
                            temp[index] = {
                              ...temp[index],
                              reason: tempInputRef.current,
                            };
                            return [...temp];
                          } else {
                            return prev;
                          }
                        });
                        tempInputRef.current = '';
                      },
                    });
                  }}
                />
              </GridIconBox>
            </>
          );
        },
      },
      {
        header: 'Trans',
        name: 'transUuid',
        resizable: true,
        draggable: true,
        sortable: true,
        userSelect: true,
        defaultFlex: 1,
      },
      {
        header: 'Product Name',
        name: 'description',
        resizable: true,
        draggable: true,
        sortable: true,
        userSelect: true,
        minWidth: 200,
        maxWidth: 200,
        defaultFlex: 1,
      },
      {
        header: 'Action',
        name: 'action',
        draggable: true,
        showColumnMenuTool: false,
        sortable: false,
        defaultLocked: 'end',
        minWidth: 120,
        maxWidth: 120,
        defaultFlex: 1,
        render: (value: any) => {
          return (
            <Button
              className="hover-danger-button"
              disabled={data.status === 2}
              icon={<DeleteOutlined />}
              onClick={() => {
                setDeleteSku(value.data.sku);
                setCloseDetailDialogVisible(true);
              }}
            >
              Delete
            </Button>
          );
        },
      },
    ];

    const columns = createColumns.filter(i => i.name !== 'maxClaimQty')


  const list = useMemo(() => {
    return [
      {
        label: 'RMA#',
        render: <CopySpan value={data.returnNo} />,
      },
      {
        label: 'Return Date',
        render: (
          <CopySpan
            value={convertTimeByUtc(
              data.transDate,
              '',
              '',
              DATE_TIME_NO_SECOND_FORMAT
            )}
          />
        ),
      },
      {
        label: 'Return W#',
        render: <CopySpan value={data.warehouseCode} />,
      },
      {
        label: 'Invoice #',
        render: <CopySpan value={data.invoiceNo} />,
      },
      {
        label: 'Invoice Date',
        render: (
          <CopySpan
            value={convertTimeByUtc(
              data.invoiceDate,
              '',
              '',
              DATE_TIME_NO_SECOND_FORMAT
            )}
          />
        ),
      },
      {
        label: 'Invoice Total Qty',
        render: <CopySpan value={getTotalShipQty().toString()} />,
      },
      {
        label: 'Channel',
        render: <CopySpan value={data.channelName} />,
      },
      {
        label: 'Store Name',
        render: <CopySpan value={data.channelAccountName} />,
      },
      {
        label: 'Channel Order ID',
        render: <CopySpan value={data.channelOrderId} />,
      },
      {
        label: 'SO #',
        render: <CopySpan value={data.salesOrderNumber} />,
      },
      {
        label: 'Name',
        render: <CopySpan value={data.endBuyerName} />,
      },
      {
        label: 'Status',
        render: <CopySpan value={statusEnum[`${data.status}`]} />,
      },
      {
        label: 'Reference #',
        render: <CopySpan value={data.referenceNo} />,
      },
      {
        label: 'Return Tracking #',
        render: (
          <Space>
            <CopySpan value={data.returnTrackingNumber} />
            {data.returnTrackingNumber && (
              <Button
                size="small"
                loading={syncLoading}
                onClick={() => {
                  syncToERP();
                }}
              >
                Sync to ERP
              </Button>
            )}
          </Space>
        ),
      },
      {
        label: 'Order Qty',
        render: <CopySpan value={data.orderQty} />,
      },
      {
        label: 'Ship Qty',
        render: <CopySpan value={data.shipQty} />,
      },
      {
        label: 'Claim Qty',
        render: <CopySpan value={data.returnQty} />,
      },
      {
        label: 'Receive Qty',
        // render: <CopySpan value={data.receiveQty} />,
        render: (<Space>
          <CopySpan value={data.receiveQty} />
          {(data.status === 0 || data.status === 1) && data.returnNo && data.receiveQty > 0 && isTestEnv() && <Button
            size="small"
            onClick={() => {
              Modal.confirm({
                title: `RMA# [${data.returnNo}] committed sku(s) to warehouse [${data.warehouseCode}]`,
                icon: <WarningOutlined />,
                content: <Space direction="vertical">
                  {lines.map((i:any)=><Typography.Text>{`${i.sku} (${i.receiveQty})`}</Typography.Text>)}
                </Space>,
                okText: 'Commit Received Qty',
                onOk() {
                  commitReceivedQty()
                },
                onCancel() {
                },
              });
            }}
          >
            Commit Received Qty
          </Button>
      }
      </Space>)
      },
      {
        label: 'Resaleable Qty',
        render: <CopySpan value={data.stockQty} />,
      },
      {
        label: 'Damage Qty',
        render: <CopySpan value={data.nonStockQty} />,
      },
      {
        label: 'Receive By',
        render: <CopySpan value={data.receiveBy} />,
      },
      {
        label: 'Receive Date',
        render: (
          <CopySpan
            value={convertTimeByUtc(
              data.receiveDate,
              '',
              '',
              DATE_TIME_NO_SECOND_FORMAT
            )}
          />
        ),
      },
    ];
  }, [data, getTotalShipQty, syncLoading, syncToERP, commitReceivedQty, lines]);

  useEffect(() => {
    if (filterValue) {
      const temp = list.filter(
        (i: any) =>
          i.label.toLowerCase().indexOf(filterValue.trim().toLowerCase()) > -1
      );
      setFilterList([...temp]);
    } else {
      setFilterList([...list]);
    }
  }, [filterValue, list]);

  useEffect(() => {
    const index = lines.findIndex((i) => i.returnQty);
    if (index > -1) {
      setDownloadAble(true);
    } else {
      setDownloadAble(false);
    }
  }, [lines]);

  useEffect(() => {
    const index = lines.findIndex((i) => i.maxClaimQty);
    if (index > -1) {
      setExpressAble(true);
    } else {
      setExpressAble(false);
    }
  }, [lines]);

  const handleButtonClick = (type: string) => {
    switch (type) {
      case 'receive':
        if (typeof onReceive === 'function') onReceive(data);
        break;
      case 'restock':
        if (typeof onRestock === 'function') onRestock(data);
        break;
      case 'complete':
        if (typeof onComplete === 'function') onComplete(data);
        break;
      case 'receiveplus':
        if (typeof onReceivePlus === 'function') onReceivePlus(data);
        break;
      case 'ExpressReceiveNoReturnQty':
        if (typeof onExpressReceiveWithNoReturnQty === 'function') onExpressReceiveWithNoReturnQty(data)
        break;
      // case 'ExpressReceive':
      //   if (typeof onExpressReceive === 'function') onExpressReceive(data);
      //   break;
    }
  };

  return (
    <ModalDialog
      centered
      fullscreen={true}
      title={isNoDownload ? 'WMS Return Detail' : 'ERP Return Detail'}
      maskClosable={false}
      closeButton={true}
      closable={false}
      visible={true}
      confirmLoading={downLoading}
      bodyHeight={630}
      destroyOnClose={true}
      onClose={handleHide}
      getContainer={false}
      footer={
        <Row justify="space-between">
          {isNoDownload ? (
            <Space>
              <Button
                icon={<DiffOutlined />}
                onClick={() => handleButtonClick('receive')}
                disabled={
                  data.status === 2 ||
                  data.status === 8 ||
                  data.warehouseNum !==
                  Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
                }
              >
                Receive
              </Button>
              {/* <Button
                icon={<DiffOutlined />}
                onClick={() => handleButtonClick('restock')}
                disabled={
                  data.status === 2 ||
                  data.warehouseNum !==
                  Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
                }
              >
                Restock
              </Button> */}
              <Button
                icon={<CheckOutlined />}
                onClick={() => handleButtonClick('complete')}
                disabled={data.status === 2 || data.status === 8}
              >
                Complete
              </Button>
              <Button
                icon={<DiffOutlined />}
                onClick={() => handleButtonClick('receiveplus')}
                disabled={
                  data.status === 2 ||
                  data.status === 8 ||
                  data.warehouseNum !==
                  Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
                }
              >
                Receive Plus
              </Button>
              {/* {data.status !== 2 && <Button
                icon={<DiffOutlined />}
                onClick={() => {
                  Modal.confirm({
                    title: 'Are you sure you want to refresh this return?',
                    icon: <ExclamationCircleOutlined />,
                    content: `RMA# : ${data.returnNo}`,
                    okText: 'Yes',
                    okType: 'default',
                    okButtonProps: { className: 'hover-danger-button' },
                    cancelText: 'No',
                    autoFocusButton: 'cancel',
                    onCancel() { },
                    onOk() {
                      refreshRMAReturn(data)
                    },
                  });
                }}
              >
                Refresh
              </Button>} */}
              {/* {data.status === 0 && <Button
                icon={<DeleteOutlined />}
                onClick={() => {
                  Modal.confirm({
                    title: 'Are you sure you want to delete this return?',
                    icon: <ExclamationCircleOutlined />,
                    content: `RMA# : ${data.returnNo}`,
                    okText: 'Yes',
                    okType: 'default',
                    okButtonProps: { className: 'hover-danger-button' },
                    cancelText: 'No',
                    autoFocusButton: 'cancel',
                    onCancel() { },
                    onOk() {
                      deleteReturn(data)
                    },
                  });
                }}
              >
                Delete
              </Button>} */}
              {data.status === 0 && (
                <Button
                  className="hover-danger-button-2"
                  icon={<EditOutlined />}
                  onClick={() => {
                    Modal.confirm({
                      title: 'Are you sure you want to Edit this return?',
                      icon: <ExclamationCircleOutlined />,
                      content: `RMA# : ${data.returnNo}`,
                      okText: 'Yes',
                      okType: 'default',
                      okButtonProps: { className: 'hover-danger-button' },
                      cancelText: 'No',
                      autoFocusButton: 'cancel',
                      onCancel() { },
                      onOk() {
                        handleAbandonReturn(data);
                      },
                    });
                  }}
                >
                  Edit
                </Button>
              )}
            </Space>
          ) : (
            <></>
          )}
          <Space>
            {!isNoDownload && (
              <Button
                type="primary"
                loading={downLoading}
                disabled={!downloadAble}
                onClick={handleDownload}
              >
                {isCreate
                  ? 'Create RMA'
                  : 'Download'}
              </Button>
            )}
            {isNoDownload && (
              <Button
                onClick={() => onShippingLabel && onShippingLabel(data)}
                type="primary"
                icon={<FilePdfOutlined />}
              >
                Export Shipping Label
              </Button>
            )}
            {isNoDownload && (
              <Button
                onClick={() => setPrintModalVisible(true)}
                type="primary"
                icon={<PrinterOutlined />}
              >
                Print Packing List
              </Button>
            )}
            <Button onClick={handleHide} icon={<CloseOutlined />}>
              Cancel
            </Button>
          </Space>
          {enableExpress === '1' && !isNoDownload && isCreate &&(<Space>
            <Tooltip placement="topLeft" title="Fast create and receive RMA">
              <Button
                disabled={!expressAble}
                type="primary"
                onClick={()=>{handleButtonClick('ExpressReceiveNoReturnQty')}}
              >
                Express Return
              </Button>
              </Tooltip>
          </Space>)}
          {/* {isTestEnv() && !isNoDownload && !isCreate &&(<Space>
            <Tooltip placement="topLeft" title="Fast create and receive RMA">
              <Button
                type="primary"
                onClick={()=>{handleButtonClick('ExpressReceive')}}
              >
                Express Return
              </Button>
              </Tooltip>
          </Space>)} */}
        </Row>
      }
    >
      <div
        style={{
          position: 'relative',
        }}
      >
        <Card
          size="small"
          title={
            <Space style={{ margin: '8px 12px' }}>
              <Input
                style={{ width: 150 }}
                prefix={<FilterOutlined />}
                size="small"
                placeholder="Filter"
                allowClear
                onChange={(e) => {
                  debounceFn(e.target.value);
                }}
              />
            </Space>
          }
        >
          <Descriptions
            size="small"
            column={3}
            labelStyle={{
              display: 'inline-block',
              width: 200,
              textAlign: 'right',
            }}
          >
            {filterList.map((item, index) => {
              return (
                <Descriptions.Item
                  key={index}
                  label={<FormLabel noColon>{item.label}</FormLabel>}
                >
                  {item.render}
                </Descriptions.Item>
              );
            })}
          </Descriptions>
        </Card>

        <Card
          style={{
            marginTop: COMMON_PADDING_SPACE,
            marginBottom: COMMON_PADDING_SPACE,
          }}
        >
          <Row>
            <Col span={12}>
              <Descriptions
                title={
                  <Space>
                    <span>Ship To</span>
                    {isNoDownload && (
                      <UnderlineButton
                        onClick={() => setEditAddressDialogVisible(true)}
                      >
                        Edit Shipping Address
                      </UnderlineButton>
                    )}
                  </Space>
                }
                size="small"
                column={2}
                labelStyle={{
                  display: 'inline-block',
                  width: 100,
                  textAlign: 'right',
                }}
              >
                <Descriptions.Item label={<FormLabel noColon>Name</FormLabel>}>
                  <CopySpan value={data.shipToName} />
                </Descriptions.Item>
                <Descriptions.Item
                  label={<FormLabel noColon>Company</FormLabel>}
                >
                  <CopySpan value={data.shipToCompany} />
                </Descriptions.Item>
                <Descriptions.Item
                  label={<FormLabel noColon>Address</FormLabel>}
                >
                  {formatAddress(
                    'row',
                    '',
                    '',
                    data.shipToAddressLine1,
                    data.shipToAddressLine2,
                    data.shipToAddressLine3,
                    data.shipToCity,
                    data.shipToState,
                    data.shipToPostalCode,
                    data.shipToCountry
                  )}
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Col span={12}>
              <Descriptions
                title="Bill to"
                size="small"
                column={2}
                labelStyle={{
                  display: 'inline-block',
                  width: 100,
                  textAlign: 'right',
                }}
              >
                <Descriptions.Item label={<FormLabel noColon>Name</FormLabel>}>
                  <CopySpan value={data.billToName} />
                </Descriptions.Item>
                <Descriptions.Item
                  label={<FormLabel noColon>Company</FormLabel>}
                >
                  <CopySpan value={data.billToCompany} />
                </Descriptions.Item>
                <Descriptions.Item
                  label={<FormLabel noColon>Address</FormLabel>}
                >
                  {formatAddress(
                    'row',
                    '',
                    '',
                    data.billToAddressLine1,
                    data.billToAddressLine2,
                    data.billToAddressLine3,
                    data.billToCity,
                    data.billToState,
                    data.billToPostalCode,
                    data.billToCountry
                  )}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        </Card>
        <Card size="small">
          <TablesFilter
            columns={isCreate ? createColumns : columns}
            dataSource={lines}
            setFilteredData={setFilteredData}
          />
          <DataGridPlus
            columns={isCreate? createColumns:columns}
            dataSource={filteredData}
            autoWith={false}
            idProperty="sku"
            pagination="local"
            {...({} as any)}
            checkboxOnlyRowSelect={true}
          />
        </Card>
        <div
          style={{
            position: 'absolute',
            zIndex: 999,
            top: 0,
            right: 0,
            height: '100%',
            width: expandRight ? 450 : 14,
            display: 'flex',
            flexFlow: 'row nowrap',
            backgroundColor: '#fff',
            overflow: 'hidden',
            transition: 'all 0.2s',
          }}
        >
          <div
            style={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Tooltip placement="right" title={expandRight ? 'Close' : 'Open'}>
              <Button
                icon={expandRight ? <RightOutlined /> : <LeftOutlined />}
                style={{
                  width: 14,
                  height: '100%',
                  alignSelf: 'center',
                }}
                onClick={() => setExpandRight((prev) => !prev)}
              />
            </Tooltip>
          </div>
          <Card
            title={'Other Details'}
            style={{
              width: expandRight ? 436 : 0,
              height: '100%',
              borderLeft: 'none',
            }}
          >
            <Descriptions column={1}>
              <Descriptions.Item
                label={<FormLabel noColon>Return Uuid</FormLabel>}
              >
                <CopySpan value={data.transUuid} />
              </Descriptions.Item>
              <Descriptions.Item
                label={<FormLabel noColon>Invoice Uuid</FormLabel>}
              >
                <CopySpan value={data.invoiceUuid} />
              </Descriptions.Item>
              <Descriptions.Item
                label={<FormLabel noColon>Fulfillment Order</FormLabel>}
              >
                <CopySpan value={data.fulfillmentOrderId} />
              </Descriptions.Item>
              <Descriptions.Item
                label={<FormLabel noColon>Central Order #</FormLabel>}
              >
                <CopySpan value={`${data.centralOrderNum}`} />
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </div>
      </div>

      {printModalVisible && data && (
        <PrintModal
          detailData={data}
          onHide={() => setPrintModalVisible(false)}
        />
      )}
      {closeDetailDialogVisible && deleteSku && (
        <CloseDetailModal
          onHide={() => {
            setCloseDetailDialogVisible(false)
            setDeleteSku('')
          }}
          onRefresh={() => {
            refreshData()
            setDeleteSku('')
          }}
          current={data}
          sku={deleteSku}
        />
      )}
      {editAddressDialogVisible && (
        <EditAddressDialog
          visible={editAddressDialogVisible}
          onRefresh={() => {
            refreshData();
          }}
          onHide={() => setEditAddressDialogVisible(false)}
          returnData={data}
          warehouseId={warehouseId}
        />
      )}
    </ModalDialog>
  );
};
