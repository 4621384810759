import React from 'react';
import {
  Button,
  Col,
  InputNumber,
  Row,
  Modal,
  Space,
  Checkbox,
} from 'antd';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { v4 as uuid } from 'uuid';
import {
  FormField,
  FormLabel,
  Loading,
  ScreenMask,
  message,
} from 'components/common';
import { InfoButton } from 'components/common/styles';
import {
  FormRightAlign,
  FormVSpacer,
} from 'components/common/styles/CustomPropsComponents';
import { LOADING_ICON_SIZE1 } from 'constants/config';
import { updateShipmentServices } from 'services/sales';
import PackageSelector from '../PackageSelector';
import ServiceSelector from '../ServiceSelector';
import ShoppingAroundDialog from '../ShoppingAroundDialog';
import SignatureSelector from '../SignatureSelector';
import WeightBoard from '../WeightBoard';
import {
  SizeInputRow,
  UnitLabel,
} from '../styles';

type Props = {
  onClose: Function;
  refreshShipment: Function;
  shipment: StringKAnyVPair;
  visible: boolean;
  warehouseNum: number;
};

const OptionDialog = (props: Props) => {
  const { useState } = React;
  const { warehouseNum } = props;
  const FORM_COL1_WIDTH = 7;
  const FORM_COL2_WIDTH = 17;
  const FORM_FIELD_WIDTH = 350;
  //const [inited, setInited] = useState(false);
  const [lockScreen, setLockScreen] = useState(false);
  //const [packageNum, setPackageNum] = useState(-1);
  const [packageHeight, setPackageHeight] = useState<number>();
  const [packageLength, setPackageLength] = useState<number>();
  const [packageNum, setPackageNum] = useState(props.shipment.shipPackageNum);
  const [packageServiceError, setPackageServiceError] = useState(false);
  const [packageServiceErrorMessage, setPackageServiceErrorMessage] =
    useState('');
  const [packageTypeError, setPackageTypeError] = useState(false);
  const [packageTypeErrorMessage, setPackageTypeErrorMessage] = useState('');
  const [packageUpdateTag, setPackageUpdateTag] = useState<string>();
  const [packageWeight, setPackageWeight] = useState(0);
  const [packageWidth, setPackageWidth] = useState<number>();
  const [serviceCode, setServiceCode] = useState<string>();
  const [serviceUpdateTag, setServiceUpdateTag] = useState<string>();
  const [shipAccountNum, setShipAccountNum] = useState(0);
  const [shipCarrier, setShipCarrier] = useState('');
  const [shipCarrierNum, setShipCarrierNum] = useState(-1000);
  const [shipConfirmation, setShipConfirmation] = useState<number | null>(null);
  const [shipServiceNum, setShipServiceNum] = useState(0);
  const [shipWeight, setShipWeight] = useState<number>();
  const [shipment, setShipment] = useState<StringKAnyVPair>({});
  const [shoppingAroundDialogVisible, setShoppingAroundDialogVisible] = useState(false);
  const [shoppingAroundSizeLocked, setShoppingAroundSizeLocked] = useState(false);
  const [shopAroundUpdateTag, setShopAroundUpdateTag] = useState('');
  const [enableHold, setEnableHold] = useState(false)
  const [hold, setHold] = useState(false)

  const handleSaveOptions = async () => {
    const data = {
      ShipmentNum: shipment.shipmentNum,
      CarrierNum: shipCarrierNum,
      ShipServiceNum: shipServiceNum,
      ShippingAccountNum: shipAccountNum,
      PackageNum: packageNum >= 0 ? packageNum : null,
      Signature: shipConfirmation,
      Height: typeof packageHeight === 'number' && packageHeight > 0 ? packageHeight : null,
      Length: typeof packageLength === 'number' && packageLength > 0 ? packageLength : null,
      Width: typeof packageWidth === 'number' && packageWidth > 0 ? packageWidth : null,
      Weight: packageWeight > 0 ? packageWeight : null,
      HoldForPickUp: enableHold ? ( hold ? 1 :0 ) : undefined, 
    };

    setLockScreen(true);

    try {
      // console.log('sv', data);
      const res = await updateShipmentServices(shipment.shipmentNum, data);

      setLockScreen(false);

      if (res && typeof res === 'object') {
        // console.log('res', res);
        props.refreshShipment({
          ...shipment,
          ...res,
        });
        props.onClose();
      }
    } catch (e) {
      setLockScreen(false);
      message.error(`Save services error: ${e}`);
    }
  };

  const onCancel = () => {
    props.onClose();
  };

  /**
   * Callback for changing package height.
   */
  const onInputHeightChange = (value: number) => {
    setPackageHeight(value);
  };

  /**
   * Callback for changing package length.
   */
  const onInputLengthChange = (value: number) => {
    setPackageLength(value);
  };

  /**
   * Callback for changing package width.
   */
  const onInputWidthChange = (value: number) => {
    setPackageWidth(value);
  };

  const onSetShoppingAround = (res: StringKAnyVPair) => {
    setServiceCode(res.shippingServiceCode);
    setShipAccountNum(res.shipAccountNum);
    setServiceUpdateTag(uuid());
    setShipCarrier(res.shippingCarrier);
    setShipCarrierNum(res.shipCarrierNum);
    setPackageNum(res.shipPackageNum);

    if (res.height) setPackageHeight(res.height);
    if (res.length) setPackageLength(res.length);
    if (res.width) setPackageWidth(res.width);
    if (res.weight) setShipWeight(res.weight);

    setShoppingAroundSizeLocked(res.height || res.length || res.width);
  };

  /**
   * Callback for selecting a shipping package.
   */
  const onShippingPackageChange = (pkg: StringKAnyVPair) => {
    const { height, length, width } = shipment;
    const heightExist = typeof height === 'number' && height >= 0;
    const lengthExist = typeof length === 'number' && length >= 0;
    const widthExist = typeof width === 'number' && width >= 0;

    //setPackageName(pkg.name);
    setPackageNum(pkg.shippingPackageNum);
    //setPackageType(pkg.code || '');

    if (shoppingAroundSizeLocked) {
      setShoppingAroundSizeLocked(false);
    } else if (shipment.shipmentNum === props.shipment.shipmentNum) {
      if (!heightExist) setPackageHeight(pkg.height || undefined);
      if (!lengthExist) setPackageLength(pkg.length || undefined);
      if (!widthExist) setPackageWidth(pkg.width || undefined);
    }

    setPackageTypeError(false);
    setPackageTypeErrorMessage('');
  };

  /**
   * Callback from selecting a shipping service.
   */
  const onShippingServiceChange = (svr: StringKAnyVPair) => {
    if (shipCarrierNum > -1000 && shipCarrierNum !== svr.carrierNum) {
      // note: the carrier package number is same in the same carrier
      // maybe this line is not needed
      setPackageNum(-1); // check carrier number to set package number
    }

    setShipAccountNum(svr.shipAccountNum);
    setShipCarrier(svr.carrierName);
    setShipCarrierNum(svr.carrierNum);
    setEnableHold(!!svr.holdForPickUp)
    //setShipService(svr.carrierServiceCode);
    setShipServiceNum(svr.carrierServiceNum);
    setPackageServiceError(false);
    setPackageServiceErrorMessage('');
    setPackageUpdateTag(uuid());
  };

  const onShoppingAroundButtonClick = () => {
    setShopAroundUpdateTag(uuid());
    setShoppingAroundDialogVisible(true);
  };

  /**
   * Callback for shipping confirmation selection change.
   */
  const onSignatureOptionChange = (value: any) => {
    //console.log('confirmation', value);
    if (typeof value === 'number') {
      setShipConfirmation(value);
    } else {
      setShipConfirmation(null);
    }
  };

  /**
   * Callback for weight changing.
   */
  const onWeightChange = (weight: number) => {
    setPackageWeight(weight);
  };

  React.useEffect(() => {
    if (shipment.shipmentNum !== props.shipment.shipmentNum) {
      const { height, length, weight, width, holdForPickUp } = props.shipment;
      setShipment(props.shipment);
      setServiceUpdateTag(uuid());
      setShipWeight(weight || undefined);
      setPackageHeight(height || undefined);
      setPackageLength(length || undefined);
      setPackageWidth(width || undefined);
      setHold(!!holdForPickUp)
    }
  }, [props, shipment]);

  return (
    <>
      <Modal
        centered
        destroyOnClose
        footer={
          <Row justify="space-between">
            <InfoButton onClick={onShoppingAroundButtonClick}>
              Shop Around
            </InfoButton>
            <Space>
              <Button
                onClick={handleSaveOptions}
                type="primary"
              >
                <SaveOutlined />
                Save
              </Button>
              <Button onClick={onCancel} icon={<CloseOutlined />}>
                Cancel
              </Button>
            </Space>
          </Row>
        }
        maskClosable={false}
        onCancel={onCancel}
        title={shipment.shipmentTitle ? `Set shipping services for '${shipment.shipmentTitle}'` : 'Set shipping services'}
        visible={props.visible}
        width={600}
      >
        <WeightBoard
          onChange={onWeightChange}
          shipmentNum={shipment.shipmentNum}
          weight={shipWeight}
        />
        <FormVSpacer />
        <Row align="middle">
          <Col span={FORM_COL1_WIDTH}>
            <FormRightAlign>
              <FormLabel>Service</FormLabel>
            </FormRightAlign>
          </Col>
          <Col span={FORM_COL2_WIDTH}>
            <FormField
              error={packageServiceError}
              errorMessage={packageServiceErrorMessage}
            >
              {shipment.shipmentNum && <ServiceSelector
                channelAccountNum={shipment.channelAccountNum || 0}
                onChange={onShippingServiceChange}
                serviceCode={serviceCode || shipment.shippingServiceCode}
                shipAccountNum={shipAccountNum || shipment.shippingAccountNum || undefined}
                shipmentNum={shipment.shipmentNum}
                size="middle"
                style={{ width: FORM_FIELD_WIDTH }}
                updateTag={serviceUpdateTag}
                warehouseNum={warehouseNum}
                shipment={shipment}
              />}
            </FormField>
          </Col>
        </Row>
        <FormVSpacer />
        <Row align="middle" justify="center">
          <Col span={FORM_COL1_WIDTH}>
            <Row justify="end">
              <FormRightAlign>
                <FormLabel>Package</FormLabel>
              </FormRightAlign>
            </Row>
          </Col>
          <Col span={FORM_COL2_WIDTH}>
            <Row align="middle">
              <FormField
                error={packageTypeError}
                errorMessage={packageTypeErrorMessage}
              >
                <PackageSelector carrier={shipCarrier}
                  carrierNum={shipCarrierNum}
                  onChange={onShippingPackageChange}
                  //packageNum={selectedShipment ? selectedShipment.shipPackageNum || undefined : undefined}
                  packageNum={packageNum >= 0 ? packageNum : undefined}
                  shipmentNum={shipment.shipmentNum}
                  size="middle"
                  style={{ width: FORM_FIELD_WIDTH }}
                  updateTag={packageUpdateTag}
                  warehouseNum={warehouseNum}
                />
              </FormField>
            </Row>
          </Col>
        </Row>
        <FormVSpacer />
        <Row align="middle">
          <Col span={FORM_COL1_WIDTH}>
            <FormRightAlign>
              <FormLabel>Size</FormLabel>
            </FormRightAlign>
          </Col>
          <Col span={FORM_COL2_WIDTH}>
            <SizeInputRow align="middle">
              <Col style={{ width: '31%' }}>
                <Space size={0}>
                  <FormField>
                    <InputNumber
                      min={0}
                      onChange={onInputLengthChange}
                      style={{ width: '100%' }}
                      value={packageLength}
                    />
                  </FormField>
                  <UnitLabel>L</UnitLabel>
                </Space>
              </Col>
              <Col style={{ width: '31%' }}>
                <Space size={0}>
                  <FormField>
                    <InputNumber
                      min={0}
                      onChange={onInputWidthChange}
                      style={{ width: '100%' }}
                      value={packageWidth}
                    />
                  </FormField>
                  <UnitLabel>W</UnitLabel>
                </Space>
              </Col>
              <Col style={{ width: '38%' }}>
                <Space size={0}>
                  <FormField>
                    <InputNumber
                      min={0}
                      onChange={onInputHeightChange}
                      style={{ width: '100%' }}
                      value={packageHeight}
                    />
                  </FormField>
                  <UnitLabel>H(in)</UnitLabel>
                </Space>
              </Col>
            </SizeInputRow>
          </Col>
        </Row>
        <FormVSpacer />
        <Row align="middle">
          <Col span={FORM_COL1_WIDTH}>
            <FormRightAlign>
              <FormLabel>Signature</FormLabel>
            </FormRightAlign>
          </Col>
          <Col className="confirmation-container" span={FORM_COL2_WIDTH}>
            <SignatureSelector
              carrierNum={shipCarrierNum}
              onChange={onSignatureOptionChange}
              shipmentNum={shipment.shipmentNum}
              signature={shipment.signatureRequired}
              size="middle"
              style={{ width: FORM_FIELD_WIDTH }}
            />
          </Col>
        </Row>
        {enableHold && <>
        <FormVSpacer />
        <Row align="middle">
          <Col span={FORM_COL1_WIDTH}>
            <FormRightAlign>
              <FormLabel>Hold for pickup</FormLabel>
            </FormRightAlign>
          </Col>
          <Col className="confirmation-container" span={FORM_COL2_WIDTH}>
            <Checkbox checked={hold} onChange={(e)=> setHold(e.target.checked)} />
          </Col>
        </Row>
        </>}
      </Modal>
      <ShoppingAroundDialog
        close={() => setShoppingAroundDialogVisible(false)}
        onSetShoppingAround={onSetShoppingAround}
        packageHeight={packageHeight}
        packageLength={packageLength}
        packageWidth={packageWidth}
        shipWeight={packageWeight}
        shipment={shipment}
        updateTag={shopAroundUpdateTag}
        visible={shoppingAroundDialogVisible}
        warehouseNum={props.warehouseNum}
      />
      {lockScreen && (
        <ScreenMask>
          <Loading size={LOADING_ICON_SIZE1} />
        </ScreenMask>
      )}
    </>
  );
};

export default OptionDialog;
