import React, { useCallback } from 'react';
import { Modal } from 'antd';
import { ButtonIcon, message } from 'components/common';
import { playAudio } from 'utils';
import { SmartRow, SmartScanner, SmartButton } from '../../../SmartComponent';
import { useTranslation } from 'react-i18next';

type FailDialogProps = {
  sku: string;
  upc: string;
  failSKU: string;
  productAllCode:string[];
  onSubmit: (valid: boolean) => void;
};
export default function FailDialog(props: FailDialogProps): JSX.Element {
  const { sku, upc, failSKU, onSubmit, productAllCode } = props;
  const { t } = useTranslation();
  /**
   * handle scan changed event
   */
  const handleScannerChange = useCallback(
    (nextValue: string) => {
      if (nextValue) {
        if (sku === nextValue || upc === nextValue || productAllCode.indexOf(nextValue) > -1) {
          playAudio('success');
          onSubmit(true);
        } else {
          playAudio('error');
          message.warning(`'${nextValue}' invalid!`);
        }
      } else {
        onSubmit(false);
      }
    },
    [onSubmit, sku, upc, productAllCode]
  );
  /**
   * return modal title
   * @returns {JSX.Element}
   */
  const Title = (): JSX.Element => {
    return (
      <SmartRow justify="center">
        <ButtonIcon className="bi-emoji-frown" />
        <div style={{ marginLeft: 5 }}>{`${failSKU} is invalid!`}</div>
      </SmartRow>
    );
  };
  return (
    <Modal visible width={300} title={<Title />} footer={null} closable={false}>
      <SmartRow>
        {t("smart.pick.failTip")}
      </SmartRow>
      <SmartRow style={{ marginTop: 8 }}>
        {`${t("smart.pick.validSku")}： ${sku}`}
      </SmartRow>
      <SmartRow>
        {`${t("smart.pick.validUpc")}： ${upc}`}
      </SmartRow>
      <SmartRow style={{ marginTop: 20 }}>
        <SmartScanner onChangeValue={handleScannerChange} />
      </SmartRow>
      <SmartRow style={{ marginTop: 20 }} justify="center">
        <SmartButton
          style={{ width: 200 }}
          onClick={() => {
            handleScannerChange('');
          }}
        >
          {t("smart.pick.nextSkuUpc")}
        </SmartButton>
      </SmartRow>
    </Modal>
  );
}
