import React, { useCallback, useEffect, useState } from 'react';
import { Typography, Row, Button, Col, Space } from 'antd';
import {
  getCustomPackageList,
  deleteCustomPackage,
  getPackageTypes,
} from 'services/shipment';
import {
  CreateButton,
  DeleteButton,
  DataGridPlus,
  GreyCardWrapper,
  message,
  HeaderTitle,
  CopySpan,
  TablesFilter,
} from 'components/common';
import { CheckOutlined } from '@ant-design/icons';
import PackageDialog from './PackageDialog';
import {
  WEIGHT_UNITS as weightUnitEnum,
  DIMENSION_UNITS as unitEnum,
} from 'constants/config';

const { Text } = Typography;
export default function CustomPackages(): JSX.Element {
  const columns = [
    {
      header: 'Name',
      name: 'name',
      defaultFlex: 1,
      // render: (value: any) => {
      //   const { data, rowIndex } = value;
      //   return <span id={`grid_name_row_${rowIndex}`}>{data.name}</span>;
      // },
    },
    {
      header: 'Package',
      name: 'packageName',
      defaultFlex: 1,
      // render: (value: any) => {
      //   const { data, rowIndex } = value;
      //   return <span id={`grid_name_row_${rowIndex}`}>{data.packageName}</span>;
      // },
    },
    {
      header: 'Length',
      name: 'length',
      type: 'number',
      defaultFlex: 1,
      render: (value: any) => {
        const { data } = value;
        return (
          <CopySpan
            inGrid
            value={
              data.length || data.length === 0
                ? `${data.length} ${unitEnum[data.linearUnit || 0]}`
                : ''
            }
          />
        );
      },
    },
    {
      header: 'Width',
      name: 'width',
      type: 'number',
      defaultFlex: 1,
      render: (value: any) => {
        const { data } = value;
        return (
          <CopySpan
            inGrid
            value={
              data.width || data.width === 0
                ? `${data.width} ${unitEnum[data.linearUnit || 0]}`
                : ''
            }
          />
        );
      },
    },
    {
      header: 'Height',
      name: 'height',
      type: 'number',
      defaultFlex: 1,
      render: (value: any) => {
        const { data } = value;
        return (
          <CopySpan
            inGrid
            value={
              data.height || data.height === 0
                ? `${data.height} ${unitEnum[data.linearUnit || 0]}`
                : ''
            }
          />
        );
      },
    },
    {
      header: 'Weight',
      name: 'weight',
      type: 'number',
      defaultFlex: 1,
      render: (value: any) => {
        const { data } = value;
        return (
          <CopySpan
            inGrid
            value={
              data.weight || data.weight === 0
                ? `${data.weight} ${weightUnitEnum[data.weightUnit || 0]}`
                : ''
            }
          />
        );
      },
    },
    {
      header: 'Scan Barcode',
      name: 'scanBarcode',
      defaultFlex: 1,
      render: (value: any) => {
        const {
          data: { scanBarcode },
          rowIndex,
        } = value;
        if (scanBarcode === 1) {
          return (
            <Row id={`grid_scan_barcode_row_${rowIndex}`} justify="center">
              <CheckOutlined />
            </Row>
          );
        }
        return null;
      },
    },
    {
      header: 'Barcode',
      name: 'barcode',
      defaultFlex: 1,
      // render: (value: any) => {
      //   const { data, rowIndex } = value;
      //   return <span id={`grid_barcode_row_${rowIndex}`}>{data.barcode}</span>;
      // },
    },
    {
      header: 'Action',
      name: 'action',
      showColumnMenuTool: false,
      groupBy: false,
      sortable: false,
      textAlign: 'center',
      maxWidth: 250,
      minWidth: 250,
      render: (value: any) => {
        const { data, rowIndex } = value;
        const { name } = data;
        if (name === 'General Package') {
          return null;
        }
        return (
          <Space>
            <Button
              id={`grid_action_row_${rowIndex}_edit`}
              type="link"
              style={{ paddingRight: 0, paddingLeft: 0 }}
              onClick={() => {
                handleCreatePackage(data as any);
              }}
            >
              Edit
            </Button>
            <DeleteButton
              id={`grid_action_row_${rowIndex}_delete`}
              type="link"
              showIcon={false}
              confirmTitle="Are you sure you want to delete the package ?"
              confirmContent={`name: ${data.name}`}
              onClick={() => {
                handleDeletePackage(data.shippingPackageNum);
              }}
            />
          </Space>
        );
      },
    },
  ];
  const [loading, setLoading] = useState(false);
  const [packages, setPackages] = useState<Array<ShipmentPackageRow>>([]);
  const [currentPackage, setCurrentPackage] = useState<ShipmentPackageRow>();
  const [visible, setVisible] = useState(false);
  const [packageTypes, setPackageTypes] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);

  /**
   * reload ShipmentPackages
   */
  const loadData = useCallback(async () => {
    setLoading(true);
    const data = await getCustomPackageList();
    const nextPackages = data.map((item) => {
      const packageItem = packageTypes.find((typeItem) => {
        if (typeItem.value === item.packageType) {
          return typeItem;
        }
        return undefined;
      });
      return {
        ...item,
        packageName: packageItem ? packageItem.name : '',
      };
    });
    setPackages(nextPackages);
    setLoading(false);
  }, [packageTypes]);

  const getTypes = useCallback(async () => {
    try {
      const res = await getPackageTypes();
      if (res.isSuccess) {
        setPackageTypes(res.data);
      }
    } catch (error) { }
  }, []);
  /**
   * handle delete package
   */
  const handleDeletePackage = useCallback(
    async (packageNum: number) => {
      await deleteCustomPackage(packageNum);
      await loadData();
      message.success('Delete Package Successfully!');
    },
    [loadData]
  );
  /**
   * handle create package event
   */
  const handleCreatePackage = useCallback(
    (shipmentPackage?: ShipmentPackageRow) => {
      setCurrentPackage(shipmentPackage);
      setVisible(true);
    },
    []
  );
  useEffect(() => {
    getTypes();
  }, [getTypes]);

  useEffect(() => {
    if (packageTypes.length > 0) {
      loadData();
    }
  }, [packageTypes, loadData]);

  return (
    <>
      <HeaderTitle breadcrumb={['Administration', 'Shipping', 'Custom Packages']} />
      {visible && (
        <PackageDialog
          packageTypes={packageTypes}
          shipmentPackage={currentPackage}
          onHide={() => {
            setVisible(false);
          }}
          onSubmit={() => {
            // setVisible(false);
            loadData();
          }}
        />
      )}
      <GreyCardWrapper style={{ marginTop: 20 }}>
        <Row>
          <Col span={24}>
            <Text style={{ fontSize: 16 }}>
              Create custom packages to use in addition to standard carrier
              packaging.These custom packages will be available to your users
              when shipping.
            </Text>
          </Col>
        </Row>
        <Row style={{ marginTop: 20 }} justify="space-between">
          <TablesFilter
            columns={columns}
            dataSource={packages}
            setFilteredData={setFilteredData}
          />
          <CreateButton
            id="add_package_button"
            title="New Package"
            onClick={() => {
              handleCreatePackage();
            }}
          />
        </Row>
        <DataGridPlus
          loading={loading}
          columns={columns}
          idProperty="shippingPackageNum"
          dataSource={filteredData}
          /* eslint-disable react/jsx-props-no-spreading */
          {...({} as any)}
        />
      </GreyCardWrapper>
    </>
  );
}
