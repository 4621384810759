import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Row, Button, Space, Image } from 'antd';
import { DataGridPlus, FormLabel, message } from 'components/common';
import { receive } from 'services/purchaseOrder';
import moment from 'moment';
import fallbackImage from 'assets/images/logo192.png';
import {
  CheckOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import theme from 'assets/styles/theme';
// import { userProfiles } from 'utils/auth';

type Props = {
  onHide: () => void;
  lines: PoLineRow[];
  PoId: string;
  warehouse: WarehouseRow;
  poWarehouseCode: string;
  onRefresh: () => void;
  dateString: string;
  enablePOOverReceive: any;
  lotEnable: boolean;
  uomEnable: boolean;
};
type ColumnRenderType = {
  data: PoLineRow;
  rowIndex: number;
};
// eslint-disable-next-line
export default (props: Props) => {
  const {
    onHide,
    lines,
    onRefresh,
    dateString,
    PoId,
    warehouse,
    poWarehouseCode,
    enablePOOverReceive,
    lotEnable,
    uomEnable,
  } = props;
  const [loading, setLoding] = useState(false);
  const [receiveable, setReceiveable] = useState(false)
  // const poOverPercentage = userProfiles.getProfileSettingValue('POOverReceivePercentage');
  const [total, setTotal] = useState<number>(0);
  /**
   * handle hide event
   */
  const handleHide = useCallback(() => {
    onHide();
  }, [onHide]);

  const handleReceive = useCallback(async () => {
    setLoding(true);
    const Items = lines.map((item) => {
      return {
        FulfillmentPoLineNum: item.poLineNum,
        SKU: item.sku,
        // FulfillmentProductNum: item.productNum,
        ReceiveQty: item.receivingQty,
        // WarehouseNum: warehouse.id,
        LocationNum: item.receivingLocationNum,
        // LocationCode: item.receivingLocationCode,
        // WarehouseCode: warehouse.code,
        // PoPrice: item.price || 0,
        ReceiptDate: dateString,
        LotNumber: item.lotNumber,
        Uom: item.uom,
        UomRate: item.uomRate
      };
    });
    try {
      const res = await receive(PoId, { WarehouseNum: warehouse.id, Items });
      setLoding(false);
      if (res) {
        message.success({ content: 'PO received successfully' });
        onRefresh();
        onHide();
      } else {
        message.error({ content: 'Po received failed' });
      }
    } catch (err) {
      setLoding(false);
    }
  }, [onHide, PoId, lines, onRefresh, warehouse, dateString]);

  const getColumns = useCallback(() =>{
    const columns = [
      {
        name: 'sku',
        header: 'SKU',
        minWidth: 220,
        showColumnMenuTool: true,
        userSelect: true,
        defaultFlex: 1,
      },
      {
        name: 'picture',
        defaultFlex: 1,
        resizable: false,
        sortable: false,
        maxWidth: 100,
        minWidth: 100,
        textAlign: 'center',
        textVerticalAlign: 'middle',
        renderHeader: () => 'Picture',
        render: (value: ColumnRenderType) => {
          const { data } = value;
  
          return (
            <Image
              width={32}
              height={32}
              preview={false}
              src={data.thumbnailUrl || 'error'}
              fallback={fallbackImage}
              onClick={() => {
                console.log('click picture', data);
              }}
              style={{ cursor: 'pointer' }}
            />
          );
        },
      },
      {
        name: 'lotNumber',
        header: 'LOT#',
        minWidth: 150,
        sortable: false,
        showColumnMenuTool: true,
        defaultFlex: 1,
      },
      {
        name: 'receivingQty',
        header: 'Receiving Qty',
        userSelect: true,
        showColumnMenuTool: true,
        defaultFlex: 1,
      },
      {
        name: 'receivingLocationCode',
        header: 'Location',
        userSelect: true,
        showColumnMenuTool: true,
        defaultFlex: 1,
      },
      {
        name: 'uom',
        header: 'UOM',
        minWidth: 150,
        sortable: false,
        showColumnMenuTool: true,
        defaultFlex: 1,
        isUom: true,
      },
      {
        name: 'uomRate',
        header: 'Rate',
        minWidth: 150,
        sortable: false,
        showColumnMenuTool: true,
        defaultFlex: 1,
        isUom: true,
      },
      {
        name: 'baseQty',
        header: 'Base Qty',
        minWidth: 150,
        sortable: false,
        showColumnMenuTool: true,
        defaultFlex: 1,
        isUom: true,
        render: (value: ColumnRenderType) => {
          const { data } = value;
          return data.receivingQty ? data.receivingQty * (data.uomRate || 1) : 0
        }
      },
    ];
    let temp = [...columns]
    temp = lotEnable ? temp : temp.filter(i=> i.name !== 'lotNumber')
    temp = uomEnable ? temp : temp.filter(i => !i.isUom) 
    return temp
  },[lotEnable, uomEnable]) 
  

  const checkLines = useCallback((lines: any) => {
    let bl: boolean = true;
    let t = 0;
    lines.map((i: any) =>{
      t = t + i.receivingQty
      return true
    })
    if (enablePOOverReceive !== '1') {
      bl = true;
    }
    setTotal(t)
    setReceiveable(bl)
  }, [enablePOOverReceive])

  const rowStyle = (rowData: any) => {
    const { data = {} } = rowData;
    const {
      humanReceiveQty = 0,
      closingQty = 0,
      waitCloseQty = 0,
      receivingQty = 0,
      quantity = 0,
      uomRate =  1
    } = data;
    const sum = humanReceiveQty + closingQty + waitCloseQty + (receivingQty * uomRate );
    return {
      backgroundColor:
        quantity === sum
          ? theme['@success-color']
          : quantity < sum
            ? theme['@danger-color']
            : '#fff',
    };
  };

  useEffect(() => {
    checkLines(lines)
  }, [lines, checkLines])

  return (
    <Modal
      title={
        warehouse.warehouseType === 6
          ? 'Receive to Receiving Warehouse'
          : 'Receive to Normal Warehouse'
      }
      centered
      visible={true}
      width={800}
      destroyOnClose={true}
      onCancel={handleHide}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              type="primary"
              disabled={lines.length === 0 || !receiveable}
              loading={loading}
              onClick={() => {
                if (poWarehouseCode !== warehouse.code) {
                  Modal.confirm({
                    icon: <ExclamationCircleOutlined />,
                    content: `This PO is for warehouse [${poWarehouseCode}], confirm that you want to store the items in warehouse [${warehouse.code}] ?`,
                    onOk() {
                      handleReceive();
                    },
                    onCancel() { },
                  });
                } else {
                  handleReceive();
                }
              }}
              icon={<CheckOutlined />}
            >
              {loading ? 'Receiving' : 'Receive'}
            </Button>
            <Button onClick={handleHide} icon={<CloseOutlined />}>
              Cancel
            </Button>
          </Space>
        </Row>
      }
    >
      <Space size="large">
        <Space>
          <FormLabel>Receipt Date</FormLabel>
          {moment(dateString).format('MM/DD/YYYY')}
        </Space>
        <Space>
          <FormLabel>Receipt Warehouse</FormLabel>
          {warehouse.warehouseCode}
        </Space>

        <Space>
          <FormLabel>Receiving Qty</FormLabel>
          {total}
        </Space>
      </Space>

      <DataGridPlus
        columns={getColumns()}
        dataSource={lines}
        rowStyle={enablePOOverReceive === '1' ? undefined : rowStyle}
        idProperty="poLineNum"
        {...({} as any)}
      />
    </Modal>
  );
};
