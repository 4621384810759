import React, { useCallback, useRef, useState, useEffect } from 'react';
import {
  ExclamationCircleOutlined,
  DiffOutlined,
  CheckOutlined,
  DeleteOutlined,
  SettingFilled,
  CaretDownOutlined,
  PlusOutlined,
  SearchOutlined,
  SyncOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import {
  Button,
  Row,
  Form,
  Col,
  Space,
  DatePicker,
  Input,
  Select,
  Card,
  Menu,
  Dropdown,
  Spin,
  Modal,
  Typography,
  Tooltip
} from 'antd';
import moment from 'moment';
// import { Link } from 'react-router-dom';
import {
  ClearButton,
  ColumnDataType,
  DataGridPlus,
  FormLabel,
  HeaderTitle,
  SelectWarehouse,
  GreyCardWrapper,
  CopySpan,
  message,
  CopyComponent,
  SearchCollapse,
  TablesFilter
} from 'components/common';
import { ConditionColumns } from 'components/common/SearchCollapse';
import { DATE_TIME_NO_SECOND_FORMAT, DEFAULT_DATE_TIME_FORMAT } from 'constants/config';
import { trimValues, sortByString, onSelectionChange } from 'utils';
import { fetchStoreList } from 'services/storeSetup';
import {
  fetchBulkReturns,
  deleteBulkReturn,
  resyncReturn,
  getCustomers,
} from 'services/return';
import CreateDialog from './CreateDialog';
import ReceiveDialog from './ReceiveDialog';
import DetailDialog from './DetailDialog';
import CompleteDialog from './CompleteDialog';
import RestockDialog from './RestockDialog';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import DeviceSettingDialog from '../../Sales/PrintGeneratedPicklists/DeviceSettingDialog';
import { RootState } from 'reducers';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';
import { getWarehouseCodeFromCache, getProfileSettingValue } from 'utils';
import { getSpecialLocations, fetchWarehouseLocationList } from 'services/warehouse';
import { specialWarehouseInfo } from 'actions/adminActions';
import ExportCSV from './ExportCSV';

const { RangePicker } = DatePicker;
const { Text } = Typography;

// const warehouseTypeEnum: { [key: string]: string } = {
//   '2': 'Return-Resalable',
//   '3': 'Return-Damage',
//   '4': 'Return-Inspection',
// };

const statusEnum: { [key: string]: string } = {
  '0': 'Draft',
  '1': 'Proccessing',
  '2': 'Complete',
};

const syncStatusEnum: { [key: string]: string } = {
  '0': 'Pending ',
  '1': 'Success',
  '2': 'Failed',
  '3': 'Proccessing',
};

type SpecialInfoItem = {
  databaseNum: number;
  locations: any[];
  warehouseCode: string;
  warehouseId: string;
  warehouseNum: number;
  warehouseType: number;
  defaultLocationNum?: number;
  defaultLocationCode?: string;
};

type SpecialInfoType = {
  2?: SpecialInfoItem;
  3?: SpecialInfoItem;
  4?: SpecialInfoItem;
};

export default function LocationReport(): JSX.Element {
  const defaultWarehouseType = 4;
  const defaultLocationCode = 'General';
  const [data, setData] = useState<any[]>();
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const whs = useSelector(
    (state: RootState) => state.admin.warehouses,
    shallowEqual
  );
  const cacheInfo = useSelector(
    (state: RootState) => state.admin.defaultSpecialWarehouseInfo,
    shallowEqual
  );
  const dispatch: Dispatch<any> = useDispatch();

  const [initing, setIniting] = useState(true);
  const [selectedWarehouse, setSelectedWarehouse] = useState<
    WarehouseRow | undefined
  >();
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [tempParams, setTempParams] = useState<any>();
  const limitRef = useRef(10);
  const skipRef = useRef(0);
  // const sortId = useRef('');
  const [current, setCurrent] = React.useState<any>();
  const [fetching, setFetching] = useState<boolean>(false);
  const [stores, setStores] = useState<Array<any>>([]);
  const [receiveDialogVisible, setReceiveDialogVisible] = useState(false); //receive
  const [restockDialogVisible, setRestockDialogVisible] = useState(false); //restock
  const [createDialogVisible, setCreateDialogVisible] = useState(false); //create
  const [completeDialogVisible, setCompleteDialogVisible] = useState(false); //complete
  // const [detailDialogVisible, setDetailDialogVisible] = React.useState(false); //detail
  const [deviceDialogClosable, setDeviceDialogClosable] = React.useState(true);
  const [deviceSettingVisible, setDeviceSettingVisible] = React.useState(false);
  const [conditionColumns, setConditionColumns] = useState<ConditionColumns[]>([]);
  const [oper, setOper] = useState<number>(0); //equals or start with
  const [detailDialogVisible, setDetailDialogVisible] =
    useState<boolean>(false);
  const [specialInfo, setSpecialInfo] = useState<any>({
    2: undefined,
    3: undefined,
    4: undefined,
  });
  const [receiveDisabled, setReceiveDisabled] = useState(false);
  const [selectedRow, setSelectedRow] = React.useState<any[]>([]);
  const [selected, setSelected] = React.useState<any>({});
  const [resyncLoading, setResyncLoading] = useState(false);
  const [customers, setCustomers] = useState<any[]>([]);

  const [form] = Form.useForm();

  const getCustomerInfo = useCallback(async () => {
    try {
      setFetching(true);
      const res = await getCustomers()
      setFetching(false);
      if (res.isSuccess) {
        setCustomers(res.data)
      }
    } catch (error) {

    }
  }, [])

  useEffect(() => {
    getCustomerInfo()
  }, [getCustomerInfo])

  /**
   * handle search event
   */
  const handleSearch = useCallback(async () => {
    if (skipRef.current !== 0) {
      skipRef.current = 0;
    }
    setSelected({});
    setSelectedRow([]);
    const params = trimValues(await form.validateFields());
    getConditionColumns(params);
    const queryparams = {
      pageIndex: 1,
      pageSize: limitRef.current,
      Sorts: [],
      ...params,
      ChannelAccountNum: params.ChannelAccountNum ? params.ChannelAccountNum.value : undefined,
      WarehouseNum: params.WarehouseNum.warehouseNum,
      ReturnDate_From: params.ReturnDate
        ? moment(params.ReturnDate[0]).format()
        : undefined,
      ReturnDate_To: params.ReturnDate
        ? moment(params.ReturnDate[1]).format()
        : undefined,
      TransStatus: params.status ? (params.status || params.status.value === 0 ? [params.status.value] : []) : undefined,
      SyncStatus: params.SyncStatus ? (params.SyncStatus || params.SyncStatus.value === 0 ? [params.SyncStatus.value] : []) : undefined,
      Sku: params.sku,
      Oper: params.sku ? oper : undefined,
      CustomerCode: params.CustomerCode ? params.CustomerCode.value : undefined
    };

    try {
      setLoading(true);
      const res = await fetchBulkReturns(queryparams);
      setLoading(false);
      if (res.isSuccess) {
        setData(res.data.data);
        setTotal(res.data.count);
        setTempParams(queryparams);
      }
    } catch (error) {
      //setLoading(false);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [form, skipRef, limitRef, oper]);

  /**
   * handle datagrid change event
   */
  const handleDataGridChange = useCallback(
    async (limit: number, skip: number) => {
      if (!tempParams) return;
      setSelected({});
      setSelectedRow([]);
      const queryparams = {
        ...tempParams,
        pageIndex: skip / limit + 1,
        pageSize: limit,
        Sorts: [],
      };
      // console.log(skip / limit + 1)
      // console.log(queryparams)
      try {
        setLoading(true);
        const res = await fetchBulkReturns(queryparams);
        setLoading(false);
        if (res.isSuccess) {
          setData(res.data.data);
          setTotal(res.data.count);
          limitRef.current = limit;
          skipRef.current = skip;
        }
      } catch (error) {
        setLoading(false);
      }
    },
    [tempParams]
  );

  const getConditionColumns = (formValues: any) => {
    // console.log('formValues', formValues)
    const {
      FulfillmentBulkReturnId = '',
      ChannelAccountNum = {},
      ReferenceNo = '',
      sku = '',
      WarehouseNum = {},
      ReturnDate = [],
      status = {},
      ControlNo = '',
      BoxID = '',
      SyncStatus = {},
      CustomerCode = ''
    } = formValues;
    const operType = oper === 0 ? 'equals' : 'starts with'
    const ReturnTime = ReturnDate && ReturnDate[0] ? `${ReturnDate[0].format(DEFAULT_DATE_TIME_FORMAT)} - ${ReturnDate[1].format(DEFAULT_DATE_TIME_FORMAT)}` : undefined;
    setConditionColumns([
      { label: 'WMS Return ID', value: FulfillmentBulkReturnId },
      { label: 'Store(Channel)', value: ChannelAccountNum.label },
      { label: 'Reference No', value: ReferenceNo },
      { label: `${oper === 0 ? 'SKU/ UPC' : 'SKU'} ${operType}`, value: sku },
      { label: 'Warehouse', value: WarehouseNum.warehouseCode },
      { label: 'Return Date', value: ReturnTime },
      { label: 'Status', value: status.label },
      { label: 'Control No', value: ControlNo },
      { label: 'Box ID', value: BoxID },
      { label: 'Sync Status', value: SyncStatus.label },
      { label: 'Customer', value: CustomerCode.label },
    ])
  }

  const batchResync = useCallback(
    async (data: any[]) => {
      const temp = data
        .filter((i) => i.transStatus === 2)
        .map((k) => k.fulfillmentBulkReturnNum);
      if (temp.length < 1) return;
      try {
        setResyncLoading(true);
        const res = await resyncReturn(temp);
        setResyncLoading(false);
        if (res.isSuccess) {
          message.success({ content: 'Resync successfully' });
          handleSearch();
        }
      } catch (error) {
        setResyncLoading(false);
      }
    },
    [handleSearch]
  );

  /**
   * handel delete Customer Return（Bulk Return）
   */
  const delBulkRturn = useCallback(
    async (data: any) => {
      try {
        const res = await deleteBulkReturn(data.fulfillmentBulkReturnNum);
        if (res.isSuccess) {
          message.success({ content: 'Deleted successfully' });
          //refresg list
          handleSearch();
        }
      } catch (error) { }
    },
    [handleSearch]
  );

  const getColumns = useCallback(() => {
    return [
      {
        header: 'WMS Return ID',
        name: 'fulfillmentBulkReturnId',
        userSelect: true,
        sortable: true,
        resizable: true,
        draggable: true,
        showColumnMenuTool: false,
        defaultFlex: 1,
        render: (row: any) => {
          const { data } = row;
          return (
            <CopyComponent value={data.fulfillmentBulkReturnId}>
              <Text
                ellipsis={{ tooltip: data.fulfillmentBulkReturnId }}
                style={{
                  cursor: 'pointer',
                  width: `calc(100% - 14px)`,
                  color: '#1976d2',
                }}
                onClick={() => {
                  setCurrent(data);
                  setDetailDialogVisible(true);
                }}
              >
                {data.fulfillmentBulkReturnId}
              </Text>
            </CopyComponent>
          );
        },
      },
      {
        header: 'Store(Channel)',
        name: 'channelAccountName',
        userSelect: true,
        sortable: true,
        resizable: true,
        draggable: true,
        showColumnMenuTool: false,
        defaultFlex: 1,
      },
      {
        header: 'Reference No',
        name: 'referenceNo',
        resizable: true,
        draggable: true,
        sortable: true,
        userSelect: true,
        showColumnMenuTool: false,
        defaultFlex: 1,
      },
      {
        name: 'receiveQty',
        header: 'Receive',
        minWidth: 70,
        resizable: true,
        draggable: true,
        sortable: true,
        userSelect: true,
        showColumnMenuTool: false,
        defaultFlex: 1,
        type: 'number',
      },
      {
        name: 'inspectionQty',
        header: 'Inspection',
        minWidth: 70,
        resizable: true,
        draggable: true,
        sortable: true,
        userSelect: true,
        showColumnMenuTool: false,
        defaultFlex: 1,
        type: 'number',
      },
      {
        name: 'stockQty',
        header: 'Resaleable',
        minWidth: 70,
        resizable: true,
        draggable: true,
        sortable: true,
        userSelect: true,
        showColumnMenuTool: false,
        defaultFlex: 1,
        type: 'number',
      },
      {
        name: 'nonStockQty',
        header: 'Damage',
        minWidth: 70,
        resizable: true,
        draggable: true,
        sortable: true,
        userSelect: true,
        showColumnMenuTool: false,
        defaultFlex: 1,
        type: 'number',
      },
      {
        header: 'Warehouse',
        name: 'warehouseCode',
        resizable: true,
        draggable: true,
        sortable: true,
        userSelect: true,
        showColumnMenuTool: false,
        defaultFlex: 1,
      },
      {
        header: 'Status',
        name: 'transStatus',
        resizable: true,
        draggable: true,
        sortable: true,
        userSelect: true,
        showColumnMenuTool: false,
        defaultFlex: 1,
        renderEnum: statusEnum,
        render: (value: any) => {
          const { data } = value;
          return <CopySpan inGrid value={statusEnum[`${data.transStatus}`]} />;
        },
      },
      {
        header: 'Sync Status',
        name: 'syncStatus',
        resizable: true,
        draggable: true,
        sortable: true,
        userSelect: true,
        showColumnMenuTool: false,
        defaultFlex: 1,
        renderEnum: syncStatusEnum,
        render: (value: any) => {
          const { data } = value;
          return (
            // <CopySpan inGrid value={syncStatusEnum[`${data.syncStatus}`]} />
            <CopyComponent
              value={syncStatusEnum[`${data.syncStatus}`]}
            >
              <Typography.Text>
                {syncStatusEnum[`${data.syncStatus}`]}
                {data?.syncNote && (
                  <Tooltip placement="top" title={data?.syncNote || ''}>
                    <InfoCircleOutlined style={{ marginLeft: 4 }} />
                  </Tooltip>
                )}
              </Typography.Text>
            </CopyComponent>
          );
        },
      },
      {
        header: 'Return Date',
        name: 'returnDate',
        resizable: true,
        draggable: true,
        userSelect: true,
        sortable: true,
        showColumnMenuTool: false,
        defaultFlex: 1,
        dataType: ColumnDataType.DATE,
        format: DATE_TIME_NO_SECOND_FORMAT,
      },
      {
        header: 'Control No',
        name: 'controlNo',
        resizable: true,
        draggable: true,
        sortable: true,
        userSelect: true,
        showColumnMenuTool: false,
        defaultFlex: 1,
      },
      {
        header: 'Customer',
        name: 'customerName',
        resizable: true,
        draggable: true,
        sortable: true,
        userSelect: true,
        showColumnMenuTool: false,
        defaultFlex: 1,
      },
      {
        header: 'Box ID',
        name: 'boxID',
        resizable: true,
        draggable: true,
        sortable: true,
        userSelect: true,
        showColumnMenuTool: false,
        defaultFlex: 1,
      },
      {
        header: 'Create by',
        name: 'createBy',
        resizable: true,
        draggable: true,
        sortable: true,
        userSelect: true,
        showColumnMenuTool: false,
        defaultFlex: 1,
      },
      {
        header: 'Last Updated by',
        name: 'updateBy',
        resizable: true,
        draggable: true,
        sortable: true,
        userSelect: true,
        showColumnMenuTool: false,
        defaultFlex: 1,
      },
      {
        header: 'Action',
        name: 'action',
        showColumnMenuTool: false,
        defaultLocked: 'end',
        sortable: false,
        maxWidth: 100,
        minWidth: 100,
        defaultFlex: 1,
        render: (value: any) => {
          const { data, rowIndex } = value;
          return (
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key="1">
                    <Button
                      id={`bulk_return_grid_action_row_${rowIndex}_dropdown_receive`}
                      type="text"
                      style={{ width: '100%', textAlign: 'left' }}
                      icon={<DiffOutlined />}
                      disabled={
                        receiveDisabled ||
                        data.warehouseNum !==
                        Number(
                          localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)
                        ) ||
                        data.transStatus === 2 ||
                        receiveDisabled
                      }
                      onClick={() => {
                        setCurrent(data);
                        setReceiveDialogVisible(true);
                      }}
                    >
                      Receive
                    </Button>
                  </Menu.Item>
                  <Menu.Item key="2">
                    <Button
                      id={`bulk_return_grid_action_row_${rowIndex}_dropdown_restock`}
                      type="text"
                      style={{ width: '100%', textAlign: 'left' }}
                      icon={<DiffOutlined />}
                      disabled={
                        receiveDisabled ||
                        data.warehouseNum !==
                        Number(
                          localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)
                        ) ||
                        data.transStatus === 2 || receiveDisabled
                      }
                      onClick={() => {
                        setCurrent(data);
                        setRestockDialogVisible(true);
                      }}
                    >
                      Restock
                    </Button>
                  </Menu.Item>
                  <Menu.Item key="3">
                    <Button
                      id={`bulk_return_grid_action_row_${rowIndex}_dropdown_comlete`}
                      type="text"
                      style={{ width: '100%', textAlign: 'left' }}
                      icon={<CheckOutlined />}
                      disabled={
                        receiveDisabled ||
                        data.warehouseNum !==
                        Number(
                          localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)
                        ) ||
                        data.transStatus === 2
                      }
                      onClick={() => {
                        setCurrent(data);
                        setCompleteDialogVisible(true);
                      }}
                    >
                      Complete
                    </Button>
                  </Menu.Item>
                  {data.transStatus === 2 && (
                    <Menu.Item key="5">
                      <Button
                        id={`bulk_return_grid_action_row_${rowIndex}_dropdown_resync`}
                        type="text"
                        style={{ width: '100%', textAlign: 'left' }}
                        icon={<SyncOutlined />}
                        onClick={() => {
                          Modal.confirm({
                            title: 'Are you sure to resync this return?',
                            okText: 'Resync',
                            cancelText: 'Cancel',
                            content: `WMS Return ID: ${data.fulfillmentBulkReturnId}`,
                            onOk: () => {
                              batchResync([{ ...data }]);
                            },
                          });
                        }}
                      >
                        Resync
                      </Button>
                    </Menu.Item>
                  )}
                  <Menu.Item key="4">
                    <Button
                      id={`bulk_return_grid_action_row_${rowIndex}_dropdown_delete`}
                      type="text"
                      style={{ width: '100%', textAlign: 'left' }}
                      className={
                        data.transStatus === 2 ? '' : 'hover-danger-button-2'
                      }
                      icon={<DeleteOutlined />}
                      disabled={data.transStatus === 2}
                      onClick={() => {
                        Modal.confirm({
                          title: 'Are you sure delete this Customer Return?',
                          icon: <ExclamationCircleOutlined />,
                          content: `Reference No:  ${data.referenceNo}`,
                          okText: 'Yes',
                          okType: 'default',
                          okButtonProps: { className: 'hover-danger-button' },
                          cancelText: 'No',
                          autoFocusButton: 'cancel',
                          onOk() {
                            delBulkRturn(data);
                          },
                          onCancel() { },
                        });
                      }}
                    >
                      Delete
                    </Button>
                  </Menu.Item>
                </Menu>
              }
              trigger={['click']}
            >
              <Button
                id={`catalog_grid_action_row_${rowIndex}_dropdown`}
                icon={<SettingFilled />}
              >
                <CaretDownOutlined />
              </Button>
            </Dropdown>
          );
        },
      },
    ];
  }, [receiveDisabled, delBulkRturn, batchResync]);

  const openDeviceSettingDialog = (config: StringKAnyVPair) => {
    setDeviceDialogClosable(
      typeof config.closable === 'boolean' ? config.closable : true
    );
    setDeviceSettingVisible(true);
  };

  React.useEffect(() => {
    if (
      !localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY) ||
      !getWarehouseCodeFromCache(
        Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
      )
    ) {
      openDeviceSettingDialog({ closable: false });
    }
  }, []);

  React.useEffect(() => {
    if (whs) {
      const exist = whs.filter(
        (e) =>
          e.id === Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) &&
          (e.warehouseType === 1 || e.warehouseType === 5)
      );
      if (exist.length === 0) {
        openDeviceSettingDialog({ closable: false });
      } else {
        setSelectedWarehouse(exist[0]);
      }
    }
  }, [whs]);

  const getAllSpecialInfo = useCallback(async () => {
    setIniting(true);
    if (
      cacheInfo &&
      cacheInfo.warehouseNum &&
      cacheInfo.warehouseNum === selectedWarehouse?.id
    ) {
      if (cacheInfo.specialWarehouseInfo) {
        let temp: SpecialInfoType = {
          2: undefined,
          3: undefined,
          4: undefined,
        };
        cacheInfo.specialWarehouseInfo.map((i: any) => {
          if (i.warehouseType === 2) {
            temp[2] = i;
          }
          if (i.warehouseType === 3) {
            temp[3] = i;
          }
          if (i.warehouseType === 4) {
            temp[4] = i;
          }
          return true;
        });
        if (!temp[2] || !temp[3] || !temp[4]) {
          setReceiveDisabled(true);
          message.info({
            content:
              'Warehouse is not found, please check whether you have permission or whether the warehouse exists',
          });
        }
        setSpecialInfo(temp);
        setIniting(false);
      }
    } else {
      try {
        const res = await getSpecialLocations(
          selectedWarehouse?.warehouseId || '',
          0
        );
        if (res) {
          dispatch(
            specialWarehouseInfo({
              warehouseNum: selectedWarehouse?.id || 0,
              specialWarehouseInfo: res,
            })
          );
        }
      } catch (error) { }
    }
  }, [selectedWarehouse, cacheInfo, dispatch]);

  const getAllSpecialInfo2 = useCallback(async()=>{
    try {
      if(!selectedWarehouse || !selectedWarehouse.warehouseNum) return;
      setIniting(true);
      const {databaseNum,inspectionLocationNum, inspectionWarehouseNum, resalableLocationNum, resalableWarehouseNum, damageLocationNum, damageWarehouseNum} = selectedWarehouse
      if(!inspectionLocationNum || !inspectionWarehouseNum || !resalableLocationNum || !resalableWarehouseNum || !damageLocationNum || !damageWarehouseNum) {
        getAllSpecialInfo()
      } else {
        const location2 = await fetchWarehouseLocationList(`${databaseNum}-${resalableWarehouseNum}`, false)
        const location4 = await fetchWarehouseLocationList(`${databaseNum}-${inspectionWarehouseNum}`, false)
        const location3 = await fetchWarehouseLocationList(`${databaseNum}-${damageWarehouseNum}`, false)
        let temp: SpecialInfoType = {
          2: undefined,
          3: undefined,
          4: undefined,
        };
        whs.map(i => {
          if(i.warehouseNum === resalableWarehouseNum){
            const idx = location2.findIndex(i => i.locationNum === resalableLocationNum)
            temp[2] = {
              databaseNum: i.databaseNum || 0,
              locations: [...location2],
              warehouseCode: i.warehouseCode || '',
              warehouseId: i.warehouseId || '',
              warehouseNum: i.warehouseNum,
              warehouseType: i.warehouseType || 0,
              defaultLocationNum: resalableLocationNum,
              defaultLocationCode: location2[idx].locationCode || '',
            }
          }
          if(i.warehouseNum === inspectionWarehouseNum){
            const idx = location4.findIndex(i => i.locationNum === inspectionLocationNum)
            temp[4] = {
              databaseNum: i.databaseNum || 0,
              locations: [...location4],
              warehouseCode: i.warehouseCode || '',
              warehouseId: i.warehouseId || '',
              warehouseNum: i.warehouseNum,
              warehouseType: i.warehouseType || 0,
              defaultLocationNum: inspectionLocationNum,
              defaultLocationCode: location4[idx].locationCode || '',
            }
          }
          if(i.warehouseNum === damageWarehouseNum){
            const idx = location3.findIndex(i => i.locationNum === damageLocationNum)
            temp[3] = {
              databaseNum: i.databaseNum || 0,
              locations: [...location3],
              warehouseCode: i.warehouseCode || '',
              warehouseId: i.warehouseId || '',
              warehouseNum: i.warehouseNum,
              warehouseType: i.warehouseType || 0,
              defaultLocationNum: damageLocationNum,
              defaultLocationCode: location3[idx].locationCode || '',
            }
          }
          return true;
        })
        if (!temp[2] || !temp[3] || !temp[4]) {
          setReceiveDisabled(true);
          message.info({
            content:
              'Warehouse is not found, please check whether you have permission or whether the warehouse exists',
          });
        }
        setSpecialInfo(temp);
        setIniting(false);
      }
      
    } catch (error) {
      setReceiveDisabled(true)
      setIniting(false);
    }
  },[selectedWarehouse, whs, getAllSpecialInfo])

  const getSetting = useCallback(async () => {
    const res = await getProfileSettingValue('EnableNormalWareshouseReceive','0');
    // setEnableNormal(res === '1');
    if(res === '1'){
      getAllSpecialInfo2()
    } else {
      getAllSpecialInfo()
    }
  }, [getAllSpecialInfo2, getAllSpecialInfo]);


  /**
   * handle reset event
   */
  const handleReset = useCallback(() => {
    form.resetFields();
  }, [form]);
  /**


  /* eslint-disable */
  useEffect(() => {
    const fetch = async () => {
      const res = await fetchStoreList();
      if (res.isSuccess) {
        setStores(
          res.data
            .filter((i: any) => i.hideFromSelection === 0)
            .sort((a: any, b: any) => sortByString(a, b, 'channelAccountName'))
        );
      }
    };
    fetch();
  }, []);

  useEffect(() => {
    if (
      selectedWarehouse &&
      selectedWarehouse.id ===
      Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
    ) {
      // getSpecialWarehouse();
      getSetting();
    }
  }, [selectedWarehouse, getSetting]);
  /* eslint-enable */

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 9 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 15 },
    },
  };
  return (
    <>
      <HeaderTitle
        breadcrumb={[
          'Return',
          `Customer Return (Bulk Return) (Warehouse : ${getWarehouseCodeFromCache(
            Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
          )}）`,
        ]}
      >
        <Button
          type="primary"
          disabled={initing}
          onClick={() => setCreateDialogVisible(true)}
        >
          <PlusOutlined />
          Create Customer Return
        </Button>
      </HeaderTitle>
      <Spin spinning={initing}>
        <GreyCardWrapper
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <SearchCollapse conditionColumns={conditionColumns}>
            <Form
              form={form}
              {...formItemLayout}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Card style={{ width: '100%' }}>
                <Row>
                  <Col lg={8} sm={24} md={12}>
                    <Form.Item
                      name="FulfillmentBulkReturnId"
                      label={<FormLabel>WMS Return ID</FormLabel>}
                    >
                      <Input id="form_wms_return_id_input" />
                    </Form.Item>
                  </Col>
                  <Col lg={8} md={12} sm={24}>
                    <Form.Item
                      label={<FormLabel>Store(Channel)</FormLabel>}
                      name="ChannelAccountNum"
                    >
                      <Select
                        id="form_store_name_select"
                        showSearch
                        allowClear
                        style={{ width: '100%' }}
                        optionFilterProp="label"
                        labelInValue
                      >
                        {stores.map((item) => (
                          <Select.Option
                            key={item.channelAccountNum}
                            value={item.channelAccountNum}
                            label={item.channelAccountName}
                          >
                            {item.channelAccountName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col lg={8} sm={24} md={12}>
                    <Form.Item
                      name="ReferenceNo"
                      label={<FormLabel>Reference No</FormLabel>}
                    >
                      <Input id="form_invoice_input" />
                    </Form.Item>
                  </Col>

                  <Col lg={8} sm={24} md={12}>
                    <Form.Item
                      id="sku_input"
                      name="sku"
                      label={
                        <Select
                          style={{ padding: 0, fontSize: 12 }}
                          size="small"
                          bordered={false}
                          dropdownMatchSelectWidth={false}
                          value={oper}
                          defaultValue={0}
                          onChange={(value) => {
                            setOper(value);
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                        >
                          <Select.Option value={0}>
                            <FormLabel>SKU / UPC equals</FormLabel>
                          </Select.Option>
                          <Select.Option value={1}>
                            <FormLabel>SKU starts with</FormLabel>
                          </Select.Option>
                        </Select>
                      }
                      labelCol={{
                        xs: { span: 24 },
                        sm: { span: 9 },
                        style: {
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'end',
                        },
                      }}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col lg={8} sm={24} md={12}>
                    <Form.Item
                      id="form_warehouse_select"
                      name="WarehouseNum"
                      label={<FormLabel>Warehouse</FormLabel>}
                    >
                      <SelectWarehouse onlyNormal />
                    </Form.Item>
                  </Col>

                  <Col lg={8} sm={24} md={12}>
                    <Form.Item
                      name="ReturnDate"
                      initialValue={[
                        moment().add(-14, 'day').startOf('day'),
                        moment().endOf('day'),
                      ]}
                      label={<FormLabel>Return Date</FormLabel>}
                    >
                      <RangePicker
                        id="form_crete_date_range_picker"
                        style={{ width: '100%' }}
                        showTime={{
                          hideDisabledOptions: true,
                          defaultValue: [
                            moment('00:00:00', 'hh:mm:ss'),
                            moment('23:59:59', 'hh:mm:ss'),
                          ],
                        }}
                        ranges={{
                          Today: [
                            moment().startOf('day'),
                            moment().endOf('day'),
                          ],
                          Yesterday: [
                            moment().add(-1, 'day').startOf('day'),
                            moment().add(-1, 'day').endOf('day'),
                          ],
                          '2 days ago': [
                            moment().add(-2, 'day').startOf('day'),
                            moment().endOf('day'),
                          ],
                          '3 days ago': [
                            moment().add(-3, 'day').startOf('day'),
                            moment().endOf('day'),
                          ],
                          'This Week': [
                            moment().startOf('week'),
                            moment().endOf('week'),
                          ],
                          'Last Week': [
                            moment().add(-1, 'week').startOf('week'),
                            moment().add(-1, 'week').endOf('week'),
                          ],
                          'This Month': [
                            moment().startOf('month'),
                            moment().endOf('month'),
                          ],
                        }}
                        format="MM/DD/YYYY hh:mm:ssA"
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={8} sm={24} md={12}>
                    <Form.Item
                      id="form_status_select"
                      name="status"
                      label={<FormLabel>Status</FormLabel>}
                    >
                      <Select style={{ width: '100%' }} allowClear labelInValue>
                        <Select.Option value={0}>Draft</Select.Option>
                        <Select.Option value={1}>Proccessing</Select.Option>
                        <Select.Option value={2}>Complete</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col lg={8} sm={24} md={12}>
                    <Form.Item
                      name="ControlNo"
                      label={<FormLabel>Control No</FormLabel>}
                    >
                      <Input id="form_ControlNo_input" />
                    </Form.Item>
                  </Col>
                  <Col lg={8} sm={24} md={12}>
                    <Form.Item
                      name="BoxID"
                      label={<FormLabel>Box ID</FormLabel>}
                    >
                      <Input id="form_BoxID_input" />
                    </Form.Item>
                  </Col>
                  <Col lg={8} sm={24} md={12}>
                    <Form.Item
                      name="SyncStatus"
                      label={<FormLabel>Sync Status</FormLabel>}
                    >
                      <Select
                        allowClear
                        id="form_syncStatus_select"
                        labelInValue
                      >
                        <Select.Option key="syncStatus0" value={0}>
                          Pending
                        </Select.Option>
                        <Select.Option key="syncStatus1" value={1}>
                          Success
                        </Select.Option>
                        <Select.Option key="syncStatus2" value={2}>
                          Failed
                        </Select.Option>
                        <Select.Option key="syncStatus3" value={3}>
                          Proccessing
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col lg={8} sm={24} md={12}>
                    <Form.Item
                      name="CustomerCode"
                      label={<FormLabel>Customer</FormLabel>}
                    >
                      <Select
                        id="form_customer_select"
                        showSearch
                        allowClear
                        labelInValue
                        style={{ width: '100%' }}
                        optionFilterProp="children"
                        loading={fetching}
                        filterOption={(input, option) => {
                          if (option) {
                            const { children = '', value = '' } = option;
                            return (typeof children === 'string' && children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                              || (typeof value === 'string' && value.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                          }
                          return false;
                        }}
                      >
                        {customers.map((item) => (
                          <Select.Option
                            key={item.customerCode}
                            value={item.customerCode}
                          >
                            {item.customerName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify="center">
                  <Space>
                    <Button
                      id="form_search_button"
                      type="primary"
                      onClick={() => {
                        handleSearch();
                      }}
                      icon={<SearchOutlined />}
                    >
                      Search
                    </Button>
                    <ExportCSV
                      id="form_export_button"
                      data={data || []}
                      columns={getColumns().filter((item) => item.header !== 'Action')}
                      scope="BulkReturn"
                      formatColumns={{ transStatus: statusEnum, syncStatus: syncStatusEnum}}
                    />
                    <ClearButton id="form_clean_button" onClick={handleReset} />
                  </Space>
                </Row>
              </Card>
            </Form>
          </SearchCollapse>
        </GreyCardWrapper>

        {!initing && data && (
          <GreyCardWrapper
            style={{
              marginTop: 20,
            }}
          >
            <Row justify="space-between" style={{ marginBottom: 8 }}>
              <TablesFilter
                columns={getColumns()}
                dataSource={data}
                setFilteredData={setFilteredData}
              />
              <Button
                id="batch_resync"
                loading={resyncLoading}
                disabled={
                  selectedRow.filter((i: any) => i.transStatus === 2).length ===
                  0
                }
                onClick={() => {
                  Modal.confirm({
                    title: 'Are you sure to resync selected returns?',
                    okText: 'Resync',
                    cancelText: 'Cancel',
                    content: '',
                    onOk() {
                      batchResync(selectedRow);
                    },
                  });
                }}
              >
                Resync
              </Button>
            </Row>
            <DataGridPlus
              skip={skipRef.current}
              limit={limitRef.current}
              name="BlukReturnGrid"
              autoWith={false}
              showScrollButton
              count={total}
              dataSource={filteredData}
              onChange={(limit, skip) => {
                limitRef.current = limit;
                skipRef.current = skip;
                handleDataGridChange(limit, skip);
              }}
              pageSizes={[10, 50, 200, 500, 1000, 1]}
              loading={loading}
              columns={getColumns()}
              idProperty="fulfillmentBulkReturnNum"
              pagination="remote"
              checkboxColumn
              checkboxOnlyRowSelect={true}
              selected={selected}
              onSelectionChange={(props) =>
                onSelectionChange(
                  props,
                  data,
                  setSelected,
                  setSelectedRow,
                  'fulfillmentBulkReturnNum'
                )
              }
              // onSelectionChange={(props) => {
              //   const { selected, data, unselected } = props;
              //   setSelected(selected);
              //   if (selected === true && !unselected) {
              //     setSelectedRow(data as any);
              //   } else {
              //     if (!unselected) {
              //       setSelectedRow(Object.values(selected as any));
              //     } else {
              //       setSelectedRow((prev: any) => {
              //         return prev.filter(
              //           (item: any) =>
              //             item.shipmentNum !== (data as any).shipmentNum
              //         );
              //       });
              //     }
              //   }
              // }}
              /* eslint-disable react/jsx-props-no-spreading */
              {...({} as any)}
            />
          </GreyCardWrapper>
        )}
      </Spin>
      {createDialogVisible && (
        <CreateDialog
          visible
          customers={customers}
          onClose={(needRefresh: boolean) => {
            setCreateDialogVisible(false);
            if (needRefresh) {
              // console.log('tempParams = ', tempParams);
              if (tempParams) {
                handleDataGridChange(limitRef.current, skipRef.current);
              } else {
                handleSearch();
              }
            }
          }}
          stores={stores.filter((i: any) => i.status === 1)}
          receiveDisabled={receiveDisabled}
          goReceive={(data: any) => {
            setCreateDialogVisible(false);
            setCurrent(data);
            setReceiveDialogVisible(true);
          }}
        />
      )}
      {receiveDialogVisible && (
        <ReceiveDialog
          visible
          onClose={() => {
            setReceiveDialogVisible(false);
            //todo refresh
            if (tempParams) {
              handleDataGridChange(limitRef.current, skipRef.current);
            } else {
              handleSearch();
            }
          }}
          modalReturn={current}
          specialInfo={specialInfo}
          defaultWarehouseType={defaultWarehouseType}
          defaultLocationCode={defaultLocationCode}
        />
      )}
      {
        <DeviceSettingDialog
          closable={deviceDialogClosable}
          close={() => {
            setDeviceSettingVisible(false);
          }}
          visible={deviceSettingVisible}
        />
      }
      {detailDialogVisible && current && (
        <DetailDialog
          visible
          onClose={() => {
            setDetailDialogVisible(false);
            if (tempParams) {
              handleDataGridChange(limitRef.current, skipRef.current);
            } else {
              handleSearch();
            }
          }}
          modalReturn={current}
          receiveDisabled={receiveDisabled}
          specialInfo={specialInfo}
          defaultLocationCode={defaultLocationCode}
          defaultWarehouseType={defaultWarehouseType}
        />
      )}
      {completeDialogVisible && current && (
        <CompleteDialog
          visible
          onClose={(value: boolean) => {
            setCompleteDialogVisible(false);
            if (value) {
              handleDataGridChange(limitRef.current, skipRef.current);
            }
          }}
          modalReturn={current}
        />
      )}
      {restockDialogVisible && current && (
        <RestockDialog
          visible
          onClose={(key: string) => {
            setRestockDialogVisible(false);
            //todo refresh
            if (key === 'complete') {
              setCompleteDialogVisible(true);
            } else {
              handleDataGridChange(limitRef.current, skipRef.current);
            }
          }}
          modalReturn={current}
          specialInfo={specialInfo}
          defaultLocationCode={defaultLocationCode}
        />
      )}
    </>
  );
}
