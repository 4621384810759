import React, { useEffect, useState } from 'react';
import { Button, Modal, Row, Space, Upload, Form, Select, Popover, Checkbox } from 'antd';
import { CloseOutlined, CloudDownloadOutlined, InboxOutlined, QuestionCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import {
  importFile,
  downloadTemplate,
  checkOrderFormatRight,
} from 'services/files';
import { message, FormLabel } from 'components/common';
import { fetchStoreList } from 'services/storeSetup';
import { sortByString } from 'utils';

const { Dragger } = Upload;

type Props = {
  onHide: () => void;
  onRefesh: () => void;
  operation?: OperationType;
  showModal?: boolean;
};
// eslint-disable-next-line
export default (props: Props) => {
  const { onHide, onRefesh, operation, showModal } = props;
  const [uploading, setUploading] = useState(false);
  const [fileList, setFileList] = useState<any[]>([]);
  const [storeList, setStoreList] = useState<StoreChannelRow[]>([]);
  const [orderType, setOrderType] = useState<string>('');

  const history = useHistory();
  const [form] = Form.useForm();

  const getChannelAccountList = async () => {
    try {
      const res = await fetchStoreList();
      if (res.isSuccess) {
        if (res.data.length > 0) {
          form.setFieldsValue({});
        }
        setStoreList(
          res.data
            .filter((i: any) => i.status === 1)
            .sort((a: any, b: any) => sortByString(a, b, 'channelAccountName'))
        );
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpload = async () => {
    let params: any;
    params = await form.validateFields();
    if (params.errorFields) return;
    const { ExtraInfo, updateToEmpty } = params;
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('File', file);
    });
    formData.append('fileoperation', `${operation?.key}`);
    if (operation?.key === 2001 || operation?.key === 2111) {
      // formData.append('OrderModelType', params.OrderModelType);
      formData.append('ExtraInfo', `{"ChannelAccountNum":${ExtraInfo}}`);
    }
    // formData.append('FileDescription', 'file Description');
    if (operation?.key === 2110) {
      formData.append('ExtraInfo', updateToEmpty ? '1' : '0');
    }
    setUploading(true);
    try {
      const res = await importFile(formData);
      setUploading(false);
      if (showModal) {
        Modal.confirm({
          title: 'Your file has been uploaded',
          okText: 'Close',
          cancelText: 'Check now',
          content:
            'We are currently processing your file, you can check the job status in the import tab.',
          onOk() {
            onHide();
          },
          onCancel() {
            history.push('/import');
          },
        });
      } else {
        message.success({ content: res.message });
        onRefesh();
        onHide();
      }
    } catch (err) {
      setUploading(false);
      console.log(err);
    }
  };

  const downloadTemplateFile = async (operation: OperationType) => {
    try {
      let suffix = '';
      if (operation.key === 2001 && orderType === 'Shopify') {
        suffix = orderType;
      }
      const res = await downloadTemplate(operation.key, suffix);
      const content = res;
      const blob = new Blob([content]);
      if ('download' in document.createElement('a')) {
        const elink = document.createElement('a');
        elink.download = `${operation?.value}-template.xlsx`;
        elink.style.display = 'none';
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href);
        document.body.removeChild(elink);
      } else {
        //navigator.msSaveBlob(blob, `${operation?.value}-template.xlsx`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const queryOrderRight = async (id: number) => {
    try {
      const res = await checkOrderFormatRight(id);
      if (res.isSuccess) {
        setOrderType(res.data);
      } else {
        setOrderType('');
      }
    } catch (error) {
      form.setFieldsValue({ ExtraInfo: undefined });
      setOrderType('');
    }
  };

  const handleCancel = () => {
    setUploading(false);
    setFileList([]);
    onHide();
  };

  const uploadProps = {
    onRemove: (file: any) => {
      setFileList((prev) => {
        const index = prev.indexOf(file);
        const newFileList = prev.slice();
        newFileList.splice(index, 1);
        return newFileList;
      });
    },
    beforeUpload: (file: any) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };

  /* eslint-disable */
  useEffect(() => {
    if (operation?.key === 2001 || operation?.key === 2111) {
      getChannelAccountList();
    }
  }, [operation]);
  /* eslint-enable */
  return (
    <Modal
      title={`Import File - ${operation?.name}`}
      centered
      visible
      maskClosable={false}
      width={650}
      confirmLoading={uploading}
      destroyOnClose
      onCancel={handleCancel}
      getContainer={false}
      forceRender
      footer={
        <Row justify="space-between">
          <Space>
            <Button
              id="downloadTemplate_btn"
              type="default"
              disabled={operation?.key === 2001 && !orderType}
              onClick={() => {
                if (operation) {
                  downloadTemplateFile(operation);
                } else {
                  message.error({ content: 'Unknown operation' });
                }
              }}
              icon={<CloudDownloadOutlined />}
            >
              Download Template
            </Button>
            <Popover
              content={
                <div style={{ maxWidth: 600 }}>
                  {
                    {
                      2101: 'Columns WarehouseCode,LocationCode, amd Reserved,Backstock are required. Parent, Seq, and Note are option.',
                      2102: 'Columns WarehouseCode, ZoneName, and LocationCode are required. ZoneDesc is optional.',
                      2001:
                        orderType === 'Shopify'
                          ? 'Columns Name, Id, Fulfillment Status, Currency, Taxes, Total, Shipping Method, Lineitem quantity, Lineitem name, Lineitem sku, Shipping Name, Shipping Address1, Shipping City, Shipping Zip, Shipping Province, Shipping Country, and Shipping Phone are required.'
                          : 'Columns UniqueOrderId, LineItem Sku, Lineitem Product Title, Lineitem Quantity, SecondOrderId, SellerOrderId, Order Tax Amount, Order Total Amount, Shipping Name, Shipping Address1, Shipping City, Shipping Zip, Shipping State, Shipping Country, and Shipping Phone are required.',
                    }[operation ? operation.key : 0]
                  }
                </div>
              }
            >
              <QuestionCircleOutlined style={{ fontSize: 16 }} />
            </Popover>
          </Space>
          <Space>
            <Button
              id="startUpload_btn"
              key="submit"
              type="primary"
              loading={uploading}
              disabled={fileList.length === 0}
              onClick={() => {
                handleUpload();
              }}
              icon={<UploadOutlined />}
            >
              {uploading ? 'Uploading' : 'Start Upload'}
            </Button>
            <Button id="hideDialog_btn" onClick={onHide} icon={<CloseOutlined />}>Cancel</Button>
          </Space>
        </Row>
      }
    >
      <Form {...layout} form={form}>
        {/* <Form.Item
            label={<FormLabel>Order Mode Type</FormLabel>}
            name="OrderModelType"
            initialValue={1}
          >
            <Select onChange={(i) => setOrderType(Number(i))}>
              <Select.Option value={1}>DigitBridge</Select.Option>
              <Select.Option value={2}>Shopify</Select.Option>
            </Select>
          </Form.Item> */}
        {
          (operation?.key === 2001 || operation?.key === 2111) && (
            <Form.Item
              label={<FormLabel>Store Account</FormLabel>}
              name="ExtraInfo"
              initialValue={undefined}
              rules={[
                { required: true, message: 'Please select store account !' },
              ]}
            >
              <Select id="extraInfo_selector" onChange={(e) => {
                if (operation.key === 2001) queryOrderRight(Number(e));
              }}>
                {storeList.map((item) => (
                  <Select.Option
                    key={item.channelAccountNum}
                    value={item.channelAccountNum}
                  >
                    {item.channelAccountName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )
        }
        {
          operation?.key === 2110 && (
            <Form.Item
              name="updateToEmpty"
              initialValue={false}
              valuePropName="checked"
            >
              <Checkbox>Update to Empty</Checkbox>
            </Form.Item>
          )
        }
      </Form>
      <Dragger {...uploadProps}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        {/* <p className="ant-upload-hint">
          Support for a single upload. Please refer to the template file for
          file format
        </p> */}
        <p className="ant-upload-hint">
          Support for a single upload. Please refer to the template file for
          file format.
          {/* {
            {
              0: 'Support for a single upload. Please refer to the template file for file format.',
              2101: 'WarehouseCode,LocationCode,Reserved,Backstock is required;and Parent,Seq,Note is the option.',
              2102: 'WarehouseCode,ZoneName,LocationCode is required; and ZoneDesc is the option',
              2001:
                orderType === 'DigitBridge'
                  ? 'UniqueOrderId,LineItem Sku,Lineitem Product Title,Lineitem Quantity,SecondOrderId,SellerOrderId ,Order Tax Amount,Order Total Amount,Shipping Name,Shipping Address1,,Shipping City,Shipping Zip,Shipping State,Shipping Country,Shipping Phone should not be null.'
                  : orderType === 'Shopify'
                  ? 'Name,Id,Fulfillment Status,Currency,Taxes,Total,Shipping Method,Lineitem quantity,Lineitem name,Lineitem sku,Shipping Name,,Shipping Address1 ,Shipping City,Shipping Zip,Shipping Province,Shipping Country,Shipping Phone should not be null.'
                  : 'Support for a single upload. Please refer to the template file for file format.',
            }[operation ? operation.key : 0]
          } */}
        </p>
      </Dragger>
    </Modal>
  );
};
