import React, { useCallback } from 'react';
import { playAudio } from 'utils';
import { message } from 'components/common';
import {
  SmartSpace,
  SmartFormLabel,
  GreenColor,
  SmartScanner,
  SmartRow,
  SmartText,
  SmartBackButton,
} from '../../../SmartComponent';
import { useTranslation } from 'react-i18next';
import SmartTable from './SmartTable';

type ScanSKUProps = {
  order: SmartPickOrder;
  onBack: () => void;
  onSubmit: (product: SmartPickOrderItem) => void;
};
export default function ScanSKU(props: ScanSKUProps): JSX.Element {
  const { onSubmit, order, onBack } = props;
  const { t } = useTranslation();
  /**
   * handle scanner change event
   */
  const handleScannerChange = useCallback(
    async (nextValue: string) => {
      if (nextValue.length > 0) {
        //Check whether the sku is in the order range
        const { items } = order;
        const orderSKU = items.filter(
          (item) => item.sku === nextValue || item.upc === nextValue || (item.productAllCode||[]).indexOf(nextValue) > -1
        );
        // Cannot find sku in this order
        if (orderSKU.length < 1) {
          playAudio('error');
          message.warning(`'${nextValue}' invalid!`);
          return;
        }
        if (orderSKU.length >= 1) {
          const skuArr = orderSKU.filter((i)=> i.pickQty > i.pickedQty )
          if(skuArr.length > 0) {
            playAudio('success');
              onSubmit(skuArr[0]);
          } else {
            playAudio('error');
              message.warning(`'${nextValue}' had done!`);
          }
          // for (const sku of orderSKU) {
          //   if (sku.pickQty <= sku.pickedQty) {
          //     playAudio('error');
          //     message.warning(`'${nextValue}' had done!`);
          //     return;
          //   } else {
          //     playAudio('success');
          //     onSubmit(sku);
          //   }
          // }
        }
      }
    },
    [onSubmit, order]
  );
  return (
    <>
      <SmartSpace>
        <SmartRow>
          <SmartFormLabel>
            {t("common.order")}
          </SmartFormLabel>
          <SmartText>{order.fulfillmentOrderNum}</SmartText>
        </SmartRow>
        <SmartFormLabel
          style={{ color: GreenColor, fontWeight: 600, fontSize: 18 }}
        >
          {t("smart.pick.scanSkuUpcContinue")}
        </SmartFormLabel>
        <SmartScanner
          style={{ marginTop: 10 }}
          onChangeValue={handleScannerChange}
        />
      </SmartSpace>
      <SmartTable dataSource={order.items} />
      <SmartBackButton type="warning" onClick={onBack} />
    </>
  );
}
