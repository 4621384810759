import styled from 'styled-components';
import { Layout, Menu } from 'antd';

export const LogoContainer = styled.div`
  margin: 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const LogoHeader = styled.div`
  display: flex;
  align-items: center;

  & img {
    width: 35px;
    height: 35px;
    border-radius: 21px;
  }
`;

export const UserContainer = styled.div`
  display: flex;
`;

export const UserInfoContainer = styled.div`
  margin-left: 12px;
`;

export const RegularText = styled.h2`
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: white;
  margin-left: 10px;
  margin-bottom: 0;
`;

export const PrimaryText = styled.h2`
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: @primary-text;
  margin: 0;
  margin-left: 10px;
`;

export const LogoText = styled(PrimaryText)`
  min-width: 100px;
  margin-top: 15px;
  color: white;
  transition: opacity 400ms;
`;

export const LabelName = styled.label`
  width: 69px;
  height: 22px;
  font-family: Lato;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
`;

export const MenuItem = styled(Menu.Item)`
  display: flex;
  align-items: center;
`;

export const Divider = styled.div`
  border-bottom-color: #d8dde6;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin: 10px 24px 10px 24px;
`;

export const SiderContainer = styled(Layout.Sider)`
  height: 100vh;
  position: fixed;
  left: 0;
  background-color: #353a40;
  overflow-y: auto;

  & .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #515961;
  font-weight: 700;
  }

  &.collapse-sidebar {
    overflow: visible;
  }
`;

export const SystemNameContainer = styled.div`
  align-items: center;
  background-color: #FFBB4D;
  border-radius: 0 10px 10px 0;
  box-shadow: rgba(0, 0, 0, .25) 0px 3px 8.3px 0px;
  #color: #F9F5E7;
  color: #FFF;
  display: flex;
  height: 42px;
  margin-bottom: 20px;
  padding-left: 20px;
  width: calc(100% - 20px);

  &.collapse-ctn {
    width: 85px
  }

  &.pim-name-ctn {
    background-color: #BC6C25;
  }

  & .app-icon-ctn {
    height: 40px;
  }

  & .app-name {
    font-size: 14px;
    font-weight: 500;
  }
`;