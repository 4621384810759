import React, { useEffect, useCallback, useState, useRef } from 'react';
import { ModalProps } from 'antd/lib/modal/Modal';
import ModalDialog from '../../../components/common/ModalDialog';
import {
  SaveOutlined,
  CloseOutlined,
  // CheckCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import {
  CopySpan,
  DataGridPlus,
  FormLabel,
  InputInteger,
  message,
  Locker,
} from '../../../components/common';
import {
  Card,
  Form,
  Row,
  Col,
  Select,
  // Space,
  Button,
  Input,
  // Space,
  Modal,
  Spin,
  Typography,
  Space,
  Steps,
} from 'antd';
import {
  receiveReturn,
  // getOrderReturnReceiveList,
  fetchReturns,
  downloadRemoteReturn,
  closeReturn,
} from 'services/return';
// import debounce from 'lodash.debounce';
import {
  // getWarehouseIdFromCache,
  convertTimeByUtc,
  getWarehouseCodeFromCache,
} from 'utils';
import { playAudio } from 'utils';
import {
  LS_DEFAULT_WAREHOUSE_KEY,
  DATE_TIME_NO_SECOND_FORMAT,
} from 'constants/config';
import theme from 'assets/styles/theme';

interface Props extends ModalProps {
  onClose: Function;
  onShowDetail: Function;
  modalReturn: any;
  specialInfo: any;
  defaultWarehouseType: number;
  warehouseId: string;
  warehouseCode: string;
  defaultLocationCode: string;
  // downLoadPaarms: any;
}

const warehouseTypeKeyValue: StringKVPair = {
  '2': 'Resalable',
  '3': 'Damage',
  '4': 'Inspection',
};

const ProductDetailDialog = (props: Props) => {
  const {
    modalReturn,
    specialInfo,
    defaultWarehouseType,
    defaultLocationCode,
    warehouseId,
    warehouseCode,
  } = props;
  const [detailDialogIsFullscreen, setDetailDialogIsFullscreen] =
    useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const refreshBtnRef = React.useRef<any>(null);
  const [selected, setSelected] = useState<number>(defaultWarehouseType);
  const [returnInfo, setReturnInfo] = useState<any>(); //return info
  const [lines, setLines] = useState<any>([]);
  const [initing, setIniting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saveAble, setSaveAble] = useState(false);
  const [showStepDialog, setShowStepDialog] = useState(false);
  const [saveStep, setSaveStep] = useState(0);
  const [retry, setRetry] = useState<number>(0);
  const newInfo = useRef<any>();

  const [form] = Form.useForm();
  const { Text } = Typography;

  const closeProductDetailDialog = () => {
    if (saveAble) {
      Modal.confirm({
        title: 'Do you want to leave this page？',
        okText: 'No',
        cancelText: 'Yes',
        cancelButtonProps: { className: 'hover-danger-button' },
        content: `Changes you made may not be saved.`,
        onCancel() {
          props.onClose();
        },
      });
    } else {
      props.onClose();
    }
  };

  const detailDialogWidth = useCallback(
    (isFullscreen = detailDialogIsFullscreen) => {
      return isFullscreen
        ? window.innerWidth - 4
        : window.innerWidth > 1280
        ? window.innerWidth * 0.8
        : 1200;
    },
    [detailDialogIsFullscreen]
  );

  const getDialogDom = () => {
    const refreshBtn = refreshBtnRef?.current;

    if (refreshBtn) {
      return refreshBtn.parentNode.parentNode.parentNode.parentNode.parentNode
        .parentNode.parentNode;
    }

    return null;
  };

  const onFullscreen = (isFullscreen: boolean) => {
    const dialog = getDialogDom();

    setDetailDialogIsFullscreen(isFullscreen);

    if (dialog) {
      const width = detailDialogWidth(isFullscreen);

      dialog.style.width = `${width}px`;
    }
  };

  const refreshData = useCallback(
    async (returnNo: any) => {
      try {
        const query = {
          $top: 10,
          $skip: 0,
          $sortBy: 'FulfillmentReturnNum desc',
          returnNo,
          oper: 0,
        };
        setLoading(true);
        const res = await fetchReturns(warehouseId, query);
        setLoading(false);
        if (res.data.length > 0) {
          props.onShowDetail(res.data[0]);
        } else {
          message.error('No data found');
          props.onClose();
        }
      } catch (error) {
        setLoading(false);
      }
    },
    [warehouseId, props]
  );

  const fomartLines = useCallback(
    (linesData: any[]) => {
      // "FulfillmentReturnLineNum": 0,
      // "ReceiveWarehouseType": 2,
      // "ReceiveWarehouseNum": 0,
      // "ReceiveLocationNum": 0,
      // "ReceiveQty": 0
      const tempLoc2 = specialInfo[2]
        ? (specialInfo[2].locations || []).find(
            (i: any) => i.locationCode === defaultLocationCode
          )
        : undefined;
      const tempLoc3 = specialInfo[3]
        ? (specialInfo[3].locations || []).find(
            (i: any) => i.locationCode === defaultLocationCode
          )
        : undefined;
      const tempLoc4 = specialInfo[4]
        ? (specialInfo[4].locations || []).find(
            (i: any) => i.locationCode === defaultLocationCode
          )
        : undefined;
      const temp2 = linesData
        .filter((i: any) => i.stockQty)
        .map((j: any) => {
          return {
            FulfillmentReturnLineNum: j.fulfillmentReturnLineNum,
            ReceiveWarehouseType: 2,
            ReceiveWarehouseNum: specialInfo[2].warehouseNum,
            ReceiveLocationNum: specialInfo[2].defaultLocationNum || tempLoc2?.locationNum,
            ReceiveQty: j.stockQty,
          };
        });
      const temp3 = linesData
        .filter((i: any) => i.nonStockQty)
        .map((j: any) => {
          return {
            FulfillmentReturnLineNum: j.fulfillmentReturnLineNum,
            ReceiveWarehouseType: 3,
            ReceiveWarehouseNum: specialInfo[3].warehouseNum,
            ReceiveLocationNum: specialInfo[3].defaultLocationNum || tempLoc3?.locationNum,
            ReceiveQty: j.nonStockQty,
          };
        });
      const temp4 = linesData
        .filter((i: any) => i.inspectionQty)
        .map((j: any) => {
          return {
            FulfillmentReturnLineNum: j.fulfillmentReturnLineNum,
            ReceiveWarehouseType: 4,
            ReceiveWarehouseNum: specialInfo[4].warehouseNum,
            ReceiveLocationNum: specialInfo[4].defaultLocationNum || tempLoc4?.locationNum,
            ReceiveQty: j.inspectionQty,
          };
        });
      return [...temp2, ...temp3, ...temp4];
    },
    [specialInfo, defaultLocationCode]
  );

  const onSave = useCallback(async () => {
    setSaveLoading(true);
    setShowStepDialog(true);
    setRetry(0);
    setSaveStep(0);
    let res: any = undefined;
    let res2: any = undefined;
    let res3: any = undefined;
    const targets = lines.filter((i: any) => i.receiveQty);
    const params = targets.map((item: any) => {
      return {
        TransUuid: item.transUuid,
        WarehouseCode: warehouseCode,
        InvoiceNumber: returnInfo.invoiceNo,
        InvoiceItemUuid: item.invoiceItemsUuid,
        Sku: item.sku,
        ReturnQty: item.returnQty,
        Reason: item.reason,
      };
    });
    for (let a = 0; a < 3; a++) {
      setRetry(a);
      try {
        res = await downloadRemoteReturn(warehouseId, params);
        console.log('res', res);
        if (res && res.isSuccess) {
          break;
        }
      } catch (error) {
        console.log('error', error);
        continue;
      }
    }
    if (res && res.isSuccess) {
      setRetry(0);
      setSaveStep(1);
    } else {
      setSaveLoading(false);
      setShowStepDialog(false);
      return;
    }

    const newReturnInfo = res.data;
    if(!newReturnInfo){
      setSaveLoading(false);
      setShowStepDialog(false);
      return;
    }
    newInfo.current = newReturnInfo;
    const newLines = newReturnInfo.items.map((i: any) => {
      const temp = lines.filter(
        (item: any) => item.invoiceItemsUuid === i.invoiceItemsUuid
      );
      if (temp.length === 1) {
        return {
          ...i,
          stockQty: temp[0].stockQty,
          inspectionQty: temp[0].inspectionQty,
          nonStockQty: temp[0].nonStockQty,
        };
      } else {
        return { ...i };
      }
    });
    const params2 = fomartLines(newLines);
    for (let a = 0; a < 3; a++) {
      setRetry(a);
      try {
        res2 = await receiveReturn(
          warehouseId,
          newReturnInfo.fulfillmentReturnNum,
          params2
        );
        if (res2) {
          break;
        }
      } catch (error) {
        console.log('error', error);

        continue;
      }
    }
    if (res2) {
      setRetry(0);
      setSaveStep(2);
    } else {
      setSaveLoading(false);
      setShowStepDialog(false);
      return;
    }

    for (let a = 0; a < 3; a++) {
      setRetry(a);
      try {
        res3 = await closeReturn(
          warehouseId,
          newReturnInfo.fulfillmentReturnNum
        );
        if (res3) {
          break;
        }
      } catch (error) {
        console.log(error);
        continue;
      }
    }
    if (res3) {
      setRetry(0);
      setSaveStep(3);
    } else {
      setSaveLoading(false);
      setShowStepDialog(false);
      return;
    }

    // try {
    //   setSaveLoading(true);
    //   const targets = lines.filter((i: any) => i.receiveQty);
    //   const params = targets.map((item: any) => {
    //     return {
    //       TransUuid: item.transUuid,
    //       WarehouseCode: warehouseCode,
    //       InvoiceNumber: returnInfo.invoiceNo,
    //       InvoiceItemUuid: item.invoiceItemsUuid,
    //       Sku: item.sku,
    //       ReturnQty: item.returnQty,
    //       Reason: item.reason,
    //     };
    //   });
    //   const res = await downloadRemoteReturn(warehouseId, params);
    //   if (res && res.isSuccess) {
    //     message.success({ content: 'Download successfully' });
    //     const newReturnInfo = res.data;
    //     const newLines = newReturnInfo.items.map((i: any) => {
    //       const temp = lines.filter(
    //         (item: any) => item.invoiceItemsUuid === i.invoiceItemsUuid
    //       );
    //       if (temp.length === 1) {
    //         return {
    //           ...i,
    //           stockQty: temp[0].stockQty,
    //           inspectionQty: temp[0].inspectionQty,
    //           nonStockQty: temp[0].nonStockQty,
    //         };
    //       } else {
    //         return { ...i };
    //       }
    //     });
    //     const params2 = fomartLines(newLines);
    //     const res2 = await receiveReturn(
    //       warehouseId,
    //       newReturnInfo.fulfillmentReturnNum,
    //       params2
    //     );
    //     if (res2) {
    //       const res3 = await closeReturn(
    //         warehouseId,
    //         newReturnInfo.fulfillmentReturnNum
    //       );
    //       setSaveLoading(false);
    //       if (res3) {
    //         Modal.confirm({
    //           title: 'Completed Successfully!',
    //           icon: <CheckCircleOutlined />,
    //           content: '',
    //           okText: 'Go to detail',
    //           okType: 'default',
    //           cancelText: 'Close',
    //           autoFocusButton: 'cancel',
    //           onOk() {
    //             refreshData(newReturnInfo.returnNo);
    //           },
    //           onCancel() {
    //             props.onClose();
    //           },
    //         });
    //       }
    //     }
    //   }
    // } catch (error) {
    //   setSaveLoading(false);
    // }
  }, [lines, returnInfo, warehouseId, fomartLines, warehouseCode]);

  const addSubmit = useCallback(async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    const { sku, warehouseType } = params;
    const qty = Number(params.qty)
    const temp = lines.filter((i: any) => i.sku === sku || i.upc === sku);
    if (temp.length === 0) {
      playAudio('error');
      message.error(`[${sku}] not found`);
      form.setFieldsValue({ sku: undefined });
    } else {
      let totalQty = 0;
      temp.map((i: any) => {
        totalQty = totalQty + i.maxClaimQty - i.receiveQty;
        return true;
      });

      if (totalQty < qty) {
        playAudio('error');
        return message.error(`Quantity overflow`);
      }

      let remainingQty = qty;
      temp.map((i: any) => {
        if (remainingQty > 0) {
          const zqty = i.maxClaimQty - i.receiveQty;
          if (zqty > 0) {
            const idx = lines.findIndex(
              (k: any) => i.invoiceItemsUuid === k.invoiceItemsUuid
            );
            const targetQty = zqty > remainingQty ? remainingQty : zqty;
            setLines((prev: any) => {
              const tempLines = prev;
              tempLines[idx] = {
                ...tempLines[idx],
                stockQty:
                  warehouseType === '2'
                    ? tempLines[idx].stockQty + targetQty
                    : tempLines[idx].stockQty,
                inspectionQty:
                  warehouseType === '4'
                    ? tempLines[idx].inspectionQty + targetQty
                    : tempLines[idx].inspectionQty,
                nonStockQty:
                  warehouseType === '3'
                    ? tempLines[idx].nonStockQty + targetQty
                    : tempLines[idx].nonStockQty,
                receiveQty: tempLines[idx].receiveQty + targetQty,
                returnQty: tempLines[idx].receiveQty + targetQty,
              };
              return [...tempLines];
            });
            remainingQty = zqty > remainingQty ? 0 : remainingQty - zqty;
          }
        }
        return true;
      });
      playAudio('success');
      setSaveAble(true);
      form.setFieldsValue({ sku: undefined });
    }
  }, [lines, form]);

  const checkFinish = useCallback(
    (items: any[]) => {
      let finished = true;
      items.map((i) => {
        if (i.returnQty !== i.maxClaimQty) {
          finished = false;
        }
        return true;
      });
      if (finished) {
        Modal.confirm({
          title:
            'All items have been received. You can now complete this return',
          icon: <ExclamationCircleOutlined />,
          content: '',
          okText: 'Complete',
          okType: 'default',
          cancelText: 'Later',
          autoFocusButton: 'cancel',
          onOk() {
            onSave();
          },
          onCancel() {},
        });
      }
    },
    [onSave]
  );

  useEffect(() => {
    if (lines.length > 0) {
      checkFinish(lines);
    }
  }, [lines, checkFinish]);

  useEffect(() => {
    if (modalReturn && !initing) {
      setReturnInfo(modalReturn);
      setLines(modalReturn.invoiceItems.map((i:any)=>{return {...i, returnQty: 0}}));
      setIniting(true);
    }
  }, [modalReturn, initing]);

  const columns = [
    {
      name: 'sku',
      header: 'SKU',
      minWidth: 220,
      showColumnMenuTool: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'upc',
      header: 'UPC',
      minWidth: 200,
      showColumnMenuTool: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'shipQty',
      header: 'Shiped Qty',
      showColumnMenuTool: true,
      defaultFlex: 1,
    },
    {
      name: 'maxClaimQty',
      header: 'Max Claim Qty',
      showColumnMenuTool: true,
      defaultFlex: 1,
    },
    {
      name: 'returnQty',
      header: 'Claim Qty',
      showColumnMenuTool: true,
      defaultFlex: 1,
    },
    {
      name: 'receiveQty',
      header: 'Receive Qty',
      showColumnMenuTool: true,
      defaultFlex: 1,
    },
    {
      name: 'stockQty',
      header: 'Resalable Qty',
      showColumnMenuTool: true,
      defaultFlex: 1,
    },
    {
      name: 'inspectionQty',
      header: 'Inspection Qty',
      showColumnMenuTool: true,
      defaultFlex: 1,
    },
    {
      name: 'nonStockQty',
      header: 'Damage Qty',
      showColumnMenuTool: true,
      defaultFlex: 1,
    },
    {
      name: 'description',
      header: 'Product Name',
      showColumnMenuTool: true,
      defaultFlex: 1,
    },
  ];

  return (
    <>
      <ModalDialog
        centered
        className="fullscreen-modal"
        closable={false}
        closeButton={true}
        footer={[
          <Button
            disabled={showStepDialog}
            onClick={closeProductDetailDialog}
            icon={<CloseOutlined />}
          >
            Cancel
          </Button>,
          <Button
            type="primary"
            disabled={!saveAble || showStepDialog}
            loading={saveLoading}
            onClick={() => {
              onSave();
            }}
            icon={<SaveOutlined />}
          >
            Complete
          </Button>,
        ]}
        fullscreen={true}
        maskClosable={false}
        onClose={closeProductDetailDialog}
        onFullscreen={onFullscreen}
        title={`Return / Express Receive (Warehouse: ${getWarehouseCodeFromCache(
          Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
        )})`}
        visible={props.visible}
        width={detailDialogWidth()}
      >
        <div style={{ height: '100%', width: '100%' }}>
          <Spin spinning={saveLoading || loading}>
            {returnInfo && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  // backgroundColor: '#fff',
                  backgroundColor: theme['@default-backgroud-color'],
                }}
              >
                <Card
                  size="small"
                  bordered={false}
                  style={{ width: '100%', height: 180 }}
                >
                  <Row>
                    <Col span={12}>
                      <Form form={form}>
                        <Form.Item
                          label={
                            <div style={{ width: 110, textAlign: 'left' }}>
                              <FormLabel require>SKU / UPC</FormLabel>
                            </div>
                          }
                          rules={[{ required: true }]}
                        >
                          <Row gutter={8} align="middle">
                            <Col span={20}>
                              <Form.Item
                                noStyle
                                name="sku"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please input SKU / UPC!',
                                  },
                                ]}
                              >
                                <Input
                                  tabIndex={0}
                                  onPressEnter={() => {
                                    addSubmit();
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.keyCode === 9) {
                                      addSubmit();
                                    }
                                  }}
                                />
                              </Form.Item>
                            </Col>

                            <Col span={4}></Col>
                          </Row>
                        </Form.Item>

                        <Form.Item
                          label={
                            <div style={{ width: 110, textAlign: 'left' }}>
                              <FormLabel require>Quantity</FormLabel>
                            </div>
                          }
                        >
                          <Row gutter={8} align="middle">
                            <Col span={20}>
                              <Form.Item
                                noStyle
                                name="qty"
                                initialValue={1}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please input Quantity!',
                                  },
                                ]}
                              >
                                <InputInteger width={186} />
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              <Locker
                                size={16}
                                value={true}
                                onChange={() => {}}
                              />
                            </Col>
                          </Row>
                        </Form.Item>

                        <Form.Item
                          label={
                            <div style={{ width: 110, textAlign: 'left' }}>
                              <FormLabel require>Marked as</FormLabel>
                            </div>
                          }
                          // extra={`Put it into Warehouse: ${
                          //   specialInfo[selected] &&
                          //   specialInfo[selected].warehouseCode
                          // }`}
                          extra={
                            specialInfo[selected]&&specialInfo[selected].warehouseCode && specialInfo[selected].defaultLocationCode ? 
                              `Put it into Location(warehouse) : ${specialInfo[selected].defaultLocationCode} (${specialInfo[selected].warehouseCode})`
                              : `Put it into Location(warehouse) : ${defaultLocationCode} (${specialInfo[selected].warehouseCode})`
                            
                            }
                        >
                          <Row gutter={8} align="middle">
                            <Col span={20}>
                              <Form.Item
                                noStyle
                                name="warehouseType"
                                initialValue={`${defaultWarehouseType}`}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please select marked as!',
                                  },
                                ]}
                              >
                                <Select
                                  onChange={(value: number) => {
                                    setSelected(value);
                                  }}
                                >
                                  {Object.keys(specialInfo).map((item) => (
                                    <Select.Option key={item} value={item}>
                                      {warehouseTypeKeyValue[item]}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              <Locker
                                size={16}
                                value={true}
                                onChange={() => {}}
                              />
                            </Col>
                          </Row>
                        </Form.Item>
                      </Form>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label={<FormLabel>Channel Order ID</FormLabel>}
                      >
                        <CopySpan value={returnInfo?.channelOrderId || ''} />
                      </Form.Item>
                      <Form.Item label={<FormLabel>Invoice Number</FormLabel>}>
                        <CopySpan value={returnInfo?.invoiceNo || ''} />
                      </Form.Item>
                      <Form.Item label={<FormLabel>Channel</FormLabel>}>
                        <CopySpan value={returnInfo?.channelName || ''} />
                      </Form.Item>
                      <Form.Item label={<FormLabel>Receipt Date</FormLabel>}>
                        <CopySpan
                          value={convertTimeByUtc(
                            modalReturn?.receiveDate,
                            '',
                            '',
                            DATE_TIME_NO_SECOND_FORMAT
                          )}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </div>
            )}
            <DataGridPlus
              style={{ minHeight: 300 }}
              columns={columns}
              autoWith={false}
              showScrollButton
              dataSource={lines}
              idProperty="invoiceItemsUuid"
              checkboxOnlyRowSelect={true}
              showColumnMenuTool={false}
              {...({} as any)}
            />
          </Spin>
          {showStepDialog && (
            <div
              style={{
                position: 'absolute',
                zIndex: 999,
                left: 'calc(50% - 250px)',
                backgroundColor: '#91d5ff',
                top: '20%',
                width: 500,
                height: 330,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography.Title level={5}>
                Your return is processing. This may take a few minutes.
              </Typography.Title>
              <Steps
                direction="vertical"
                current={saveStep}
                style={{ paddingLeft: 100, marginTop: 10 }}
              >
                <Steps.Step
                  title="Create RMA"
                  description={
                    saveStep === 0 ? (
                      retry ? (
                        <Text type="warning">{`Retrying Process(${retry} time)`}</Text>
                      ) : (
                        <Text type="success">In Progress</Text>
                      )
                    ) : saveStep > 0 ? (
                      <Text type="success">Done</Text>
                    ) : (
                      ''
                    )
                  }
                />
                <Steps.Step
                  title="Save received quantity"
                  description={
                    saveStep === 1 ? (
                      retry ? (
                        <Text type="warning">{`Retrying Process(${retry} time)`}</Text>
                      ) : (
                        <Text type="success">In Progress</Text>
                      )
                    ) : saveStep > 1 ? (
                      <Text type="success">Done</Text>
                    ) : (
                      ''
                    )
                  }
                />
                <Steps.Step
                  title="Complete RMA in WMS"
                  description={
                    saveStep === 2 ? (
                      retry ? (
                        <Text type="warning">{`Retrying Process(${retry} time)`}</Text>
                      ) : (
                        <Text type="success">In Progress</Text>
                      )
                    ) : saveStep > 2 ? (
                      <Text type="success">Done</Text>
                    ) : (
                      ''
                    )
                  }
                />
              </Steps>
              {saveStep === 3 && (
                <Row justify="center">
                  <Space>
                    <Button
                      onClick={() => {
                        console.log(newInfo.current);
                        setShowStepDialog(false);
                        setSaveLoading(false);
                        if (newInfo.current) {
                          refreshData(newInfo.current.returnNo);
                        } else {
                          props.onClose();
                        }
                      }}
                    >
                      Go to detail
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => {
                        props.onClose();
                      }}
                    >
                      Close
                    </Button>
                  </Space>
                </Row>
              )}
            </div>
          )}
        </div>
      </ModalDialog>
    </>
  );
};

export default ProductDetailDialog;
