import React from 'react';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import {
  Typography,
  Input,
  Radio,
  Select,
  InputNumber,
  DatePicker,
  Checkbox,
  TimePicker,
} from 'antd';

import { isJsonString } from 'utils';

const { Text } = Typography;

const renderItem = (
  thirdPartySettingDefUI: any,
  disabled: boolean,
) => {
  switch (thirdPartySettingDefUI.uiType) {
    case 1:
      return (
        <Input disabled={disabled} style={{ maxWidth: 600 }} />
      );
    case 2:
      return <InputNumber disabled={disabled} />;
    case 3:
      return (
        <DatePicker format="MM/DD/YYYY" disabled={disabled} />
      );
    case 4:
      console.log('list1', thirdPartySettingDefUI.optionList);
      if (isJsonString(thirdPartySettingDefUI.optionList)) {
        const list1 = JSON.parse(thirdPartySettingDefUI.optionList);
        return (
          <Radio.Group disabled={disabled}>
            {list1.map((i: any, index: number) => (
              <Radio key={`${i.Value}${index}`} value={i.Value}>
                {i.Name}
              </Radio>
            ))}
          </Radio.Group>
        );
      } else {
        return <Text type="danger">database data error</Text>;
      }

    case 5:
      console.log('list', thirdPartySettingDefUI.optionList);
      if (isJsonString(thirdPartySettingDefUI.optionList)) {
        const list = JSON.parse(thirdPartySettingDefUI.optionList);
        const options = list.map((i: any, index: number) => {
          return { label: i.Name, value: i.Value, key: `${i.Value}${index}` };
        });
        return (
          <Checkbox.Group
            options={options}
            disabled={disabled}
          />
        );
      } else {
        return <Text type="danger">database data error</Text>;
      }

    case 6:
      console.log('list4', thirdPartySettingDefUI.optionList);
      if (isJsonString(thirdPartySettingDefUI.optionList)) {
        const list4 = JSON.parse(thirdPartySettingDefUI.optionList);
        return (
          <Select style={{ maxWidth: 600 }} disabled={disabled}>
            {list4.map((i: any, index: number) => (
              <Select.Option key={`${i.Value}${index}`} value={i.Value}>
                {i.Name}
              </Select.Option>
            ))}
          </Select>
        );
      } else {
        return <Text type="danger">database data error</Text>;
      }

    case 7:
      console.log('list3', thirdPartySettingDefUI.optionList);
      if (isJsonString(thirdPartySettingDefUI.optionList)) {
        const list3 = JSON.parse(thirdPartySettingDefUI.optionList);

        return (
          <Select
            mode="multiple"
            style={{ maxWidth: 600 }}
            disabled={disabled}
          >
            {list3.map((i: any, index: number) => (
              <Select.Option key={`${i.Value}${index}`} value={i.Value}>
                {i.Name}
              </Select.Option>
            ))}
          </Select>
        );
      } else {
        return <Text type="danger">database data error</Text>;
      }
    case 8:
      return (
        <Input.TextArea
          disabled={disabled}
          rows={3}
          style={{ maxWidth: 600 }}
        />
      );
    case 11:
      return (
        <TimePicker format="h:mm:ss A" disabled={disabled} />
      );
    case 12:
      return (
        <Input.Password
          style={{ maxWidth: 600 }}
          disabled={disabled}
          iconRender={(visible) => visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
        />
      );
    default:
      return <Input style={{ maxWidth: 600 }} />;
  }
};

export default renderItem;
