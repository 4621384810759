import React, { useState } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import {
  Avatar,
  Col,
  Dropdown,
  Menu,
  Modal,
  Row,
  Select,
  Space,
  Typography,
  Button,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { changeLanguage } from 'actions/regularActions';
import VersionModal from 'components/common/VersionModal';
import { DEFAULT_LOCALE, LS_LOCALE_CODE, LS_THEME_CODE } from 'constants/config';
import { LanguageList, themeList } from 'i18n/config';
import { RootState } from 'reducers';
// eslint-disable-next-line
import { userProfiles, logout } from 'utils/auth';
import AvatarIcon from '../../assets/icons/avatar';
import CCSVG from 'assets/icons/CC_bg';
import DSCSVG from 'assets/icons/DSC_bg';
import ERPSVG from 'assets/icons/ERP_bg';
import PIMSVG from 'assets/icons/PIM_bg';
// import WMSSVG from 'assets/icons/WMS_bg';
import MenuIcon from 'assets/icons/menu';


const LinkPanel = styled.div`
  background-color: #FFF;
  border-radius: 10px;
  padding: 12px 20px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

  a {
    color: #1F1F1FD9;
  }

  & .app-abbr-name-ctn {
    font-size: 13px;
    font-weight: 600;
  }

  & .app-full-name-ctn {
    font-size: 12px;
  }

  & .app-icon-ctn {
    padding: 10px;
  }

  & .app-title-bar {
    font-size: 16px;
    font-weight: 500;
  }

  & .item-linker {
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 8px;
    padding: 10px;
  }

  & .item-linker:hover {
    background-color: rgba(0, 0, 0, .15);
    cursor: pointer;
  }

  & .link-grid-ctn {
    display: grid;
    gap: 15px;
    grid-template-columns: 280px 280px;
    row-gap: 15px !important;
  }
`;

const HeaderRow = styled(Row)`
  width: 100%;

  & .left-col {
    width: calc(100% - 36px);
  }

  & .profile-select-box {
    margin-top: 5px;
    width: 400px;
  }

  & .profile-select-box .ant-select-selector {
    color: #666;
    font-size: 16px;
    font-weight: 500;
    padding: 0px;
  }

  & .right-col {
    width: 36px;
  }
`;


const ProfilesWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  & .ant-select-arrow{
    display: none;
  }

  & .menu-trigger-icon {
    margin-right: 8px;
    padding: 4px 0;
  }

  & .menu-trigger-icon:hover {
    color: #1677FF;
  }
`;
const ProfileSelect = styled(Select)`
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  // line-height: 1.57;
  letter-spacing: normal;
  // color: #606060 !important;
`;

const ActionItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  color: black;
  font-size: 14px;
`;

const ProfileDropdownMenu = styled(Menu)`
    width: 300px;
    padding: 4px;
  & .ant-dropdown-menu-item:hover,
  & .ant-dropdown-menu-submenu-title:hover {
    background-color: transparent;
  }
  & .ant-dropdown-menu-title-content:not(:has(${ActionItemWrapper})),
  & .profile-menu-label {
    color: #606060;
    font-size: 13px;
    &:hover {
      color:#006dff;
      text-decoration: underline;
    }
  }
`;

const logoutUser = () => {
  Modal.confirm({
    title: 'Do you want to logout?',
    icon: <ExclamationCircleOutlined />,
    content: '',
    onOk() {
      logout();
      userProfiles.removeProfileFingerprint();
      localStorage.removeItem(LS_LOCALE_CODE);
      localStorage.removeItem(LS_THEME_CODE);
    },
    onCancel() { },
  });
};

// eslint-disable-next-line
export default () => {
  const { Text } = Typography;
  const [versionModalVisible, setVersionModalVisible] = useState<boolean>(false);
  const { i18n } = useTranslation();
  const dispatch: Dispatch<any> = useDispatch();
  const locale: string = useSelector(
    (state: RootState) => state.regular.locale,
    shallowEqual
  );

  // eslint-disable-next-line
  const profileOptions = (list: StringKAnyVPair[]) => {
    return list.map((e, i) => (<Select.Option key={i} value={i}>
      {e.displayName}
    </Select.Option>
    ));
  };

  const getUserItemLabel = () => {
    const { email, name } = userProfiles.profile;
    const words = [
      name || '',
      name ? `(${email})` : email,
    ].filter(e => e);

    return words.join(' ');
  };

  // eslint-disable-next-line
  const languageMenuItem = (lang: StringKAnyVPair) => {
    return (
      <Menu.Item onClick={() => selectLanguage(lang.code)} key={lang.code}>
        {lang.name}
      </Menu.Item>
    );
  };

  const languageSelector = React.useCallback(() => {
    const fs = LanguageList.filter(e => e.code === locale);
    let title = DEFAULT_LOCALE;

    if (fs.length > 0) {
      title = fs[0].name;
    }

    return (
      <Menu.SubMenu
        key="langMenus"
        popupOffset={[-3, -3]}
        icon={<i className="bi bi-translate" />}
        title={title}
      >
        {LanguageList.map(e => languageMenuItem(e))}
      </Menu.SubMenu>
    );
  }, [languageMenuItem, locale]);

  // eslint-disable-next-line
  const themeMenuItem = (theme: StringKAnyVPair) => {
    return (
      <Menu.Item onClick={() => selectTheme(theme.code)} key={theme.code}>
        {theme.name}
      </Menu.Item>
    );
  };

  const themeSelector = React.useCallback(() => {
    // const fs = themeList.filter(e => e.code === theme);
    // let title = DEFAULT_THEME;

    // if (fs.length > 0) {
    //   title = fs[0].name;
    // }

    return (
      <Menu.SubMenu
        key="themeMenus"
        popupOffset={[-3, -3]}
        icon={<i className="bi bi-layout-text-window-reverse" />}
        title="Theme"
      >
        {themeList.map(e => themeMenuItem(e))}
      </Menu.SubMenu>
    );
  }, [themeMenuItem]);

  // eslint-disable-next-line
  const profileMenuItem = (p: StringKAnyVPair) => {
    return (
      <Menu.Item onClick={() => selectProfile(p)} key={p.profileNum}>
        {p.displayName}
      </Menu.Item>
    );
  };

  const profileSelector = React.useCallback(() => {
    // const { profileNum } = userProfiles.profile;
    // const fs = userProfiles.profiles.filter(e => e.profileNum === profileNum);
    //const ps = userProfiles.profiles.filter(e => e.profileNum !== profileNum);
    // let title = '';

    // if (fs.length > 0) {
    //   title = fs[0].displayName;
    // }

    return (
      <Menu.SubMenu
        className="ant-typography-ellipsis"
        icon={<i className="bi bi-house-gear" />}
        key="profileMenus"
        popupOffset={[-3, -3]}
        // title={<Text className="profile-menu-label" ellipsis={{ tooltip: title }}>{title}</Text>}
        title={<Text className="profile-menu-label" >Switch Profile</Text>}
      >
        {userProfiles.profiles.map(e => profileMenuItem(e))}
        {/*ps.profiles.map(e => profileMenuItem(e))*/}
      </Menu.SubMenu>
    );
  }, [Text, profileMenuItem]);

  const selectLanguage = async (lang: string) => {
    await i18n.changeLanguage(lang);
    dispatch(changeLanguage(lang));
    localStorage.setItem(LS_LOCALE_CODE, lang);
  };

  const selectTheme = async (theme: string) => {
    localStorage.setItem(LS_THEME_CODE, theme);
    setTimeout(() => {
      window.location.reload();
    }, 800);
  };

  const selectProfile = (pf: Record<string, any>) => {
    const { profileNum: pn } = userProfiles.profile;

    if (pn !== pf.profileNum) {
      userProfiles.profiles.forEach((p, i) => {
        if (p.profileNum === pf.profileNum) {
          userProfiles.profileIndex = i;
          userProfiles.saveCurrentProfileToBrowser();
          window.location.reload();
        }
      });
    }
  };

  const userMenu = (
    <ProfileDropdownMenu>
      <Menu.Item icon={<i className="bi bi-person" />} key="1">
        {/* {profile.Email} */}
        <ActionItemWrapper>
          <Typography.Text ellipsis={{ tooltip: getUserItemLabel() }} style={{ width: 180 }}>{getUserItemLabel()}</Typography.Text>
          <Button type="link" onClick={logoutUser}>Logout</Button>
        </ActionItemWrapper>
      </Menu.Item>
      {profileSelector()}
      {userProfiles.profiles.length > 1 && languageSelector()}
      {themeSelector()}
      {/* <Menu.Item icon={<i className="bi bi-door-closed" />} key="2" onClick={logoutUser}>
        <span>Logout</span>
      </Menu.Item> */}
      <Menu.Item
        icon={<i className="bi bi-intersect" />}
        key="3"
        onClick={() => setVersionModalVisible(true)}
      >
        <span>About</span>
      </Menu.Item>
    </ProfileDropdownMenu>
  );

  const otherLinkMenu = () => {
    return (
      <LinkPanel>
        <div className="app-title-bar">Your apps</div>
        <div className="app-icon-ctn">
          <Row className="link-grid-ctn" gutter={[15, 15]}>
            <a href={process.env.CMC_LINK} target="_blank" rel="noreferrer">
              <Row className="item-linker">
              <CCSVG />
              <div>
                <div className="app-abbr-name-ctn">CC</div>
                <div className="app-full-name-ctn">Commerce  Central</div>
              </div>
              </Row>
            </a>
            <a href={`${process.env.PIM_LINK}dashboard`} target="_blank" rel="noreferrer">
              <Row className="item-linker">
                <PIMSVG />
                <div>
                  <div className="app-abbr-name-ctn">PIM</div>
                  <div className="app-full-name-ctn">Product Information Management</div>
                </div>
              </Row>
            </a>
            <a href={process.env.ERP_LINK} target="_blank" rel="noreferrer">
              <Row className="item-linker">
                <ERPSVG />
                <div>
                  <div className="app-abbr-name-ctn">ERP</div>
                  <div className="app-full-name-ctn">Enterprise Resource Planning</div>
                </div>
              </Row>
            </a>
            {/* <a href={process.env.WMS_LINK} target="_blank" rel="noreferrer">
              <Row className="item-linker">
                <WMSSVG />
                <div>
                  <div className="app-abbr-name-ctn">WMS</div>
                  <div className="app-full-name-ctn">Warehouse Management System</div>
                </div>
              </Row>
            </a> */}
            <a href={process.env.DSC_LINK} target="_blank" rel="noreferrer">
              <Row className="item-linker">
                <DSCSVG />
                <div>
                  <div className="app-abbr-name-ctn">DSC</div>
                  <div className="app-full-name-ctn">Dropship Central</div>
                </div>
              </Row>
            </a>
          </Row>
        </div>
      </LinkPanel>
    );
  };

  return (
    <HeaderRow align="middle" style={{ width: '100%' }}>
      <Col className="left-col">
        <ProfilesWrapper>
          <Dropdown
              overlay={otherLinkMenu()}
              //overlayStyle={{backgroundColor: '#FFF'}}
              placement="bottomLeft"
            >
              <Button
                className="menu-trigger-icon"
                type="text"
              >
                <MenuIcon fill="currentColor" height="18px" width="18px" />
              </Button>
            </Dropdown>
            <ProfileSelect
              bordered={false}
              showArrow={false}
              className="profile-select-box"
              defaultValue={userProfiles.profileIndex}
              onChange={(e:any) => selectProfile(userProfiles.profiles[e])}
            // disabled
            >
              {profileOptions(userProfiles.profiles)}
            </ProfileSelect>
        </ProfilesWrapper>      
      </Col>
      <Col className="right-col">
        <Space>
          <Dropdown overlay={userMenu} placement="bottomCenter">
            <Avatar
              size={36}
              style={{
                backgroundColor: '#fff',
                fontSize: 36,
              }}
              icon={<AvatarIcon />}
            />
          </Dropdown>
          {/* <Dropdown.Button
            className="headerIconDropdown header-btnicon"
            overlay={userMenu}
            style={{ margin: '4px 16px 4px 4px', width: 'auto' }}
            icon={<AvatarIcon />}
          /> */}
        </Space>
      </Col>
      <VersionModal show={versionModalVisible} onClose={() => setVersionModalVisible(false)} />
    </HeaderRow>
  );
};
