import React, { useCallback, useState } from 'react';
import { Modal, Row, Button, Space, Form, Select, message } from 'antd';
import { FormLabel } from 'components/common';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

type Props = {
  onHide: () => void;
  onOk: (params: any) => void;
  specialInfo: any;
  defaultWarehouseType: number;
  defaultLocationCode: string;
};
// eslint-disable-next-line
export default (props: Props) => {
  const {
    onHide,
    specialInfo,
    onOk,
    defaultWarehouseType,
    defaultLocationCode,
  } = props;
  const [selected, setSelected] = useState<any>(specialInfo[4]);
  const [form] = Form.useForm();
  /**
   * handle hide event
   */
  const handleHide = useCallback(() => {
    onHide();
  }, [onHide]);

  const handleOk = useCallback(async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    const tempLocation = selected.locations.filter(
      (i: any) => i.locationCode === params.locationCode
    );
    if (tempLocation.length > 0) {
      onOk({
        ...params,
        locationNum: tempLocation[0].locationNum,
        warehouseNum: selected.warehouseNum,
        warehouseCode: selected.warehouseCode,
      });
    }
  }, [onOk, selected, form]);

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };

  return (
    <Modal
      title="Select Location"
      centered
      visible={true}
      width={800}
      destroyOnClose={true}
      onCancel={handleHide}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button type="primary" onClick={handleOk} icon={<CheckOutlined />}>
              OK
            </Button>
            <Button id="cancel_button" onClick={handleHide} icon={<CloseOutlined />}>
              Cancel
            </Button>
          </Space>
        </Row>
      }
    >
      <Form {...layout} form={form} style={{ marginTop: 20 }}>
        <Form.Item
          name="warehouseType"
          initialValue={defaultWarehouseType}
          label={<FormLabel>Warehouse Type</FormLabel>}
          rules={[{ required: true, message: 'Please select warehouse type!' }]}
        >
          <Select
            onChange={(value: number) => {
              if (!specialInfo[value]) {
                message.info(
                  'Warehouse is not found, please check whether you have permission or whether the warehouse exists'
                );
              }
              setSelected(specialInfo[value]);
              form.setFieldsValue({ locationNum: undefined, locationCode:  specialInfo[value].defaultLocationCode || defaultLocationCode});
            }}
          >
            <Select.Option value={2}>Return-Resalable</Select.Option>
            <Select.Option value={3}>Return-Damage</Select.Option>
            <Select.Option value={4}>Return-Inspection</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label={<FormLabel>Warehouse Code</FormLabel>}>
          {selected?.warehouseCode || ''}
        </Form.Item>

        <Form.Item
          name="locationCode"
          initialValue={specialInfo[defaultWarehouseType].defaultLocationCode || defaultLocationCode}
          label={<FormLabel>location</FormLabel>}
          rules={[{ required: true, message: 'Please select location' }]}
        >
          <Select disabled={!selected}>
            {(selected?.locations || []).map((i: any) => (
              <Select.Option key={i.locationNum} value={i.locationCode}>
                {i.locationCode}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
