import React, { useState } from 'react';
import {
  Form,
  Row,
  Spin,
  Modal,
  Button,
  Space,
  Select,
  Typography,
  Col,
} from 'antd';
import { editWarehouse } from 'services/warehouse';
import { FormLabel } from 'components/common';
import { message as showMessage } from 'components/common';
import {
  CloseOutlined,
  LoadingOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import SelectLocation from 'components/Inventory/Audit/SelectLocation';

interface EditProps {
  visible: boolean;
  onHide: () => void;
  onRefresh: () => void;
  warehouse: WarehouseRow;
  warehouseList: WarehouseRow[];
}
// eslint-disable-next-line
export default (props: EditProps) => {
  const { visible, onHide, onRefresh, warehouse, warehouseList } = props;
  const [loading, setLoading] = useState(false);
  const [warehouse1, setWarehouse1] = useState<number | undefined>(
    warehouse.resalableWarehouseNum || undefined
  );
  const [location1, setLocation1] = useState<number | undefined>(
    warehouse.resalableLocationNum || undefined
  );
  const [warehouse2, setWarehouse2] = useState<number | undefined>(
    warehouse.inspectionWarehouseNum || undefined
  );
  const [location2, setLocation2] = useState<number | undefined>(
    warehouse.inspectionLocationNum || undefined
  );
  const [warehouse3, setWarehouse3] = useState<number | undefined>(
    warehouse.damageWarehouseNum || undefined
  );
  const [location3, setLocation3] = useState<number | undefined>(
    warehouse.damageLocationNum || undefined
  );

  const [form] = Form.useForm();

  const handleSave = async () => {
    if (!warehouse) return;
    setLoading(true);
    try {
      const res = await editWarehouse(warehouse.warehouseId, {
        ResalableWarehouseNum: warehouse1 || 0,
        ResalableLocationNum: location1 || 0,
        InspectionWarehouseNum: warehouse2 || 0,
        InspectionLocationNum: location2 || 0,
        DamageWarehouseNum: warehouse3 || 0,
        DamageLocationNum: location3 || 0,
      });
      if (!res.error) {
        showMessage.success({
          content: 'Saved Successfully！',
        });
        form.resetFields();
        onRefresh();
        onHide();
      } else {
        showMessage.error({ content: res.error });
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  /**
   * handle cancel event
   */
  const handleCloseModal = React.useCallback(async () => {
    const cancel = form.isFieldsTouched();
    if (!cancel) {
      onHide();
    } else {
      Modal.confirm({
        title: 'Leave / Refresh Page?',
        okText: 'Leave',
        cancelText: 'Cancel',
        content: `Changes you made may not be saved.`,
        onOk() {
          onHide();
        },
      });
    }
  }, [onHide, form]);

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14 },
  };
  return (
    <Modal
      title={`Manage Return Warehouses - ${warehouse.warehouseCode}`}
      centered
      visible={visible}
      maskClosable={false}
      width={900}
      destroyOnClose={true}
      bodyStyle={{ paddingTop: 0 }}
      onCancel={handleCloseModal}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              key="submit"
              type="primary"
              onClick={handleSave}
              disabled={
                !(
                  (warehouse1 &&
                    warehouse2 &&
                    warehouse3 &&
                    location1 &&
                    location2 &&
                    location3) ||
                  (!warehouse1 &&
                    !warehouse2 &&
                    !warehouse3 &&
                    !location1 &&
                    !location2 &&
                    !location3)
                )
              }
              loading={loading}
              icon={<SaveOutlined />}
            >
              {loading ? 'Saving' : 'Save'}
            </Button>
            <Button onClick={handleCloseModal} icon={<CloseOutlined />}>
              Cancel
            </Button>
          </Space>
        </Row>
      }
    >
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Form {...layout} form={form} style={{ marginTop: 20 }}>
          <Row justify="end">
            <Button
              type="link"
              onClick={() => {
                setWarehouse1(undefined);
                setWarehouse2(undefined);
                setWarehouse3(undefined);
                setLocation1(undefined);
                setLocation2(undefined);
                setLocation3(undefined);
                form.setFieldsValue({
                  ResalableLocationNum: undefined,
                  InspectionLocationNum: undefined,
                  DamageLocationNum: undefined,
                });
              }}
            >
              Clear All
            </Button>
          </Row>
          <Row>
            <Typography.Title level={5}>Resalable</Typography.Title>
          </Row>
          <Row style={{ marginBottom: 16 }}>
            <Col span={12}>
              <Form.Item label={<FormLabel>Warehouse</FormLabel>}>
                <Select
                  allowClear
                  onChange={(value) => {
                    setLocation1(undefined);
                    form.setFieldsValue({ ResalableLocationNum: undefined });
                    setWarehouse1(value);
                  }}
                  value={warehouse1}
                >
                  {warehouseList
                    .filter((i) => i.warehouseType === 1)
                    .map((item) => (
                      <Select.Option
                        key={item.warehouseNum}
                        value={item.warehouseNum}
                      >
                        {item.warehouseCode}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="ResalableLocationNum"
                initialValue={location1}
                label={<FormLabel>Default Location</FormLabel>}
              >
                <SelectLocation
                  id="resalable_location"
                  warehouseNum={warehouse1 || 0}
                  onChange={setLocation1}
                  value={location1}
                  includeVirtual={false}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Typography.Title level={5}>Inspection</Typography.Title>
          </Row>
          <Row style={{ marginBottom: 16 }}>
            <Col span={12}>
              <Form.Item label={<FormLabel>Warehouse</FormLabel>}>
                <Select
                  allowClear
                  onChange={(value) => {
                    setLocation2(undefined);
                    form.setFieldsValue({ InspectionLocationNum: undefined });
                    setWarehouse2(value);
                  }}
                  value={warehouse2}
                >
                  {warehouseList
                    .filter((i) => i.warehouseType === 1)
                    .map((item) => (
                      <Select.Option
                        key={item.warehouseNum}
                        value={item.warehouseNum}
                      >
                        {item.warehouseCode}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="InspectionLocationNum"
                initialValue={location2}
                label={<FormLabel>Default Location</FormLabel>}
              >
                <SelectLocation
                  id="inspection_location"
                  warehouseNum={warehouse2 || 0}
                  onChange={setLocation2}
                  value={location2}
                  includeVirtual={false}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Typography.Title level={5}>Damaged</Typography.Title>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item label={<FormLabel>Warehouse</FormLabel>}>
                <Select
                  allowClear
                  onChange={(value) => {
                    setLocation3(undefined);
                    form.setFieldsValue({ DamageLocationNum: undefined });
                    setWarehouse3(value);
                  }}
                  value={warehouse3}
                >
                  {warehouseList
                    .filter((i) => i.warehouseType === 1)
                    .map((item) => (
                      <Select.Option
                        key={item.warehouseNum}
                        value={item.warehouseNum}
                      >
                        {item.warehouseCode}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="DamageLocationNum"
                initialValue={location3}
                label={<FormLabel>Default Location</FormLabel>}
              >
                <SelectLocation
                  id="damage_location"
                  warehouseNum={warehouse3 || 0}
                  onChange={setLocation3}
                  value={location3}
                  includeVirtual={false}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};
