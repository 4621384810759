import React from 'react';
import {
  Button,
  Space,
  Card,
  Row,
  Input,
  // Switch,
  Select,
  DatePicker,
  Form,
  Col,
} from 'antd';
import { DownloadOutlined, ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import {
  DataGridPlus,
  HeaderTitle,
  ColumnDataType,
  ClearButton,
  FormLabel,
  CopySpan,
  TablesFilter,
} from 'components/common';
import { DEFAULT_DATE_FORMAT } from 'constants/config';
import { fetchExportFileJobs, downloadExportFile } from 'services/files';
import { convertToUtcTime } from 'utils';
// import FilterImport from './FilterImportDialog';

const { RangePicker } = DatePicker;
const dateFormat = 'MM/DD/YYYY';

type ColumnRenderType = {
  data: FileJobRow;
};
// eslint-disable-next-line
export default () => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<FileJobRow[]>([]);
  const [total, setTotal] = React.useState(0);
  const [filteredData, setFilteredData] = React.useState<any[]>([]);
  // const [operation, setOperation] = React.useState<OperationType[]>([]);
  // const [filterParams, setFilterParams] = React.useState<FilterParams>();
  // const [filterVisible, setFilterVisible] = React.useState(false);
  // const [autoRefresh, setAutoRefresh] = React.useState(false);
  const limitRef = React.useRef<number>(10);
  const skipRef = React.useRef<number>(0);
  // const [limit, setLimit] = React.useState<number>(5);
  // const [skip, setSkip] = React.useState<number>(0);
  // const [sortInfo, setSortInfo] = React.useState<TypeSortInfo>();

  const [form] = Form.useForm();

  const columns = [
    {
      name: 'operationName',
      header: 'Type',
      defaultFlex: 1,
      userSelect: true,
      sortable: true,
    },
    {
      name: 'fileName',
      header: 'File Name',
      defaultFlex: 1,
      userSelect: true,
      sortable: true,
    },
    {
      name: 'createDate',
      header: 'Request Time',
      defaultFlex: 1,
      sortable: true,
      userSelect: true,
      dataType: ColumnDataType.DATE,
    },
    // {
    //   name: 'note',
    //   header: 'Note',
    //   defaultFlex: 1,
    //   render: (value: ColumnRenderType) => {
    //     const { data } = value;
    //     return <Button type="link">{data.originalFileName}</Button>;
    //   },
    // },
    {
      name: 'createBy',
      header: 'User',
      defaultFlex: 1,
      userSelect: true,
      // render: (value: ColumnRenderType) => {
      //   const { data } = value;
      //   return data.createBy;
      // },
    },
    {
      name: 'processStatus',
      header: 'Status',
      defaultFlex: 1,
      userSelect: true,
      renderEnum: {
        0: 'Queue',
        1: 'Pending',
        2: 'Processing',
        3: 'Complete',
        4: 'Completed with Error',
        5: 'Failed Validation',
        6: 'Call for Help',
        7: 'Template Error',
        8: 'Failed after Max Retry'
      },
      render: (value: ColumnRenderType) => {
        const { data } = value;
        switch (data.processStatus) {
          case 0:
            return <CopySpan inGrid value={'Queue'} />;
          case 1:
            return <CopySpan inGrid value={'Pending'} />;
          case 2:
            return <CopySpan inGrid value={'Processing'} />;
          case 3:
            return <CopySpan inGrid value={'Complete'} />;
          case 4:
            return <CopySpan inGrid value={'Completed with Error'} />;
          case 5:
            return <CopySpan inGrid value={'Failed Validation'} />;
          case 6:
            return <CopySpan inGrid value={'Call for Help'} />;
          // case 7:
          //   return 'Template Error';
          case 8:
            return <CopySpan inGrid value={'Failed after Max Retry'} />;
          default:
            return <CopySpan inGrid value={'Undefined'} />;
        }
      },
    },
    {
      name: 'action',
      header: 'Action',
      showColumnMenuTool: false,
      groupBy: false,
      defaultFlex: 1,
      sortable: false,
      maxWidth: 150,
      minWidth: 150,
      render: (value: ColumnRenderType) => {
        const { data } = value;
        return data.processStatus === 3 ? (
          <Button type="default" onClick={() => downloadFile(data)}>
            <DownloadOutlined />
            Download
          </Button>
        ) : (
          ''
        );
      },
    },
  ];

  // const getOperations = React.useCallback(async () => {
  //   try {
  //     const nextOperations = await fetchOperation();
  //     setOperation(nextOperations);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, []);

  const getFileList = React.useCallback(
    async (fromSearchButton?: boolean) => {
      setLoading(true);
      const filterParams = await form.getFieldsValue();
      let filter = '';
      if (filterParams) {
        let [startTime, endTime] = ['', ''];

        if (filterParams.SubmitedDate) {
          const dFormat = DEFAULT_DATE_FORMAT;
          startTime = convertToUtcTime(
            `${filterParams.SubmitedDate[0].format(dFormat)} 00:00:00`
          );
          endTime = convertToUtcTime(
            `${filterParams.SubmitedDate[1].format(dFormat)} 16:00:00`
          );
        }

        /*const startTimeFilter = filterParams.SubmitedDate
        ? `CreateDate ge '${moment(filterParams.SubmitedDate[0]).format(
            'YYYY-MM-DD'
          )} 00:00:00'`
        : '';
      const endTimeFilter = filterParams.SubmitedDate
        ? `CreateDate le '${moment(filterParams.SubmitedDate[1]).format(
            'YYYY-MM-DD'
          )} 16:00:00'`
        : '';*/
        const startTimeFilter = filterParams.SubmitedDate
          ? `CreateDate ge '${startTime}'`
          : '';
        const endTimeFilter = filterParams.SubmitedDate
          ? `CreateDate le '${endTime}'`
          : '';
        const statusFilter = (filterParams.status || filterParams.status === 0)
          ? `processStatus eq ${filterParams.status}`
          : '';
        const userFilter = filterParams.user
          ? `UserEmail eq '${filterParams.user}`
          : '';
        if (startTimeFilter) {
          filter = startTimeFilter;
        }
        if (endTimeFilter) {
          filter = filter
            ? `${filter} and ${endTimeFilter}`
            : `${endTimeFilter}`;
        }
        if (statusFilter) {
          filter = filter ? `${filter} and ${statusFilter}` : `${statusFilter}`;
        }
        if (userFilter) {
          filter = filter ? `${filter} and ${userFilter}` : `${userFilter}`;
        }
      }
      const params = {
        operatetype: 1,
        $skip: fromSearchButton ? 0 : skipRef.current,
        $top: limitRef.current,
        $sortBy: 'FileNum desc',
        $count: true,
        $filter: filter,
      };
      try {
        const res = await fetchExportFileJobs(params);
        setLoading(false);
        if (res.isSuccess) {
          let { data, count } = res.data;
          data = data.map((row: any) => {
            const operationName =
              [
                'undefined',
                'Location',
                'Inventory Log',
                'Locations Report',
                'Inventory',
                'Catalog',
                'Store Account',
                'Pick List',
                'PO Receive History',
                'Order',
                'Shipment',
                'Shipment Export File for Import'
              ][row.operation] || '';
            return {
              ...row,
              operationName,
            };
          });
          if (fromSearchButton) {
            skipRef.current = 0;
          }
          setData(data);
          setTotal(count);
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    },
    [form]
  );

  const downloadFile = async (data: FileJobRow) => {
    try {
      const res = await downloadExportFile({
        fileoperation: data.operation,
        filesystemname: data.fileName,
      });
      const content = res;
      const blob = new Blob([content]);
      if ('download' in document.createElement('a')) {
        // const elink = document.createElement('a');
        // elink.download = `Locations_${dayjs(data.createDate).format(
        //   'MM_DD_YYYY_h_mm_A'
        // )}.xlsx`;
        // elink.style.display = 'none';
        // elink.href = URL.createObjectURL(blob);
        // document.body.appendChild(elink);
        // elink.click();
        // URL.revokeObjectURL(elink.href);
        // document.body.removeChild(elink);
        const elink = document.createElement('a');
        elink.download = data.fileName;
        elink.style.display = 'none';
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href);
        document.body.removeChild(elink);
      } else {
        /*navigator.msSaveBlob(
          blob,
          `Locations_${dayjs(data.createDate).format('MM_DD_YYYY_h_mm_A')}.xls`
        );*/
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const downloadLoaction = async () => {
  //   try {
  //     const res = await exportLocation({
  //       Operation: 2101,
  //       SelectField: 'createBy,locationCode,warehouseCode,seq,quantity',
  //       OrderByField: 'warehouseCode desc',
  //       FilterCondition: {
  //         WarehouseId: '200-10009',
  //       },
  //     });
  //     const content = res;
  //     const blob = new Blob([content]);
  //     if ('download' in document.createElement('a')) {
  //       const elink = document.createElement('a');
  //       elink.download = 'loacation.xlsx';
  //       elink.style.display = 'none';
  //       elink.href = URL.createObjectURL(blob);
  //       document.body.appendChild(elink);
  //       elink.click();
  //       URL.revokeObjectURL(elink.href);
  //       document.body.removeChild(elink);
  //     } else {
  //       navigator.msSaveBlob(blob, 'loacation.xlsx');
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    },
  };

  return (
    <>
      <HeaderTitle breadcrumb={['Export', 'File Export Center']}>
        {/* <Space>
          <Button onClick={() => downloadLoaction()}>
            Test Export Location
          </Button>
        </Space> */}
      </HeaderTitle>
      <Form
        form={form}
        {...formItemLayout}
        style={{ justifyContent: 'center' }}
      >
        <Card style={{ width: '100%', maxWidth: 1890 }}>
          <Row>
            <Col md={8} sm={24}>
              <Form.Item
                name="SubmitedDate"
                initialValue={[
                  moment().add(-1, 'day').startOf('day'),
                  moment().add(1, 'day').endOf('day'),
                ]}
                label={<FormLabel>Request Date</FormLabel>}
              >
                <RangePicker
                  format={dateFormat}
                  style={{ width: '100%' }}
                  showTime
                  ranges={{
                    Today: [moment().startOf('day'), moment().endOf('day')],
                    Yesterday: [
                      moment().add(-1, 'day').startOf('day'),
                      moment().add(-1, 'day').endOf('day'),
                    ],
                    '2 days ago': [
                      moment().add(-2, 'day').startOf('day'),
                      moment().endOf('day'),
                    ],
                    '3 days ago': [
                      moment().add(-3, 'day').startOf('day'),
                      moment().endOf('day'),
                    ],
                    'This Week': [
                      moment().startOf('week'),
                      moment().endOf('week'),
                    ],
                    'Last Week': [
                      moment().add(-1, 'week').startOf('week'),
                      moment().add(-1, 'week').endOf('week'),
                    ],
                    'This Month': [
                      moment().startOf('month'),
                      moment().endOf('month'),
                    ],
                  }}
                />
              </Form.Item>
            </Col>
            <Col md={8} sm={24}>
              <Form.Item name="user" label={<FormLabel>User</FormLabel>}>
                <Input placeholder="input a user" />
              </Form.Item>
            </Col>
            <Col md={8} sm={24}>
              <Form.Item name="status" label={<FormLabel>Status</FormLabel>}>
                <Select placeholder="Select an option">
                  <Select.Option value={0}>Queue</Select.Option>
                  <Select.Option value={1}>Pending</Select.Option>
                  <Select.Option value={2}>Processing</Select.Option>
                  <Select.Option value={3}>Complete</Select.Option>
                  <Select.Option value={4}>Completed with Error</Select.Option>
                  <Select.Option value={5}>Failed Validation</Select.Option>
                  <Select.Option value={6}>Call for Help</Select.Option>
                  <Select.Option value={8}>
                    Failed after Max Retry
                  </Select.Option>
                  {/* <Select.Option value={7}>Template Error</Select.Option> */}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }} justify="center">
            <Space>
              <Button
                type="primary"
                loading={loading}
                onClick={() => getFileList(true)}
              >
                <SearchOutlined />
                Search
              </Button>
              <ClearButton form={form} />
              <Button onClick={() => getFileList()}>
                <ReloadOutlined spin={loading} />
                Refresh
              </Button>
            </Space>
          </Row>
        </Card>
      </Form>

      <Card style={{ marginTop: 10 }}>
        <TablesFilter
          columns={columns}
          dataSource={data}
          setFilteredData={setFilteredData}
        />
        <DataGridPlus
          idProperty="fileJobNum"
          name="ExportList"
          autoWith={false}
          skip={skipRef.current}
          loading={loading}
          columns={columns}
          dataSource={filteredData}
          count={total}
          pagination
          onChange={(limit, skip) => {
            limitRef.current = limit;
            skipRef.current = skip;
            getFileList();
          }}
          {...({} as any)}
        />
      </Card>
    </>
  );
};
