import React, { useEffect, useState, Dispatch } from 'react';
import { HeaderTitle, FormLabel, message } from 'components/common';
import {
  Card,
  Tabs,
  Form,
  Typography,
  Button,
  Result,
  Modal,
  Table,
} from 'antd';
import {
  fetchSettings,
  fetchUserSettings,
  saveUserSettings,
} from 'services/userProfileSetting';
import { userProfiles } from 'utils/auth';
import { isJsonString } from 'utils';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { userProfileSettingList } from 'actions/adminActions';
import { SaveOutlined } from '@ant-design/icons';
import uiTypeRender from 'utils/uiTypeRender';

const { TabPane } = Tabs;
const { Title, Text } = Typography;

const renderValue = (thirdPartySettingDefUI: any, defaultValue: any) => {
  switch (thirdPartySettingDefUI.uiType) {
    case 1:
    case 2:
    case 8:
      return defaultValue;
    case 3:
      return moment(defaultValue).format('MM/DD/YYYY');
    case 4:
    case 5:
    case 6:
      if (isJsonString(thirdPartySettingDefUI.optionList)) {
        const list1 = JSON.parse(thirdPartySettingDefUI.optionList);
        const temp = list1.filter((i: any) => i.Value === defaultValue);
        if (temp.length > 0) {
          return temp[0].Name || defaultValue;
        } else {
          return defaultValue;
        }
      } else {
        return defaultValue;
      }

    case 7:
      if (isJsonString(thirdPartySettingDefUI.optionList)) {
        const list3 = JSON.parse(thirdPartySettingDefUI.optionList);

        const temp = list3.filter(
          (i: any) => defaultValue.indexOf(i.Value) > -1
        );
        if (temp.length > 0) {
          return temp.map((i: any) => i.Name) || defaultValue;
        } else {
          return defaultValue;
        }
      } else {
        return defaultValue;
      }
    case 11:
      return moment(defaultValue).format('h:mm:ss A');

    case 12:
      return '********';
    default:
      return defaultValue;
  }
};

const filterValue = (
  thirdPartySettingDefUI: any,
  defaultValue: any,
  newValue: any
) => {
  switch (thirdPartySettingDefUI.uiType) {
    case 1:
    case 2:
    case 8:
      return defaultValue === newValue;
    case 3:
      return (
        moment(defaultValue).format('MM/DD/YYYY') ===
        moment(newValue).format('MM/DD/YYYY')
      );
    case 4:
    case 5:
    case 6:
      return `${defaultValue}` === `${newValue}`;

    case 7:
      return `${defaultValue.toString()}` === `${newValue.toString()}`;
    case 11:
      return (
        moment(defaultValue).format('h:mm:ss A') ===
        moment(newValue).format('h:mm:ss A')
      );

    case 12:
      return !newValue;
    default:
      return true;
  }
};

const columns = [
  {
    title: 'Path',
    dataIndex: 'path',
    key: 'path',
  },
  {
    title: 'Setting Name',
    dataIndex: 'settingName',
    key: 'settingName',
  },
  {
    title: 'Old Value',
    dataIndex: 'settingValue',
    key: 'settingValue',
    render: (text: any, record: any) => {
      const v = renderValue(record.profileSettingDefUI, text);
      return v;
    },
  },
  {
    title: 'New Value',
    dataIndex: 'newValue',
    key: 'newValue',
    render: (text: any, record: any) => {
      const v = renderValue(record.profileSettingDefUI, text);
      return v;
    },
  },
];

// eslint-disable-next-line
export default () => {
  const dispatch: Dispatch<any> = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [settings, setSettings] = useState<any[]>([]);
  const [userSetting, setUserSetting] = useState<{ [key: string]: any }>({});
  const [activeTab, setActiveTab] = useState<string>();
  const [tempSettings, setTempSettings] = useState<any[]>([]);

  const [form] = Form.useForm();

  const getSettings = async () => {
    try {
      setLoading(true);
      const res = await fetchSettings();
      setLoading(false);
      if (res.isSuccess) {
        setSettings(res.data);
        if (!activeTab && res.data.length > 0) {
          setActiveTab(res.data[0].category);
        }
        const temp: any = [];
        res.data.map((i: any) => {
          i.scopes.map((k: any) => {
            k.scopeItems.map((j: any) => {
              temp.push({
                ...j,
                path: `${i.category} -> ${k.scope}`,
                canBeModifiedByCustomer: 0,
              });
              return true;
            });
            return true;
          });
          return true;
        });
        setTempSettings([...temp]);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getUserSettings = async () => {
    try {
      setLoading1(true);
      const res = await fetchUserSettings();
      setLoading1(false);
      if (res.isSuccess) {
        const temp: { [key: string]: any } = {};
        res.data.map((item: any) => {
          temp[item.settingCode] = item.settingValue;
          return true;
        });
        setUserSetting({ ...temp, isReady: true });
        dispatch(userProfileSettingList(res.data));
      }
    } catch (error) {
      setLoading1(false);
    }
  };

  const saveSettings = async () => {
    const { masterAccountNum, profileNum } = userProfiles.profile;
    try {
      const params = await form.validateFields();
      if (params.errorFields) return;
      const postParams: any = [];

      const changedCode: string[] = [];
      const changedCodeKeyValue: { [key: string]: any } = {};
      for (let i in params) {
        const tmp = i.split('_$_$_');
        if (tmp.length === 2) {
          postParams.push({
            SettingCode: tmp[1],
            SettingValue: params[i],
            MasterAccountNum: masterAccountNum,
            ProfileNum: profileNum,
          });
          if (params[i] !== userSetting[tmp[1]]) {
            changedCode.push(tmp[1]);
            changedCodeKeyValue[tmp[1]] = params[i];
          }
        }
      }
      if (changedCode.length > 0) {
        const temp = tempSettings
          .filter((k: any) => changedCode.indexOf(k.settingCode) > -1)
          .map((i: any) => {
            return {
              ...i,
              settingValue: userSetting[i.settingCode],
              newValue: changedCodeKeyValue[i.settingCode],
            };
          });
        const filterTemp = temp.filter(
          (i) => !filterValue(i.profileSettingDefUI, i.settingValue, i.newValue)
        );
        Modal.confirm({
          width: window.innerWidth > 1200 ? window.innerWidth * 0.8 : 800,
          bodyStyle: { width: window.innerWidth > 1200 ? window.innerWidth * 0.8 : 800 },
          title: 'These settings will be updated',
          content: (
            <Table
              size="small"
              scroll={{ y: 500 }}
              dataSource={filterTemp}
              columns={columns}
              pagination={false}
            />
          ),
          onOk: async () => {
            setSaveLoading(true);
            const res = await saveUserSettings(postParams);
            setSaveLoading(false);
            if (res.isSuccess) {
              message.success({ content: 'Saved Successfully' });
              // getUserSettings();
              window.location.reload();
            }
          },
        });
      }
    } catch (error: any) {
      if (
        error &&
        error.errorFields &&
        error.errorFields.length > 0 &&
        error.errorFields[0].name &&
        error.errorFields[0].name.length > 0
      ) {
        const t = error.errorFields[0].name[0].split('_$_$_');
        if (t.length === 2) {
          setActiveTab(t[0]);
        }
        if (
          error &&
          error.errorFields &&
          error.errorFields.length > 0 &&
          error.errorFields[0].errors &&
          error.errorFields[0].errors.length > 0
        )
          message.info(error.errorFields[0].errors[0]);
      }
      setSaveLoading(false);
    }
  };

  /* eslint-disable */
  useEffect(() => {
    getSettings();
    getUserSettings();
  }, []);
  /* eslint-enable */
  return (
    <>
      <HeaderTitle breadcrumb={['Administration', 'Profile Settings']}>
        <Button
          type="primary"
          loading={saveLoading}
          disabled={settings.length === 0}
          onClick={() => saveSettings()}
          icon={<SaveOutlined />}
        >
          Save Settings
        </Button>
      </HeaderTitle>

      <Form
        form={form}
        labelCol={{
          style: { width: 350, display: 'flex', justifyContent: 'start' },
        }}
        wrapperCol={{
          style: { width: 'calc(100% - 300px)' },
        }}
      >
        <Card
          style={{ width: '100%', marginTop: 16, minHeight: 500 }}
          loading={loading || loading1}
          bodyStyle={{ padding: 8 }}
        >
          {settings.length > 0 ? (
            userSetting &&
            userSetting.isReady &&
            activeTab && (
              <Tabs
                activeKey={activeTab}
                size="middle"
                type="card"
                onChange={(value) => setActiveTab(value)}
              >
                {settings.map((i: any) => (
                  <TabPane tab={i.category} key={i.category}>
                    {i.scopes.map((m: any, idx2: number) => (
                      <Card
                        key={`${m.scope}${idx2}`}
                        size="small"
                        bordered={false}
                        style={{ marginBottom: 10 }}
                        title={<Title level={5}>{m.scope}</Title>}
                      >
                        {m.scopeItems.map((f: any, index: number) => {
                          return !f.isVisibleByCustomer ? null : (
                            <div key={`${f.settingCode}${index}`}>
                              <Form.Item
                                name={`${i.category}_$_$_${f.settingCode}`}
                                initialValue={
                                  f.profileSettingDefUI.uiType === 3 ||
                                  f.profileSettingDefUI.uiType === 11
                                    ? moment(userSetting[f.settingCode])
                                    : f.profileSettingDefUI.uiType === 7
                                    ? isJsonString(userSetting[f.settingCode])
                                      ? JSON.parse(userSetting[f.settingCode])
                                      : []
                                    : f.profileSettingDefUI.uiType === 12
                                    ? undefined
                                    : userSetting[f.settingCode]
                                }
                                label={
                                  <FormLabel noColon>{f.settingName}</FormLabel>
                                }
                                rules={[
                                  {
                                    required:
                                      f.profileSettingDefUI.uiType === 12 &&
                                      userSetting[f.settingCode]
                                        ? false
                                        : true,
                                    message: `${f.settingName} is required`,
                                  },
                                ]}
                              >
                                {uiTypeRender(
                                  f.profileSettingDefUI,
                                  !f.canBeModifiedByCustomer
                                )}
                              </Form.Item>
                              {f.note && (
                                <Text
                                  style={{ marginLeft: 20 }}
                                  type="secondary"
                                >
                                  {f.note}
                                </Text>
                              )}
                            </div>
                          );
                        })}
                      </Card>
                    ))}
                  </TabPane>
                ))}
              </Tabs>
            )
          ) : (
            <Result
              status="error"
              title="Failed to extract configuration data."
              subTitle="Please contact the system administrator."
            ></Result>
          )}
        </Card>
      </Form>
    </>
  );
};
