import React, { useEffect, useCallback, useState, useRef } from 'react';
import { ModalProps } from 'antd/lib/modal/Modal';
import ModalDialog from '../../../components/common/ModalDialog';
import {
  DeleteOutlined,
  SettingFilled,
  CaretDownOutlined,
  EditOutlined,
  ClearOutlined,
  ExclamationCircleOutlined,
  FormOutlined,
} from '@ant-design/icons';
import {
  CopySpan,
  DataGridPlus,
  FormLabel,
  InputInteger,
  message,
  Locker,
} from '../../../components/common';
import {
  Card,
  Form,
  Row,
  Col,
  Select,
  // Space,
  Button,
  Input,
  Spin,
  // Divider,
  Dropdown,
  Menu,
  Space,
  Modal,
  Tabs,
} from 'antd';
import {
  fetchBulkReturnLines,
  saveBulkReturnLine,
  deleteBulkReturnLine,
} from 'services/return';
import { queryProductByKeyword } from 'services/product';
import EditQtyDialog from './EditQtyDialog';
import EditClaimQtyDialog from './EditClaimTotalQty';
import debounce from 'lodash.debounce';
import { convertTimeByUtc } from 'utils';
import { RootState } from 'reducers';
import { shallowEqual, useSelector } from 'react-redux';

interface Props extends ModalProps {
  onClose: Function;
  modalReturn: any;
  specialInfo: any;
  defaultWarehouseType: number;
  defaultLocationCode: string;
}

const warehouseTypeKeyValue: StringKVPair = {
  '2': 'Resalable',
  '3': 'Damage',
  '4': 'Inspection',
};

const ProductDetailDialog = (props: Props) => {
  const {
    specialInfo,
    defaultWarehouseType,
    defaultLocationCode,
  } = props;
  const userPermissions = useSelector(
    (state: RootState) => state.admin.userPermissions,
    shallowEqual
  );
  const hasPermissions = userPermissions ? userPermissions.filter(i=>i.permissionNum === 40022 && i.permissionLevel >= 2) : []
  const [detailDialogIsFullscreen, setDetailDialogIsFullscreen] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [querying, setQuerying] = useState(false);
  const [modalReturn, setModalReturn] = useState<any>(props.modalReturn)
  const [saveLoading, setSaveLoading] = useState(false);
  const [lines, setLines] = useState<any[]>([]);
  const refreshBtnRef = React.useRef<any>(null);
  const [selected, setSelected] = useState<number>(defaultWarehouseType);
  const [qtyLocker, setQtyLocker] = useState(true);
  const [warehouseLocker, setWarehouseLocker] = useState(false);
  const [editQtyDialogVisible, setEditQtyDialogVisible] = useState(false);
  const [editQtyDialogVisible2, setEditQtyDialogVisible2] = useState(false);
  const [current, setCurrent] = useState<any>();
  const [currentProduct, setCurrentProduct] = useState<any>();
  const [filterLines, setFilterlines] = useState<any[]>([]);
  const [filterValue, setFilterValue] = useState<string>();
  const [totalReceiveQty, setTotalReceiveQty] = useState<number>(0);
  const [totalInspectionQty, setTotalInspectionQty] = useState<number>(0);
  const [totalResaleableQty, setTotalResaleableQty] = useState<number>(0);
  const [totalDamageQty, setTotalDamageQty] = useState<number>(0);
  const InputRef = useRef<any>();

  const [form] = Form.useForm();

  const closeProductDetailDialog = () => {
    props.onClose();
  };

  const debounceFn = debounce((val: string) => {
    setFilterValue(val);
  }, 300);

  const detailDialogWidth = useCallback(
    (isFullscreen = detailDialogIsFullscreen) => {
      return isFullscreen
        ? window.innerWidth - 4
        : window.innerWidth > 1280
          ? window.innerWidth * 0.8
          : 1200;
    },
    [detailDialogIsFullscreen]
  );

  const getDialogDom = () => {
    const refreshBtn = refreshBtnRef?.current;

    if (refreshBtn) {
      return refreshBtn.parentNode.parentNode.parentNode.parentNode.parentNode
        .parentNode.parentNode;
    }

    return null;
  };

  const onFullscreen = (isFullscreen: boolean) => {
    const dialog = getDialogDom();

    setDetailDialogIsFullscreen(isFullscreen);

    if (dialog) {
      const width = detailDialogWidth(isFullscreen);

      dialog.style.width = `${width}px`;
    }
  };

  const getBulkReturnLines = useCallback(async () => {
    try {
      setLoading(true);
      const res = await fetchBulkReturnLines(
        modalReturn.fulfillmentBulkReturnNum
      );
      setLoading(false);
      if (res.isSuccess) {
        setLines(res.data);
      }
    } catch (error) {
      setLoading(false);
    }
  }, [modalReturn]);

  useEffect(() => {
    let rcvtemp = 0;
    let insptemp = 0;
    let rlstemp = 0;
    let dmgtemp = 0;
    (lines || []).forEach((line: any) => {
      rcvtemp += line.receiveQty;
      insptemp += line.inspectionQty;
      rlstemp += line.stockQty;
      dmgtemp += line.nonStockQty;
    })
    setTotalReceiveQty(rcvtemp);
    setTotalInspectionQty(insptemp);
    setTotalResaleableQty(rlstemp);
    setTotalDamageQty(dmgtemp);
  }, [lines])

  const clearForm = useCallback(() => {
    form.setFieldsValue({ sku: undefined });
    setCurrentProduct(undefined);
    if (!qtyLocker) {
      form.setFieldsValue({ qty: 1 });
    }
    if (!warehouseLocker) {
      form.setFieldsValue({ warehouseType: `${defaultWarehouseType}` });
    }
  }, [form, qtyLocker, warehouseLocker, defaultWarehouseType]);

  const columns = [
    { name: 'id', type: 'string', header: 'Id', defaultVisible: false },
    {
      name: 'sku',
      header: 'SKU',
      minWidth: 150,
      showColumnMenuTool: false,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'upc',
      header: 'UPC',
      showColumnMenuTool: false,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'receiveQty',
      header: 'Receive',
      minWidth: 70,
      showColumnMenuTool: false,
      userSelect: true,
      defaultFlex: 1,
      type: 'number',
    },
    {
      name: 'inspectionQty',
      header: 'Inspection',
      minWidth: 70,
      showColumnMenuTool: false,
      sortable: true,
      userSelect: true,
      type: 'number',
      defaultFlex: 1,
    },
    {
      name: 'stockQty',
      header: 'Resaleable',
      minWidth: 70,
      showColumnMenuTool: false,
      userSelect: true,
      defaultFlex: 1,
      type: 'number',
    },
    {
      name: 'nonStockQty',
      header: 'Damage',
      minWidth: 70,
      showColumnMenuTool: false,
      userSelect: true,
      defaultFlex: 1,
      type: 'number',
    },
    {
      header: 'Action',
      name: 'action',
      showColumnMenuTool: false,
      defaultLocked: 'end',
      sortable: false,
      maxWidth: 100,
      minWidth: 100,
      defaultFlex: 1,
      render: (value: any) => {
        const { data, rowIndex } = value;
        return (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="0">
                  <Button
                    id={`bulk_return_receive_grid_action_row_${rowIndex}_dropdown_details`}
                    type="text"
                    style={{ width: '100%', textAlign: 'left' }}
                    icon={<EditOutlined />}
                    onClick={() => {
                      setCurrent(data);
                      setEditQtyDialogVisible(true);
                    }}
                  >
                    Edit
                  </Button>
                </Menu.Item>
                <Menu.Item key="1">
                  <Button
                    id={`bulk_return_receive_grid_action_row_${rowIndex}_dropdown_delete`}
                    type="text"
                    // disabled={data.receiveQty !== 0}
                    style={{ width: '100%', textAlign: 'left' }}
                    className="hover-danger-button-2"
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      Modal.confirm({
                        title: 'Are you sure delete this Row?',
                        icon: <ExclamationCircleOutlined />,
                        content: `SKU: ${data.sku}`,
                        okText: 'Yes',
                        okType: 'default',
                        okButtonProps: { className: 'hover-danger-button' },
                        cancelText: 'No',
                        autoFocusButton: 'cancel',
                        onOk() {
                          deleteLines(data);
                        },
                        onCancel() { },
                      });
                    }}
                  >
                    Delete
                  </Button>
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <Button
              id={`catalog_grid_action_row_${rowIndex}_dropdown`}
              icon={<SettingFilled />}
            >
              <CaretDownOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const saveLine = useCallback(
    async (line: any) => {
      try {
        setSaveLoading(true);
        const res = await saveBulkReturnLine(
          modalReturn.fulfillmentBulkReturnNum,
          {
            ...line,
            inspectionQty:
              (line.receiveQty || 0) -
              (line.stockQty || 0) -
              (line.nonStockQty || 0),
          }
        );
        setSaveLoading(false);
        if (res.isSuccess) {
          setLines(res.data);
          clearForm();
          message.success({ content: `Update [${line.sku}] successfully` });
          InputRef?.current?.focus();
        }
      } catch (error) {
        setSaveLoading(false);
      }
    },
    [modalReturn, clearForm, InputRef]
  );

  const deleteLines = useCallback(
    async (line) => {
      try {
        setSaveLoading(true);
        const res = await deleteBulkReturnLine(
          modalReturn.fulfillmentBulkReturnNum,
          line.fulfillmentBulkReturnLineNum
        );
        setSaveLoading(false);
        if (res.isSuccess) {
          message.info({ content: 'Deleted successfully' });
          setLines(res.data);
        }
      } catch (error) {
        setSaveLoading(false);
      }
    },
    [modalReturn]
  );

  const queryProduct = useCallback(async () => {
    const params = await form.getFieldsValue();
    if (!params.sku.trim()) {
      return message.warning({ content: 'invaild SKU/UPC' });
    }
    try {
      let res: any;
      const sameProduct = lines.filter(
        (i) => i.sku === params.sku.trim() || i.upc === params.sku.trim()
      );
      setQuerying(true);
      res =
        sameProduct.length > 0
          ? sameProduct
          : await queryProductByKeyword(params.sku.trim());
      setQuerying(false);
      if (res && res.length > 0) {
        setCurrentProduct(res[0]);
        const index = lines.findIndex((item) => item.sku === res[0].sku);
        if (index > -1) {
          setLines((prev) => {
            const temp = prev;
            temp.unshift(temp.splice(index, 1)[0]);
            return [...temp];
          });
        }
      } else {
        message.info({ content: `[${params.sku}] not found` });
        form.setFieldsValue({ sku: undefined });
      }
    } catch (error) {
      setQuerying(false);
    }
  }, [form, lines]);

  const addSubmit = useCallback(async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    if (!params.sku.trim()) {
      return message.warning({ content: 'invaild SKU/UPC' });
    }
    try {
      let res: any;
      if (currentProduct) {
        res = [currentProduct];
      } else {
        const sameProduct = lines.filter(
          (i) => i.sku === params.sku.trim() || i.upc === params.sku.trim()
        );
        setLoading(true);
        res =
          sameProduct.length > 0
            ? sameProduct
            : await queryProductByKeyword(params.sku.trim());
        setLoading(false);
      }

      if (res && res.length > 0) {
        // todo add to lines
        const index = lines.findIndex((item) => item.sku === res[0].sku);
        const temp2Loc = specialInfo[2]
          ? (specialInfo[2].locations || []).find(
            (i: any) => i.locationCode === defaultLocationCode
          )
          : undefined;
        const temp3Loc = specialInfo[3]
          ? (specialInfo[3].locations || []).find(
            (i: any) => i.locationCode === defaultLocationCode
          )
          : undefined;
        const temp4Loc = specialInfo[4]
          ? (specialInfo[4].locations || []).find(
            (i: any) => i.locationCode === defaultLocationCode
          )
          : undefined;

        switch (params.warehouseType) {
          case '2':
            if (index > -1) {
              saveLine({
                ...lines[index],
                receiveQty: lines[index].receiveQty + Number(params.qty),
                stockQty: lines[index].stockQty + Number(params.qty),
                stockWarehouseCode: specialInfo[2].warehouseCode,
                stockWarehouseNum: specialInfo[2].warehouseNum,
                stockLocationCode: specialInfo[2].defaultLocationCode || temp2Loc?.locationCode,
                stockLocationNum: specialInfo[2].defaultLocationNum || temp2Loc?.locationNum,
              });
            } else {
              saveLine({
                fulfillmentBulkReturnLineNum: 0,
                sku: res[0].sku,
                upc: res[0].upc,
                fulfillmentProductNum: res[0].fulfillmentProductNum,
                productTitle: res[0].productTitle,
                receiveWarehouseCode: specialInfo[4]
                  ? specialInfo[4].warehouseCode
                  : '',
                receiveWarehouseNum: specialInfo[4]
                  ? specialInfo[4].warehouseNum
                  : 0,
                receiveLocationCode: specialInfo[4].defaultLocationCode || temp4Loc?.locationCode || '',
                receiveLocationNum: specialInfo[4].defaultLocationNum || temp4Loc?.locationNum || 0,
                receiveQty: Number(params.qty),
                stockWarehouseCode: specialInfo[2]
                  ? specialInfo[2].warehouseCode
                  : '',
                stockWarehouseNum: specialInfo[2]
                  ? specialInfo[2].warehouseNum
                  : 0,
                stockLocationCode:specialInfo[2].defaultLocationCode || temp2Loc?.locationCode || '',
                stockLocationNum: specialInfo[2].defaultLocationNum || temp2Loc?.locationNum || 0,
                stockQty: Number(params.qty),
                nonStockWarehouseCode: specialInfo[3]
                  ? specialInfo[3].warehouseCode
                  : '',
                nonStockWarehouseNum: specialInfo[3]
                  ? specialInfo[3].warehouseNum
                  : 0,
                nonStockLocationCode: specialInfo[3].defaultLocationCode || temp3Loc?.locationCode || '',
                nonStockLocationNum: specialInfo[3].defaultLocationNum || temp3Loc?.locationNum || 0,
                nonStockQty: 0,
              });
            }
            break;
          case '3':
            if (index > -1) {
              saveLine({
                ...lines[index],
                receiveQty: lines[index].receiveQty + Number(params.qty),
                nonStockQty: lines[index].nonStockQty + Number(params.qty),
                nonStockWarehouseCode: specialInfo[3]
                  ? specialInfo[3].warehouseCode
                  : '',
                nonStockWarehouseNum: specialInfo[3]
                  ? specialInfo[3].warehouseNum
                  : 0,
                nonStockLocationCode: specialInfo[3].defaultLocationCode || temp3Loc?.locationCode || '',
                nonStockLocationNum: specialInfo[3].defaultLocationNum || temp3Loc?.locationNum || 0,
              });
            } else {
              saveLine({
                fulfillmentBulkReturnLineNum: 0,
                sku: res[0].sku,
                upc: res[0].upc,
                fulfillmentProductNum: res[0].fulfillmentProductNum,
                productTitle: res[0].productTitle,
                receiveWarehouseCode: specialInfo[4]
                  ? specialInfo[4].warehouseCode
                  : '',
                receiveWarehouseNum: specialInfo[4]
                  ? specialInfo[4].warehouseNum
                  : 0,
                receiveLocationCode: specialInfo[4].defaultLocationCode || temp4Loc?.locationCode || '',
                receiveLocationNum: specialInfo[4].defaultLocationNum || temp4Loc?.locationNum || 0,
                receiveQty: Number(params.qty),
                stockWarehouseCode: specialInfo[2]
                  ? specialInfo[2].warehouseCode
                  : '',
                stockWarehouseNum: specialInfo[2]
                  ? specialInfo[2].warehouseNum
                  : 0,
                stockLocationCode: specialInfo[2].defaultLocationCode || temp2Loc?.locationCode || '',
                stockLocationNum: specialInfo[2].defaultLocationNum || temp2Loc?.locationNum || 0,
                stockQty: 0,
                nonStockWarehouseCode: specialInfo[3]
                  ? specialInfo[3].warehouseCode
                  : '',
                nonStockWarehouseNum: specialInfo[3]
                  ? specialInfo[3].warehouseNum
                  : 0,
                nonStockLocationCode: specialInfo[3].defaultLocationCode || temp3Loc?.locationCode || '',
                nonStockLocationNum: specialInfo[3].defaultLocationNum || temp3Loc?.locationNum || 0,
                nonStockQty: Number(params.qty),
              });
            }
            break;
          case '4':
            if (index > -1) {
              saveLine({
                ...lines[index],
                receiveQty: lines[index].receiveQty + Number(params.qty),
                receiveWarehouseCode: specialInfo[4]
                  ? specialInfo[4].warehouseCode
                  : '',
                receiveWarehouseNum: specialInfo[4]
                  ? specialInfo[4].warehouseNum
                  : 0,
                receiveLocationCode: specialInfo[4].defaultLocationCode || temp4Loc?.locationCode || '',
                receiveLocationNum: specialInfo[4].defaultLocationNum || temp4Loc?.locationNum || 0,
              });
            } else {
              saveLine({
                fulfillmentBulkReturnLineNum: 0,
                sku: res[0].sku,
                upc: res[0].upc,
                fulfillmentProductNum: res[0].fulfillmentProductNum,
                productTitle: res[0].productTitle,
                receiveWarehouseCode: specialInfo[4]
                  ? specialInfo[4].warehouseCode
                  : '',
                receiveWarehouseNum: specialInfo[4]
                  ? specialInfo[4].warehouseNum
                  : 0,
                receiveLocationCode: specialInfo[4].defaultLocationCode || temp4Loc?.locationCode || '',
                receiveLocationNum: specialInfo[4].defaultLocationNum || temp4Loc?.locationNum || 0,
                receiveQty: Number(params.qty),
                stockWarehouseCode: specialInfo[2]
                  ? specialInfo[2].warehouseCode
                  : '',
                stockWarehouseNum: specialInfo[2]
                  ? specialInfo[2].warehouseNum
                  : 0,
                stockLocationCode: specialInfo[2].defaultLocationCode || temp2Loc?.locationCode || '',
                stockLocationNum: specialInfo[2].defaultLocationNum || temp2Loc?.locationNum || 0,
                stockQty: 0,
                nonStockWarehouseCode: specialInfo[3]
                  ? specialInfo[3].warehouseCode
                  : '',
                nonStockWarehouseNum: specialInfo[3]
                  ? specialInfo[3].warehouseNum
                  : 0,
                nonStockLocationCode: specialInfo[3].defaultLocationCode || temp3Loc?.locationCode || '',
                nonStockLocationNum: specialInfo[3].defaultLocationNum || temp3Loc?.locationNum || 0,
                nonStockQty: 0,
              });
            }
            break;
          default:
            break;
        }
      } else {
        message.info({ content: `[${params.sku}] not found` });
        form.setFieldsValue({ sku: undefined });
        setCurrentProduct(undefined);
      }
    } catch (error) {
      setLoading(false);
    }
  }, [form, defaultLocationCode, lines, specialInfo, currentProduct, saveLine]);

  useEffect(() => {
    if (modalReturn.fulfillmentBulkReturnNum) {
      getBulkReturnLines();
    }
  }, [modalReturn, getBulkReturnLines]);

  useEffect(() => {
    if (filterValue) {
      const temp = lines.filter(
        (i: any) =>
          (i.sku || '')
            .toLowerCase()
            .indexOf((filterValue || '').trim().toLowerCase()) === 0 ||
          (i.upc || '')
            .toLowerCase()
            .indexOf((filterValue || '').trim().toLowerCase()) === 0
      );
      setFilterlines([...temp]);
    } else {
      setFilterlines([...lines]);
    }
  }, [filterValue, lines]);

  return (
    <>
      <ModalDialog
        centered
        className="fullscreen-modal"
        closable={false}
        closeButton={true}
        footer={null}
        fullscreen={true}
        maskClosable={false}
        onClose={closeProductDetailDialog}
        onFullscreen={onFullscreen}
        title="Receive Customer Return (Bulk Return)"
        visible={props.visible}
        width={detailDialogWidth()}
      >
        <Spin
          style={{ height: '100%', width: '100%' }}
          spinning={loading || saveLoading}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              height: '100%',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              backgroundColor: '#fff',
            }}
          >
            <div
              style={{
                display: 'flex',
                width: 450,
                minHeight: 518,
                borderRightWidth: 1,
                borderRightColor: ' rgba(0, 0, 0, 0.15)',
                borderRightStyle: 'solid',
              }}
            >
              <Card size="small" bordered={false}>
                <Tabs
                  onChange={(key) => {
                    if (key === 'item-1') {
                      setFilterValue('');
                    }
                  }}
                  destroyInactiveTabPane={true}
                >
                  <Tabs.TabPane tab="Add" key="item-1" style={{overflow: 'hidden'}}>
                    <Form form={form}>
                      <Form.Item
                        label={
                          <div style={{ width: 110, textAlign: 'left' }}>
                            <FormLabel require>SKU / UPC</FormLabel>
                          </div>
                        }
                        rules={[{ required: true }]}
                      >
                        <Row gutter={8} align="middle">
                          <Col span={20}>
                            <Form.Item
                              noStyle
                              name="sku"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input SKU / UPC!',
                                },
                              ]}
                            >
                              <Input
                                ref={InputRef}
                                tabIndex={0}
                                onPressEnter={() => {
                                  if (qtyLocker && warehouseLocker) {
                                    addSubmit();
                                  } else {
                                    queryProduct();
                                  }
                                }}
                                onKeyDown={(e) => {
                                  if (e.keyCode === 9) {
                                    if (qtyLocker && warehouseLocker) {
                                      addSubmit();
                                    } else {
                                      queryProduct();
                                    }
                                  }
                                }}
                              />
                            </Form.Item>
                          </Col>

                          <Col span={4}></Col>
                        </Row>
                      </Form.Item>

                      {currentProduct && (
                        <Form.Item
                          label={
                            <div
                              style={{
                                width: 110,
                                textAlign: 'left',
                                paddingLeft: 7,
                              }}
                            >
                              <FormLabel>Product Name</FormLabel>
                            </div>
                          }
                        >
                          <CopySpan
                            needToolTip
                            value={currentProduct.productTitle}
                          />
                        </Form.Item>
                      )}

                      <Form.Item
                        label={
                          <div style={{ width: 110, textAlign: 'left' }}>
                            <FormLabel require>Quantity</FormLabel>
                          </div>
                        }
                      >
                        <Row gutter={8} align="middle">
                          <Col span={20}>
                            <Form.Item
                              noStyle
                              name="qty"
                              initialValue={1}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input Quantity!',
                                },
                              ]}
                            >
                              <InputInteger
                                isTabwork={!qtyLocker}
                                width={186}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Locker
                              size={16}
                              value={qtyLocker}
                              onChange={(value: boolean) => {
                                setQtyLocker(value);
                              }}
                            />
                          </Col>
                        </Row>
                      </Form.Item>

                      <Form.Item
                        label={
                          <div style={{ width: 110, textAlign: 'left' }}>
                            <FormLabel require>Marked as</FormLabel>
                          </div>
                        }
                        // extra={`Put it into Warehouse: ${specialInfo[selected].warehouseCode}`}
                        extra={
                          specialInfo[selected]&&specialInfo[selected].warehouseCode && specialInfo[selected].defaultLocationCode ? 
                            `Put it into Location(warehouse) : ${specialInfo[selected].defaultLocationCode} (${specialInfo[selected].warehouseCode})`
                            : `Put it into Location(warehouse) : ${defaultLocationCode} (${specialInfo[selected].warehouseCode})`
                          
                          }
                      >
                        <Row gutter={8} align="middle">
                          <Col span={20}>
                            <Form.Item
                              noStyle
                              name="warehouseType"
                              initialValue={`${defaultWarehouseType}`}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select marked as!',
                                },
                              ]}
                            >
                              <Select
                                tabIndex={warehouseLocker ? -1 : 0}
                                onChange={(value: number) => {
                                  setSelected(value);
                                }}
                              >
                                {Object.keys(specialInfo).map((item) => (
                                  <Select.Option key={item} value={item}>
                                    {warehouseTypeKeyValue[item]}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Locker
                              size={16}
                              value={warehouseLocker}
                              onChange={(value: boolean) => {
                                setWarehouseLocker(value);
                              }}
                            />
                          </Col>
                        </Row>
                      </Form.Item>
                      <Row
                        justify="center"
                        style={{ marginTop: 20, marginBottom: 20 }}
                      >
                        <Space>
                          <Button
                            type="primary"
                            disabled={querying}
                            onClick={addSubmit}
                            icon={<FormOutlined />}
                          >
                            Submit
                          </Button>
                          <Button onClick={clearForm}>
                            <ClearOutlined />
                            Clear
                          </Button>
                        </Space>
                      </Row>
                    </Form>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Adjustment" key="item-2">
                    <Form.Item
                      label={
                        <div style={{ width: 110, textAlign: 'left' }}>
                          <FormLabel>SKU / UPC</FormLabel>
                        </div>
                      }
                    >
                      <Input
                        allowClear
                        onChange={(e) => {
                          debounceFn(e.target.value);
                          // setFilterValue(e.target.value);
                        }}
                      />
                    </Form.Item>
                  </Tabs.TabPane>
                </Tabs>
                {/* <Divider /> */}
                <Row style={{ marginTop: 48 }}>
                  <Col span={12}>
                    <Form.Item label={<FormLabel>WMS Return#</FormLabel>}>
                      <CopySpan
                        value={modalReturn.fulfillmentBulkReturnNum || ''}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={<FormLabel>Store(Channel)</FormLabel>}>
                      <CopySpan value={modalReturn.channelAccountName || ''} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={<FormLabel>Reference No</FormLabel>}>
                      <CopySpan value={modalReturn.referenceNo || ''} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={<FormLabel>Warehouse</FormLabel>}>
                      <CopySpan value={modalReturn.warehouseCode || ''} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label={<FormLabel>Return Date</FormLabel>}>
                      <CopySpan
                        value={convertTimeByUtc(modalReturn.returnDate, '', '', 'MM/DD/YYYY')}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label={<FormLabel>Claimed total Qty</FormLabel>}>
                      <span>
                        <CopySpan value={`${modalReturn.claimTotalQty}`} />
                        {hasPermissions.length > 0 && <Button type="link" onClick={()=> setEditQtyDialogVisible2(true)} icon={<EditOutlined />}/>}
                      </span>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label={<FormLabel>Total Received</FormLabel>}>
                      <CopySpan value={`${totalReceiveQty}`} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label={<FormLabel>Note</FormLabel>}>
                      <CopySpan value={modalReturn.notes || ''} />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </div>
            <div
              style={{
                height: '100%',
                flexGrow: 1,
                paddingLeft: 8,
                paddingRight: 8,
                paddingBottom: 8,
              }}
            >
              <Row style={{marginTop: 8}}>
                <Col span={6}>
                  <FormLabel>Total Receive Qty</FormLabel>
                  <CopySpan value={`${totalReceiveQty}`} />
                </Col>
                <Col span={6}>
                  <FormLabel>Total Inspection Qty</FormLabel>
                  <CopySpan value={`${totalInspectionQty}`} />
                </Col>
                <Col span={6}>
                  <FormLabel>Total Resaleable Qty</FormLabel>
                  <CopySpan value={`${totalResaleableQty}`} />
                </Col>
                <Col span={6}>
                  <FormLabel>Total Damage Qty</FormLabel>
                  <CopySpan value={`${totalDamageQty}`} />
                </Col>
              </Row>
              <DataGridPlus
                id="id"
                columns={columns}
                dataSource={filterLines.map((i: any) => {
                  return {
                    id: i.sku,
                    ...i,
                  };
                })}
                autoWith={false}
                autoHeight={false}
                selected={lines.length > 0 ? { [lines[0].sku]: true } : {}}
                pagination="local"
                {...({} as any)}
              />
            </div>
          </div>
        </Spin>
        {editQtyDialogVisible && (
          <EditQtyDialog
            visible
            onHide={() => setEditQtyDialogVisible(false)}
            onSave={(data: any) => {
              setLines(data);
              setEditQtyDialogVisible(false);
            }}
            data={current}
          />
        )}
        {editQtyDialogVisible2 && (
          <EditClaimQtyDialog
            visible
            onHide={() => setEditQtyDialogVisible2(false)}
            onSave={(value: number) => {
              setModalReturn((prev:any)=>{
                return {
                  ...prev,
                  claimTotalQty: value
                }
              })
              setEditQtyDialogVisible2(false);
            }}
            data={modalReturn}
          />
        )}
      </ModalDialog>
    </>
  );
};

export default ProductDetailDialog;
