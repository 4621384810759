import React, { useCallback, useEffect, useState } from 'react';
import { Button, Space, Card, Row, Col, Collapse, Spin, Tooltip } from 'antd';
// import {Loading} from 'components/common';
import {
  EditOutlined,
  PlusOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
  LoadingOutlined,
  CompressOutlined,
  ExpandOutlined,
} from '@ant-design/icons';
import { message } from 'components/common';
import { HeaderTitle } from 'components/common';
import {
  getRuteRules,
  // editRouteRules,
  deleteRouteRules,
  sortRouteRules,
  //   addRouteRules,
} from 'services/routeRule';
import { fetchStoreList } from 'services/storeSetup';
import { DeleteButton } from 'components/common';
import CreateDialog from './CreateDialog';
import EditDialog from './EditDialog';
import { HoverBgButton } from 'components/common';
import { FormLabel } from 'components/common';
import { sortByString } from 'utils';

const { Panel } = Collapse;

type ItemType = {
  item1: number;
  item2: string;
};

type RuleRow = {
  country: string;
  default: boolean;
  routeChannelList: ItemType[];
  routeRuleNum: number;
  routeStatesList: ItemType[];
  routeWarehouseList: ItemType[];
  routeZipCodeList: ItemType[];
  ruleName: string;
  seq: number;
};

// eslint-disable-next-line
export default () => {
  const [loading, setLoading] = useState(false);
  const [ruleList, setRuleList] = useState<RuleRow[]>([]);
  const [current, setCurrent] = useState<RuleRow>();
  const [channels, setChannels] = useState<StoreChannelRow[]>([]);
  const [createlVisiable, setCreatelVisible] = useState(false);
  const [editVisiable, setEditVisible] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [activeArr, setActiveArr] = useState<any[]>([]);
  const [nextSeq, setNextSeq] = useState<number>(1);
  const [noDefault, setNoDefault] = useState(false);
  const [showAddDefaultButton, setShowAddDefaultButton] = useState(false);

  const getRules = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getRuteRules();
      setLoading(false);
      if (res.isSuccess) {
        if (res.data.length > 0) {
          setRuleList(res.data);
          setNextSeq(res.data[0].seq + 1);
          setShowAddDefaultButton(
            res.data.filter((i: any) => i.default).length === 0 ? true : false
          );
        } else {
          setShowAddDefaultButton(true);
        }
        setActiveArr([]);
      } else {
        setShowAddDefaultButton(false);
        message.error({ content: res.message });
      }
    } catch (error) {
      setLoading(false);
    }
  }, []);

  // const editRuleSeq = useCallback(
  //   async (seq: number, item: any) => {
  //     try {
  //       setLoading(true);
  //       const res = await editRouteRules(item.routeRuleNum, {
  //         RuleName: item.ruleName,
  //         Country: item.country,
  //         Seq: seq,
  //         RouteZipCodeList: [],
  //         RouteStatesList: [],
  //         RouteChannelList: [],
  //         RouteWarehouseList: [],
  //         Default: false,
  //       });
  //       setLoading(false);
  //       if (res) {
  //         getRules();
  //       }
  //     } catch (error) {
  //       setLoading(false);
  //     }
  //   },
  //   [getRules]
  // );

  const getChannels = async () => {
    try {
      const res = await fetchStoreList();
      if (res.isSuccess) {
        setChannels(
          res.data
            .filter((i: any) => i.status === 1)
            .sort((a: any, b: any) => sortByString(a, b, 'channelAccountName'))
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteHandle = useCallback(
    async (rule: any) => {
      try {
        const res = await deleteRouteRules(rule.routeRuleId);
        if (res) {
          message.success({ content: `Deleted ${rule.ruleName} Successfully` });
          getRules();
        } else {
          // message.error({ content: 'Delete failed' });
        }
      } catch (error) {
        console.log(error);
      }
    },
    [getRules]
  );

  const swapRules = useCallback(
    async (initiator: RuleRow, target: RuleRow) => {
      if (!initiator || !target) {
        message.error({ content: 'Invalid operation' });
      }
      try {
        setLoading(true);
        const res = await sortRouteRules([
          { RouteRuleNum: initiator.routeRuleNum, Seq: target.seq },
          { RouteRuleNum: target.routeRuleNum, Seq: initiator.seq },
        ]);
        if (res) {
          getRules();
        }
      } catch (error) {
        setLoading(false);
      }
    },
    [getRules]
  );

  /* eslint-disable */
  useEffect(() => {
    getRules();
    getChannels();
  }, []);
  /* eslint-enable */
  return (
    <>
      <HeaderTitle breadcrumb={['Administration', 'Warehouse Routing Rule']}>
        <Space>
          {/* {showAddDefaultButton && (
            <Button
              type="primary"
              onClick={() => {
                setNoDefault(true);
                setCreatelVisible(true);
              }}
            >
              {'Create Default Route Rule'}
              <PlusOutlined />
            </Button>
          )} */}
          <Button
            type="primary"
            onClick={() => {
              if (showAddDefaultButton) {
                setNoDefault(true);
                setCreatelVisible(true);
              } else {
                setNoDefault(false);
                setCreatelVisible(true);
              }
            }}
          >
            <PlusOutlined />
            {showAddDefaultButton
              ? 'Default routing rule'
              : 'Create routing rule'}
          </Button>
        </Space>
      </HeaderTitle>
      <Card
        title={
          <Button
            type="primary"
            onClick={() => {
              setActiveArr(() =>
                ruleList.map(() => {
                  return showAll ? [] : ['1'];
                })
              );
              setShowAll((prev) => !prev);
            }}
            icon={showAll? <CompressOutlined /> : <ExpandOutlined />}
          >
            {showAll ? 'Collapse All' : 'Expand All'}
          </Button>
        }
        size="small"
        bodyStyle={{ overflow: 'auto' }}
      >
        <Spin
          spinning={loading}
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        >
          <Space direction="vertical" style={{ width: '100%' }}>
            {ruleList.map((item, index) =>
              !item.default ? (
                <Collapse
                  // collapsible="header"
                  key={item.routeRuleNum}
                  // defaultActiveKey={showAll ? ['1'] : []}
                  activeKey={activeArr[index]}
                  onChange={(i) => {
                    setActiveArr((prev) => {
                      const temp = [...prev];
                      temp[index] = i;
                      return [...temp];
                    });
                  }}
                >
                  <Panel
                    header={
                      <Space>
                        <span>{`${item.ruleName}`}</span>
                        {/* <Tag color="default">{`priority： ${item.seq}`}</Tag> */}

                        {index !== 0 && (
                          <Tooltip title="Increase priority">
                            <Button
                              icon={<CaretUpOutlined />}
                              onClick={(e) => {
                                // editRuleSeq(item.seq - 1, item);
                                swapRules(ruleList[index], ruleList[index - 1]);
                                e.stopPropagation();
                                return false;
                              }}
                            />
                          </Tooltip>
                        )}

                        {index !== ruleList.length - 2 && (
                          <Tooltip title="Reduce priority">
                            <Button
                              icon={<CaretDownOutlined />}
                              onClick={(e) => {
                                // editRuleSeq(item.seq + 1, item);
                                swapRules(ruleList[index], ruleList[index + 1]);
                                e.stopPropagation();
                                return false;
                              }}
                            />
                          </Tooltip>
                        )}
                      </Space>
                    }
                    key="1"
                    extra={
                      !item.default ? (
                        <Space>
                          <HoverBgButton
                            hovertype="info"
                            onClick={(e) => {
                              setCurrent(item);
                              setEditVisible(true);
                              e.stopPropagation();
                              return false;
                            }}
                          >
                            <EditOutlined />
                            Edit
                          </HoverBgButton>
                          <DeleteButton
                            confirmContent={`name: ${item.ruleName}`}
                            confirmTitle="Are you sure you want to delete the rule ?"
                            onClick={() => {
                              deleteHandle(item);
                            }}
                          />
                        </Space>
                      ) : (
                        <Space />
                      )
                    }
                  >
                    <Row>
                      <Col span={18} style={{ padding: 4 }}>
                        <Card size="small" title="Condition Options">
                          <Row>
                            <Col span={6} style={{ padding: 4 }}>
                              <FormLabel noColon>Country</FormLabel>
                              <Card
                                bodyStyle={{
                                  padding: 4,
                                  height: 30,
                                }}
                              >
                                <p>{item.country}</p>
                              </Card>
                            </Col>
                            <Col span={6} style={{ padding: 4 }}>
                              <FormLabel noColon>States</FormLabel>
                              <Card
                                bodyStyle={{
                                  padding: 4,
                                  height: 120,
                                  lineHeight: 1,
                                  overflow: 'auto',
                                }}
                              >
                                {item.routeStatesList
                                  ? item.routeStatesList.map(
                                    (stateItem: ItemType) => (
                                      <p key={stateItem.item1}>
                                        {stateItem.item2}
                                      </p>
                                    )
                                  )
                                  : null}
                              </Card>
                            </Col>
                            <Col span={6} style={{ padding: 4 }}>
                              <FormLabel noColon>Zip Code</FormLabel>
                              <Card
                                bodyStyle={{
                                  padding: 4,
                                  height: 120,
                                  lineHeight: 1,
                                  overflow: 'auto',
                                }}
                              >
                                {item.routeZipCodeList
                                  ? item.routeZipCodeList.map(
                                    (zipItem: ItemType) => (
                                      <p key={zipItem.item1}>
                                        {zipItem.item2}
                                      </p>
                                    )
                                  )
                                  : null}
                              </Card>
                            </Col>
                            <Col span={6} style={{ padding: 4 }}>
                              <FormLabel noColon>Store Account</FormLabel>
                              <Card
                                bodyStyle={{
                                  padding: 4,
                                  height: 120,
                                  lineHeight: 1,
                                  overflow: 'auto',
                                }}
                              >
                                {item.routeChannelList
                                  ? item.routeChannelList.map(
                                    (channelItem: ItemType) => (
                                      <p key={channelItem.item1}>
                                        {channelItem.item2}
                                      </p>
                                    )
                                  )
                                  : null}
                              </Card>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      <Col span={6} style={{ padding: 4 }}>
                        <Card size="small" title="Priority">
                          <Col style={{ padding: 4 }}>
                            <FormLabel noColon>Warehouse Priority</FormLabel>
                            <Card
                              bodyStyle={{
                                padding: 4,
                                height: 120,
                                lineHeight: 1,
                                overflow: 'auto',
                              }}
                            >
                              {item.routeWarehouseList
                                ? item.routeWarehouseList.map(
                                  (warehouseItem: ItemType) => (
                                    <p key={warehouseItem.item1}>
                                      {warehouseItem.item2}
                                    </p>
                                  )
                                )
                                : null}
                            </Card>
                          </Col>
                        </Card>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              ) : (
                <Collapse
                  collapsible="header"
                  key={item.routeRuleNum}
                  activeKey={activeArr[index]}
                  onChange={(i) => {
                    setActiveArr((prev) => {
                      const temp = [...prev];
                      temp[index] = i;
                      return [...temp];
                    });
                  }}
                >
                  <Panel
                    header={
                      <Space>
                        <span>Default</span>
                      </Space>
                    }
                    key="1"
                    extra={
                      <Space>
                        <HoverBgButton
                          hovertype="info"
                          onClick={() => {
                            setCurrent(item);
                            setEditVisible(true);
                          }}
                        >
                          <EditOutlined />
                          Edit
                        </HoverBgButton>
                      </Space>
                    }
                  >
                    <Row justify="center">
                      <Col span={12} style={{ padding: 4 }}>
                        <Card size="small" title="Priority">
                          <Col style={{ padding: 4 }}>
                            <FormLabel noColon>Warehouse Priority</FormLabel>
                            <Card
                              bodyStyle={{
                                padding: 4,
                                height: 120,
                                lineHeight: 1,
                                overflow: 'auto',
                              }}
                            >
                              {item.routeWarehouseList
                                ? item.routeWarehouseList.map(
                                  (warehouseItem: ItemType) => (
                                    <p key={warehouseItem.item1}>
                                      {warehouseItem.item2}
                                    </p>
                                  )
                                )
                                : null}
                            </Card>
                          </Col>
                        </Card>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              )
            )}
          </Space>
        </Spin>
      </Card>
      {createlVisiable && (
        <CreateDialog
          visible
          channels={channels}
          onHide={() => setCreatelVisible(false)}
          onRefresh={() => getRules()}
          nextSeq={nextSeq}
          noDefault={noDefault}
        />
      )}
      {editVisiable && (
        <EditDialog
          visible
          channels={channels}
          onHide={() => setEditVisible(false)}
          onRefresh={() => getRules()}
          rule={current}
        />
      )}
    </>
  );
};
