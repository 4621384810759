/**
 * PO Enums
 */
export const poControlStatus: { [key: string]: string } = {
  '0': 'No Control',
  '1': 'Locked',
  '2': 'Closed',
  '8': 'Abandoned'
};

export const poStatusMap: { [key: number]: string } = {
  1: 'Open',
  2: 'Paritially Received',
  3: 'Received',
  4: 'Over Received',
};

export const warehouseTypeEnum: { [key: number]: string } = {
  1: 'Normal',
  2: 'Return-Resalable',
  3: 'Return-Damage',
  4: 'Return-Inspection',
  5: 'Third Party',
  6: 'Receiving',
  7: 'Damage'
}

export const warehouseTransferStatusEnum: { [key: string]: string } = {
  '0': 'Pending',
  '1': 'Processing',
  '2': 'Complete',
}; 

export const orderProcessStatusKeyValue: { [key: string]: string } = {
  '0': 'Not Processed Yet',
  '1': 'Processed Successfully',
  '2': 'Processed With Error',
  '250': 'Processing',
  '255': 'Pending Retry',
  '99': 'Cancelled',
};

export const orderStatusKeyValue : { [key: string]: string } = {
  '0': 'Pending',
  '1': 'Shipped',
  '2': 'Partially Shipped',
  '8': 'Held',
  '16': 'Cancelled',
  '32': 'Abandoned',
  '250': 'Processing',
};

export const shipmentStatusKeyValue: { [key: string]: string } = {
  '0': 'Pending',
  '1': 'Shipped',
  '8': 'Held',
  '9': 'Printing',
  '10': 'SmartVerify Ready',
  '16': 'Cancelled',
  '32': 'Abandoned',
  '250': 'Processing',
};

export const shopAroundStatusKeyValue: { [key: string]: string } = {
  '0': 'Pending',
  '1': 'Success',
  '2': 'Failed',
};

export const threePLSyncStatusKeyValue: { [key: string]: string } = {
  '0': 'Pending',
  '1': 'Success',
  '2': 'Failed',
};


export const unpackStatusKeyValue: { [key: string]: string } = {
  '0': 'Pending',
  '1': 'Processing',
  '2': 'Completed',
  '3': 'Canceled',
};

export const packStatusKeyValue: { [key: string]: string } = {
  '0': 'Pending',
  '1': 'Processing',
  '2': 'Completed',
  '3': 'Canceled',
};