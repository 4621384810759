import React, { useCallback } from 'react';
import {
  Card,
  Form,
  Select,
  Row,
  Col,
  Checkbox,
  Space,
  Button,
  Spin,
  Divider,
  Radio,
  DatePicker,
  Input,
  Anchor,
  Modal,
  Tooltip,
} from 'antd';
import {
  HeaderTitle,
  FormLabel,
  HelpMessage,
  message,
  /*DataGridPlus,*/
  SpaceCell,
  TablesFilter,
} from 'components/common';
import DataGridPlus from 'components/common/DataGridPlus/DataGrid';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import {
  ReloadOutlined,
  PrinterOutlined,
  LoadingOutlined,
  CopyOutlined,
  FileSearchOutlined,
  CloudDownloadOutlined,
  DiffOutlined,
} from '@ant-design/icons';
import {
  HTTP_STATUS_OK,
  LS_DEFAULT_WAREHOUSE_KEY,
  DEFAULT_PRINT_MODE,
  DATAGRID_CACHE_PRINT_PICKLIST_COLS,
  DATAGRID_CELL_ICON_WIDTH1,
} from 'constants/config';
import {
  fetchPrinterList,
  fetchPrintTemplates,
  // PrintLabelExport,
  // PrintLabelPreview,
} from 'services/print';
import {
  // getQueuedOrders,
  // PreParePrint,
  getPickList,
  printPicklistExport,
  printPicklistExportAndMark,
  printPicklistPreview,
  getPickListPreviewList,
  postPrintPicklist,
} from 'services/printPickList';
import { printPickList } from 'actions/printActions';
import { setPrintPickListStatus } from 'actions/salesAction';
import { fetchZones } from 'services/zone';
import { getWarehouseServiceList } from 'services/warehouse';
import { exportBusinessFile } from 'services/files';
import DeviceSettingDialog from './DeviceSettingDialog';
import PickingStatusDialog from './PickingStatusDialog';
import { userProfiles } from 'utils/auth';
import {
  convertTimeByUtc,
  convertToUtcTime,
  getWarehouseCodeFromCache,
  getWarehouseIdFromCache,
  getProfileSettingValue,
} from 'utils';
import { RootState } from 'reducers';
import moment from 'moment';
import copy from 'copy-text-to-clipboard';
import theme from 'assets/styles/theme';
import { queryProductByKeyword } from 'services/product';
import OrderDetail from 'components/Sales/ScanVerifyPrint/OrderDetailDialog';
import ProductDetail from 'components/Inventory/ProductDetail/ProductDetailsDialog';
import { formatPrinterToTree } from 'utils';

const { RangePicker } = DatePicker;

// const locationTypes = ['Picking', 'Backstock', 'Reserved'];

type OptionsItem = {
  label: string;
  value: string | number;
};
type OptionsTree = {
  label: string;
  online: boolean;
  options: OptionsItem[];
};

const PrintGeneratedPicklists = () => {
  // const [data, setData] = React.useState<any[]>([]);
  // const [printDialogVisible, setPrintDialogVisible] = React.useState(false);
  // const [selectedRow, setSelectedRow] = React.useState<ProductRow[]>([]);
  // const [selectedData, setSelectedData] = React.useState<any[]>([]);
  const hash = useLocation();
  const whs = useSelector(
    (state: RootState) => state.admin.warehouses,
    shallowEqual
  );
  const searchParams = new URLSearchParams(hash.search);
  const defaultPick = searchParams.get('pickListNum');
  const printStatus = useSelector(
    (state: RootState) => state.sales.printPickListStatus
  );
  // const [checkedList, setCheckedList] = React.useState<string[]>(['Picking']);
  // const [indeterminate, setIndeterminate] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [printLoading, setPrintLoading] = React.useState(false);
  // const [checkAll, setCheckAll] = React.useState(false);
  const [refreshPrinter, setRefeshPrinter] = React.useState(true);
  const [refreshTemplate, setRefeshTemplate] = React.useState(true);
  const [printerList, setPrinterList] = React.useState<Printer[]>([]);
  const [printerTree, setPrinterTree] = React.useState<OptionsTree[]>();
  const [printTemplateList, setPrintTemplateList] = React.useState<
    PrintTemplate[]
  >([]);
  const [template7, setTemplate7] = React.useState<PrintTemplate[]>([]);
  const [template6, setTemplate6] = React.useState<PrintTemplate[]>([]);
  const [pickList, setPickList] = React.useState<any[]>([]);
  //const ref = React.useRef<HTMLInputElement | null>(null);
  const ref = React.useRef<any>(null);
  // const [data, setData] = React.useState<any[]>([]);
  const [templateType, setTemplateType] = React.useState<number>(7);
  const [deviceDialogClosable, setDeviceDialogClosable] = React.useState(true);
  const [deviceSettingVisible, setDeviceSettingVisible] = React.useState(false);
  const [checkAble, setCheckAble] = React.useState(true);
  const [selectedPick, setSelectedPick] = React.useState<any>();
  const [markPrinted, setMarkPrinted] = React.useState(false);
  const [selectedZoneType, setSelectedZoneType] = React.useState('0');
  const [zoneList, setZoneList] = React.useState<any[]>([]);
  const [zones, setZones] = React.useState<any[] | null>();
  // const [hadRangeDate, setHadRangeDate] = React.useState(false);
  const [gridData, setGridData] = React.useState<any[]>([]);
  const [previewLoading, setPreviewLoading] = React.useState(false);
  const [targetOffset, setTargetOffset] = React.useState<number | undefined>(
    undefined
  );
  const [keyWord, setKeyWord] = React.useState<string>('ChanelOrderId');
  const [serviceList, setServiceList] = React.useState<any[]>([]);

  const [pickingStatusVisible, setPickingStatusVisible] = React.useState<boolean>(false);

  const [product, setProduct] = React.useState<any>();
  const [current, setCurrent] = React.useState<any>();
  const [detailDialogVisible, setDetailDialogVisible] = React.useState<boolean>(false);
  const [detailVisible, setDetailVisible] = React.useState<boolean>(false);
  const [filteredData, setFilteredData] = React.useState<any[]>([]);

  const enableUOM = userProfiles.getProfileSettingValue("EnableUOM");

  // const warehouseId = Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY));
  // const warehouseCode = getWarehouseCodeFromCache(
  //   Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
  // );
  const history = useHistory();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const columns = [
    {
      header: 'Fulfillment Order ID',
      name: 'fulfillmentOrderId',
      userSelect: true,
      sortable: true,
      defaultFlex: 1,
    },
    {
      header: 'Channel Order ID',
      name: 'channelOrderId',
      userSelect: true,
      sortable: true,
      defaultFlex: 1,
      render: (row: any) => {
        const { data } = row;

        return (
          <SpaceCell
            icon={
              <Tooltip
                placement="top"
                title="Copy"
                trigger={['hover', 'click']}
              >
                <CopyOutlined
                  onClick={() => {
                    copy(data.channelOrderId);
                    message.info(
                      `"${data.channelOrderId}" has been copied to clipboard`
                    );
                  }}
                  style={{ color: theme['@info-color'] }}
                />
              </Tooltip>
            }
            iconWidth={DATAGRID_CELL_ICON_WIDTH1}
            onTextClick={() => {
              setCurrent(data);
              setDetailDialogVisible(true);
            }}
            text={data.channelOrderId}
            textIsButton
          />
        );
      },
    },
    {
      header: 'Store Account',
      name: 'channelAccountName',
      userSelect: true,
      sortable: true,
      defaultFlex: 1,
    },
    {
      header: 'Shipping Method',
      name: 'requestShippingService',
      userSelect: true,
      sortable: true,
      defaultFlex: 1,
    },
    {
      header: 'SKU',
      name: 'sku',
      minWidth: 220,
      userSelect: true,
      sortable: true,
      defaultFlex: 1,
      render: (row: any) => {
        const { data } = row;
        return (
          <SpaceCell
            icon={
              <Tooltip
                placement="top"
                title="Copy"
                trigger={['hover', 'click']}
              >
                <CopyOutlined
                  onClick={() => {
                    copy(data.sku);
                    message.info(
                      `"${data.sku}" has been copied to clipboard`
                    );
                  }}
                  style={{ color: theme['@info-color'] }}
                />
              </Tooltip>
            }
            iconWidth={DATAGRID_CELL_ICON_WIDTH1}
            onTextClick={() => {
              getProductDetail(data);
            }}
            text={data.sku}
            textIsButton
          />
        )
      }
    },
    {
      header: 'UPC',
      name: 'upc',
      userSelect: true,
      sortable: true,
      defaultFlex: 1,
    },
    {
      name: 'uom',
      header: 'UOM',
      userSelect: true,
      sortable: true,
      defaultFlex: 1,
      visible: enableUOM === "1",
    },
    {
      name: 'uomRate',
      header: 'UOM Rate',
      userSelect: true,
      sortable: true,
      defaultFlex: 1,
      visible: enableUOM === "1",
    },
    {
      name: 'uomBaseQty',
      header: 'Base Qty',
      userSelect: true,
      sortable: true,
      defaultFlex: 1,
      visible: enableUOM === "1",
    },
    {
      header: 'Product Name',
      name: 'itemTitle',
      userSelect: true,
      sortable: true,
      defaultFlex: 1,
    },
    {
      header: 'Bar Code',
      name: 'barCode',
      userSelect: true,
      sortable: true,
      defaultFlex: 1,
    },
    {
      header: 'Pick Quantity',
      name: 'pickQty',
      userSelect: true,
      sortable: true,
      defaultFlex: 1,
    },
    {
      header: 'Picked Quantity',
      name: 'pickedQty',
      userSelect: true,
      sortable: true,
      defaultFlex: 1,
    },
    {
      header: 'Locations',
      name: 'locations',
      userSelect: true,
      sortable: true,
      defaultFlex: 1,
      render: (value: any) => {
        const { data } = value;
        return data.locations
          ? data.locations.map((i: any) => i.locationCode)
          : '';
      },
    },
  ];

  const getProductDetail = async (data: any) => {
    const { sku = '' } = data;
    setPreviewLoading(true);
    try {
      const res = await queryProductByKeyword(sku);
      if (res.length > 0) {
        setProduct(res[0]);
        setDetailVisible(true);
      }
    } catch (error) {
      console.log(error)
    }
    setPreviewLoading(false);
  }

  const openDeviceSettingDialog = (config: StringKAnyVPair) => {
    setDeviceDialogClosable(
      typeof config.closable === 'boolean' ? config.closable : true
    );
    setDeviceSettingVisible(true);
  };

  React.useEffect(() => {
    if (
      !localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY) ||
      !getWarehouseCodeFromCache(
        Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
      )
    ) {
      openDeviceSettingDialog({ closable: false });
    }
  }, []);

  React.useEffect(() => {
    if (whs) {
      const exist = whs.filter(
        (e) =>
          e.id === Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) &&
          (e.warehouseType === 1 || e.warehouseType === 5)
      );
      if (exist.length === 0) {
        openDeviceSettingDialog({ closable: false });
      }
    }
  }, [whs]);

  const ZonesValidate = (rule: any, value: any) => {
    if (!value || value.length < 2) {
      return Promise.reject('Select at least 2 zones');
    }
    return Promise.resolve();
  };

  const downLoadPicklist = useCallback(async () => {
    try {
      const formValues = await form.getFieldsValue();
      const job = await exportBusinessFile(7, {
        FilterCondition: {
          warehouseNum: Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)),
          pickListNum: formValues.PickListNum,
          createDate: formValues.createDate
            ? [
              convertToUtcTime(
                moment(formValues.createDate[0]).format('YYYY-MM-DD')
              ),
              convertToUtcTime(
                moment(formValues.createDate[1]).format('YYYY-MM-DD')
              ),
            ]
            : undefined,
        },
        SelectField: {
          SKU: 'SKU',
          UPC: 'UPC',
          PickQty: 'Pick Qty',
          Locations: 'Locations',
        },
        OrderByField: 'sku asc',
      });
      if (job.jobguid && job.systemfilename) {
        Modal.confirm({
          title: 'job has been generated',
          okText: 'Close',
          cancelText: 'Check now',
          content:
            'job in progress ,you can check the job status in the export menu on the left side later.',
          onOk() { },
          onCancel() {
            history.push('/export');
          },
        });
      }
    } catch (error) { }
  }, [history, form]);

  const getPicks = React.useCallback(
    async (first?: boolean) => {
      try {
        setLoading(true);
        const res = await getPickList(
          Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
        );
        setLoading(false);
        if (res.isSuccess) {
          setPickList(res.data);
          if (defaultPick && first) {
            const temp = res.data.filter(
              (i: any) => `${i.pickListNum}` === defaultPick
            );
            if (temp.length > 0) {
              setSelectedPick(temp[0]);
            } else {
              if (res.data.length > 1) {
                form.setFieldsValue({PickListNum: res.data[1].pickListNum})
                setSelectedPick(res.data[1]);
              } else {
                form.setFieldsValue({PickListNum: res.data[0]})
                setSelectedPick(res.data[0]);
              }
            }
          } else {
            if (res.data.length > 1) {
              form.setFieldsValue({PickListNum: res.data[1].pickListNum})
              setSelectedPick(res.data[1]);
            } else {
              form.setFieldsValue({PickListNum: res.data[0]})
              setSelectedPick(res.data[0]);
            }
          }
        }
      } catch (err) {
        setLoading(false);
      }
    },
    [defaultPick, form]
  );

  const getServiceList = React.useCallback(async () => {
    try {
      const res = await getWarehouseServiceList(
        Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
      );
      if (res.isSuccess) {
        const tree: any[] = [];
        const ChannelNameList: any[] = [];
        res.data.map((item: any, index: number) => {
          const i = ChannelNameList.indexOf(item.channelAccountName);
          if (i < 0) {
            ChannelNameList.push(item.channelAccountName);
            tree.push({
              label: item.channelAccountName,
              options: [
                {
                  key: item.channelAccountServiceNum,
                  value: item.mappedService,
                },
              ],
            });
          } else {
            tree[i].options.push({
              key: item.channelAccountServiceNum,
              value: item.mappedService,
            });
          }
          return true;
        });
        setServiceList(tree);
      }
    } catch (error) { }
  }, []);

  // const columns = [
  //   {
  //     name: 'id',
  //     header: 'id',
  //     defaultVisible: false,
  //   },
  //   {
  //     name: 'fulfillmentOrderNum',
  //     header: 'Order Number',
  //     defaultFlex: 1,
  //     sortable: true,
  //   },
  // ];

  // const onClose = () => {
  //   //reset data before close modal
  //   form.resetFields();
  //   setData([]);
  // };

  const getZoneList = React.useCallback(async () => {
    try {
      const res = await fetchZones(
        getWarehouseIdFromCache(
          Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
        )
      );
      setZoneList(res);
    } catch (error) { }
  }, []);

  const getPrinterList = React.useCallback(async () => {
    try {
      const data = await fetchPrinterList();
      setRefeshPrinter(false);
      if (data.isSuccess) {

        setPrinterList(data.data);
        setPrinterTree(formatPrinterToTree(data.data));
      } else {
        message.error({
          content: data.message,
        });
      }
    } catch (err) {
      setRefeshPrinter(false);
      //   console.log(err);
    }
  }, []);

  const getPrinterTemplateList = React.useCallback(async () => {
    //group by order : 6
    //group by sku: 7
    try {
      const profile = userProfiles.profile;
      const data = await fetchPrintTemplates({
        TemplateType: 7,
        MasterAccountNum: profile.masterAccountNum,
        ProfileNum: profile.profileNum,
      });
      const data1 = await fetchPrintTemplates({
        TemplateType: 6,
        MasterAccountNum: profile.masterAccountNum,
        ProfileNum: profile.profileNum,
      });
      setRefeshTemplate(false);
      if (data.isSuccess && data1.isSuccess) {
        setTemplate7(data.data);
        setTemplate6(data1.data);
        if (templateType === 7) {
          setPrintTemplateList(data.data);
          if (data.data.length > 0) {
            form.setFieldsValue({
              PrintTemplateNum: data.data[0].printTemplateNum,
            });
          }
        } else {
          setPrintTemplateList(data1.data);
          if (data1.data.length > 0) {
            form.setFieldsValue({
              PrintTemplateNum: data1.data[0].printTemplateNum,
            });
          }
        }
      } else {
        message.error({
          content: data.message,
        });
      }
    } catch (err) {
      setRefeshTemplate(false);
      //   console.log(err);
    }
  }, [templateType, form]);

  // const getQueuedOrderList = React.useCallback(async () => {
  //   try {
  //     const res = await getQueuedOrders({
  //       WarehouseNum: 10231,
  //       WarehouseCode: 'WonderFold',
  //     });
  //     setData(res.data[0]);
  //   } catch (error) {}
  // }, []);

  const postPrintTask = async (postType: string) => {
    const formValues = await form.getFieldsValue();
    const profile = userProfiles.profile;
    formValues.KitBreakDown = 0;
    // console.log({
    //   ...formValues,
    //   WarehouseNum: warehouseId,
    //   WarehouseCode: warehouseCode,
    //   ZoneSelectType: Number(selectedZoneType),
    //   Zones: selectedZoneType === '1' ? [formValues.Zones] : formValues.Zones,
    // });
    // return;
    try {
      switch (postType) {
        case 'printTask': {
          const { errorFields } = await form.validateFields();
          if (errorFields) return;
          if (!formValues.PrinterNum) {
            message.warning({ content: 'Please select a printer' });
            return;
          }
          const printMode = await getProfileSettingValue(
            'PrintStationMode',
            DEFAULT_PRINT_MODE
          );
          const selectedPrinter = printerList.filter(
            (item) => item.printerNum === formValues.PrinterNum
          )[0];
          const paramsData = {
            ...formValues,
            WarehouseNum: Number(
              localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)
            ),
            WarehouseCode: getWarehouseCodeFromCache(
              Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
            ),
            MasterAccountNum: profile.masterAccountNum,
            PrinterAccountNum: selectedPrinter.printerAccountNum,
            ProfileNum: profile.profileNum,
            User: profile.email,
            ZoneSelectType: Number(selectedZoneType),
            Zones:
              selectedZoneType === '1' ? [formValues.Zones] : formValues.Zones,
            IncludeZones:
              formValues.IncludeZones && formValues.IncludeZones.length > 0
                ? formValues.IncludeZones
                : null,
            PickListStart: formValues.createDate
              ? convertToUtcTime(
                moment(formValues.createDate[0]).format('YYYY-MM-DD')
              )
              : null,
            PickListEnd: formValues.createDate
              ? convertToUtcTime(
                moment(formValues.createDate[1]).format('YYYY-MM-DD')
              )
              : null,
            RequestServices: formValues.rqServices
              ? [...formValues.rqServices.split('\n')]
              : [],
            ChannelOrderId:
              keyWord === 'ChannelOrderId'
                ? (formValues.keyWord||'').trim() || undefined
                : undefined,
            FulfillmentOrderId:
              keyWord === 'FulfillmentOrderId'
                ? (formValues.keyWord||'').trim() || undefined
                : undefined,
            CentralOrderId:
              keyWord === 'CentralOrderId'
                ? (formValues.keyWord||'').trim() || undefined
                : undefined,
          };

          if (printMode === DEFAULT_PRINT_MODE) {
            dispatch(setPrintPickListStatus(2));
            dispatch(printPickList(paramsData));
          } else {
            setLoading(true);
            const res1 = await postPrintPicklist(paramsData);
            setLoading(false);
            if (res1.isSuccess) {
              message.success({ content: 'Add printTask success' });
            } else {
              message.info({ content: res1.message });
            }
          }
          break;
        }
        case 'pdfExport': {
          const { errorFields } = await form.validateFields();
          if (errorFields) return;
          setLoading(true);
          const postApi = markPrinted
            ? printPicklistExportAndMark
            : printPicklistExport;
          const item = postApi({
            ...formValues,
            WarehouseNum: Number(
              localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)
            ),
            WarehouseCode: getWarehouseCodeFromCache(
              Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
            ),
            ZoneSelectType: Number(selectedZoneType),
            Zones:
              selectedZoneType === '1' ? [formValues.Zones] : formValues.Zones,
            IncludeZones:
              formValues.IncludeZones && formValues.IncludeZones.length > 0
                ? formValues.IncludeZones
                : null,
            PickListStart: formValues.createDate
              ? convertToUtcTime(
                moment(formValues.createDate[0]).format('YYYY-MM-DD')
              )
              : null,
            PickListEnd: formValues.createDate
              ? convertToUtcTime(
                moment(formValues.createDate[1]).format('YYYY-MM-DD')
              )
              : null,
            RequestServices: formValues.rqServices
              ? [...formValues.rqServices.split('\n')]
              : [],
            // MappedServices: string[],
            ChannelOrderId:
              keyWord === 'ChannelOrderId' ? formValues.keyWord : undefined,
            FulfillmentOrderId:
              keyWord === 'FulfillmentOrderId' ? formValues.keyWord : undefined,
            CentralOrderId:
              keyWord === 'CentralOrderId' ? formValues.keyWord : undefined,
          });

          const res = await item;
          const content = res;
          const blob = new Blob([content], { type: 'application/pdf' });
          const fileName = `PickList${formValues.PickListNum}.pdf`;
          if ('download' in document.createElement('a')) {
            const elink = document.createElement('a');
            elink.download = fileName;
            elink.style.display = 'none';
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href);
            document.body.removeChild(elink);
          } else {
            //navigator.msSaveBlob(blob, fileName);
          }

          setLoading(false);
          break;
        }
        case 'pdfPreview': {
          const { errorFields } = await form.validateFields();
          if (errorFields) return;
          setLoading(true);
          const previewRes = await printPicklistPreview({
            ...formValues,
            WarehouseNum: Number(
              localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)
            ),
            WarehouseCode: getWarehouseCodeFromCache(
              Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
            ),
            ZoneSelectType: Number(selectedZoneType),
            Zones:
              selectedZoneType === '1' ? [formValues.Zones] : formValues.Zones,
            IncludeZones:
              formValues.IncludeZones && formValues.IncludeZones.length > 0
                ? formValues.IncludeZones
                : null,
            PickListStart: formValues.createDate
              ? convertToUtcTime(
                moment(formValues.createDate[0]).format('YYYY-MM-DD')
              )
              : null,
            PickListEnd: formValues.createDate
              ? convertToUtcTime(
                moment(formValues.createDate[1]).format('YYYY-MM-DD')
              )
              : null,
            RequestServices: formValues.rqServices
              ? [...formValues.rqServices.split('\n')]
              : [],
            ChannelOrderId:
              keyWord === 'ChannelOrderId' ? formValues.keyWord : undefined,
            FulfillmentOrderId:
              keyWord === 'FulfillmentOrderId' ? formValues.keyWord : undefined,
            CentralOrderId:
              keyWord === 'CentralOrderId' ? formValues.keyWord : undefined,
          });

          if (
            typeof previewRes === 'object' &&
            typeof previewRes.code === 'number' &&
            previewRes.code !== HTTP_STATUS_OK
          ) {
            if (previewRes.message) {
              message.info(previewRes.message);
            }
          } else {
            const w = window.open('about:blank');
            w?.document.write(previewRes);
          }

          setLoading(false);
          break;
        }
        case 'gridPreview': {
          setPreviewLoading(true);
          const res = await getPickListPreviewList({
            ...formValues,
            WarehouseNum: Number(
              localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)
            ),
            WarehouseCode: getWarehouseCodeFromCache(
              Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
            ),
            ZoneSelectType: Number(selectedZoneType),
            Zones:
              selectedZoneType === '1' ? [formValues.Zones] : formValues.Zones,
            IncludeZones:
              formValues.IncludeZones && formValues.IncludeZones.length > 0
                ? formValues.IncludeZones
                : null,
            PickListStart: formValues.createDate
              ? convertToUtcTime(
                moment(formValues.createDate[0]).format('YYYY-MM-DD')
              )
              : null,
            PickListEnd: formValues.createDate
              ? convertToUtcTime(
                moment(formValues.createDate[1]).format('YYYY-MM-DD')
              )
              : null,
            RequestServices: formValues.rqServices
              ? [...formValues.rqServices.split('\n')]
              : [],
            ChannelOrderId:
              keyWord === 'ChannelOrderId' ? formValues.keyWord : undefined,
            FulfillmentOrderId:
              keyWord === 'FulfillmentOrderId' ? formValues.keyWord : undefined,
            CentralOrderId:
              keyWord === 'CentralOrderId' ? formValues.keyWord : undefined,
          });
          setPreviewLoading(false);
          if (res.isSuccess) {
            setGridData(res.data);
          }
        }
      }
    } catch (error) {
      setLoading(false);
      setPreviewLoading(false);
    }
  };

  React.useEffect(() => {
    if (refreshPrinter) {
      getPrinterList();
    }
  }, [getPrinterList, refreshPrinter]);

  React.useEffect(() => {
    if (refreshTemplate) {
      getPrinterTemplateList();
      setRefeshTemplate(false);
    }
  }, [getPrinterTemplateList, refreshTemplate]);

  React.useEffect(() => {
    if (templateType === 7) {
      setPrintTemplateList(template7);
      if (template7.length > 0) {
        form.setFieldsValue({
          PrintTemplateNum: template7[0].printTemplateNum,
        });
      }
    } else {
      setPrintTemplateList(template6);
      if (template6.length > 0) {
        form.setFieldsValue({
          PrintTemplateNum: template6[0].printTemplateNum,
        });
      }
    }
  }, [templateType, form, template6, template7]);

  React.useEffect(() => {
    if (localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) {
      getPicks(true);
    }
  }, [getPicks]);

  React.useEffect(() => {
    if (localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) {
      getZoneList();
    }
  }, [getZoneList]);

  React.useEffect(() => {
    getServiceList();
  }, [getServiceList]);

  /* eslint-disable */
  React.useEffect(() => {
    if (printStatus === 2 || printStatus === 3) {
      setPrintLoading(true);
    } else {
      setPrintLoading(false);
    }
    if (printStatus === 1 || printStatus === 3) {
      if (printStatus === 1 && templateType === 7) {
        Modal.confirm({
          title: 'Continue print group by order (Multiple SKU Only)?',
          okText: 'Yes',
          cancelText: 'Cancel',
          // content: `Changes you made may not be saved.`,
          onOk() {
            form.setFieldsValue({ PickListGroupBy: 2, PickListOrderBy: 0 });
            setTemplateType(6);
            setPrintTemplateList(template6);
            if (template6.length > 0) {
              form.setFieldsValue({
                PrintTemplateNum: template6[0].printTemplateNum,
              });
            }
            postPrintTask('printTask');
          },
          onCancel() {
            getPicks();
          },
        });
      } else {
        getPicks();
      }
    }
  }, [printStatus]);
  /* eslint-enable */

  return (
    <Anchor targetOffset={targetOffset}>
      <HeaderTitle
        breadcrumb={[
          'Sales Order',
          `Print Pick List  (Warehouse : ${getWarehouseCodeFromCache(
            Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
          )}）`,
        ]}
      />
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Form form={form}>
          <Card title="Print Settings" bordered={false}>
            <Row>
              <Col span={24}>
                <Row>
                  <Col style={{ paddingLeft: 6, paddingRight: 6 }}>
                    {/* <HelpMessage placement="bottom" helpId={4} marginLeft={5} /> */}

                    <Form.Item
                      //

                      label={<FormLabel>Pick List</FormLabel>}
                      name="PickListNum"
                      initialValue={defaultPick ? Number(defaultPick) : undefined}
                      rules={[
                        { required: true, message: 'Pick list is required' },
                      ]}
                    >
                      <Select
                        style={{ width: '100%', minWidth: 200 }}
                        dropdownMatchSelectWidth={false}
                        onChange={(value) => {
                          setSelectedPick(
                            pickList.filter((i) => i.pickListNum === value)[0]
                          );
                          if (value === 0) {
                            form.setFieldsValue({ AllowReprint: false });
                            setCheckAble(false);
                          } else {
                            setCheckAble(true);
                          }
                        }}
                      >
                        {Array.isArray(pickList)
                          ? pickList.map((item, index) => {
                            return (
                              <Select.Option
                                key={index}
                                value={item.pickListNum}
                              >
                                {item.pickListNum === 0
                                  ? 'All'
                                  : `${item.pickListTag
                                    ? `[${item.pickListTag}]`
                                    : ''
                                  }#${item.pickListNum} by ${item.createBy
                                  } on ${convertTimeByUtc(
                                    item.createDate
                                  )}`}
                              </Select.Option>
                            );
                          })
                          : ''}
                      </Select>
                    </Form.Item>
                  </Col>

                  {!checkAble && (
                    <Col>
                      <Form.Item
                        style={{ marginLeft: 8 }}
                        label={<FormLabel>Create Date</FormLabel>}
                        name="createDate"
                      >
                        <RangePicker
                          style={{ width: 400 }}
                          showTime
                          ranges={{
                            Today: [
                              moment().startOf('day'),
                              moment().endOf('day'),
                            ],
                            Yesterday: [
                              moment().add(-1, 'day').startOf('day'),
                              moment().add(-1, 'day').endOf('day'),
                            ],
                            '2 days ago': [
                              moment().add(-2, 'day').startOf('day'),
                              moment().endOf('day'),
                            ],
                            '3 days ago': [
                              moment().add(-3, 'day').startOf('day'),
                              moment().endOf('day'),
                            ],
                            'This Week': [
                              moment().startOf('week'),
                              moment().endOf('week'),
                            ],
                            'Last Week': [
                              moment().add(-1, 'week').startOf('week'),
                              moment().add(-1, 'week').endOf('week'),
                            ],
                            'This Month': [
                              moment().startOf('month'),
                              moment().endOf('month'),
                            ],
                          }}
                          format="MM/DD/YYYY"
                        // onChange={(value) =>
                        //   setHadRangeDate(value ? true : false)
                        // }
                        // disabledDate={rangePickerDisabledDate}
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {checkAble && (
                    <Col
                      // span={4}
                      style={{ paddingLeft: 6, paddingRight: 6 }}
                    >
                      <Form.Item
                        valuePropName="checked"
                        name="AllowReprint"
                        initialValue={false}
                        label={<FormLabel noColon>{''}</FormLabel>}
                      >
                        <Checkbox>Allow Reprint</Checkbox>
                      </Form.Item>
                    </Col>
                  )}
                  {
                    checkAble && selectedPick && (
                      <Space style={{ position: 'absolute', right: 10 }}>
                        <Button onClick={() => setPickingStatusVisible(true)} icon={<DiffOutlined />}>Picking Status</Button>
                      </Space>
                    )
                  }
                </Row>
              </Col>
              <Col
                span={24}
                style={{
                  paddingLeft: 6,
                  paddingRight: 6,
                  marginBottom: 6,
                }}
              >
                <Row justify="space-between" wrap={false}>
                  <Col>
                    <Form.Item>
                      {selectedPick && (
                        <Space style={{ minWidth: 500 }}>
                          <span>Total Orders: {selectedPick.ordersCount}</span>
                          <span>Total Products: {selectedPick.skusCount}</span>
                          <span>Printed Orders: {selectedPick.printedCount}</span>
                          <span>Shipped Orders: {selectedPick.shippedCount}</span>
                          <span>
                            Partially Shipped Orders:{' '}
                            {selectedPick.partialShippedCount}
                          </span>
                          <span>Pending Orders: {selectedPick.pendingCount}</span>
                          <span>Canceled Orders: {selectedPick.cancelCount}</span>
                        </Space>
                      )}
                    </Form.Item>
                  </Col>
                  {
                    checkAble && selectedPick && (
                      <Col>
                        <Button onClick={() => downLoadPicklist()} icon={<CloudDownloadOutlined />}>
                          Export Pick List Detail
                        </Button>
                      </Col>
                    )
                  }
                </Row>
              </Col>
            </Row>
            <Divider style={{ margin: '0px 0px 10px 0px' }} />

            <Row>
              <Col span={12}>
                <Row>
                  <Col
                    span={12}
                    style={{ paddingLeft: 6, paddingRight: 6, marginBottom: 6 }}
                  >
                    <FormLabel>Group By</FormLabel>
                    <HelpMessage placement="bottom" helpId={4} marginLeft={5} />
                    <Form.Item name="PickListGroupBy" initialValue={1}>
                      <Select
                        style={{ width: '100%' }}
                        onChange={(value) => {
                          setTemplateType(`${value}` === '1' ? 7 : 6);
                          form.setFieldsValue({
                            // PrintTemplateNum: undefined,
                            PickListOrderBy: 0,
                          });
                        }}
                      >
                        <Select.Option value={1}>
                          Group by Single SKU
                        </Select.Option>
                        <Select.Option value={2}>
                          Group by Order (Multiple SKU Only)
                        </Select.Option>
                        <Select.Option value={3}>
                          Group by Order (All)
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col
                    span={12}
                    style={{ paddingLeft: 6, paddingRight: 6, marginBottom: 6 }}
                  >
                    <FormLabel>Order By</FormLabel>
                    <HelpMessage placement="bottom" helpId={4} marginLeft={5} />
                    <Form.Item name="PickListOrderBy" initialValue={0}>
                      <Select style={{ width: '100%' }}>
                        <Select.Option value={0}>Location</Select.Option>
                        <Select.Option value={1}>SKU</Select.Option>
                        {templateType === 6 && (
                          <Select.Option value={2}>Sale Date Asc</Select.Option>
                        )}
                        {templateType === 6 && (
                          <Select.Option value={3}>
                            Sale Date Desc
                          </Select.Option>
                        )}
                        {templateType === 6 && (
                          <Select.Option value={4}>Channel Id</Select.Option>
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                  {templateType === 6 && (
                    <Col
                      span={12}
                      style={{
                        paddingLeft: 6,
                        paddingRight: 6,
                        marginBottom: 6,
                      }}
                    >
                      <FormLabel>Items Order By</FormLabel>
                      <HelpMessage
                        placement="bottom"
                        helpId={4}
                        marginLeft={5}
                      />
                      <Form.Item name="ItemOrderby" initialValue={0}>
                        <Select style={{ width: '100%' }}>
                          <Select.Option value={0}>Line Id</Select.Option>
                          <Select.Option value={1}>SKU</Select.Option>
                          <Select.Option value={2}>Location</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                  <Col
                    span={12}
                    style={{ paddingLeft: 6, paddingRight: 6, marginBottom: 6 }}
                  >
                    <FormLabel>Show Not Found Items</FormLabel>
                    <HelpMessage placement="bottom" helpId={4} marginLeft={5} />
                    <Form.Item name="ShowNotFoundItem" initialValue={0}>
                      <Select style={{ width: '100%' }}>
                        <Select.Option value={0}>Always</Select.Option>
                        <Select.Option value={1}>Never</Select.Option>
                        <Select.Option value={2}>Only</Select.Option>
                        <Select.Option value={3}>
                          When in Multi-Item Order
                        </Select.Option>{' '}
                      </Select>
                    </Form.Item>
                  </Col>
                  {templateType === 7 && (
                    <Col
                      span={12}
                      style={{
                        paddingLeft: 6,
                        paddingRight: 6,
                        marginBottom: 6,
                      }}
                    >
                      <FormLabel>SKUs per Page</FormLabel>
                      <HelpMessage
                        placement="bottom"
                        helpId={4}
                        marginLeft={5}
                      />
                      <Form.Item name="SkusPerPage" initialValue={0}>
                        <Select style={{ width: '100%' }}>
                          <Select.Option value={0}>
                            Print Single SKU
                          </Select.Option>

                          <Select.Option value={1}>
                            Print Mutiple SKUs
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  )}

                  {templateType === 6 && (
                    <Col
                      span={12}
                      style={{
                        paddingLeft: 6,
                        paddingRight: 6,
                        marginBottom: 6,
                      }}
                    >
                      <FormLabel>Orders Per Page</FormLabel>
                      <HelpMessage
                        placement="bottom"
                        helpId={4}
                        marginLeft={5}
                      />
                      <Form.Item name="OrdersPerPage" initialValue={1}>
                        <Select style={{ width: '100%' }}>
                          <Select.Option value={0}>
                            Print Single Order
                          </Select.Option>
                          <Select.Option value={1}>
                            Print Mutiple Orders
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                  <Col
                    span={12}
                    style={{ paddingLeft: 6, paddingRight: 6, marginBottom: 6 }}
                  >
                    <FormLabel>Show Out of Stock Items</FormLabel>
                    <HelpMessage placement="bottom" helpId={4} marginLeft={5} />
                    <Form.Item name="ShowOutofStockItem" initialValue={0}>
                      <Select style={{ width: '100%' }}>
                        <Select.Option value={0}>Always</Select.Option>
                        <Select.Option value={1}>Never</Select.Option>
                        <Select.Option value={2}>Only</Select.Option>
                        <Select.Option value={3}>
                          When in Multi-Item Order
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col
                    span={12}
                    style={{ paddingLeft: 6, paddingRight: 6, marginBottom: 6 }}
                  >
                    <FormLabel>Location Sort</FormLabel>
                    <HelpMessage placement="bottom" helpId={4} marginLeft={5} />
                    <Form.Item name="LocationSortType" initialValue={0}>
                      <Select style={{ width: '100%' }}>
                        <Select.Option value={0}>Alphabetical</Select.Option>
                        <Select.Option value={1}>
                          Location Sequence
                        </Select.Option>
                        <Select.Option value={2}>Ascending Qty</Select.Option>
                        <Select.Option value={3}>Descending Qty</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col
                    span={12}
                    style={{ paddingLeft: 6, paddingRight: 6, marginBottom: 6 }}
                  >
                    <FormLabel>Location Types</FormLabel>
                    <HelpMessage placement="bottom" helpId={4} marginLeft={5} />
                    <Form.Item name="LocationType" initialValue={[1]}>
                      <Select mode="multiple" style={{ width: '100%' }}>
                        <Select.Option disabled value={1}>
                          Default
                        </Select.Option>
                        <Select.Option value={2}>Reserve</Select.Option>
                        <Select.Option value={3}>Backstock</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col
                    span={12}
                    style={{ paddingLeft: 6, paddingRight: 6, marginBottom: 6 }}
                  >
                    <FormLabel>Location Limit</FormLabel>
                    <HelpMessage placement="bottom" helpId={4} marginLeft={5} />
                    <Form.Item name="LocationLimit" initialValue={0}>
                      <Select style={{ width: '100%' }}>
                        <Select.Option value={0}>All Locations</Select.Option>
                        <Select.Option value={1}>
                          1 Location Per Product
                        </Select.Option>
                        <Select.Option value={5}>
                          5 Locations Per Product
                        </Select.Option>
                        <Select.Option value={10}>
                          10 Locations Per Product
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col
                    span={12}
                    style={{ paddingLeft: 6, paddingRight: 6, marginBottom: 6 }}
                  >
                    {/* <FormLabel>Location Limit</FormLabel> */}
                    <Select
                      size="small"
                      dropdownMatchSelectWidth={false}
                      style={{ paddingLeft: 0, fontWeight: 600 }}
                      value={keyWord}
                      onChange={setKeyWord}
                      bordered={false}
                    >
                      <Select.Option value="ChannelOrderId">
                        Channel Order ID
                      </Select.Option>
                      <Select.Option value="FulfillmentOrderId">
                        Fulfillment Order ID
                      </Select.Option>
                      <Select.Option value="CentralOrderId">
                        Central Order#
                      </Select.Option>
                    </Select>
                    <HelpMessage placement="bottom" helpId={4} marginLeft={5} />
                    <Form.Item name="keyWord">
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col
                    span={12}
                    style={{ paddingLeft: 6, paddingRight: 6, marginBottom: 6 }}
                  >
                    <FormLabel>Mapped Shipping Service</FormLabel>
                    <HelpMessage placement="bottom" helpId={4} marginLeft={5} />
                    <Form.Item name="MappedShippingServices">
                      <Select
                        style={{ width: '100%' }}
                        allowClear
                        mode="multiple"
                      >
                        {serviceList?.map((item) => {
                          return (
                            <Select.OptGroup
                              key={item.label}
                              label={item.label}
                            >
                              {item.options.map((citem: any) => {
                                return (
                                  <Select.Option
                                    key={citem.key}
                                    value={citem.value}
                                    label={citem.value}
                                  >
                                    {citem.value}
                                  </Select.Option>
                                );
                              })}
                            </Select.OptGroup>
                          );
                        })}
                        {/* {serviceList.map((item) => (
                          <Select.Option
                            key={item.carrierServiceNum}
                            value={item.name}
                          >
                            {item.name}
                          </Select.Option>
                        ))} */}
                      </Select>
                    </Form.Item>
                  </Col>
                  {/* <Col
                    span={12}
                    style={{ paddingLeft: 6, paddingRight: 6, marginBottom: 6, opacity: 0 }}
                  >
                    <FormLabel>Kit Breakdown</FormLabel>
                    <HelpMessage placement="bottom" helpId={4} marginLeft={5} />
                    <Form.Item name="KitBreakDown" initialValue={0}>
                      <Select style={{ width: '100%' }}>
                        <Select.Option value={0}>
                          Hide Kits, Show Child Products
                        </Select.Option>
                        <Select.Option value={1}>
                          Hide Kits, Show Child Products
                        </Select.Option>
                        <Select.Option value={2}>
                          Show Kits, Show products
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col> */}
                </Row>
              </Col>
              <Col
                span={12}
                style={{ paddingLeft: 6, paddingRight: 6, marginBottom: 6 }}
              >
                <FormLabel>Zone Option</FormLabel>
                <HelpMessage placement="bottom" helpId={4} marginLeft={5} />
                <Card
                  style={{ marginBottom: 16 }}
                  bodyStyle={{ padding: 8, height: 230 }}
                >
                  <Row>
                    <Col span={8}>
                      <Radio.Group
                        onChange={(e) => {
                          form.setFieldsValue({
                            Zones: undefined,
                            IncludeZones: undefined,
                          });
                          setZones([]);
                          setSelectedZoneType(e.target.value);
                        }}
                        value={selectedZoneType}
                      >
                        <Space direction="vertical" size="small">
                          <Radio value="0">From all zones</Radio>
                          <Radio value="1">From single zone</Radio>
                          <Radio value="2" disabled={zoneList.length === 0}>
                            From multiple zones
                          </Radio>
                        </Space>
                      </Radio.Group>
                    </Col>
                    <Col span={8} style={{ paddingRight: 4 }}>
                      {selectedZoneType === '1' && (
                        <Form.Item
                          name="Zones"
                          rules={[
                            { required: true, message: 'Please select a zone' },
                          ]}
                        >
                          <Select
                            style={{ width: '100%', marginTop: 24 }}
                            placeholder="Please select a zone"
                          >
                            {zoneList.length > 0 ? (
                              zoneList.map((item) => (
                                <Select.Option key={item.id} value={item.id}>
                                  {item.name}
                                </Select.Option>
                              ))
                            ) : (
                              <Select.Option value={0}>
                                Locations without zone
                              </Select.Option>
                            )}
                          </Select>
                        </Form.Item>
                      )}
                      {selectedZoneType === '2' && (
                        <div style={{ marginTop: 60 }}>
                          <FormLabel strong={false}>Zone List</FormLabel>
                          <Card
                            bodyStyle={{
                              padding: 8,
                              height: 130,
                              overflowY: 'auto',
                            }}
                          >
                            <Form.Item
                              name="Zones"
                              rules={[{ validator: ZonesValidate }]}
                            >
                              <Checkbox.Group
                                onChange={(value) => setZones(value)}
                              >
                                <Space direction="vertical" size={1}>
                                  {zoneList.map((item) => (
                                    <Checkbox key={item.id} value={item.id}>
                                      {item.name}
                                    </Checkbox>
                                  ))}
                                  <Checkbox value={0}>
                                    Locations without zone
                                  </Checkbox>
                                </Space>
                              </Checkbox.Group>
                            </Form.Item>
                          </Card>
                        </div>
                      )}
                    </Col>
                    <Col span={8} style={{ paddingRight: 4 }}>
                      {selectedZoneType === '2' && (
                        <div style={{ marginTop: 60 }}>
                          <FormLabel strong={false}>
                            Must include Zone
                          </FormLabel>
                          <Card
                            bodyStyle={{
                              padding: 8,
                              height: 125,
                              overflowY: 'auto',
                            }}
                          >
                            <Form.Item name="IncludeZones">
                              <Checkbox.Group>
                                <Space direction="vertical" size={1}>
                                  {zones &&
                                    zoneList
                                      .filter((i) => zones.indexOf(i.id) > -1)
                                      .map((item) => (
                                        <Checkbox key={item.id} value={item.id}>
                                          {item.name}
                                        </Checkbox>
                                      ))}
                                  {zones && zones.indexOf(0) > -1 && (
                                    <Checkbox value={0}>
                                      Locations without zone
                                    </Checkbox>
                                  )}
                                </Space>
                              </Checkbox.Group>
                            </Form.Item>
                          </Card>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Card>
                <FormLabel>Requested Services</FormLabel>
                <HelpMessage placement="bottom" helpId={4} marginLeft={5} />
                <Form.Item
                  noStyle
                  name="ExcludeRequestServices"
                  initialValue={0}
                >
                  <Radio.Group>
                    <Radio value={0}>include</Radio>
                    <Radio value={1}>exclude</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item name="rqServices">
                  <Input.TextArea rows={3} />
                </Form.Item>
              </Col>

              {/* <Col
                span={12}
                style={{ paddingLeft: 6, paddingRight: 6, marginBottom: 6 }}
              >
                <FormLabel>Requested Services</FormLabel>
                <HelpMessage placement="bottom" helpId={4} marginLeft={5} />

                <Form.Item name="requestedServices" initialValue={1}>
                  <Input.TextArea rows={4} />
                </Form.Item>
              </Col> */}

              {/* {templateType === 6 && (
                <Col
                  span={6}
                  style={{ paddingLeft: 6, paddingRight: 6, marginBottom: 6 }}
                >
                  <FormLabel>Multi-Item Order Priority</FormLabel>
                  <HelpMessage placement="bottom" helpId={4} marginLeft={5} />
                  <Form.Item name="MutipleItemPiority" initialValue="0">
                    <Select style={{ width: '100%' }}>
                      <Select.Option value="0">Print Inline</Select.Option>
                      <Select.Option value="1">Print First</Select.Option>
                      <Select.Option value="2">Print Last</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              )} */}
            </Row>
            <Row justify="center">
              <div
                style={{
                  width: 360,
                  marginTop: 20,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'start',
                  alignItems: 'start',
                }}
              >
                <FormLabel>Select A Picklist Template</FormLabel>
                <Form.Item
                  name="PrintTemplateNum"
                  rules={[
                    {
                      required: true,
                      message: 'Please select a picklist template',
                    },
                  ]}
                  colon={false}
                  label={
                    <Button
                      icon={
                        <ReloadOutlined
                          spin={false}
                          style={{ fontSize: 12, color: 'green' }}
                        />
                      }
                      onClick={() => setRefeshTemplate(true)}
                    />
                  }
                >
                  <Select
                    placeholder="Select a print template"
                    style={{ width: 300 }}
                  >
                    {printTemplateList.map((item, index) => (
                      <Select.Option key={index} value={item.printTemplateNum}>
                        {item.templateName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <FormLabel>Printer</FormLabel>
                <Form.Item
                  name="PrinterNum"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: 'Please select a Printer',
                  //   },
                  // ]}
                  colon={false}
                  label={
                    <Button
                      style={{ marginLeft: 8 }}
                      icon={
                        <ReloadOutlined
                          spin={false}
                          style={{
                            fontSize: 12,
                            color: 'green',
                          }}
                        />
                      }
                      onClick={() => setRefeshPrinter(true)}
                    />
                  }
                >
                  <Select
                    ref={ref}
                    placeholder="Select a printer"
                    style={{ width: 300 }}
                    optionLabelProp="label"
                  >
                    {printerTree?.map((item) => {
                      return (
                        <Select.OptGroup
                          key={item.label}
                          label={item.label}
                        >
                          {item.options.map((citem: any) => {
                            return (
                              <Select.Option
                                key={citem.value}
                                value={citem.value}
                                label={`${item.label} - ${citem.label}`}
                              >
                                {citem.label}
                              </Select.Option>
                            );
                          })
                          }
                        </Select.OptGroup>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Space style={{ alignSelf: 'center', marginLeft: 250, whiteSpace: 'nowrap' }}>
                  <Button
                    onClick={() => {
                      setTargetOffset(0);
                      postPrintTask('gridPreview');
                    }}
                  >
                    <FileSearchOutlined />
                    Preview
                  </Button>
                  {false && <Button onClick={() => postPrintTask('pdfPreview')}>
                    <FileSearchOutlined />
                    Preview Report
                  </Button>}
                  <Button
                    type="primary"
                    loading={printLoading}
                    onClick={() => postPrintTask('printTask')}
                  >
                    <PrinterOutlined />
                    Print Picklist
                  </Button>
                  <Button
                    type={markPrinted ? 'primary' : 'default'}
                    onClick={() => postPrintTask('pdfExport')}
                  >
                    <CloudDownloadOutlined />
                    Export to PDF
                  </Button>
                  <Checkbox
                    style={{ width: 255 }}
                    onChange={(e) => {
                      setMarkPrinted(e.target.checked);
                    }}
                  >
                    <CloudDownloadOutlined /> Export PDF and Mark as Printed
                  </Checkbox>
                </Space>
              </div>
            </Row>
          </Card>
        </Form>
        <Card title="Preview" bordered={false}>
          <TablesFilter
            columns={columns}
            dataSource={gridData}
            setFilteredData={setFilteredData}
          />
          <DataGridPlus
            cacheKey={DATAGRID_CACHE_PRINT_PICKLIST_COLS}
            showScrollButton
            name="PicklistPreview"
            autoWith={false}
            dataSource={filteredData}
            loading={previewLoading}
            columns={columns}
            idProperty="id"
            pagination="local"
            /* eslint-disable react/jsx-props-no-spreading */
            {...({} as any)}
          />
        </Card>
      </Spin>

      {
        <DeviceSettingDialog
          closable={deviceDialogClosable}
          close={() => {
            setDeviceSettingVisible(false);
          }}
          visible={deviceSettingVisible}
        />
      }
      {
        pickingStatusVisible && (
          <PickingStatusDialog
            onClose={() => setPickingStatusVisible(false)}
            visible={pickingStatusVisible}
            selectedPick={selectedPick}
          />
        )
      }
      {detailDialogVisible && current && (
        <OrderDetail
          allowPopupWindow
          isHistory={false}
          fulfillmentOrderId={current.fulfillmentOrderId.substr(1, current.fulfillmentOrderId.length - 2)}
          fulfillmentOrderNum={current.fulfillmentOrderNum}
          onClose={() => setDetailDialogVisible(false)}
        />
      )}
      {
        detailVisible && product && (
          <ProductDetail
            visible={detailVisible}
            onHide={() => setDetailVisible(false)}
            productId={product.productId}
          />
        )
      }
    </Anchor>
  );
};

export default PrintGeneratedPicklists;
