import React, { useEffect, useRef, useState, useCallback } from 'react';
import {
  Input,
  Row,
  Spin,
  Select,
  Button,
  Card,
  Dropdown,
  Space,
  Menu,
  Modal,
  Tooltip,
} from 'antd';
import axios from 'axios';
import { useLocation, useHistory } from 'react-router-dom';
import {
  ClearOutlined,
  //LoadingOutlined,
  CopyOutlined,
  DownOutlined,
  SearchOutlined,
  CloudSyncOutlined,
} from '@ant-design/icons';
import copy from 'copy-text-to-clipboard';
import theme from 'assets/styles/theme';
import {
  ButtonIcon,
  ExportCSV,
  GreyCardWrapper,
  HeaderTitle,
  Loading,
  LoadingIcon,
  SpaceCell,
  message,
  /*DataGridPlus,*/
  PrintLabel as PrintLabelDialog,
  CopySpan,
  CopyComponent,
  FormLabel,
  ScreenMask,
  TablesFilter,
} from 'components/common';
import DataGridPlus from 'components/common/DataGridPlus/DataGrid';
import EditPartNumber from 'components/Inventory/EditProductPartNumberDialog';
import EditPic from 'components/Inventory/EditProductPicturesDialog';
import EditTitle from 'components/Inventory/EditProductTitleDialog';
import ProductDetail from 'components/Inventory/ProductDetail/ProductDetailsDialog';
import { DATAGRID_CACHE_VIEWCATALOG_COLS, DATAGRID_CELL_ICON_WIDTH1, LOADING_ICON_SIZE1, PERMISSION_LEVEL } from 'constants/config';
import {
  fetchErpSystemCode,
  queryPruducts,
  syncSystemCodes,
} from 'services/catalog';
import { exportBusinessFile } from 'services/files';
import ProductActionCell from './ProductActionCell';
import { TableHeader } from './style';
import PackageDialog from './DefaultPackage';
import FlatRateDialog from './FlatRateDialog';
import SignatureOptionDialog from './SignatureOptionDialog';
import CustomsDialog from './CustomsDialog';
import CommodityDescriptionDialog from './CommodityDescriptionDialog';
import CarrierDeclareValueDialog from './CarrierDeclareValueDialog';
import SyncProductDialog from './SyncERP';
import ArchiveDialog from './ArchiveDialog';
import StatusDialog from './StatusDialog';
import { getPermissionValue, onSelectionChange } from 'utils';
import BundleModal from './BundleModal';
import LOTSettingDialog from './LOTSettingDialog';
import InputSkuListDialog from  './InputSkuListDialog';

const { Option } = Select;

const productStatusKeyValue: { [key: string]: string } = {
  '0': 'Active',
  '1': 'Archived',
};

const conditionEmun: { [key: string]: string } = {
  '0': 'New',
  '1': 'New with tags',
  '2': 'New without tags',
  '3': 'New with defects',
  '4': 'New other',
  '10': 'Open box',
  '11': 'Used - Very Good',
  '12': 'Used - Good',
  '13': 'Used Acceptable',
  '20': 'Used',
  '21': 'Manufacturer refurbished',
  '22': 'Seller refurbished',
  '30': 'Pre-owned',
  '40': 'Refurbished',
  '50': 'Reconditioned',
  '60': 'Remanufactured',
  '70': 'For parts or not working',
  '80': 'Other',
};
// eslint-disable-next-line
export default () => {
  const hash = useLocation();
  const searchParams = new URLSearchParams(hash.search);
  const defaultSKU = searchParams.get('sku');
  const defaultSkuList = searchParams.get('skulist');
  const [loading, setLoading] = useState(false);
  const [productList, setProductList] = useState<ProductRow[]>([]);
  const [filteredData, setFilteredData] = useState<ProductRow[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [searchType, setSearchType] = useState<string>(defaultSkuList? 'uniqueCodeList':'SKU');
  const ref = useRef<any>(null);
  const limitRef = useRef<number>(10);
  const skipRef = useRef<number>(0);
  const [selectedRow, setSelectedRow] = React.useState<ProductRow[]>([]);
  const [editPartNmberVisible, setEditPartNumberVisible] =
    React.useState(false);
  const [editPicVisiable, setEditPicVisible] = React.useState(false);
  const [editTitleVisible, setEditTitleVisible] = React.useState(false);
  const [detailVisible, setDetailVisible] = React.useState(false);
  const [packageVisiable, setPackageVisible] = React.useState(false);
  const [flatRateVisible, setFlatRateVisible] = React.useState(false);
  const [signatureOptionVisible, setSignatureOptionVisible] =
    React.useState(false);
  const [
    commodityDescriptionDialogVisible,
    setCommodityDescriptionDialogVisible,
  ] = React.useState(false);
  const [syncProductDialogVisible, setSyncProductDialogVisible] =
    useState(false);
  const [customsVisible, setCustomsVisible] = React.useState(false);
  const [carrierDeclareValueVisible, setCarrierDeclareValueVisible] =
    useState(false);
  const [current, setCurrent] = React.useState<ProductRow>({
    id: 0,
    productId: '',
  });
  const [printLabelVisible, setPrintLabelVisible] = React.useState(false);
  const [labelData, setLabelData] = React.useState<PrintLabelTypes[]>([]);
  const [upcList, setUpcList] = React.useState<string[]>([]);
  const [skuList, setSkuList] = React.useState<string[]>([]);
  const sortString = React.useRef<string>('fulfillmentProductNum desc');
  const [selected, setSelected] = React.useState<any>({});
  const [productStatus, setProductStatus] = useState<number>(0);
  const [archiveDialogVisible, setArchiveDialogVisible] = useState(false);
  const [statusDialogVisible, setStatusDialogVisible] = useState(false);
  const [LOTDialogVisible, setLOTDialogVisible] = useState<boolean>(false);
  const tempParams = useRef<any>();
  const [managePermission, setManagePermission] = useState<number>(PERMISSION_LEVEL['NONE']);
  const [bundleModalVisible, setBundleModalVisible] = useState(false);
  const [bundleType, setBundleType] = useState<-1 | 0 | 1>(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [listSkuInputVisible, setListSkuInputVisible] = useState(false);

  const requestCancelSource = axios.CancelToken.source();
  const history = useHistory();

  const getProductList = useCallback(async ({
    limit,
    skip,
    sortString,
    fromSearch,
  }: {
    limit?: number;
    skip?: number;
    sortString?: string;
    fromSearch?: boolean;
  }) => {
    setSelected([]);
    setSelectedRow([]);
    setLoading(true);
    let params: any;
    if (!fromSearch) {
      params = {
        ...tempParams.current,
        $top: limit ? limit : 10,
        $skip: skip ? skip : 0,
        cancelToken: requestCancelSource.token,
        $sortBy: sortString || 'fulfillmentProductNum desc',
      };
    } else {
      let keyword = '';
      const inputState = ref.current.state;
      if (inputState && inputState.value) {
        const inputVal = inputState.value.trim();
        ref.current.setState({ value: inputVal })
        keyword = inputVal;
      }
      // const inputVal = ref.current.state.value
      // ref.current.setState({value: })
      // const keyword = ref.current ? ref.current.state.value : '';
      let filter = '';
      const unique7params = [
        'divisionCode',
        'departmentCode',
        'groupCode',
        'subGroupCode',
        'skuStartsWith',
        'productTitle',
        'productTitleContains',
        'uniqueCodeList',
      ].includes(searchType);
      if (searchType && keyword && !unique7params) {
        filter =
          searchType === 'SKU' ? '' : `${searchType} cn '${keyword}'`;
      }
      // if(bundleType >= 0){
      //   filter = filter ? `${filter} and bundleType cn ${bundleType}`: `bundleType cn ${bundleType}`
      // }

      params = {
        $filter: filter,
        $top: limit ? limit : 10,
        $skip: skip ? skip : 0,
        uniqueCode: '',
        $sortBy: sortString || 'fulfillmentProductNum desc',
        bundleType: bundleType >= 0 ? bundleType : undefined,
        // quanities: quantityType,
        cancelToken: requestCancelSource.token,
      };
      if (searchType === 'SKU' && keyword) {
        params.uniqueCode = keyword;
      }
      if (unique7params) {
        //@ts-ignore
        params[searchType] = keyword;
      }
      if (productStatus >= 0) {
        params.productStatus = productStatus;
      }
    }

    try {
      const list = await queryPruducts(params);
      setTotal(list.count);
      setProductList(
        list.data.map((item: any) => {
          item.id = item.fulfillmentProductNum;
          return item;
        })
      );
      tempParams.current = params;
      skipRef.current = skip || 0;
      limitRef.current = limit || 10;

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log('err', err);
    }
  },[bundleType, productStatus, searchType, tempParams, requestCancelSource.token]);

  const downLoadAll = useCallback(
    async (SelectField) => {
      try {
        const keyword = ref.current ? ref.current.state.value : '';
        const job = await exportBusinessFile(5, {
          FilterCondition: {
            uniqueCode: searchType === 'SKU' && keyword ? keyword : undefined,
            skuStartsWith: searchType === 'skuStartsWith' && keyword ? keyword : undefined,
            productTitleContains:
              searchType === 'productTitleContains' && keyword ? keyword : undefined,
            divisionCode:
              searchType === 'divisionCode' && keyword ? keyword : undefined,
            departmentCode:
              searchType === 'departmentCode' && keyword ? keyword : undefined,
            groupCode:
              searchType === 'groupCode' && keyword ? keyword : undefined,
            subGroupCode:
              searchType === 'subGroupCode' && keyword ? keyword : undefined,
            uniqueCodeList:
              searchType === 'uniqueCodeList' && keyword ? keyword : undefined,
            bundleType: bundleType >= 0 ? bundleType : undefined,
          },
          SelectField,
          OrderByField: 'sku asc',
        });
        if (job.jobguid && job.systemfilename) {
          Modal.confirm({
            title: 'job has been generated',
            okText: 'Close',
            cancelText: 'Check now',
            content:
              'job in progress ,you can check the job status in the export menu on the left side later.',
            onOk() { },
            onCancel() {
              history.push('/export');
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    [history, searchType, bundleType]
  );

  const checkManageProductPermission = async () => {
    const permission = await getPermissionValue(40003);
    setManagePermission(permission);
  }

  /* eslint-disable */
  useEffect(() => {
    if (defaultSKU) {
      ref.current.state.value = defaultSKU;
      getProductList({ fromSearch: true });
    }
    if(defaultSkuList) {
      ref.current.state.value = defaultSkuList;
      getProductList({ fromSearch: true });
    }
    checkManageProductPermission();
  }, []);

  /* eslint-enable */

  const columns = [
    {
      name: 'action',
      header: 'Action',
      showColumnMenuTool: false,
      defaultLocked: 'end',
      groupBy: false,
      sortable: false,
      maxWidth: 90,
      minWidth: 90,
      render: (value: any) => {
        const { data, rowIndex } = value;
        return (
          <ProductActionCell
            rowIndex={rowIndex}
            product={data}
            onShowStatusDialog={() => {
              setCurrent(data);
              setStatusDialogVisible(true);
            }}
            onShowCustomsDialog={() => {
              setCustomsVisible(true);
              setCurrent(data);
            }}
            onShowDetail={() => {
              setDetailVisible(true);
              setCurrent(data);
            }}
            onRefresh={() => {
              getProductList({
                limit: limitRef.current,
                skip: 0,
                sortString: sortString.current,
              });
            }}
            onShowPackageDialog={() => {
              setPackageVisible(true);
              setCurrent(data);
            }}
            onShowFlatRateDialog={() => {
              setFlatRateVisible(true);
              setCurrent(data);
            }}
            onShowSignatureOptionDialog={() => {
              setSignatureOptionVisible(true);
              setCurrent(data);
            }}
            onShowCarrierDeclareValueDialog={() => {
              setCarrierDeclareValueVisible(true);
              setCurrent(data);
            }}
            onShowPrintLabel={() => {
              setPrintLabelVisible(true);
              setSkuList([data.sku || '']);
              setUpcList([data.upc || '']);
              setLabelData([
                {
                  code: data.sku + '',
                  title: data.productTitle,
                  quantity: 1,
                },
              ]);
            }}
            onShowLOTDialog={() => {
              setCurrent(data);
              setLOTDialogVisible(true);
            }}
          />
        );
      },
    },
    {
      name: 'sku',
      header: 'SKU',
      userSelect: true,
      sortable: true,
      defaultFlex: 1,
      minWidth: 220,
      render: (value: any) => {
        const { data, rowIndex } = value;

        return (
          <SpaceCell
            id={`catalog_grid_product_sku_row_${rowIndex}`}
            icon={
              <Tooltip
                placement="top"
                title="Copy"
                trigger={['hover', 'click']}
              >
                <CopyOutlined
                  onClick={() => {
                    copy(data.sku);
                    message.info(`"${data.sku}" has been copied to clipboard`);
                  }}
                  style={{ color: theme['@info-color'] }}
                />
              </Tooltip>
            }
            iconWidth={DATAGRID_CELL_ICON_WIDTH1}
            onTextClick={() => onClickSkuCell(data)}
            text={data.sku}
            textIsButton
          />
        );
      },
    },
    {
      name: 'productTitle',
      header: 'Product Name',
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      minWidth: 200,
      needToolTip: true,
    },
    {
      name: 'upc',
      header: 'UPC',
      userSelect: true,
      sortable: true,
      defaultFlex: 1,
      minWidth: 200,
      needToolTip: true,
    },
    {
      name: 'productStatus',
      header: 'Product Status',
      userSelect: true,
      sortable: true,
      defaultFlex: 1,
      renderEnum: productStatusKeyValue,
      render: (value: any) => {
        const { data } = value;
        return (
          <CopySpan
            inGrid
            value={productStatusKeyValue[data.productStatus] || ''}
          />
        );
      },
    },
    {
      name: 'brand',
      header: 'Brand',
      userSelect: true,
      sortable: false,
      defaultFlex: 1,
      minWidth: 200,
    },
    {
      name: 'ean',
      header: 'EAN',
      sortable: false,
      userSelect: true,
      defaultFlex: 1,
      minWidth: 200,
    },
    // {
    //   name: 'isbn',
    //   header: 'ISBN',
    //   sortable: false,
    //   userSelect: true,
    //   defaultFlex: 1,
    //   minWidth: 200,
    // },
    {
      name: 'mpn',
      header: 'MPN',
      sortable: false,
      userSelect: true,
      defaultFlex: 1,
      minWidth: 200,
    },
    {
      name: 'condition',
      header: 'Condition',
      sortable: false,
      userSelect: true,
      defaultFlex: 1,
      minWidth: 200,
      renderEnum: conditionEmun,
      render: (row: any) => {
        const { data } = row;
        return (
          <CopySpan
            inGrid
            value={conditionEmun[data.condition]}
          />
        );
      }
    },
    {
      name: 'boxLength',
      header: 'Box Length',
      userSelect: true,
      sortable: false,
      defaultFlex: 1,
      minWidth: 120,
      render: (value: any) => {
        const { data, rowIndex } = value;
        return (
          <span id={`catalog_grid_box_length_row_${rowIndex}`}>
            {data.boxLength ? `${data.boxLength}${data.dimensionUnitName}` : ''}
          </span>
        );
      },
    },
    {
      name: 'boxWidth',
      header: 'Box Width',
      userSelect: true,
      sortable: false,
      defaultFlex: 1,
      minWidth: 120,
      render: (value: any) => {
        const { data, rowIndex } = value;
        return (
          <span id={`catalog_grid_box_width_row_${rowIndex}`}>
            {data.boxWidth ? `${data.boxWidth}${data.dimensionUnitName}` : ''}
          </span>
        );
      },
    },
    {
      name: 'boxHeight',
      header: 'Box Height',
      sortable: false,
      defaultFlex: 1,
      userSelect: true,
      minWidth: 120,
      render: (value: any) => {
        const { data, rowIndex } = value;
        return (
          <span id={`catalog_grid_box_height_row_${rowIndex}`}>
            {data.boxHeight ? `${data.boxHeight}${data.dimensionUnitName}` : ''}
          </span>
        );
      },
    },
    {
      name: 'grossWeight',
      header: 'Gross Weight',
      sortable: false,
      defaultFlex: 1,
      userSelect: true,
      minWidth: 120,
      render: (value: any) => {
        const { data, rowIndex } = value;
        return (
          <span id={`catalog_grid_bross_weight_row_${rowIndex}`}>
            {data.grossWeight
              ? `${data.grossWeight}${data.weightUnitName}`
              : ''}
          </span>
        );
      },
    },
    {
      name: 'productLength',
      header: 'Product Length',
      sortable: false,
      userSelect: true,
      defaultFlex: 1,
      minWidth: 120,
      render: (value: any) => {
        const { data, rowIndex } = value;
        return (
          <span id={`catalog_grid_product_length_row_${rowIndex}`}>
            {data.productLength
              ? `${data.productLength}${data.dimensionUnitName}`
              : ''}
          </span>
        );
      },
    },
    {
      name: 'productWidth',
      header: 'Product Width',
      sortable: false,
      defaultFlex: 1,
      userSelect: true,
      minWidth: 120,
      render: (value: any) => {
        const { data, rowIndex } = value;
        return (
          <span id={`catalog_grid_product_width_row_${rowIndex}`}>
            {data.productWidth
              ? `${data.productWidth}${data.dimensionUnitName}`
              : ''}
          </span>
        );
      },
    },
    {
      name: 'productHeight',
      header: 'Product Height',
      sortable: false,
      userSelect: true,
      defaultFlex: 1,
      minWidth: 120,
      render: (value: any) => {
        const { data, rowIndex } = value;
        return (
          <span id={`catalog_grid_product_height_row_${rowIndex}`}>
            {data.productHeight
              ? `${data.productHeight}${data.dimensionUnitName}`
              : ''}
          </span>
        );
      },
    },
    {
      name: 'netWeight',
      header: 'Net Weight',
      sortable: false,
      userSelect: true,
      defaultFlex: 1,
      minWidth: 120,
      render: (value: any) => {
        const { data, rowIndex } = value;
        return (
          <span id={`catalog_grid_net_weight_row_${rowIndex}`}>
            {data.netWeight ? `${data.netWeight}${data.weightUnitName}` : ''}
          </span>
        );
      },
    },
    {
      name: 'divisionCode',
      header: 'Division Code',
      sortable: false,
      defaultFlex: 1,
      userSelect: true,
      minWidth: 120,
    },
    {
      name: 'departmentCode',
      header: 'Department Code',
      sortable: false,
      defaultFlex: 1,
      minWidth: 120,
      userSelect: true,
    },
    {
      name: 'groupCode',
      header: 'Group Code',
      sortable: false,
      defaultFlex: 1,
      minWidth: 120,
      userSelect: true,
    },
    {
      name: 'subGroupCode',
      header: 'Subgroup Code',
      sortable: false,
      defaultFlex: 1,
      userSelect: true,
      minWidth: 120,
    },
    {
      name: 'customPackageName',
      header: 'Default Package',
      sortable: false,
      defaultFlex: 1,
      userSelect: true,
      minWidth: 120,
    },
    {
      name: 'packageMaxQty',
      header: 'Max Qty',
      sortable: false,
      defaultFlex: 1,
      userSelect: true,
      minWidth: 120,
    },
    {
      name: 'commodityDescription',
      header: 'Commodity Description',
      userSelect: true,
      sortable: true,
      defaultFlex: 1,
      minWidth: 200,
      needToolTip: true,
    },
    {
      name: 'bundleType',
      header: 'Bundle',
      userSelect: true,
      sortable: true,
      defaultFlex: 1,
      renderEnum: { 1: 'Yes', 0: 'No' },
      render: (value: any) => {
        const { data } = value;
        return (
          <CopyComponent
            value={data.bundleType ? 'Yes' : 'No'}
          >
            {data.bundleType ? <Button style={{ paddingLeft: 0 }} type="link" onClick={() => {
              setCurrent(data);
              setBundleModalVisible(true);
            }}>Yes</Button> : <span>No</span>}
          </CopyComponent>
        );
      },
    },
  ];

  let customProps = {} as any;
  if (selectedRow.length === 0) {
    customProps = {
      ...customProps,
      selected: [],
    };
  }

  const onClickSkuCell = (data: ProductRow) => {
    const selObj = window.getSelection();
    let open = selObj && selObj.toString() ? false : true;

    if (open) {
      //console.log(data.productNum, data.productId, data);
      setCurrent(data);
      setDetailVisible(true);
    }
  };

  const syncColorSizeCodeFromErp = async () => {
    let ope = 'Fetched color codes from ERP';

    setIsLoading(true);

    try {
      let params: StringKAnyVPair[] = [];
      let co: StringKAnyVPair = {};
      let codes = await fetchErpSystemCode('ColorPatternCode');

      co = codes.systemCodes.systemCodes;
      if ('inActive' in co) co.inActive = co.inActive ? 1 : 0;
      if ('fields' in co) {
        co.jsonFields = JSON.stringify(co.fields);
        delete co.fields;
      }
      //console.log('cc', co);
      params.push(co);
      ope = 'Fetched size codes from ERP';
      codes = await fetchErpSystemCode('SizeCode');
      co = codes.systemCodes.systemCodes;
      if ('inActive' in co) co.inActive = co.inActive ? 1 : 0;
      if ('fields' in co) {
        co.jsonFields = JSON.stringify(co.fields);
        delete co.fields;
      }
      //console.log('cc', co);
      params.push(co);
      ope = 'Synced codes from ERP';
      await syncSystemCodes(params);
      //message.info('Synced successfully');
      Modal.info({
        centered: true,
        title: 'Synced successfully',
        content: 'Synced from ERP successfully',
      });
    } catch(e) {
      message.error(`${ope} error: ${e}`);
    } finally {
      setIsLoading(false);
    }
  };

  const syncDropdownMenu = () => {
    return (
      <Menu onClick={({key})=>{
        switch(key){
          case 'sync1':
            setSyncProductDialogVisible(true);
            break;
          case 'sync2':
            syncColorSizeCodeFromErp();
            break;
          default:
            break;
        }
      }}>
        <Menu.Item key="sync1" style={{ paddingLeft: 0 }}>
          <Button
            type="text"
          >
            <CloudSyncOutlined />
            Sync SKU from ERP
          </Button>
        </Menu.Item>
        <Menu.Item  key="sync2" style={{ paddingLeft: 0 }}>
          <Button
            type="text"
          >
            <CloudSyncOutlined />
            Sync Color and Size from ERP
          </Button>
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <>
      <HeaderTitle breadcrumb={['Catalog', 'View Catalog']}>
        <Space>
          {/*<Button
            id="sync_product_button"
            onClick={() => {
              setSyncProductDialogVisible(true);
            }}
            icon={<CloudSyncOutlined />}
          >
            Sync From ERP
          </Button>*/}
          <Dropdown
            overlay={syncDropdownMenu()}
            trigger={['click']}
          >
            <Button onClick={(e) => e.stopPropagation()}>
              <DownOutlined />
              Sync
            </Button>
          </Dropdown>
          <ExportCSV
            id="export_catalog_button"
            columns={columns.filter(item => item.header !== 'Action')}
            data={productList}
            scope="products"
            formatColumns={{
              productStatus: productStatusKeyValue
            }}
            downloadFromApi={(data) => downLoadAll(data)}
          />
        </Space>
      </HeaderTitle>
      <Card>
        <Card>
          <Row justify="center" align="middle">
              <Input.Group compact style={{ width: 500, margin:'0 6px 4px 0' }}>
                <Select
                  id="search_key_select"
                  style={{ width: '40%' }}
                  // defaultValue="SKU"
                  value={searchType}
                  onChange={(value) => setSearchType(value)}
                >
                  <Option key={1} value="SKU">
                    SKU / UPC / Code equals
                  </Option>
                  <Option key={3} value="skuStartsWith">
                    SKU starts with
                  </Option>
                  {/* <Option key={2} value="MPN">
                    MPN
                  </Option> */}
                  {/* <Option key={3} value="Serial Number">
                    Serial Number
                  </Option> */}
                  {/* <Option key={4} value="productTitle">
                    Title starts with
                  </Option> */}
                  <Option key={9} value="productTitleContains">
                    Product Name
                  </Option>
                  {/* <Option key={5} value="Brand">
                    Brand
                  </Option> */}
                  <Option key={5} value="divisionCode">
                    Division Code
                  </Option>
                  <Option key={6} value="departmentCode">
                    Department Code
                  </Option>
                  <Option key={7} value="groupCode">
                    Group Code
                  </Option>
                  <Option key={8} value="subGroupCode">
                    Subgroup Code
                  </Option>
                  <Option key={9} value="uniqueCodeList">
                    List SKU/UPC
                  </Option>
                </Select>
                <Input
                  id="search_value_input"
                  placeholder="Search..."
                  style={{ width: '60%' }}
                  onClick={()=>{
                    if(searchType === 'uniqueCodeList')
                    setListSkuInputVisible(true)
                  }}
                  onPressEnter={(e)=>{
                    if(e.currentTarget.value.trim()){
                      getProductList({
                        limit: limitRef.current,
                        skip: 0,
                        fromSearch: true,
                      });
                    }
                  }}
                  ref={ref}
                />
              </Input.Group>
              <Space style={{margin:'0 6px 4px 0'}}>
              <FormLabel>Product Status</FormLabel>
              <Select
                onChange={setProductStatus}
                value={productStatus}
                style={{ width: 100 }}
              >
                <Select.Option value={-1}>All</Select.Option>
                <Select.Option value={0}>Active</Select.Option>
                <Select.Option value={1}>Archived</Select.Option>
              </Select>
              </Space>
              <Space style={{margin:'0 6px 4px 0'}}>
              <FormLabel>Bundle Type</FormLabel>
              <Select
                onChange={setBundleType}
                value={bundleType}
                style={{ width: 130 }}
              >
                <Select.Option value={-1}>All</Select.Option>
                <Select.Option value={0}>Regular SKU</Select.Option>
                <Select.Option value={1}>Bundle SKU</Select.Option>
                <Select.Option value={2}>Prepack SKU</Select.Option>
              </Select>
              </Space>
              <Button
                id="search_button"
                type="primary"
                style={{margin:'0 6px 4px 0'}}
                onClick={() => {
                  getProductList({
                    limit: limitRef.current,
                    skip: 0,
                    fromSearch: true,
                  });
                  // getProductList(limitKitRef.current, 0, 1);
                }}
              >
                <SearchOutlined />
                Search
              </Button>
              <Button
                id="clear_button"
                htmlType="button"
                style={{margin:'0 6px 4px 0'}}
                onClick={() => {
                  ref.current?.setState({ value: '' });
                  setProductStatus(-1);
                  setBundleType(-1);
                }}
              >
                <ClearOutlined />
                Clear
              </Button>
              {/* <Checkbox id="only_bundle" value={onlyBundle} onChange={(e)=>setOnlyBundle(e.target.checked)}>Bundle Only</Checkbox> */}
          </Row>
          {/* </Form> */}
        </Card>
      </Card>

      <GreyCardWrapper
        style={{
          marginTop: 20,
        }}
      >
        <Spin
          spinning={loading}
          indicator={<LoadingIcon size="large" />}
        >
          {/* <ProductList
            productList={productList}
            total={total}
            onRefresh={(limit: number, top: number, sortString: string) =>
              getProductList(limit, top, sortString)
            }
            skip={skipRef.current}
            limit={limitRef.current}
          /> */}
          <>
            <TableHeader>
              <Space>
                <Button
                  id="batch_print_button"
                  type="primary"
                  disabled={selectedRow.length === 0 ? true : false}
                  onClick={() => {
                    setPrintLabelVisible(true);
                    const data = selectedRow.map((item) => {
                      return {
                        code: item.sku + '',
                        title: item.productTitle,
                        quantity: 1,
                      };
                    });
                    const skus = selectedRow.map((item) => item.sku || '');
                    const upcs = selectedRow.map((item) => item.upc || '');
                    setSkuList(skus);
                    setUpcList(upcs);
                    setLabelData(data);
                  }}
                >
                  <ButtonIcon className="bi-upc-scan" />
                  Batch Print
                </Button>
                <Button
                  id="commodity_description_button"
                  type="primary"
                  disabled={selectedRow.length === 0 ? true : false}
                  onClick={() => {
                    setCommodityDescriptionDialogVisible(true);
                  }}
                >
                  Commodity Description
                </Button>
                <Button
                  id="archive_button"
                  type="primary"
                  disabled={selectedRow.length === 0 ? true : false}
                  onClick={() => {
                    setArchiveDialogVisible(true);
                  }}
                >
                  Archive
                </Button>
                <TablesFilter
                  dataSource={productList}
                  columns={columns}
                  setFilteredData={setFilteredData}
                />
              </Space>

              <div>
                {`Selected(${selectedRow.length}) |  Total(${productList.length})`}
              </div>
            </TableHeader>
            <DataGridPlus
              cacheKey={DATAGRID_CACHE_VIEWCATALOG_COLS}
              skip={skipRef.current}
              limit={limitRef.current}
              showScrollButton
              idProperty="sku"
              columns={columns}
              // groups={groups}
              pageSizes={[10, 50, 200, 500, 1000]}
              dataSource={filteredData}
              count={total}
              checkboxColumn
              selected={selected}
              onSelectionChange={(props) => onSelectionChange(props, productList, setSelected, setSelectedRow, 'sku')}
              /* eslint-disable react/jsx-props-no-spreading */
              {...({ customProps } as any)}
              checkboxOnlyRowSelect={true}
              pagination
              onChange={(limit, skip) => {
                setSelected({});
                setSelectedRow([]);
                limitRef.current = limit;
                skipRef.current = skip;
                getProductList({ limit, skip, sortString: sortString.current });
              }}
              onSortInfoChange={async (v: any) => {
                setSelected({});
                setSelectedRow([]);
                if (v) {
                  if (v.dir === 1) {
                    sortString.current = `${v.id} asc`;
                    getProductList({
                      limit: limitRef.current,
                      skip: skipRef.current,
                      sortString: sortString.current,
                    });
                  }
                  if (v.dir === -1) {
                    sortString.current = `${v.id} desc`;
                    getProductList({
                      limit: limitRef.current,
                      skip: skipRef.current,
                      sortString: sortString.current,
                    });
                  }
                } else {
                  sortString.current = 'fulfillmentProductNum desc';
                  getProductList({
                    limit: limitRef.current,
                    skip: skipRef.current,
                    sortString: sortString.current,
                  });
                }
              }}
            />
            <EditPartNumber
              visible={editPartNmberVisible}
              product={current}
              onHide={() => {
                setEditPartNumberVisible(false);
                setCurrent({ id: 0, productId: '' });
              }}
            />
            <EditPic
              visible={editPicVisiable}
              onHide={() => {
                setEditPicVisible(false);
                setCurrent({ id: 0, productId: '' });
              }}
              product={current}
            />
            <EditTitle
              visible={editTitleVisible}
              product={current}
              onHide={() => {
                setEditTitleVisible(false);
                setCurrent({ id: 0, productId: '' });
              }}
            />
            {
              detailVisible && (
                <ProductDetail
                  visible={detailVisible}
                  onHide={() => setDetailVisible(false)}
                  productId={current.productId}
                />
              )
            }
            {printLabelVisible && (
              <PrintLabelDialog
                visible={true}
                onHide={() => setPrintLabelVisible(false)}
                printType="product"
                labelData={labelData}
                upcList={upcList}
                skuList={skuList}
              />
            )}
            {packageVisiable && (
              <PackageDialog
                visible={true}
                onRefresh={() => { }}
                onHide={() => {
                  setPackageVisible(false);
                }}
                product={current}
                managePermission={managePermission}
              />
            )}
            {flatRateVisible && (
              <FlatRateDialog
                visible={true}
                // onRefresh={() => {}}
                onHide={() => setFlatRateVisible(false)}
                product={current}
              />
            )}
            {signatureOptionVisible && (
              <SignatureOptionDialog
                visible={true}
                onHide={() => setSignatureOptionVisible(false)}
                product={current}
              />
            )}
            {customsVisible && (
              <CustomsDialog
                visible={true}
                onHide={() => setCustomsVisible(false)}
                product={current}
              />
            )}
            {carrierDeclareValueVisible && (
              <CarrierDeclareValueDialog
                visible={true}
                onHide={() => setCarrierDeclareValueVisible(false)}
                product={current}
              />
            )}
            {commodityDescriptionDialogVisible && (
              <CommodityDescriptionDialog
                visible={true}
                onHide={() => setCommodityDescriptionDialogVisible(false)}
                onRefresh={() => {
                  setSelected({});
                  getProductList({
                    limit: limitRef.current,
                    skip: skipRef.current,
                    sortString: sortString.current,
                  });
                }}
                rows={selectedRow}
              />
            )}
            {syncProductDialogVisible && (
              <SyncProductDialog
                visible={true}
                onHide={() => setSyncProductDialogVisible(false)}
                selectedRow={selectedRow}
              />
            )}
            {archiveDialogVisible && (
              <ArchiveDialog
                rows={selectedRow}
                visible
                onHide={() => setArchiveDialogVisible(false)}
                onRefresh={() => {
                  setSelected({});
                  getProductList({
                    limit: limitRef.current,
                    skip: skipRef.current,
                    sortString: sortString.current,
                  });
                }}
              />
            )}
            {statusDialogVisible && (
              <StatusDialog
                visible={true}
                onRefresh={() => {
                  getProductList({
                    limit: limitRef.current,
                    skip: skipRef.current,
                    sortString: sortString.current,
                  });
                }}
                onHide={() => {
                  setStatusDialogVisible(false);
                }}
                product={current}
              />
            )}
            {bundleModalVisible && current && (
              <BundleModal
                visible
                onHide={() => setBundleModalVisible(false)}
                product={current}
              />
            )}
            {LOTDialogVisible && current && (
              <LOTSettingDialog
                onClose={() => {
                  setLOTDialogVisible(false);
                }}
                onRefresh={() => {
                  getProductList({
                    limit: limitRef.current,
                    skip: skipRef.current,
                    sortString: sortString.current,
                  });
                }}
                visible={LOTDialogVisible}
                product={current}
              />
            )}
            {
              listSkuInputVisible && (
                <InputSkuListDialog
                  visible={true}
                  onHide={() => setListSkuInputVisible(false)}
                  onApply={(data)=>{
                    ref.current.setState({ value: data })
                    setListSkuInputVisible(false)
                  } }
                  defaultValue={ref.current.state?.value || ''}
                />
              )
            }
          </>
        </Spin>
      </GreyCardWrapper>
      {isLoading && (
        <ScreenMask>
          <Loading size={LOADING_ICON_SIZE1} />
        </ScreenMask>
      )}
    </>
  );
};
