import React, { useCallback, useRef, useState } from 'react';
import { Button, Row, Select, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import { pickingItemBySKU2 } from 'services/smart';
import { playAudio } from 'utils';
import FinishDialog from './FinishDialog';
import FailDialog from './FailDialog';
import { message } from 'components/common';
import { fetchPickingBySKU } from 'services/smart';
import {
  SmartSpace,
  SmartFormLabel,
  SmartLabel,
  SmartRow,
  SmartText,
  SmartScanner,
  SmartInputNumber,
  SmartBlock,
  SmartButton,
  GreenColor,
  YellowColor,
  SmartBackButton,
  SmartSpin,
  SmartInput
} from '../../../SmartComponent';
import { useTranslation } from 'react-i18next';

type ScanQtyProps = {
  product: SmartPickSKU;
  location: WarehouseLocationRow;
  lotEnable: boolean;
  uomEnable: boolean;
  lotNumbers: string[];
  lotControl: number;
  defaultLotNumber?: string;
  onSubmit: (product: SmartPickSKU) => void;
  onBack: (
    step: 'scanSKU' | 'scanLocaiton' | 'scanQty',
    nextLocation?: string
  ) => void;
};
export default function ScanQty(props: ScanQtyProps): JSX.Element {
  const { product, location, onBack, onSubmit, lotEnable, uomEnable, lotControl, lotNumbers, defaultLotNumber } = props;
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const [scannedQty, setScannedQty] = useState(0);
  const [qtyDetail, setQtyDetail] = useState<{lotNumber?: string, qty: number}[]>([])
  const [scannedAt, setScannedAt] = useState<Date | undefined>();
  const [selectedLot, setSelectedLot] = useState<string | undefined>()
  const [scannedStatus, setScannedStatus] = useState<
    'success' | 'fail' | 'default'
  >('default');
  const [finishStatus, setFinishStatus] = useState<'default' | 'finished'>(
    'default'
  );
  const { pickQty } = product;
  const failSKURef = useRef('');

  React.useEffect(()=>{
    if(lotEnable && lotControl === 1 && lotNumbers &&lotNumbers.length > 0 ){
      setSelectedLot(lotNumbers[0])
      
    }
  },[selectedLot, lotNumbers, lotControl, lotEnable])
  /**
   * Post qty
   */
  const handleSubmit = useCallback(
    async (source: 'skip' | 'auto', qty: number,  detail:any[]) => {
      if (qty > 0) {
        setSubmitting(true);
        try {
          const params = detail.map(i=>{
            return {
              PickListNum: product.pickListNum,
              LocationCode: location.locationCode || '',
              SKU: product.sku || product.upc,
              PickedQty: i.qty,
              LotNumber: i.lotNumber || '',
              UOM: product.uom || '',
              UomRate: product.uomRate || 1,
            }
          })
          const res = await pickingItemBySKU2(params);
          if (res.isSuccess) {
            setSubmitting(false);
            if (source === 'skip') {
              onBack('scanLocaiton');
            } else {
              setFinishStatus('finished');
            }
          }
        } catch (error) {
          setSubmitting(false);
        }
      }
    },
    [product, location, onBack]
  );
  /**
   * hanle finish status
   */
  const handleFinishStatus = useCallback(
    async (nextScannedQty: number, detail:any[]) => {
      //Whether the SKUS has been scanned
      if (pickQty > 0 && nextScannedQty === pickQty) {
        handleSubmit('auto', nextScannedQty, detail);
      }
    },
    [pickQty, handleSubmit]
  );

  const addQtyDetail = useCallback((qty:number, lotNumber: string)=>{
    const temp = [...qtyDetail]
    const index = temp.findIndex(i => i.lotNumber === lotNumber)
    if(index > -1){
      temp[index] = {
        ...temp[index],
        qty: temp[index].qty + qty
      }
      setQtyDetail([...temp])
      return [...temp]
    } else {
      setQtyDetail([{lotNumber, qty},...temp])
      return [{lotNumber, qty},...temp]
    }
  },[qtyDetail])

  /**
   * handle scan sku  event
   */
  const handleScannerChange = useCallback(
    async (nextValue: string) => {
      if (
        nextValue === product.sku ||
        (nextValue === product.upc && product.upc) ||
        (product.productAllCode||[]).indexOf(nextValue) > -1
      ) {
        playAudio('success');
        setScannedStatus('success');
        const nextQty = scannedQty + 1;
        setScannedQty(nextQty);
        const detail = addQtyDetail(1, defaultLotNumber || selectedLot || '')
        handleFinishStatus(nextQty, detail);
      } else {
        playAudio('error');
        failSKURef.current = nextValue;
        setScannedStatus('fail');
      }
      setScannedAt(new Date());
    },
    [scannedQty, failSKURef, product, handleFinishStatus, addQtyDetail, selectedLot, defaultLotNumber]
  );
  /**
   * handle finish event
   */
  const handleFinish = useCallback(
    async (nextValue) => {
      if (nextValue) {
        try {
          const res = await fetchPickingBySKU(nextValue);
          if (res.isSuccess) {
            if (res.data.pickQty <= 0) {
              playAudio('error');
              message.warning(`'${nextValue}' had done!`);
            } else {
              playAudio('success');
              onSubmit(res.data);
              setFinishStatus('default')
            }
          } else {
            playAudio('error');
            message.warning(`'${nextValue}' not found!`);
          }
        } catch (error) {
          onBack('scanSKU');
        }
      } else {
        onBack('scanSKU');
      }
    },
    [onBack, onSubmit]
  );
  const scannedAtStr = dayjs(scannedAt).format('hh:mm:ss A');
  const successTip = t("smart.pick.successTip", {value: scannedAtStr});
  const failTip = t('smart.pick.failedTip', {value: scannedAtStr});
  const buttonTitle = t("smart.pick.skipLocation");
  return (
    <>
      {scannedStatus === 'fail' && product && (
        <FailDialog
          failSKU={failSKURef.current}
          sku={product.sku || ''}
          productAllCode={product.productAllCode || []}
          upc={product.upc || ''}
          onSubmit={(ok) => {
            if (ok) {
              handleScannerChange(product.sku || '');
            } else {
              setScannedStatus('default');
            }
            setTimeout(() => {
              document.getElementById('scanner')?.focus();
            });
          }}
        />
      )}
      {finishStatus === 'finished' && (
        <FinishDialog product={product} onSubmit={handleFinish} />
      )}
      <SmartSpin loading={submitting} />
      <SmartSpace>
        <SmartRow style={{ marginTop: 10 }}>
          <SmartLabel>
            <SmartFormLabel>
              {t("smart.pick.pickSku")}
            </SmartFormLabel>
          </SmartLabel>
          <SmartText>{product.sku}</SmartText>
        </SmartRow>
        <SmartRow style={{ marginTop: 10 }}>
          <SmartLabel>
            <SmartFormLabel>
              {t("smart.pick.pickUpc")}
            </SmartFormLabel>
          </SmartLabel>
          <SmartText>{product.upc}</SmartText>
        </SmartRow>
        {uomEnable && <SmartRow style={{ marginTop: 10 }}>
          <SmartLabel>
            <SmartFormLabel>
            {t("common.uom")}
            </SmartFormLabel>
          </SmartLabel>
          <SmartText>{product.uom}</SmartText>
        </SmartRow>}
        <SmartRow style={{ marginTop: 10, justifyContent: 'space-between' }}>
          <SmartRow>
            <SmartLabel>
              <SmartFormLabel>
                {t("common.location")}
              </SmartFormLabel>
            </SmartLabel>
            <SmartText>{location?.locationCode}</SmartText>
          </SmartRow>
          {scannedQty === 0 && (
            <Button
              size="small"
              onClick={() => {
                onBack('scanLocaiton');
              }}
            >
              {t("smart.pick.changeLocation")}
            </Button>
          )}
        </SmartRow>
        <SmartRow style={{ marginTop: 10 }}>
          <SmartLabel>
            <SmartFormLabel>
              {t("common.title")}
            </SmartFormLabel>
          </SmartLabel>
          <SmartText>{product ? product.itemTitle : ''}</SmartText>
        </SmartRow>
        <SmartRow style={{ marginTop: 10 }}>
          <SmartLabel>
            <SmartFormLabel>
              {t("smart.pick.pickQty")}
            </SmartFormLabel>
          </SmartLabel>
          <SmartInputNumber
            value={pickQty}
            style={{ width: 95, backgroundColor: 'rgb(255,253,128)' }}
          />
        </SmartRow>
        {lotEnable && <SmartRow style={{ marginTop: 10 }}>
          <SmartLabel>
            <SmartFormLabel>
            {t("common.lotNumber")}
            </SmartFormLabel>
          </SmartLabel>
          {defaultLotNumber ? <Typography.Text>{defaultLotNumber}</Typography.Text> : <Select
            style={{width: 190}} 
            allowClear={lotControl === 0}
            defaultValue={lotControl === 1 && lotNumbers.length > 0 ? lotNumbers[0]: undefined}
            onChange={setSelectedLot}
          >
            {lotNumbers.map(i => <Select.Option key={i} value={i}>{i}</Select.Option>)}
          </Select>}
        </SmartRow>}
        <SmartRow style={{ marginTop: 10 }}>
          <SmartLabel>
            <SmartFormLabel>
              {t("smart.pick.scan")}
            </SmartFormLabel>
          </SmartLabel>
          <SmartScanner
            id="scanner"
            style={{ width: 190 }}
            // disabled={lotEnable && !selectedLot && lotNumbers.length !==0 && !defaultLotNumber}
            onChangeValue={handleScannerChange}
          />
          {scannedStatus === 'success' && (
            <SmartRow style={{ color: GreenColor }}>{successTip}</SmartRow>
          )}
          {scannedStatus === 'fail' && (
            <SmartRow style={{ color: YellowColor }}>{failTip}</SmartRow>
          )}
        </SmartRow>


        {qtyDetail.map(i => 
          <SmartRow key={`${i.lotNumber}-key`} style={{ marginTop: 10, justifyContent: 'space-between' }}>
          <Row align="middle">
            <SmartLabel>
              <SmartFormLabel>
                {t("smart.pick.scannedQty")}
              </SmartFormLabel>
            </SmartLabel>
            <SmartInputNumber
              value={i.qty}
              readOnly
              style={{ width: 95, backgroundColor: 'rgb(208,248,157)' }}
            />
          </Row>
          {lotEnable && <Row align="middle">
            <SmartLabel>
              <SmartFormLabel>
                {t("common.lotNumber")}
              </SmartFormLabel>
            </SmartLabel>
            <Tooltip
              title={i.lotNumber}
              trigger="click"
            >
            <SmartInput              
              value={i.lotNumber}
              readOnly
              style={{ width: 150, backgroundColor: 'rgb(208,248,157)' }}
            />
            </Tooltip>
          </Row>}
        </SmartRow>
          
        )}
      </SmartSpace>
      <SmartBackButton>
        <SmartBlock>
          {scannedQty > 0 && (
            <SmartButton
              color="white"
              backgroundColor="red"
              loading={submitting}
              style={{ width: 'auto' }}
              onClick={() => {
                handleSubmit('skip', scannedQty, qtyDetail);
              }}
            >
              {buttonTitle}
            </SmartButton>
          )}

          {/* {pickQty !== scannedQty && ( */}
          <SmartButton
            type="default"
            onClick={() => {
              onBack('scanSKU');
            }}
          >
            {t("smart.pick.chooseAnother")}
          </SmartButton>
          {/* )} */}
        </SmartBlock>
      </SmartBackButton>
    </>
  );
}
