import React, { useCallback, useMemo, useState } from 'react';
//import NumberFormat from 'react-number-format';
import {
  Button,
  Col,
  // Dropdown,
  Grid,
  Image,
  Input,
  // Menu,
  Modal,
  // Popover,
  Row,
  Select,
  Tooltip,
  Form,
  Space,
  Checkbox,
  Typography,
} from 'antd';
import {
  CopyOutlined,
  EditOutlined,
  SearchOutlined,
  RedoOutlined
  //FileTextOutlined,
} from '@ant-design/icons';
import copy from 'copy-text-to-clipboard';
import theme from 'assets/styles/theme';
import { useHistory } from 'react-router-dom';
import {
  COMMON_ERR_MSG_DEFAULT_DISPLAY_DURATION,
  DATAGRID_CACHE_INVENTORY_SEARCH_COLS,
  DATAGRID_CELL_ICON_WIDTH1,
  REGULAR_SECTION_INNER_VERTICAL_SPACING,
  // THUMBNAIL_REQUEST_SIZE1,
} from 'constants/config';
// import {
//   ExclamationCircleOutlined,
//   SettingFilled,
//   CaretDownOutlined,
// } from '@ant-design/icons';
import {
  /*DataGridPlus,*/
  ExportCSV,
  FormLabel,
  HeaderTitle,
  Loading,
  SpaceCell,
  message,
  CopyComponent,
  TablesFilter,
} from 'components/common';
import DataGridPlus from 'components/common/DataGridPlus/DataGrid';
import { GreyCardWrapper, GridIconBox, Label } from 'components/common/styles';
// import { fetchProductThumbnailUrl } from 'services/product';
import fallbackImage from 'assets/images/logo192.png';
import WarehouseSelectBox from '../Warehouse/WarehouseSelectBox';
import EditProductSKUDialog from './EditProductSKUDialog';
import EditProductTitleDialog from './EditProductTitleDialog';
import EditProductCodeDialog from './EditProductCodeDialog';
import EditProductPartNumberDialog from './EditProductPartNumberDialog';
import ProductDetailsDialog from './ProductDetail/ProductDetailsDialog';
import MoveItemDialog from './MoveItemDialog';
import { CenterBox, SearchListGridWrapper } from './style';
import EditProductQuantity from 'components/Inventory/Audit/EditProductQuantityDialog';
// import LocationProductsDialog from './LocationProductsDialog';
import { exportBusinessFile } from 'services/files';
import { fetchWarehouseProductList, syncToErp } from 'services/inventory';
import AuditModal from './AuditModalDialog';
import { userProfiles } from 'utils/auth';
import { formatMessagewithBr, getWarehouseCodeFromCache } from 'utils'

const { Text } = Typography;
/**
 * grid item types
 */
type ColumnRenderType = {
  data: InventoryProductRow;
};

export default function SearchList() {
  const DEFAULT_LIST_ROW_NUM = 30;
  //const DEFAULT_GRID_HEIGHT = 400;
  //const GRID_TOP_OFFSET = 214;

  //const [currentWarehouse, setCurrentWarehouse] = React.useState(-1);
  /**
   * This is a legacy state. Maybe it is needed in the future.
   */
  const [selectedWarehouseNums, setSelectedWarehouseNums] = React.useState<
    Array<number>
  >([]);
  const [selectedWarehouseIds, setSelectedWarehouseIds] = React.useState<
    Array<string>
  >([]);
  //const [gridHeight, setGridHeight] = React.useState(DEFAULT_GRID_HEIGHT);
  const [inited, setInited] = React.useState(false);
  const [isLoadingProductList, setIsLoadingProductList] = React.useState(false);
  const [isSearchingProductList, setIsSearchingProductList] =
    React.useState(false);
  const [productSearchKeyword, setProductSearchKeyword] = React.useState('');
  const [productSearchType, setProductSearchType] = React.useState('SKU');
  //const [warehouseOptionValue, setWarehouseOptionValue] = React.useState('');
  //const [warehouseRawOptions, setWarehouseRawOptions] = React.useState<WarehouseOption[]>([]);
  const [paramLimit, setParamLimit] = React.useState(DEFAULT_LIST_ROW_NUM);
  const [paramSkip, setParamSkip] = React.useState(0);
  // const [paramSortBy /*setParamSortBy*/] = React.useState('ProductTitle');
  const [paramCalculateTotal /*setParamCalculateTotal*/] = React.useState(true);
  const [paramCount /*setParamCount*/] = React.useState(true);
  const [count, setCount] = React.useState(0);
  const [productList, setProductList] = React.useState<InventoryProductRow[]>(
    []
  );
  const [filteredData, setFilteredData] = React.useState<InventoryProductRow[]>(
    []
  );
  const [productStatus, setProductStatus] = React.useState<number>(0)
  const [productLoaded, setProductLoaded] = React.useState(false);
  const [totalSku, setTotalSku] = React.useState(0);
  const [totalLine, setTotalLine] = React.useState(0);
  const [totalQuantity, setTotalQuantity] = React.useState(0);
  //const [totalCost, setTotalCost] = React.useState(0);
  // maybe it is current selecting or editing product
  const [editingProduct, setEditingProduct] = React.useState<ProductRow>({
    id: 0,
    productId: '',
  });
  const [warehouseSelectBoxWidth, setWarehouseSelectBoxWidth] =
    React.useState(20);
  const [currentProduct, setCurrentProduct] =
    React.useState<InventoryProductRow>();
  const sortString = React.useRef('sku asc');
  const [qty, setQty] = useState<number>();
  // const [qtyValue, setQtyValue] = useState<number>();
  const [geLe, setGeLe] = useState<string>('le');
  const [editProductQuantityVisible, setEditProductQuantityVisible] =
    useState(false);
  // const [locationProductsVisible, setLocationProductsVisible] = useState(false);
  const [hideZero, setHideZero] = useState<boolean>(true);
  const [syncing, setSyncing] = useState<boolean>(false);
  const [auditModalVisible, setAuditModalVisible] = useState(false);
  const enableLOT = userProfiles.getProfileSettingValue("EnableLOT");
  const enableUOM = userProfiles.getProfileSettingValue("EnableUOM");
  const enableSnapShotSync = userProfiles.getProfileSettingValue("EnableInentorySync");

  /**
   * The screen size type.
   */
  const screens = Grid.useBreakpoint();
  const history = useHistory();

  const [productSKUDialogVisible, setProductSKUDialogVisible] =
    useState<boolean>(false);
  const [productCodeDialogVisible, setProductCodeDialogVisible] =
    useState<boolean>(false);
  const [productPartNumberDialogVisible, setProductPartNumberDialogVisible] =
    useState<boolean>(false);
  const [productDetailsDialogVisible, setProductDetailsDialogVisible] =
    useState<boolean>(false);

  const [editProductTitleDialogVisible, setProductTitleDialogVisible] =
    useState<boolean>(false);

  const [moveItemDialogVisible, setMoveItemDialogVisible] =
    useState<boolean>(false);

  // const [thumbnailDict, setThumbnailDict] = useState<{ [key: number]: string }>(
  //   {}
  // );

  const showProductSKUDialog = useCallback((visible: boolean) => {
    setProductSKUDialogVisible(visible);
  }, []);

  const showProductPartNumberDialog = useCallback((visible: boolean) => {
    setProductPartNumberDialogVisible(visible);
  }, []);

  const showProductCodeDialog = useCallback((visible: boolean) => {
    setProductCodeDialogVisible(visible);
  }, []);

  const showProductDetailsDialog = useCallback((visible: boolean) => {
    setProductDetailsDialogVisible(visible);
  }, []);
  const showProductTitleDialog = useCallback((visible: boolean) => {
    setProductTitleDialogVisible(visible);
  }, []);
  const showMoveItemDialog = useCallback((visible: boolean) => {
    setMoveItemDialogVisible(visible);
  }, []);

  /**
   * Clear the product search state data.
   * When switch the warehouse, it needs to set these search data to initial state.
   */
  const clearProductSearchData = () => {
    setProductSearchType('SKU');
    setProductSearchKeyword('');
    setIsSearchingProductList(false);
  };

  /**
   * Get the product filter. The filter is like sku cn 'xxx'.
   *
   * @return {string}
   */
  const getProductSearchFilter = (type: string, keyword: string) => {
    let filter = '';

    if (type && keyword) {
      filter =
        type === 'SKU'
          ? // ? `(SKU eq '${keyword}' or UPC eq '${keyword}' or FNSKU eq '${keyword}' or EAN eq '${keyword}')`
          ''
          : type === 'skuStartsWith'
            // : type === 'skuStartsWith'
            ? `SKU cn '${keyword}'`
            : `${type} cn '${keyword}'`;
    }

    return filter;
  };

  /**
   * Get the product thumbnail;
   */
  // const getProductThumbnail = async (productId: string) => {
  //   return await fetchProductThumbnailUrl(productId, THUMBNAIL_REQUEST_SIZE1);
  // };

  /**
   * Get the product list for selected warehouses.
   *
   * @param {number} warehouseId - the id of warehouse, note: 0 is all warehouses
   * @param {?string} filter - SQL filter
   */
  const getWarehouseProductList = React.useCallback(
    async (
      warehouseNum: number,
      warehouseId: string,
      skip: number,
      top: number,
      filter?: string,
      title?: string
    ) => {
      let query: StringKAnyVPair = {
        $skip: skip,
        $top: top,
        $sortBy: sortString.current,
        $calculateTotal: paramCalculateTotal,
        $count: paramCount,
      };

      if (!title && filter) {
        query.$filter = filter;
      }
      if (title) {
        query.title = title;
      }
      if (productSearchType === 'SKU' && productSearchKeyword) {
        query.uniqueCode = productSearchKeyword;
      }
      if (qty || qty === 0) {
        query.qty = geLe === 'le'
          ? `le ${qty}`
          : geLe === 'ge'
            ? `ge ${qty}`
            : `eq ${qty}`
      }
      if (productStatus > -1) {
        query.productStatus = productStatus;
      }
      if (
        [
          'skuStartsWith',
          'divisionCode',
          'departmentCode',
          'groupCode',
          'subGroupCode',
          'productTitleContains',
          'lotNumber',
        ].includes(productSearchType)
      ) {
        query[productSearchType] = productSearchKeyword;
        delete query.$filter;
      }

      query.hideZero = hideZero ? true : false;
      query.thumbnailSize = 50;

      let products;

      setIsLoadingProductList(true);
      try {
        products = await fetchWarehouseProductList(warehouseId, query);
        setIsLoadingProductList(false);
      } catch (e) {
        setIsLoadingProductList(false);
        return;
      }

      if (products && 'object' === typeof products) {
        // let thumbDict: { [key: number]: string } = {};
        setProductLoaded(true);
        //setTotalCost(products.totalCost);
        setTotalLine(products.count);
        setTotalQuantity(products.totalQuantity);
        setTotalSku(products.totalSku);
        // setThumbnailDict({});
        setCount(products.count);
        setProductList(
          products.data.map((e: any, i: number) => {
            let product = {
              id: i,
              code: 'code',
              warehouseId: warehouseId,
              sku: e.sku,
              upc: e.upc,
              title: e.productTitle,
              picture: e.pictureUrl,
              productTitle: e.productTitle,
              locationCode: e.locationCode,
              locationId: e.locationId,
              locationNum: e.locationNum,
              lotNumber: e.lotNumber,
              warehouseCode: e.warehouseCode,
              warehouseNum: e.warehouseNum,
              quantity: e.quantity,
              partNumber: e.partNumber,
              productNum: e.fulfillmentProductNum,
              productId: e.productId,
              divisionCode: e.divisionCode,
              departmentCode: e.departmentCode,
              groupCode: e.groupCode,
              subGroupCode: e.subGroupCode,
              thumbnailUrl: e.thumbnailUrl,
              productStatus: e.productStatus,
              uom: e.uom,
              uomBaseQty: e.uomBaseQty,
              uomRate: e.uomRate,
            };

            // if (
            //   !thumbDict[product.productNum] &&
            //   product.productNum > 0 &&
            //   product.productId
            // ) {
            //   getProductThumbnail(product.productId).then((e) => {
            //     if (e && 'string' === typeof e) {
            //       thumbDict[product.productNum] = e;
            //       setThumbnailDict({ ...thumbDict });
            //     }
            //   });
            //   thumbDict[product.productNum] = 'error';
            // }

            return product;
          })
        );
      }
    },
    [
      //getProductSearchFilter,
      //isSearchingProductList,
      // paramSkip,
      // paramTop,
      productSearchKeyword,
      productSearchType,
      productStatus,
      paramCalculateTotal,
      paramCount,
      geLe,
      qty,
      hideZero,
    ]
  );

  /**
   * Make the warehouse select box grid width.
   */
  const getWarehouseSearchBoxWidth = React.useCallback(() => {
    let width = 20;

    if (screens.lg) {
      width = 18;
    } else if (screens.md) {
      width = 11;
    } else if (screens.xs || screens.sm) {
      width = 11;
    }

    //setWarehouseSelectBoxWidth(width);
    return width;
  }, [screens]);

  /**
   * Search product list handler.
   */
  const handleSearchProduct = React.useCallback(async () => {
    if (isSearchingProductList) {
      return;
    }

    //console.log(searchStr, evt, evt.currentTarget.tagName);
    // if (!force && 'INPUT' === evt.currentTarget.tagName) {
    //   return;
    // }

    if (selectedWarehouseNums.length === 0) {
      message.error({
        content: 'Please select a warehouse.',
        duration: COMMON_ERR_MSG_DEFAULT_DISPLAY_DURATION,
      });
    }

    if (!productSearchType) {
      if (productSearchKeyword) {
        message.error({
          content: 'Please select a product type.',
          duration: COMMON_ERR_MSG_DEFAULT_DISPLAY_DURATION,
        });
        return;
      } else {
        getWarehouseProductList(
          selectedWarehouseNums[0],
          selectedWarehouseIds[0],
          0,
          paramLimit
        );
        return;
      }
    }

    let filter = getProductSearchFilter(
      productSearchType,
      productSearchKeyword
    );
    const title =
      productSearchType === 'ProductTitle' ? productSearchKeyword : '';
    setIsSearchingProductList(true);
    await getWarehouseProductList(
      selectedWarehouseNums[0],
      selectedWarehouseIds[0],
      0,
      paramLimit,
      filter,
      title
    );
    setIsSearchingProductList(false);
  }, [
    getWarehouseProductList,
    isSearchingProductList,
    paramLimit,
    productSearchType,
    selectedWarehouseNums,
    selectedWarehouseIds,
    productSearchKeyword,
  ]);

  /**
   * Handle warehouse selection.
   * The related state(selectedWarehouseIds) will be updated.
   * @param {number|Array<number>} If the warehouse is an array, it indicates that you can select more than one warehouse at a time.
   */
  const handleWarehouseCodeChange = (
    value: number | Array<number>,
    opt: any
  ) => {
    if ('number' === typeof value) {
      setSelectedWarehouseNums([value]);
      setSelectedWarehouseIds([opt.warehouseId]);
    } else {
      setSelectedWarehouseNums(value);
      setSelectedWarehouseIds(opt.warehouseId);
    }

    clearProductSearchData();
    // Note: if value is an array, the way of passing parameters needs to be changed
    //getWarehouseProductList(value as number, 0, 10);
  };

  /**
   * The callback for editing title of product.
   * Note: the properties of parameter product must be the same with type InventoryProductRow
   *
   * @param {number} id - the product id
   * @param {[key: string]: string} - some properties for the product
   */
  const onEditProduct = (id: number, product: StringKVPair) => {
    let list = [];

    for (let i = 0; i < productList.length; i++) {
      list.push(productList[i]);

      if (productList[i].productNum === id) {
        list[i] = {
          ...list[i],
          ...product,
        };
      }
    }

    setProductList(list);
  };

  /**
   * download all data
   */
  const downLoadAll = useCallback(
    async (SelectField, groupBy) => {
      try {
        const job = await exportBusinessFile(4, {
          FilterCondition: {
            warehouseId: selectedWarehouseIds[0],
            locationCode:
              productSearchType === 'LocationCode' && productSearchKeyword
                ? productSearchKeyword
                : undefined,
            skuStartsWith:
              productSearchType === 'skuStartsWith' && productSearchKeyword
                ? productSearchKeyword
                : undefined,
            productTitleContains:
              productSearchType === 'productTitleContains' && productSearchKeyword
                ? productSearchKeyword
                : undefined,
            divisionCode:
              productSearchType === 'divisionCode' && productSearchKeyword
                ? productSearchKeyword
                : undefined,
            departmentCode:
              productSearchType === 'departmentCode' && productSearchKeyword
                ? productSearchKeyword
                : undefined,
            groupCode:
              productSearchType === 'groupCode' && productSearchKeyword
                ? productSearchKeyword
                : undefined,
            subGroupCode:
              productSearchType === 'subGroupCode' && productSearchKeyword
                ? productSearchKeyword
                : undefined,
            lotNumber:
              productSearchType === 'lotNumber' && productSearchKeyword
                ? productSearchKeyword
                : undefined,
            uniqueCode:
              productSearchType === 'SKU' && productSearchKeyword
                ? productSearchKeyword
                : undefined,
            // qty:
            //   qty > 0 ? (geLe === 'le'
            //     ? `le ${qty}`
            //     : geLe === 'ge'
            //       ? `ge ${qty}`
            //       : `eq ${qty}`) : undefined,

            qty: (qty || qty === 0) ? geLe === 'le'
              ? `le ${qty}`
              : geLe === 'ge'
                ? `ge ${qty}`
                : `eq ${qty}` : undefined,
            productStatus: productStatus > -1 ? productStatus : undefined,
            hideZero: hideZero ? true : false,
          },
          SelectField,
          OrderByField: sortString.current,
          GroupByField: groupBy || '',
        });
        if (job.jobguid && job.systemfilename) {
          Modal.confirm({
            title: 'job has been generated',
            okText: 'Close',
            cancelText: 'Check now',
            content:
              'job in progress ,you can check the job status in the export menu on the left side later.',
            onOk() { },
            onCancel() {
              history.push('/export');
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    [
      geLe,
      selectedWarehouseIds,
      history,
      productSearchKeyword,
      productSearchType,
      productStatus,
      qty,
      hideZero,
    ]
  );

  const syncInventoryToErp = useCallback(async ()=>{
    try {
      setSyncing(true)
       const warehouseCode = getWarehouseCodeFromCache(selectedWarehouseNums[0])
      const res = await syncToErp({warehouseCode})
      setSyncing(false)
      if(res.isSuccess){
        message.success({content: formatMessagewithBr(res.message) || 'Sync Successfully'})
      }
    } catch (error) {
      setSyncing(false)
    }
  },[selectedWarehouseNums])

  /**
   * Refresh the product list. It is often used as a callback while modify a product.
   */
  const refreshProductList = async () => {
    if (selectedWarehouseNums.length === 0) {
      return;
    }

    let filter = getProductSearchFilter(
      productSearchType,
      productSearchKeyword
    );
    setIsLoadingProductList(true);
    await getWarehouseProductList(
      selectedWarehouseNums[0],
      selectedWarehouseIds[0],
      paramSkip,
      paramLimit,
      filter
    );
    setIsLoadingProductList(false);
  };

  const StatisticColumns = () => {
    const columns = [
      { label: 'Total SKUs', total: totalSku },
      { label: 'Total Lines', total: totalLine },
      { label: 'Total Quantity On Hand', total: totalQuantity },
      /*
      {
        label: "Total Cost",
        total: (
          <NumberFormat
            value={totalCost}
            decimalScale={2}
            fixedDecimalScale={true}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
          />
        ),
      },
      */
    ];

    return (
      <Space split="|" size="large">
        {columns.map((col, i) => (
          <CenterBox key={`statistic_col_${i}`}>
            <Label>
              {col.label}: <span className="label-grey">{col.total}</span>
            </Label>
          </CenterBox>
        ))}
      </Space>
    );
  };

  const onClickSkuCell = useCallback((data: StringKAnyVPair) => {
    const selObj = window.getSelection();
    let open = selObj && selObj.toString() ? false : true;

    if (open) {
      setEditingProduct({
        id: data.productNum,
        productId: data.productId,
      });
      showProductDetailsDialog(true);
    }
  }, [showProductDetailsDialog]);

  const columns = useMemo(() => {
    const cls = [
      {
        minWidth: 220,
        name: 'sku',
        header: 'SKU',
        // headerAlign: 'center' as 'center',
        defaultFlex: 1,
        userSelect: true,
        render: (value: ColumnRenderType) => {
          const { data } = value;

          return (
            <SpaceCell
              icon={
                <Tooltip
                  placement="top"
                  title="Copy"
                  trigger={['hover', 'click']}
                >
                  <CopyOutlined
                    onClick={() => {
                      copy(data.sku);
                      message.info(`"${data.sku}" has been copied to clipboard`);
                    }}
                    style={{ color: theme['@info-color'] }}
                  />
                </Tooltip>
              }
              iconWidth={DATAGRID_CELL_ICON_WIDTH1}
              onTextClick={() => onClickSkuCell(data)}
              text={data.sku}
              textIsButton
            />
          );
        },
      },
      {
        minWidth: 200,
        name: 'upc',
        header: 'UPC',
        // headerAlign: 'center' as 'center',
        defaultFlex: 1,
        userSelect: true,
        needToolTip: true,
        // render: (value: ColumnRenderType) => {
        //   const { data } = value;

        //   return (
        //     <Popover placement="topLeft" content={data.upc}>
        //       {data.upc}
        //     </Popover>
        //   );
        // },
      },
      {
        minWidth: 130,
        name: 'lotNumber',
        header: 'LOT#',
        defaultFlex: 1,
        lotColumn: true,
        userSelect: true,
      },
      {
        minWidth: 130,
        name: 'warehouseCode',
        header: 'Warehouse',
        // headerAlign: 'center' as 'center',
        defaultFlex: 1,
        userSelect: true,
      },
      {
        minWidth: 120,
        name: 'locationCode',
        header: 'Location',
        // headerAlign: 'center' as 'center',
        defaultFlex: 1,
        userSelect: true,
        render: (value: ColumnRenderType) => {
          const { data } = value;
          return (
            <CopyComponent value={data.locationCode}>
              <Text
                underline
                ellipsis={{ tooltip: data.locationCode }}
                style={{ cursor: 'pointer', width: `calc(100% - 14px)` }}
                onClick={() => {
                  setCurrentProduct(data);
                  setAuditModalVisible(true);
                }}
              >
                {data.locationCode}
              </Text>
            </CopyComponent>

            // <>
            //   <Tooltip
            //     placement="top"
            //     title={data.locationCode}
            //     // trigger={['hover', 'click']}
            //   >
            //     {data.locationCode}
            //   </Tooltip>

            //   <GridIconBox>
            //     <EditOutlined
            //       onClick={() => {
            //         setCurrentProduct(data);
            //         setLocationProductsVisible(true);
            //       }}
            //     />
            //   </GridIconBox>
            // </>
          );
        },
      },
      {
        minWidth: 120,
        name: 'zoneName',
        header: 'Zone',
        // headerAlign: 'center' as 'center',
        defaultFlex: 1,
        userSelect: true,
        needToolTip: true,
        // render: (value: ColumnRenderType) => {
        //   const { data } = value;
        //   return (
        //     <Tooltip
        //       placement="top"
        //       title={data.zoneName}
        //       // trigger={['hover', 'click']}
        //     >
        //       {data.zoneName}
        //     </Tooltip>
        //   );
        // },
      },
      {
        minWidth: 120,
        name: 'quantity',
        header: 'Quantity',
        // headerAlign: 'center' as 'center',
        defaultFlex: 1,
        type: 'number',
        sort: (a: number, b: number) => a - b,
        render: (value: ColumnRenderType) => {
          const { data } = value;
          return (
            <>
              {data.quantity}
              <GridIconBox>
                <EditOutlined
                  onClick={() => {
                    setCurrentProduct(data);
                    setEditProductQuantityVisible(true);
                  }}
                />
              </GridIconBox>
            </>
          );
        },
      },
      {
        minWidth: 130,
        name: 'uom',
        header: 'UOM',
        defaultFlex: 1,
        uomColumn: true,
        userSelect: true,
      },
      {
        minWidth: 130,
        name: 'uomRate',
        header: 'UOM Rate',
        defaultFlex: 1,
        uomColumn: true,
        userSelect: true,
      },
      {
        minWidth: 130,
        name: 'uomBaseQty',
        header: 'Base Qty',
        defaultFlex: 1,
        uomColumn: true,
        userSelect: true,
      },
      {
        minWidth: 200,
        name: 'divisionCode',
        header: 'Division Code',
        // headerAlign: 'center' as 'center',
        defaultFlex: 1,
        userSelect: true,
        needToolTip: true,
        // render: (value: ColumnRenderType) => {
        //   const { data } = value;
        //   return (
        //     <Popover placement="topLeft" content={data.divisionCode}>
        //       {data.divisionCode}
        //     </Popover>
        //   );
        // },
      },
      {
        minWidth: 270,
        name: 'productTitle',
        header: 'Product Name',
        defaultFlex: 1,
        userSelect: true,
        needToolTip: false,
      },
      {
        minWidth: 120,
        name: 'productStatus',
        header: 'Product Status',
        defaultFlex: 1,
        userSelect: true,
        renderEnum: {
          0: 'Active',
          1: 'Archive'
        },
        render: (row: any) => {
          const { data } = row;
          const map = {
            0: 'Active',
            1: 'Archive'
          }
          return (
            // @ts-ignore
            <CopyComponent value={map[data.productStatus]}>
              {/* @ts-ignore */}
              <Text>{map[data.productStatus]}</Text>
            </CopyComponent>
          )
        }
      },
      {
        minWidth: 200,
        name: 'departmentCode',
        header: 'Department Code',
        // headerAlign: 'center' as 'center',
        defaultFlex: 1,
        userSelect: true,
        needToolTip: true,
        // render: (value: ColumnRenderType) => {
        //   const { data } = value;

        //   return (
        //     <Popover placement="topLeft" content={data.departmentCode}>
        //       {data.departmentCode}
        //     </Popover>
        //   );
        // },
      },
      {
        minWidth: 200,
        name: 'groupCode',
        header: 'Group Code',
        // headerAlign: 'center' as 'center',
        defaultFlex: 1,
        userSelect: true,
        needToolTip: true,
        // render: (value: ColumnRenderType) => {
        //   const { data } = value;

        //   return (
        //     <Popover placement="topLeft" content={data.groupCode}>
        //       {data.groupCode}
        //     </Popover>
        //   );
        // },
      },
      {
        minWidth: 200,
        name: 'subGroupCode',
        header: 'Subgroup Code',
        // headerAlign: 'center' as 'center',
        defaultFlex: 1,
        userSelect: true,
        needToolTip: true,
      },
      {
        minWidth: 100,
        name: 'thumbnailUrl',
        header: 'Picture',
        showColumnMenuTool: false,
        groupBy: false,
        defaultFlex: 1,
        resizable: false,
        sortable: false,
        // maxWidth: 100,
        // minWidth: 100,
        textAlign: 'center',
        textVerticalAlign: 'middle',
        // renderHeader: () => 'Picture',
        render: (value: ColumnRenderType) => {
          const { data } = value;
          const { thumbnailUrl = '' } = data;
          if (thumbnailUrl) {
            return <Image
              width={32}
              height={32}
              preview={false}
              // src={thumbnailDict[data.productNum] || 'error'}
              src={data.thumbnailUrl}
              fallback={fallbackImage}
              style={{ cursor: 'pointer' }}
            />
          }
        },
      },
    ];
    let temp = [...cls]
    temp = enableLOT === '1' ? [...temp] : temp.filter(i => !i.lotColumn)
    temp = enableUOM === '1' ? [...temp] : temp.filter(i => !i.uomColumn)
    return temp;
  }, [enableLOT, enableUOM, onClickSkuCell])


  React.useEffect(() => {
    if (!inited) {
      /*let height = window.innerHeight - GRID_TOP_OFFSET - 6;

      height = height <= DEFAULT_GRID_HEIGHT ? DEFAULT_GRID_HEIGHT : height;

      setGridHeight(height - 36);*/
      setInited(true);
    }

    const width = getWarehouseSearchBoxWidth();

    if (width !== warehouseSelectBoxWidth) {
      setWarehouseSelectBoxWidth(width);
    }
  }, [getWarehouseSearchBoxWidth, inited, warehouseSelectBoxWidth]);

  // const formItemLayout = {
  //   labelCol: {
  //     xs: { span: 24 },
  //     sm: { span: 7 },
  //   },
  //   wrapperCol: {
  //     xs: { span: 24 },
  //     sm: { span: 20 },
  //   },
  // };
  return (
    <SearchListGridWrapper className="common-hcontent-box">
      <Row justify="end">
        <EditProductSKUDialog
          product={editingProduct}
          visible={productSKUDialogVisible}
          onHide={() => showProductSKUDialog(false)}
        />
        <EditProductCodeDialog
          product={editingProduct}
          visible={productCodeDialogVisible}
          onHide={() => showProductCodeDialog(false)}
        />
        <EditProductPartNumberDialog
          product={editingProduct}
          visible={productPartNumberDialogVisible}
          onHide={() => showProductPartNumberDialog(false)}
        />
        {editingProduct && productDetailsDialogVisible && (
          <ProductDetailsDialog
            productId={editingProduct.productId}
            visible={productDetailsDialogVisible}
            onHide={() => showProductDetailsDialog(false)}
          />
        )}
        {editProductTitleDialogVisible && (
          <EditProductTitleDialog
            product={editingProduct}
            visible={true}
            onSuccess={onEditProduct}
            onHide={() => showProductTitleDialog(false)}
          />
        )}
        {/* {currentProduct && locationProductsVisible && (
          <LocationProductsDialog
            visible
            onHide={() => setLocationProductsVisible(false)}
            product={currentProduct}
            warehouseId={selectedWarehouseIds[0]}
          />
        )} */}
        {currentProduct && auditModalVisible && (
          <AuditModal
            product={currentProduct}
            visible={true}
            onClose={() => setAuditModalVisible(false)}
          />
        )}
        {currentProduct && editProductQuantityVisible && (
          <EditProductQuantity
            onHide={() => setEditProductQuantityVisible(false)}
            onSuccess={() => {
              setEditProductQuantityVisible(false);
              refreshProductList();
            }}
            warehouseId={selectedWarehouseIds[0]}
            locationId={currentProduct.locationId}
            product={currentProduct}
            uomEnable={enableUOM}
            lotEnable={enableLOT}
          />
        )}
        {currentProduct && moveItemDialogVisible ? (
          <MoveItemDialog
            product={currentProduct}
            visible={moveItemDialogVisible}
            onSuccess={refreshProductList}
            onHide={() => showMoveItemDialog(false)}
          />
        ) : (
          ''
        )}
      </Row>
      <HeaderTitle breadcrumb={['Inventory', 'Search']}>
        <Space>
          {enableSnapShotSync === '1' && <Button loading={syncing} icon={<RedoOutlined />} onClick={()=>syncInventoryToErp()}>Sync Inventory to ERP</Button>}
        </Space>
      </HeaderTitle>
      <GreyCardWrapper>
        <Form>
          <Row>
            <Col>
              <Form.Item label={<FormLabel>Warehouse</FormLabel>}>
                <div style={{ paddingRight: 10, minWidth: 150 }}>
                  <WarehouseSelectBox
                    onChange={handleWarehouseCodeChange}
                    onlyNormal={false}
                  />
                </div>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item style={{ marginRight: '20px' }} label={<FormLabel>Qty</FormLabel>}>
                <Input
                  style={{ width: '150px' }}
                  value={qty}
                  onChange={(e) => {
                    e.persist();
                    if(e.target.value){
                      const iptVal = Number(e.target.value);
                      if(isNaN(iptVal)){
                        setQty(undefined)
                      } else {
                        setQty(iptVal)
                      }
                    } else {
                      setQty(undefined)
                    }

                  }}
                  type="number"
                  min={0}
                  addonBefore={
                    <Select
                      defaultValue="le"
                      dropdownMatchSelectWidth={false}
                      // style={{ width: 70 }}
                      onChange={(value: string) => setGeLe(value)}
                    >
                      <Select.Option value="le">{'<='}</Select.Option>
                      <Select.Option value="ge">{'>='}</Select.Option>
                      <Select.Option value="eq">{'='}</Select.Option>
                    </Select>
                  }
                />
              </Form.Item>
            </Col>

            <Col>
              <Form.Item
                labelCol={{ style: { width: '200px', display: 'flex', flexDirection: 'row' } }}
                label={
                  <Select
                    style={{ width: '200px', textAlign: 'left' }}
                    dropdownMatchSelectWidth={false}
                    disabled={selectedWarehouseNums.length === 0}
                    onChange={(val) => {
                      setProductSearchType(val as string);
                      setProductSearchKeyword('');
                    }}
                    value={productSearchType}
                  >
                    <Select.Option value="LocationCode">Location</Select.Option>
                    <Select.Option value="SKU">SKU / UPC / Code equals</Select.Option>
                    {/* <Select.Option value="SKUContains">
                      SKU starts with
                    </Select.Option> */}
                    <Select.Option value="skuStartsWith">
                      SKU starts with
                    </Select.Option>
                    {/* <Select.Option value="ProductTitle">
                      Title starts with
                    </Select.Option> */}
                    <Select.Option value="productTitleContains">
                      Product Name
                    </Select.Option>
                    <Select.Option value="divisionCode">
                      Division Code
                    </Select.Option>
                    <Select.Option value="departmentCode">
                      Department Code
                    </Select.Option>
                    <Select.Option value="groupCode">Group Code</Select.Option>
                    <Select.Option value="subGroupCode">
                      Subgroup Code
                    </Select.Option>
                    <Select.Option value="lotNumber">
                      LOT#
                    </Select.Option>
                  </Select>
                }
              >
                <Input
                  allowClear
                  disabled={selectedWarehouseNums.length === 0}
                  onChange={(val) => setProductSearchKeyword(val.target.value)}
                  onBlur={(val) =>
                    setProductSearchKeyword(val.target.value.trim())
                  }
                  placeholder=""
                  value={productSearchKeyword}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item labelCol={{ style: { width: '130px' } }} label={<FormLabel>Product Status</FormLabel>}>
                <Select
                  value={productStatus}
                  dropdownMatchSelectWidth={false}
                  onChange={(value: number) => setProductStatus(value)}
                >
                  <Select.Option value={-1}>All</Select.Option>
                  <Select.Option value={0}>Active</Select.Option>
                  <Select.Option value={1}>Archive</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Space>
                <Checkbox
                  style={{ marginLeft: '20px' }}
                  defaultChecked={true}
                  onChange={(e) => setHideZero(e.target.checked)}
                >
                  Hide Zero Quantity Item
                </Checkbox>
                <Button
                  type="primary"
                  onClick={() => {
                    setParamSkip(0)
                    handleSearchProduct();
                  }}
                  className="ant-search-btn"
                >
                  <SearchOutlined />
                  Search
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </GreyCardWrapper>
      {productLoaded ? (
        <Row justify="space-between" style={{ marginTop: REGULAR_SECTION_INNER_VERTICAL_SPACING }}>
          <Col>
            <TablesFilter
              dataSource={productList}
              setFilteredData={setFilteredData}
              columns={columns}
              inputProps={{ width: 220 }}
            />
          </Col>
          <Col>
            <StatisticColumns />
          </Col>
          <Col>
            <ExportCSV
              columns={columns.filter((column) => {
                if (
                  column.header === 'Action' ||
                  column.header === 'Picture' ||
                  !column.header
                ) {
                  return false;
                }
                return true;
              })}
              disabled={productList.length === 0}
              data={productList}
              formatColumns={{
                productStatus: {
                  0: 'Active',
                  1: 'Archive'
                }
              }}
              showGroupBy
              scope="products"
              downloadFromApi={(data, groupBy) => downLoadAll(data, groupBy)}
            />
          </Col>
        </Row>
      ) : (
        ''
      )}
      {productLoaded ? (<div className="grid-ctn">
        <DataGridPlus
          cacheKey={DATAGRID_CACHE_INVENTORY_SEARCH_COLS}
          showScrollButton
          columns={columns}
          dataSource={filteredData}
          idProperty="sku"
          autoWith={false}
          skip={paramSkip}
          limit={DEFAULT_LIST_ROW_NUM}
          loading={isLoadingProductList}
          pagination
          pageSizes={[10, 20, 50, 100, 200, 500]}
          count={count}
          onChange={(limit, skip) => {
            if (limit === paramLimit && skip === paramSkip) return;
            setParamLimit(limit);
            setParamSkip(skip);
            getWarehouseProductList(
              selectedWarehouseNums[0],
              selectedWarehouseIds[0],
              skip,
              limit,
              getProductSearchFilter(productSearchType, productSearchKeyword)
            );
          }}
          // onSortInfoChange={async (v: any) => {
          //   if (v) {
          //     if (v.dir === 1) {
          //       sortString.current = `${v.id} asc`;
          //       getWarehouseProductList(
          //         selectedWarehouseNums[0],
          //         selectedWarehouseIds[0],
          //         paramSkip,
          //         paramLimit,
          //         getProductSearchFilter(
          //           productSearchType,
          //           productSearchKeyword
          //         )
          //       );
          //     }
          //     if (v.dir === -1) {
          //       sortString.current = `${v.id} desc`;
          //       getWarehouseProductList(
          //         selectedWarehouseNums[0],
          //         selectedWarehouseIds[0],
          //         paramSkip,
          //         paramLimit,
          //         getProductSearchFilter(
          //           productSearchType,
          //           productSearchKeyword
          //         )
          //       );
          //     }
          //   } else {
          //     sortString.current = 'sku asc';
          //     getWarehouseProductList(
          //       selectedWarehouseNums[0],
          //       selectedWarehouseIds[0],
          //       paramSkip,
          //       paramLimit,
          //       getProductSearchFilter(productSearchType, productSearchKeyword)
          //     );
          //   }
          // }}
          //style={{ height: gridHeight }}
          style={{ height: 'auto', minHeight: 'none' }}
          {...({} as any)}
        />
      </div>) : // </Row>
        isLoadingProductList && (<div className="search-list-empty-space">
          <Row align="middle" justify="center" style={{ height: '100%' }}>
            <Loading color="#006dff" size={48} />
          </Row>
        </div>)
      }
    </SearchListGridWrapper>
  );
}
