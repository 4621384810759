import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Row,
  Button,
  Space,
  Form,
  Card,
  Input,
  Typography,
  DatePicker,
  Select,
  InputNumber,
  Spin,
  Modal,
} from 'antd';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { DiffOutlined, EditOutlined, UnorderedListOutlined, CheckCircleOutlined, } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import {
  DataGridPlus,
  ButtonIcon,
  message,
  HeaderTitle,
  SelectWarehouse,
  FormLabel,
  CopySpan,
  TablesFilter,
} from 'components/common';
// import SelectLocationDialog from 'components/common/SelectLocationPlus/SelectLocationDialog';
import { GridIconBox } from 'components/common/styles';
import { closeReturn, fetchReturns, getReturnLines, receiveReturn } from 'services/return';
import {
  // fetchWarehouseLocationList,
  // getWarehouseSpecial,
  getSpecialLocations,
  fetchWarehouseLocationList,
} from 'services/warehouse';
import ReceiveDialog from './ReceiveDialog';
// import ScanToReceiveDialog from './ScanToReceive';
import ScanToReceiveDialog from '../../Po/PoReceive2/ScanToReceive2'
import SelectPoDialog from './SelectPoDialog';
import SelectLocation from './SelectLocation';
import { getWarehouseIdFromCache, getWarehouseCodeFromCache, getProfileSettingValue } from 'utils';
// import ReceiveAllDialog from './ReceiveAllDialog';
// import DamageDialog from './DamageDialog';
// import StockDialog from './StockDialog';
import { useSelector, shallowEqual } from 'react-redux';
import DeviceSettingDialog from '../../Sales/PrintGeneratedPicklists/DeviceSettingDialog';
import { RootState } from 'reducers';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';
import { specialWarehouseInfo } from 'actions/adminActions';
import { userProfiles } from 'utils/auth';


const { Text } = Typography;
const { Search } = Input;
const statusEnum: { [key: string]: string } = {
  '0': 'Pending',
  '1': 'Processing',
  '2': 'Completed',
  '8': 'Abandoned'
};

const warehouseTypeEnum: { [key: string]: string } = {
  '2': 'Return-Resalable',
  '3': 'Return-Damage',
  '4': 'Return-Inspection',
};

type SpecialInfoItem = {
  databaseNum: number;
  locations: any[];
  warehouseCode: string;
  warehouseId: string;
  warehouseNum: number;
  warehouseType: number;
  defaultLocationNum?: number;
  defaultLocationCode?: string;
};

type SpecialInfoType = {
  2?: SpecialInfoItem;
  3?: SpecialInfoItem;
  4?: SpecialInfoItem;
};

type ColumnRenderType = {
  data: any;
  rowIndex: number;
};

type ReturnReiveProps = {
  isModal?: boolean;
  modalReturn?: any;
  showDetail?: Function;
  showComplete?: Function;
};
// eslint-disable-next-line
export default (props: ReturnReiveProps) => {
  const defaultWarehouseType = 4;
  const defaultLocationCode = 'General';
  const { isModal, modalReturn } = props;
  const whs = useSelector(
    (state: RootState) => state.admin.warehouses,
    shallowEqual
  );
  const cacheInfo = useSelector(
    (state: RootState) => state.admin.defaultSpecialWarehouseInfo,
    shallowEqual
  );
  const dispatch: Dispatch<any> = useDispatch();
  const hash = useLocation();
  const searchParams = new URLSearchParams(hash.search);
  const defaultSearch = searchParams.get('channelOrderId');
  const defaultWarehouseNum = searchParams.get('warehouseNum');
  const [selectReturnDialogVisible, setSelectReturnDialogVisible] =
    useState(false);
  const [receiveDialog, setReceiveDialog] = useState(false);
  const [scanToReceiveDialogVisible, setScanToReceiveDialogVisible] =
    useState(false);
  // const [damageDialogVisible, setDamageDialogVisible] = useState(false);
  // const [stockDialogVisible, setStockDialogVisible] = useState(false);
  const [returnList, setReturnList] = useState<any[]>([]);
  const [selectedReturn, setSelectedReturn] = useState<any>();
  const [lines, setLines] = useState<any[]>([]);
  const [initing, setIniting] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState<WarehouseRow | undefined>();
  const [loading, setLoading] = useState(false);
  const [receiptDate, setReceiptDate] = useState<moment.Moment>(moment());
  const [selectSearchType, setSelectSearchType] =
    useState<string>('channelOrderId');
  const [current, setCurrent] = useState<any>();
  const [selectLocationDialogVisible, setSelectLocationDialogVisible] =
    useState(false);
  // const [locations, setLocations] = useState<any[]>([]);
  const [receiveAllDialogVisible, setReceiveAllDialogVisible] =
    useState<boolean>(false);
  const [inited, setInited] = useState(false);
  const [specialInfo, setSpecialInfo] = useState<any>({
    2: undefined,
    3: undefined,
    4: undefined,
  });
  const [deviceDialogClosable, setDeviceDialogClosable] = React.useState(true);
  const [deviceSettingVisible, setDeviceSettingVisible] = React.useState(false);
  const autoComplete = userProfiles.getProfileSettingValue('ReturnAutoComplete');
  const searchRef = useRef('');
  /**
   * handle hide event
   */

  const initWarehouse = useCallback(()=>{
    if(isModal && modalReturn){
      const temp = whs.filter(i=> i.warehouseNum === modalReturn.warehouseNum)
      if(temp.length > 0){
        setSelectedWarehouse({
          ...temp[0],
          id: temp[0].warehouseNum || 0,
          code: temp[0].warehouseCode || '',
        })
      }
    } else if (defaultWarehouseNum) {
      const temp = whs.filter(i=> i.warehouseNum === Number(defaultWarehouseNum))
      if(temp.length > 0){
        setSelectedWarehouse({
          ...temp[0],
          id: temp[0].warehouseNum || 0,
          code: temp[0].warehouseCode || '',
        })
      }
    }
  },[whs, isModal, modalReturn, defaultWarehouseNum])

  useEffect(()=>{
    initWarehouse()
  },[initWarehouse])

  const columns = [
    {
      name: 'sku',
      header: 'SKU',
      minWidth: 220,
      showColumnMenuTool: true,
      userSelect: true,
      defaultFlex: 1,
      // render: (value: ColumnRenderType) => {
      //   const { data } = value;
      //   return (
      //     <CopySpan value={data.sku} />
      //     );
      // },
    },
    {
      name: 'upc',
      header: 'UPC',
      minWidth: 200,
      showColumnMenuTool: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'productTitle',
      header: 'Product Name',
      showColumnMenuTool: true,
      defaultFlex: 1,
      render: (value: ColumnRenderType) => {
        const { data } = value;
        return (
          <CopySpan value={data.productTitle || data.description} />
          );
      },
    },
    {
      name: 'returnQty',
      header: 'Claim Qty',
      showColumnMenuTool: true,
      defaultFlex: 1,
    },
    {
      name: 'receiveQty',
      header: 'Received Qty',
      showColumnMenuTool: true,
      sortable: false,
      defaultFlex: 1,
    },
    {
      name: 'receivingQty',
      header: 'Receiving Qty',
      sortable: false,
      showColumnMenuTool: true,
      defaultFlex: 1,
      render: (value: ColumnRenderType) => {
        const { data, rowIndex } = value;
        return (
          <InputNumber
            id={`grid_columns_receiving_qty_row_${rowIndex}`}
            value={data.receivingQty}
            min={0}
            max={data.returnQty - data.receiveQty}
            onChange={(qty) =>
              setLines((prev) => {
                const temp = prev;
                const index = prev.findIndex(
                  (item) =>
                    item.fulfillmentReturnLineNum ===
                    data.fulfillmentReturnLineNum
                );
                if (index > -1) {
                  temp[index] = {
                    ...temp[index],
                    receivingQty: qty,
                  };
                  return [...temp];
                } else {
                  return prev;
                }
              })
            }
          />
        );
      },
    },
    {
      name: 'receivingWarehouseCode',
      header: 'Warehouse',
      showColumnMenuTool: true,
      defaultFlex: 1,
      maxWidth: 200,
      minWidth: 200,
      render: (value: ColumnRenderType) => {
        const { data, rowIndex } = value;
        return (
          <>
            {data.receivingWarehouseCode || ''}
            <GridIconBox>
              <EditOutlined
                id={`grid_columns_warehouse_row_${rowIndex}`}
                onClick={() => {
                  setCurrent(data);
                  setSelectLocationDialogVisible(true);
                }}
              />
            </GridIconBox>
          </>
        );
      },
    },
    {
      name: 'receivingLocationCode',
      header: 'Location',
      showColumnMenuTool: true,
      defaultFlex: 1,
      maxWidth: 200,
      minWidth: 200,
      render: (value: ColumnRenderType) => {
        const { data, rowIndex } = value;
        return (
          <>
            {data.receivingLocationCode || ''}
            <GridIconBox>
              <EditOutlined
                id={`grid_columns_location_row_${rowIndex}`}
                onClick={() => {
                  setCurrent(data);
                  setSelectLocationDialogVisible(true);
                }}
              />
            </GridIconBox>
          </>
        );
      },
    },
    {
      name: 'receivingWarehouseType',
      header: 'Warehouse Type',
      showColumnMenuTool: true,
      defaultFlex: 1,
      maxWidth: 200,
      minWidth: 200,
      render: (value: ColumnRenderType) => {
        const { data } = value;
        return warehouseTypeEnum[`${data.receivingWarehouseType}`] || '';
      },
    },
    {
      name: 'notes',
      header: 'Note',
      showColumnMenuTool: true,
      defaultFlex: 1,
      // render: (value: ColumnRenderType) => {
      //   const { data } = value;
      //   return (
      //     <CopySpan value={data.notes} />
      //     );
      // },
    },
  ];

  const openDeviceSettingDialog = (config: StringKAnyVPair) => {
    setDeviceDialogClosable(
      typeof config.closable === 'boolean' ? config.closable : true
    );
    setDeviceSettingVisible(true);
  };

  React.useEffect(() => {
    if (
      !localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY) ||
      !getWarehouseCodeFromCache(
        Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
      )
    ) {
      openDeviceSettingDialog({ closable: false });
    }
  }, []);

  React.useEffect(() => {
    if (whs) {
      const exist = whs.filter(
        (e) =>
          e.id === Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) &&
          (e.warehouseType === 1 || e.warehouseType === 5)
      );
      if (exist.length === 0) {
        openDeviceSettingDialog({ closable: false });
      }
    }
  }, [whs]);

  const getTransferByValue = useCallback(
    async (poNumberSearchValue) => {
      if (
        !selectedWarehouse ||
        !selectedWarehouse.warehouseId ||
        !poNumberSearchValue
      )
        return;
      setInited(true);
      try {
        const queryparams = {
          $skip: 0,
          $top: 10,
          $sortBy: 'TransferNum desc',
          channelOrderId:
            selectSearchType === 'channelOrderId'
              ? poNumberSearchValue
              : undefined,
          returnNo:
            selectSearchType === 'returnNo' ? poNumberSearchValue : undefined,
          invoice:
            selectSearchType === 'invoiceNo' ? poNumberSearchValue : undefined,
          oper: 0,
        };
        const transferList = await fetchReturns(
          selectedWarehouse.warehouseId,
          queryparams,
        );
        if (transferList.data.length === 0) {
          return message.warning({ content: 'no data matched' });
        }
        setSelectedReturn(transferList.data[0]);
      } catch (err) {
        console.log(err);
      }
    },
    [selectedWarehouse, selectSearchType]
  );

  const getTransferList = useCallback(async () => {
    if (!selectedWarehouse || !selectedWarehouse.warehouseId) return;
    setInited(true);
    try {
      const queryparams = {
        $skip: 0,
        $top: 10000,
        $sortBy: '"transDate desc',
        oper: 0,
        status: 0,
      };
      const transferList = await fetchReturns(
        selectedWarehouse.warehouseId,
        queryparams
      );
      setReturnList(
        transferList.data.map((item: any) => {
          return { ...item, receivingQty: 0 };
        })
      );
      setSelectReturnDialogVisible(true);
    } catch (err) {
      console.log(err);
    }
  }, [selectedWarehouse]);

  const getPoLines = useCallback(
    async (returnNum: number) => {
      if (!selectedWarehouse || !selectedWarehouse.warehouseId) return;
      try {
        setLoading(true);
        const lines = await getReturnLines(
          selectedWarehouse.warehouseId,
          returnNum
        );
        setLoading(false);
        if (specialInfo[defaultWarehouseType]) {
          const temp = specialInfo[defaultWarehouseType].locations.filter(
            (i: any) => i.locationCode === defaultLocationCode
          );
          setLines(
            lines.map((item: any) => {
              return {
                ...item,
                receivingLocationCode:
                  specialInfo[defaultWarehouseType].defaultLocationCode ? specialInfo[defaultWarehouseType].defaultLocationCode : temp.length > 0 ? temp[0].locationCode : undefined,
                receivingLocationNum:
                specialInfo[defaultWarehouseType].defaultLocationNum ? specialInfo[defaultWarehouseType].defaultLocationNum: temp.length > 0 ? temp[0].locationNum : undefined,
                receivingWarehouseType:
                  defaultWarehouseType,
                receivingWarehouseNum:
                  specialInfo[defaultWarehouseType].warehouseNum,
                receivingWarehouseCode:
                  specialInfo[defaultWarehouseType].warehouseCode,
              };
            })
          );
        } else {
          setLines(
            lines.map((item: any) => {
              return {
                ...item,
                receivingLocationCode: undefined,
                receivingLocationNum: undefined,
                receivingWarehouseType: undefined,
                receivingWarehouseNum: undefined,
                receivingWarehouseCode: undefined,
              };
            })
          );
        }
      } catch (err) {
        setLoading(false);
      }
    },
    [selectedWarehouse, specialInfo]
  );

  /**
   * receive all event
   */
  const handelReceiveAll = async (value: any) => {
    // setLines((prev: any[]) => {
    //   const temp = prev.map((item) => {
    //     return {
    //       ...item,
    //       receivingLocationCode: value.locationCode,
    //       receivingLocationNum: value.locationNum,
    //       receivingWarehouseType: value.warehouseType,
    //       receivingWarehouseNum: value.warehouseNum,
    //       receivingWarehouseCode: value.warehouseCode,
    //       receivingQty: item.returnQty - item.receiveQty,
    //     };
    //   });
    //   return [...temp];
    // });
    setLoading(true);
    if (!selectedWarehouse || !selectedWarehouse.warehouseId) return;
    const newLines = lines.map((item) => {
      return {
        ...item,
        receivingLocationCode: value.locationCode,
        receivingLocationNum: value.locationNum,
        receivingWarehouseType: value.warehouseType,
        receivingWarehouseNum: value.warehouseNum,
        receivingWarehouseCode: value.warehouseCode,
        receivingQty: item.returnQty - item.receiveQty,
      };
    });
    setLines(newLines);
    const Items = newLines.filter((item) =>
      item.receivingLocationCode &&
      item.receivingQty > 0 &&
      item.receivingLocationNum
    ).map((item) => {
      return {
        FulfillmentReturnLineNum: item.fulfillmentReturnLineNum,
        ReceiveQty: item.receivingQty,
        ReceiveWarehouseType: item.receivingWarehouseType,
        ReceiveWarehouseNum: item.receivingWarehouseNum,
        ReceiveLocationNum: item.receivingLocationNum,
      };
    });
    try {
      const res = await receiveReturn(selectedWarehouse.warehouseId, selectedReturn.fulfillmentReturnNum, Items);
      setLoading(false);
      if (res) {
        message.success({ content: 'receive successful' });
        refreshReturn();
      } else {
        message.error({ content: 'receive failed' });
      }
    } catch (error: any) {
      // message.error({ content: error.response.data.error });
      setLoading(false);
    }
  }

  const handleScanToReceive = async (st: any) => {
    if (!Array.isArray(st)) return;
    const temp = [...lines];
    const skus = lines.map(item => item.sku);
    st.map((item) => {
      const i = skus.indexOf(item.sku);
      if (i > -1) {
        temp[i].receivingQty = temp[i].receivingQty
          ? item.quantity + (temp[i].receivingQty || 0)
          : item.quantity;
      }
      return true;
    })
    setLines(temp);
    setLoading(true);
    if (!selectedWarehouse || !selectedWarehouse.warehouseId) return;
    const Items = temp.filter((item) =>
      item.receivingLocationCode &&
      item.receivingQty > 0 &&
      item.receivingLocationNum
    ).map((item) => {
      return {
        FulfillmentReturnLineNum: item.fulfillmentReturnLineNum,
        ReceiveQty: item.receivingQty,
        ReceiveWarehouseType: item.receivingWarehouseType,
        ReceiveWarehouseNum: item.receivingWarehouseNum,
        ReceiveLocationNum: item.receivingLocationNum,
      };
    });
    try {
      const res = await receiveReturn(selectedWarehouse.warehouseId, selectedReturn.fulfillmentReturnNum, Items);
      setLoading(false);
      if (res) {
        message.success({ content: 'receive successful' });
        refreshReturn();
      } else {
        message.error({ content: 'receive failed' });
      }
    } catch (error: any) {
      // message.error({ content: error.response.data.error });
      setLoading(false);
    }
  }

  const getAllSpecialInfo = useCallback(async () => {
    if (
      cacheInfo &&
      cacheInfo.warehouseNum &&
      cacheInfo.warehouseNum === selectedWarehouse?.id
    ) {
      if (cacheInfo.specialWarehouseInfo) {
        let temp: SpecialInfoType = {
          2: undefined,
          3: undefined,
          4: undefined,
        };
        cacheInfo.specialWarehouseInfo.map((i: any) => {
          if (i.warehouseType === 2) {
            temp[2] = i;
          }
          if (i.warehouseType === 3) {
            temp[3] = i;
          }
          if (i.warehouseType === 4) {
            temp[4] = i;
          }
          return true;
        });
        if (!temp[defaultWarehouseType]) {
          message.info({
            content: `${warehouseTypeEnum[`${defaultWarehouseType}`]
              } is not found, please check whether you have permission or whether the warehouse exists`,
          });
        }
        setSpecialInfo(temp);
        if (isModal && modalReturn) {
          setSelectedReturn(modalReturn);
        }
      }
    } else {
      try {
        setIniting(true);
        const res = await getSpecialLocations(
          selectedWarehouse?.warehouseId || '',
          0
        );
        setIniting(false);
        if (res) {
          dispatch(
            specialWarehouseInfo({
              warehouseNum: selectedWarehouse?.id || 0,
              specialWarehouseInfo: res,
            })
          );
        }
      } catch (error) {
        console.log(error)
        setIniting(false);
      }
    }
  }, [selectedWarehouse, isModal, modalReturn, cacheInfo, dispatch]);

  const getAllSpecialInfo2 = useCallback(async()=>{
    try {
      if(!selectedWarehouse || !selectedWarehouse.id) return;
      const {databaseNum,inspectionLocationNum, inspectionWarehouseNum, resalableLocationNum, resalableWarehouseNum, damageLocationNum, damageWarehouseNum} = selectedWarehouse
      if(!inspectionLocationNum || !inspectionWarehouseNum || !resalableLocationNum || !resalableWarehouseNum || !damageLocationNum || !damageWarehouseNum) {
        getAllSpecialInfo()
      } else {
        setIniting(true)
        const location2 = await fetchWarehouseLocationList(`${databaseNum}-${resalableWarehouseNum}`, false)
        const location4 = await fetchWarehouseLocationList(`${databaseNum}-${inspectionWarehouseNum}`, false)
        const location3 = await fetchWarehouseLocationList(`${databaseNum}-${damageWarehouseNum}`, false)
        setIniting(false);
        let temp: SpecialInfoType = {
          2: undefined,
          3: undefined,
          4: undefined,
        };
        whs.map(i => {
          if(i.warehouseNum === resalableWarehouseNum){
            const idx = location2.findIndex(i => i.locationNum === resalableLocationNum)
            temp[2] = {
              databaseNum: i.databaseNum || 0,
              locations: [...location2],
              warehouseCode: i.warehouseCode || '',
              warehouseId: i.warehouseId || '',
              warehouseNum: i.warehouseNum,
              warehouseType: i.warehouseType || 0,
              defaultLocationNum: resalableLocationNum,
              defaultLocationCode: location2[idx].locationCode || '',
            }
          }
          if(i.warehouseNum === inspectionWarehouseNum){
            const idx = location4.findIndex(i => i.locationNum === inspectionLocationNum)
            temp[4] = {
              databaseNum: i.databaseNum || 0,
              locations: [...location4],
              warehouseCode: i.warehouseCode || '',
              warehouseId: i.warehouseId || '',
              warehouseNum: i.warehouseNum,
              warehouseType: i.warehouseType || 0,
              defaultLocationNum: inspectionLocationNum,
              defaultLocationCode: location4[idx].locationCode || '',
            }
          }
          if(i.warehouseNum === damageWarehouseNum){
            const idx = location3.findIndex(i => i.locationNum === damageLocationNum)
            temp[3] = {
              databaseNum: i.databaseNum || 0,
              locations: [...location3],
              warehouseCode: i.warehouseCode || '',
              warehouseId: i.warehouseId || '',
              warehouseNum: i.warehouseNum,
              warehouseType: i.warehouseType || 0,
              defaultLocationNum: damageLocationNum,
              defaultLocationCode: location3[idx].locationCode || '',
            }
          }
          return true;
        })
        if (!temp[2] || !temp[3] || !temp[4]) {
          message.info({
            content:
              'Warehouse is not found, please check whether you have permission or whether the warehouse exists',
          });
        }
        setSpecialInfo(temp);
        if (isModal && modalReturn) {
          setSelectedReturn(modalReturn);
        }
      }
      
    } catch (error) {
      console.log(error)
      setIniting(false);
    }
  },[selectedWarehouse, whs, getAllSpecialInfo, isModal, modalReturn])

  const getSetting = useCallback(async () => {
    const res = await getProfileSettingValue('EnableNormalWareshouseReceive','0');
    // setEnableNormal(res === '1');
    if(res === '1'){
      getAllSpecialInfo2()
    } else {
      getAllSpecialInfo()
    }
  }, [getAllSpecialInfo2, getAllSpecialInfo]);

  const handleReceive = async () => {
    setLoading(true);
    if (!selectedWarehouse || !selectedWarehouse.warehouseId) return;
    const Items = lines.filter((item) =>
      item.receivingLocationCode &&
      item.receivingQty > 0 &&
      item.receivingLocationNum
    ).map((item) => {
      return {
        FulfillmentReturnLineNum: item.fulfillmentReturnLineNum,
        ReceiveQty: item.receivingQty,
        ReceiveWarehouseType: item.receivingWarehouseType,
        ReceiveWarehouseNum: item.receivingWarehouseNum,
        ReceiveLocationNum: item.receivingLocationNum,
      };
    });
    try {
      const res = await receiveReturn(selectedWarehouse.warehouseId, selectedReturn.fulfillmentReturnNum, Items);
      setLoading(false);
      if (res) {
        message.success({ content: 'receive successful' });
        refreshReturn();
      } else {
        message.error({ content: 'receive failed' });
      }
    } catch (error: any) {
      // message.error({ content: error.response.data.error });
      setLoading(false);
    }
  }

  const refreshReturn = async () => {
    if (!selectedWarehouse || !selectedWarehouse.warehouseId) return;
    setLoading(true);
    try {
      const res = await fetchReturns(selectedWarehouse.warehouseId, {
        returnNo: selectedReturn?.returnNo,
        $top: 10,
        $skip: 0,
        $sortBy: 'FulfillmentReturnNum desc',
        oper: 0,
      });
      setLoading(false);
      if (res) {
        const { data = [] } = res;
        const target = data.find((item: any) => item.fulfillmentReturnNum === selectedReturn.fulfillmentReturnNum);
        setSelectedReturn(target);
      }
    } catch (error) {
      console.log('error = ', error)
    } finally {
      setLoading(true);
    }
  }

  useEffect(() => {
    if (selectedReturn && selectedReturn.fulfillmentReturnNum) {
      getPoLines(selectedReturn.fulfillmentReturnNum);
    }
  }, [selectedReturn, getPoLines]);

  useEffect(() => {
    if (
      selectedWarehouse &&
      selectedWarehouse.id ===
      Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
    ) {
      // getSpecialWarehouse();
      getSetting();
    }
  }, [selectedWarehouse, getSetting]);

  useEffect(() => {
    if (defaultSearch && !inited) {
      getTransferByValue(defaultSearch.trim());
    }
  }, [defaultSearch, getTransferByValue, inited]);

  const handleClose = async () => {
    try {
      setLoading(true);
      const res = await closeReturn(
        getWarehouseIdFromCache(selectedReturn.warehouseNum),
        selectedReturn.fulfillmentReturnNum
      );
      setLoading(false);
      if (res) {
        message.success({ content: 'completed successfully' });
        refreshReturn();
      }
    } catch (error) {
      setLoading(false);
    }
  }

  const showCompleteTips = useCallback(() => {
    Modal.confirm({
      closable: false,
      maskClosable: false,
      title: 'Item received, do you want to Complete this RMA?',
      icon: <CheckCircleOutlined />,
      content: '',
      okText: 'Back to detail',
      okButtonProps: {
        type: 'default',
      },
      cancelButtonProps: {
        danger: true,
        type: 'primary',
      },
      cancelText: 'Complete this RMA',
      onOk() {
        props.showDetail && props.showDetail(selectedReturn)
      },
      onCancel() {
        // props.showComplete && props.showComplete(selectedReturn)
        handleClose();
      },
    });
    // eslint-disable-next-line  
  }, [props, selectedReturn])

  useEffect(() => {
    if (selectedReturn && selectedReturn.status === 1 && autoComplete === '0' && isModal) {
      const canComplete = selectedReturn.items.filter((i: any) => i.receiveQty < i.returnQty).length === 0
      if (canComplete) {
        showCompleteTips()
      }
    }
  }, [
    selectedReturn, autoComplete, showCompleteTips, isModal
  ])

  return (
    <>
      {!isModal && (
        <HeaderTitle
          breadcrumb={[
            'Return',
            `RMA Receive  (Warehouse : ${getWarehouseCodeFromCache(
              Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
            )}）`,
          ]}
        />
      )}
      <Spin spinning={initing}>
        {!isModal && (
          <Card style={{ justifyContent: 'center' }}>
            <Row justify="center">
              <Space>
                <>
                  <FormLabel>Warehouse</FormLabel>
                  <SelectWarehouse
                    onlyNormal
                    id="form_warehouse_select"
                    disabled
                    value={selectedWarehouse}
                    width={200}
                    onChange={(warehouse) => {
                      setSelectedWarehouse(warehouse);
                      setSelectedReturn(undefined);
                      setLines([]);
                    }}
                  />
                </>
                <Input.Group compact style={{ width: 'auto', minWidth: 360 }}>
                  <Select
                    id="form_channel_order_select"
                    defaultValue="channelOrderId"
                    dropdownMatchSelectWidth={false}
                    onChange={(value) => setSelectSearchType(value)}
                    style={{ width: '45%' }}
                  >
                    <Select.Option value="channelOrderId">
                      Channel Order ID
                    </Select.Option>
                    <Select.Option value="returnNo">RMA#</Select.Option>
                    <Select.Option value="invoiceNo">Invoice#</Select.Option>
                    {/* <Select.Option value="sku">SKU</Select.Option> */}
                  </Select>
                  <Search
                    id="form_search_value_input"
                    style={{ width: '55%' }}
                    allowClear
                    defaultValue={defaultSearch ? defaultSearch : undefined}
                    loading={loading}
                    placeholder="Please input search value"
                    onKeyDown={(e) =>
                      e.keyCode === 9 && getTransferByValue(searchRef.current)
                    }
                    onChange={(e) => (searchRef.current = e.target.value)}
                    onSearch={(value) => {
                      getTransferByValue(value.trim());
                    }}
                  />
                </Input.Group>
                <Button
                  id="form_search_list__button"
                  onClick={() => getTransferList()}
                  icon={<UnorderedListOutlined />}
                >
                  Show Pending RMA
                </Button>
              </Space>
            </Row>
          </Card>
        )}
        <Card style={{ marginTop: isModal ? 0 : 20 }}>
          <Card>
            <Row justify="center" align="middle">
              <Space size={50}>
                <Form.Item label={<FormLabel>Channel Order ID</FormLabel>}>
                  <CopySpan value={selectedReturn?.channelOrderId} />
                </Form.Item>
                <Form.Item label={<FormLabel>RMA#</FormLabel>}>
                  <CopySpan value={selectedReturn?.returnNo} />
                </Form.Item>
                <Form.Item label={<FormLabel>Invoice#</FormLabel>}>
                  <CopySpan value={selectedReturn?.invoiceNo} />
                </Form.Item>
                <Form.Item label={<FormLabel>Status</FormLabel>}>
                  <Text
                    type={selectedReturn?.status === 2 ? 'danger' : 'success'}
                  >
                    {selectedReturn ? statusEnum[selectedReturn.status] : ''}
                  </Text>
                </Form.Item>
              </Space>
            </Row>

            <Row justify="center" align="middle">
              <Space>
                <FormLabel>Receipt Date</FormLabel>
                <DatePicker
                  id="form_receipt_data_date_picker"
                  allowClear={false}
                  style={{ marginLeft: 10 }}
                  defaultValue={moment()}
                  format="MM/DD/YYYY"
                  onChange={(date) => setReceiptDate(date || moment())}
                />
                <Button
                  id="form_receive_button"
                  type="primary"
                  disabled={
                    !selectedWarehouse ||
                    !selectedReturn ||
                    selectedReturn.status === 2 ||
                    lines.filter((item) =>
                      item.receivingLocationCode &&
                      item.receivingQty > 0 &&
                      item.receivingLocationNum
                    ).length <= 0
                  }
                  onClick={() => {
                    handleReceive();
                  }}
                  icon={<DiffOutlined />}
                >
                  Receive
                </Button>
                <Button
                  id="form_scan_to_receive_button"
                  disabled={!selectedReturn}
                  onClick={() => setScanToReceiveDialogVisible(true)}
                >
                  <ButtonIcon className="bi-upc-scan" />
                  Scan to Receive
                </Button>
                {/* <Button
            disabled={!selectedReturn}
            onClick={() => setReceiveToLotDialogVisible(true)}
          >
            Scan to Lot
          </Button> */}
              </Space>
            </Row>
          </Card>
          <Card
            title={
              <Row align="middle">
                <Space>
                  <Text>Return lines</Text>
                  {/* <Button
                disabled={!selectedReturn}
                onClick={() => setAddItemDialogVisible(true)}
              >
                Add Line Item
              </Button> */}
                  <TablesFilter
                    columns={columns}
                    dataSource={lines}
                    setFilteredData={setFilteredData}
                  />
                </Space>
              </Row>
            }
            extra={
              <Button.Group>
                <Button
                  id="form_receive_all_button"
                  disabled={!selectedWarehouse || lines.length === 0}
                  onClick={() => setReceiveAllDialogVisible(true)}
                  icon={<DiffOutlined />}
                >
                  Receive All
                </Button>
              </Button.Group>
            }
            bodyStyle={{ padding: 12 }}
          >
            {selectedWarehouse && (
              <DataGridPlus
                columns={columns}
                showScrollButton
                loading={loading}
                dataSource={filteredData}
                idProperty="fulfillmentReturnLineNum"
                checkboxOnlyRowSelect={true}
                showColumnMenuTool={false}
                {...({} as any)}
              />
            )}
          </Card>
        </Card>
      </Spin>
      {selectReturnDialogVisible && (
        <SelectPoDialog
          onHide={() => {
            setSelectReturnDialogVisible(false);
          }}
          onOk={(retrurn) => {
            setSelectReturnDialogVisible(false);
            if (retrurn.fulfillmentReturnNum) {
              setSelectedReturn(retrurn);
            }
          }}
          returnList={returnList}
        />
      )}

      {receiveDialog && selectedReturn && selectedWarehouse && (
        <ReceiveDialog
          warehouse={selectedWarehouse}
          lines={lines.filter(
            (item) =>
              item.receivingLocationCode &&
              item.receivingQty > 0 &&
              item.receivingLocationNum
          )}
          returnNum={selectedReturn.fulfillmentReturnNum}
          dateString={moment(receiptDate).toString()}
          onRefresh={() => {
            // getPoLines(selectedReturn.fulfillmentReturnNum);
            refreshReturn();
          }}
          onHide={() => setReceiveDialog(false)}
        />
      )}

      {selectedReturn && scanToReceiveDialogVisible && (
        <ScanToReceiveDialog
        targetStrs={lines.map((i) => {
          return {
            sku: i.sku,
            upc: i.upc || ''
          }
        })}
          // sourceItems={lines}
          onHide={() => setScanToReceiveDialogVisible(false)}
          onSuccess={(st) => {
            // if (Array.isArray(st)) {
            //   setLines((prev) => {
            //     const tmp = prev;
            //     const skus = prev.map((item) => item.sku);
            //     st.map((item, index) => {
            //       const i = skus.indexOf(item.sku);
            //       if (i > -1) {
            //         tmp[i].receivingQty = tmp[i].receivingQty
            //           ? item.quantity + (tmp[i].receivingQty || 0)
            //           : item.quantity;
            //       }
            //       return true;
            //     });
            //     return [...tmp];
            //   });
            // }
            handleScanToReceive(st);
            setScanToReceiveDialogVisible(false);
          }}
        />
      )}

      {selectLocationDialogVisible && current && (
        <SelectLocation
          defaultLocationCode={defaultLocationCode}
          defaultWarehouseType={defaultWarehouseType}
          specialInfo={specialInfo}
          onHide={() => {
            setSelectLocationDialogVisible(false);
            setCurrent(undefined);
          }}
          onOk={(value) => {
            setLines((prev) => {
              const temp = prev;
              const index = prev.findIndex(
                (item) =>
                  item.fulfillmentReturnLineNum ===
                  current.fulfillmentReturnLineNum
              );

              if (index > -1) {
                temp[index] = {
                  ...temp[index],
                  receivingLocationCode: value.locationCode,
                  receivingLocationNum: value.locationNum,
                  receivingWarehouseType: value.warehouseType,
                  receivingWarehouseNum: value.warehouseNum,
                  receivingWarehouseCode: value.warehouseCode,
                };
                return [...temp];
              } else {
                return temp;
              }
            });
            setSelectLocationDialogVisible(false);
            setCurrent(undefined);
          }}
        />
      )}

      {receiveAllDialogVisible && selectedWarehouse && (
        <SelectLocation
          defaultLocationCode={defaultLocationCode}
          defaultWarehouseType={defaultWarehouseType}
          specialInfo={specialInfo}
          onHide={() => setReceiveAllDialogVisible(false)}
          onOk={(value) => {
            setReceiveAllDialogVisible(false);
            handelReceiveAll(value);
          }}
        />
      )}
      {
        <DeviceSettingDialog
          closable={deviceDialogClosable}
          close={() => {
            setDeviceSettingVisible(false);
          }}
          visible={deviceSettingVisible}
        />
      }
    </>
  );
};
