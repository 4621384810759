import React from "react";

const Icon = ({ width = '1.8em', height = '1.8em', stroke = "white", fill = "currentColor", ...other }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill={fill}
      {...other}
    >
      <path d="M11.3423 11.2215H13.6359C13.7023 11.2215 13.7566 11.2758 13.7566 11.3422V13.6359C13.7566 13.7023 13.7023 13.7566 13.6359 13.7566H11.3423C11.2759 13.7566 11.2216 13.7023 11.2216 13.6359V11.3422C11.2216 11.2758 11.2759 11.2215 11.3423 11.2215Z" fill=""></path>
      <path d="M6.85337 6.73284H9.14695C9.21334 6.73284 9.26767 6.78716 9.26767 6.85356V9.14722C9.26767 9.21362 9.21334 9.26794 9.14695 9.26794H6.85337C6.78697 9.26794 6.73265 9.21362 6.73265 9.14722V6.85356C6.73265 6.78716 6.78697 6.73284 6.85337 6.73284Z" fill=""></path>
      <path d="M6.85337 11.2215H9.14695C9.21334 11.2215 9.26767 11.2758 9.26767 11.3422V13.6359C9.26767 13.7023 9.21334 13.7566 9.14695 13.7566H6.85337C6.78697 13.7566 6.73265 13.7023 6.73265 13.6359V11.3422C6.73265 11.2758 6.78697 11.2215 6.85337 11.2215Z" fill=""></path>
      <path d="M13.6359 6.73284H11.3423C11.2759 6.73284 11.2216 6.78716 11.2216 6.85356V9.14722C11.2216 9.21362 11.2759 9.26794 11.3423 9.26794H13.6359C13.7023 9.26794 13.7566 9.21362 13.7566 9.14722V6.85356C13.7566 6.78716 13.7023 6.73284 13.6359 6.73284Z" fill=""></path>
      <path d="M2.36486 11.2215H4.65844C4.72483 11.2215 4.77915 11.2758 4.77915 11.3422V13.6359C4.77915 13.7023 4.72483 13.7566 4.65844 13.7566H2.36486C2.29846 13.7566 2.24414 13.7023 2.24414 13.6359V11.3422C2.24414 11.2758 2.29846 11.2215 2.36486 11.2215Z" fill=""></path>
      <path d="M2.36486 6.73284H4.65844C4.72483 6.73284 4.77915 6.78716 4.77915 6.85356V9.14722C4.77915 9.21362 4.72483 9.26794 4.65844 9.26794H2.36486C2.29846 9.26794 2.24414 9.21362 2.24414 9.14722V6.85356C2.24414 6.78716 2.29846 6.73284 2.36486 6.73284Z" fill=""></path>
      <path d="M6.85337 2.24414H9.14695C9.21334 2.24414 9.26767 2.29846 9.26767 2.36486V4.65852C9.26767 4.72492 9.21334 4.77924 9.14695 4.77924H6.85337C6.78697 4.77924 6.73265 4.72492 6.73265 4.65852V2.36486C6.73265 2.29846 6.78697 2.24414 6.85337 2.24414Z" fill=""></path>
      <path d="M4.65844 2.24414H2.36486C2.29846 2.24414 2.24414 2.29846 2.24414 2.36486V4.65852C2.24414 4.72492 2.29846 4.77924 2.36486 4.77924H4.65844C4.72483 4.77924 4.77915 4.72492 4.77915 4.65852V2.36486C4.77915 2.29846 4.72483 2.24414 4.65844 2.24414Z" fill=""></path>
      <path d="M13.6359 2.24414H11.3423C11.2759 2.24414 11.2216 2.29846 11.2216 2.36486V4.65852C11.2216 4.72492 11.2759 4.77924 11.3423 4.77924H13.6359C13.7023 4.77924 13.7566 4.72492 13.7566 4.65852V2.36486C13.7566 2.29846 13.7023 2.24414 13.6359 2.24414Z" fill=""></path> 
    </svg>
  );
};

export default React.memo(Icon);
