import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Card,
  Space,
  Button,
  Row,
  Col,
  Dropdown,
  Menu,
  Modal,
  Typography,
  Empty,
  Spin,
  Descriptions,
  Input,
  Tooltip,
} from 'antd';
import {
  DiffOutlined,
  CloudDownloadOutlined,
  CaretDownOutlined,
  SettingOutlined,
  UpOutlined,
  DownOutlined,
  FilterOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import {
  CopySpan,
  FormLabel,
  message,
  DataGridPlus,
  CopyComponent,
  TablesFilter,
} from 'components/common';
import { convertTimeByUtc, formatAddress, formatNum, nowTime } from 'utils';
import {
  COMMON_PADDING_SPACE,
  WEIGHT_UNITS as weightUnitEnum,
  DIMENSION_UNITS_ABBR as unitEnum,
  SHIPMENT_CANCELLED_STATE,
  SHIPMENT_SHIPPING_STATE /* shipped status */,
} from 'constants/config';
import { postExportShipmentLabelPdf } from 'services/sales';
import {
  exportShipmentPackingSlip,
  fetchShipmentDetail,
  fetchShipmentHistoryDetail,
  setShippingLabelRelease,
  holdShipment,
  unholdShipment,
} from 'services/shipment';
import ExportLabelPDF from 'components/Sales/ShipmentList/LablePdf';
import OptionDialog from 'components/Sales/ScanVerifyPrint/ManageShipment/OptionDialog';
import debounce from 'lodash.debounce';
import CancelShipmentDialog from './CancelShipmentDialog';
import { MiniButton } from '../ScanVerifyPrint/styles';
import { userProfiles } from 'utils/auth';
import {
  shipmentStatusKeyValue as statusKeyValue,
  shopAroundStatusKeyValue,
} from 'constants/enums'
import ProductDetail from 'components/Inventory/ProductDetail/ProductDetailsDialog';
import BundleModal from 'components/Catalog/CatalogView/BundleModal';

const { Text } = Typography;
const labelWidth = 170;

const signatureKeyValue: { [key: string]: string } = {
  '-2': 'N/A',
  '0': 'Adult',
  '1': 'InDirect',
  '2': 'Signature',
  '3': 'No',
  '4': 'Adult Restrict',
};

/**
 * For the product, it includes these attributes:
 * [ ProductId, ProductTitle, SKU, styleCode, ]
 */
interface Props {
  shipmentNum: any;
  isHistory: boolean;
}

const ShipmentDetailDialog = (props: Props) => {
  const [shipmentInfo, setShipmentInfo] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [exportLablePdfVisible, setExportLablePdfVisible] = useState(false);
  const [cancelDialogVisible, setCancelDialogVisible] =
    useState<boolean>(false);
  const [optionDialogVisible, setOptionDialogVisible] = useState(false);
  const reason = React.useRef<string>('');
  const [init, setInit] = useState(true);
  const [isExporting, setIsExporting] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [filterList, setFilterList] = useState<any[]>([]);
  const [filterValue, setFilterValue] = useState<string>('');
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [detailVisible, setDetailVisible] = React.useState(false);
  const [current, setCurrent] = React.useState<any>();
  const hadChanged = useRef(false);
  const [expandedNodes, setExpandedNodes] = useState<any>({});
  const [lineItems, setLineItems] = useState<any[]>([]);
  const [bundleModalVisible, setBundleModalVisible] = useState(false);


  const enableUOM = userProfiles.getProfileSettingValue('EnableUOM') === '1';
  const enableLOT = userProfiles.getProfileSettingValue('EnableLOT') === '1';

  const debounceFn = debounce((val: string) => {
    setFilterValue(val);
  }, 300);

  const getLotStr = (arr:any[]) => {
    const temp:string[] = []
    arr.map((i:any) =>{
      // str = `${str}${i.lotNumber}(${i.quantity}), `
      if(i.lotNumber){
        temp.push(`${i.lotNumber}(${i.quantity})`)
      }
      return true
    })
    return temp.length > 0 ?  temp.join(', '): '';
  }

  const getItemColumns = (shipped: boolean) => [
    {
      name: 'sku',
      header: 'SKU',
      minWidth: 220,
      defaultFlex: 1,
      userSelect: true,
      sortable: true,
      render: (row: any) => {
        const { data } = row;
        return data.isbundle ? (
          <CopyComponent value={data.sku}>
            <Typography.Link
              onClick={() => {
                setCurrent({
                  productId: data.bundleProductId,
                  sku: data.sku,
                  upc: data.upc,
                  productTitle: data.productTitle,
                });
                setBundleModalVisible(true);
              }}
            >
              {data.sku}
            </Typography.Link>
          </CopyComponent>
        ) : (
          <CopyComponent value={data.sku}>
            <Typography.Link
              onClick={() => {
                setCurrent({
                  productId: `${data.databaseNum}-${data.fulfillmentProductNum}`,
                  sku: data.sku,
                  upc: data.upc,
                  productTitle: data.productTitle,
                });
                setDetailVisible(true);
              }}
            >
              {data.sku}
            </Typography.Link>
          </CopyComponent>
        );
      },
    },
    {
      name: 'upc',
      header: 'UPC',
      defaultFlex: 1,
      userSelect: true,
      sortable: true,
    },
    {
      name: 'lotNumber',
      header: 'LOT#',
      defaultFlex: 1,
      userSelect: true,
      sortable: true,
      visible: enableLOT,
      render: (value: any) => {
        const { data } = value;
        return shipped ? getLotStr(data.items || []) : '';
      },
    },
    {
      name: 'itemTitle',
      header: 'Product Name',
      userSelect: true,
      defaultFlex: 1,
      sortable: true,
    },
    {
      name: 'shippingQty',
      header: 'Quantity',
      defaultFlex: 1,
    },
    {
      name: 'uom',
      header: 'UOM',
      defaultFlex: 1,
      userSelect: true,
      sortable: true,
      visible: enableUOM,
    },
    {
      name: 'uomRate',
      header: 'Rate',
      defaultFlex: 1,
      visible: enableUOM,
    },
    {
      name: 'uomBaseQty',
      header: 'Base Qty',
      defaultFlex: 1,
      visible: enableUOM,
    },
  ];

  const getInfo = useCallback(async () => {
    try {
      setLoading(true);
      const res = props.isHistory
        ? await fetchShipmentHistoryDetail(props.shipmentNum)
        : await fetchShipmentDetail(props.shipmentNum);
      setLoading(false);
      setShipmentInfo(res);
      groupFormatItems(res.items || []);
      setInit(false);
    } catch (error) {
      setLoading(false);
    }
  }, [props.shipmentNum, props.isHistory]);

  const allowRelease = useCallback(async () => {
    try {
      setLoading(true);
      const res = await setShippingLabelRelease(shipmentInfo?.shipmentNum);
      setLoading(false);
      if (res) {
        message.success({
          content: 'Allow shipping label release successfully',
        });
        getInfo();
      }
    } catch (error) {
      setLoading(false);
    }
  }, [shipmentInfo, getInfo]);

  const list = useMemo(() => {
    const arr = [
      {
        label: 'Shipment ID',
        render: <CopySpan value={shipmentInfo?.shipmentId} />,
      },
      {
        label: 'Channel Order ID',
        render: <CopySpan value={shipmentInfo?.channelOrderId} />,
      },
      {
        label: 'Order Date',
        render: (
          <CopySpan
            value={
              shipmentInfo?.orderDate
                ? convertTimeByUtc(shipmentInfo?.orderDate)
                : ''
            }
          />
        ),
      },
      {
        label: 'Store Name',
        render: <CopySpan value={shipmentInfo?.channelAccountName} />,
      },
      {
        label: 'Channel Name',
        render: <CopySpan value={shipmentInfo?.channelName} />,
      },
      {
        label: 'Status',
        render: (
          <Typography.Text type={[8, 16, 32].includes(shipmentInfo?.shippingStatus) ? 'danger' : 'success'}>
            <CopySpan
              value={statusKeyValue[`${shipmentInfo?.shippingStatus}`]}
            />
          </Typography.Text>
        ),
      },
      {
        label: 'Update Date',
        render: <CopySpan value={shipmentInfo?.updateDate && convertTimeByUtc(shipmentInfo?.updateDate)} />,
      },
      {
        label: 'Update By',
        render: <CopySpan value={shipmentInfo?.updateBy} />,
      },
      {
        label: 'Ship Date',
        render: (
          <CopySpan
            value={
              shipmentInfo?.actualShipDate
                ? convertTimeByUtc(shipmentInfo?.actualShipDate)
                : ''
            }
          />
        ),
      },
      {
        label: 'Shipped by',
        render: <CopySpan value={shipmentInfo?.shippedBy} />,
      },
      {
        label: 'Fulfillment Order ID',
        render: <CopySpan value={shipmentInfo?.fulfillmentOrderId} />,
      },
      {
        label: '2nd Channel Order ID',
        render: <CopySpan value={shipmentInfo?.secondaryChannelOrderId} />,
      },
      {
        label: 'Seller Order ID',
        render: <CopySpan value={shipmentInfo?.sellerOrderId} />,
      },
      {
        label: 'In Stock',
        render: <CopySpan value={shipmentInfo?.stock} />,
      },
      {
        label: 'Ship Qty',
        render: <CopySpan value={shipmentInfo?.shippingQty} />,
      },
      {
        label: 'Weight',
        render: (
          <CopySpan
            value={
              shipmentInfo?.weight
                ? `${shipmentInfo?.weight} ${weightUnitEnum[shipmentInfo?.weightUnit || 0]
                }`
                : ''
            }
          />
        ),
      },
      {
        label: 'Package',
        render: <CopySpan value={shipmentInfo?.packageName} />,
      },
      {
        label: 'Size',
        render: (
          <CopySpan
            value={`${shipmentInfo?.length || 0} * ${shipmentInfo?.width || 0
              } * ${shipmentInfo?.height || 0}${unitEnum[shipmentInfo?.lengthUnit || 0]
              } (L*W*H)`}
          />
        ),
      },
      {
        label: 'Enter Date',
        render: (
          <CopySpan
            value={
              shipmentInfo?.createDate
                ? convertTimeByUtc(shipmentInfo?.createDate)
                : ''
            }
          />
        ),
      },
      {
        label: 'Carrier',
        render: <CopySpan value={shipmentInfo?.shippingCarrier} />,
      },
      {
        label: 'Service',
        render: <CopySpan value={shipmentInfo?.shippingService} />,
      },
      {
        label: 'Tracking #',
        render: <CopySpan value={shipmentInfo?.trackingNumber} />,
      },
      {
        label: 'Recipient',
        render: <CopySpan value={shipmentInfo?.shipToName} />,
      },
      {
        label: 'Shopping Around Status',
        // render: (
        //   <CopySpan
        //     value={
        //       shopAroundStatusKeyValue[`${shipmentInfo?.shopAroundStatus}`]
        //     }
        //   />
        // ),
        render: (
          <Text>
            <CopyComponent
              value={
                shopAroundStatusKeyValue[`${shipmentInfo?.shopAroundStatus}`]
              }
            >
              <Text>
                {shipmentInfo
                  ? shopAroundStatusKeyValue[`${shipmentInfo.shopAroundStatus}`]
                  : ''}
                {shipmentInfo?.shopAroundError && (
                  <Tooltip
                    placement="top"
                    title={`${shipmentInfo?.shopAroundErrorCarrier}: ${shipmentInfo?.shopAroundError}` || ''}
                  >
                    <MiniButton
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        Modal.error({
                          title: shipmentInfo?.shopAroundErrorCarrier || 'Error',
                          content: (
                            <Text copyable>
                              {shipmentInfo?.shopAroundError}
                            </Text>
                          ),
                        });
                      }}
                    >
                      View
                    </MiniButton>
                  </Tooltip>
                )}
              </Text>
            </CopyComponent>
          </Text>
        ),
      },
      {
        label: 'Shopping Around Count',
        render: <CopySpan value={shipmentInfo?.shopAroundCount} />,
      },
      {
        label: 'Require Shopping Around',
        render: (
          <CopySpan
            value={
              shipmentInfo?.shopAroundRequired ? 'Required' : 'Not Required'
            }
          />
        ),
      },
      {
        label: 'Ship Cost',
        render: (
          <CopySpan
            value={
              shipmentInfo?.totalShippingCost
                ? `${formatNum(shipmentInfo?.totalShippingCost)}`
                : ''
            }
          />
        ),
      },
      {
        label: 'Signature',
        render: (
          <CopySpan
            value={signatureKeyValue[`${shipmentInfo?.shippingService}`]}
          />
        ),
      },
      {
        label: 'B2T',
        render: (
          <CopySpan value={shipmentInfo?.shipFromType === 2 ? 'Yes' : ''} />
        ),
      },
      {
        label: 'Verified By',
        render: <CopySpan value={shipmentInfo?.smartVerifyUser} />,
      },
      {
        label: 'Verified Date',
        render: (
          <CopySpan
            value={
              shipmentInfo?.smartVerifyDate
                ? convertTimeByUtc(shipmentInfo?.smartVerifyDate)
                : ''
            }
          />
        ),
      },
      {
        label: 'Trace ID',
        render: <CopySpan value={shipmentInfo?.traceId} />,
      },
      {
        label: 'Declared Value',
        render: (
          <CopySpan
            value={
              shipmentInfo?.insuredValue || shipmentInfo?.insuredValue === 0
                ? `$${formatNum(shipmentInfo?.insuredValue)}`
                : ''
            }
          />
        ),
      },
      {
        label: 'Shipping Label Release',
        render: (
          <CopyComponent
            value={
              shipmentInfo?.shippingLabelRelease === 1 ? 'Allow' : 'Not Allow'
            }
          >
            <Typography.Text>
              {shipmentInfo?.shippingLabelRelease === 1 ? 'Allow' : 'Not Allow'}
            </Typography.Text>
            {shipmentInfo?.shippingLabelRelease === 0 &&
              shipmentInfo?.shippingStatus === 1 &&
              shipmentInfo?.shippingLabelRelease === 0 && (
                <Button
                  size="small"
                  style={{ marginLeft: 4 }}
                  type="default"
                  onClick={() => {
                    Modal.confirm({
                      title:
                        'Are you sure you want to allow shipping label release?',
                      content: '',
                      okText: 'Yes',
                      okType: 'default',
                      // okButtonProps: { className: 'hover-danger-button' },
                      cancelText: 'No',
                      autoFocusButton: 'cancel',
                      onCancel() { },
                      onOk() {
                        allowRelease();
                      },
                    });
                  }}
                >
                  Release
                </Button>
              )}
          </CopyComponent>
        ),
      },
      {
        label: 'Shipping Label Dispensed',
        render: (
          <CopySpan
            value={
              shipmentInfo?.shippingLabelDispensed === 1
                ? 'Released'
                : 'Not Released'
            }
          />
        ),
      },
      // {
      //   label: 'Customer # / Name',
      //   render: (
      //     <CopySpan
      //       value={
      //         shipmentInfo
      //           ? shipmentInfo.customerCode
      //             ? shipmentInfo.customerName
      //               ? `${shipmentInfo.customerCode} / ${shipmentInfo.customerName}`
      //               : shipmentInfo.customerCode
      //             : ''
      //           : ''
      //       }
      //     />
      //   ),
      // }
      {
        label: 'Customer Code',
        render: (
          <CopySpan
            value={shipmentInfo?.customerCode || ''}
          />
        ),
      },
      {
        label: 'Customer Name',
        render: (
          <CopySpan
            value={shipmentInfo?.customerName || ''}
          />
        ),
      },
    ];
    // if pending\held\cancelled, should show the reason under the status
    if ([0, 8, 16].includes(shipmentInfo?.shippingStatus)) {
      const REASON_ENUM: { [key: number]: string } = {
        0: 'unHoldReason',
        8: 'holdReason',
        16: 'cancelReason',
      };
      const { shippingStatus: ss } = shipmentInfo;
      const isPending = ss === 0;
      // pending reason equals unhold reason
      const label = `${isPending ? 'Unhold' : statusKeyValue[ss]} Reason`
      const reasonAttr = REASON_ENUM[ss];
      arr.splice(8, 0, {
        label,
        render: (
          <Typography.Text type={isPending ? 'success' : 'danger'}>
            <CopySpan value={shipmentInfo[reasonAttr]} />
          </Typography.Text>
        )
      })
    }
    if (shipmentInfo && shipmentInfo.erpSyncInfo !== null) {
      arr.push({
        label: 'Erp Sync status',
        render: (
          <CopyComponent value={shipmentInfo.erpSyncInfo.syncStatus || ''} >
            <Text>
              <Row align="middle" gutter={5}>
                <Col>{shipmentInfo.erpSyncInfo.syncStatus}</Col>
                {
                  shipmentInfo.erpSyncInfo.syncStatus === 'Failed' && shipmentInfo.erpSyncInfo.message && (
                    <Col>
                      <Tooltip
                        placement="top"
                        trigger={['trigger', 'focus', 'click']}
                        title={`Failed reson: ${shipmentInfo.erpSyncInfo.message}`}
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Col>
                  )
                }
              </Row>
            </Text>
          </CopyComponent>
        )
      })
    }
    if (shipmentInfo && shipmentInfo.threeWarehouseSyncInfo !== null) {
      arr.push({
        label: '3PL sync status',
        render: (
          <CopyComponent value={shipmentInfo.threeWarehouseSyncInfo.syncStatus || ''} >
            <Text>
              <Row align="middle" gutter={5}>
                <Col>{shipmentInfo.threeWarehouseSyncInfo.syncStatus}</Col>
                {
                  shipmentInfo.threeWarehouseSyncInfo.syncStatus === 'Failed' && shipmentInfo.threeWarehouseSyncInfo.message && (
                    <Col>
                      <Tooltip
                        placement="top"
                        trigger={['trigger', 'focus', 'click']}
                        title={`Failed reson: ${shipmentInfo.threeWarehouseSyncInfo.message}`}
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Col>
                  )
                }
              </Row>
            </Text>
          </CopyComponent>
        )
      })
    }
    return arr;
  }, [shipmentInfo, allowRelease]);

  const handleUnholdShipment = async () => {
    setLoading(true);
    try {
      const res = await unholdShipment(shipmentInfo?.shipmentNum, reason.current);
      reason.current = '';
      if (res.isSuccess) {
        message.success({ content: 'Unhold successfully' });
        getInfo();
        hadChanged.current = true;
      }
    } catch (error) {
      reason.current = '';
    } finally {
      setLoading(false);
    }
  };

  const handleHoldShipment = async () => {
    setLoading(true);
    try {
      const res = await holdShipment(shipmentInfo?.shipmentNum, reason.current);
      reason.current = '';
      if (res.isSuccess) {
        message.success({ content: 'Hold successfully' });
        getInfo();
        hadChanged.current = true;
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleOpenOptionDialog = (evt: any) => {
    setOptionDialogVisible(true);
    evt.stopPropagation();
  };

  const isCancelNotAvaliable = (shipment: any) => {
    return (
      shipment.shippingStatus === SHIPMENT_SHIPPING_STATE ||
      shipment.shippingStatus === SHIPMENT_CANCELLED_STATE
    );
  };

  const isShipmentEditable = (shipment: any) => {
    return shipment.shippingStatus === SHIPMENT_SHIPPING_STATE;
  };

  const handleExportShippingLabel = async (data: any) => {
    if (data.shippingStatus === 1) {
      try {
        const params = {
          isBatch: false,
          ShipmentNum: data.shipmentNum,
          ShippingAccountNum: data.shippingAccountNum,
          ShipFrom: data.warehouseNum,
          //ShipFrom: warehouseId,
          //ShipFrom: 10032,
          PrinterAccountNum: 0,
          PrinterNum: 0,
          PrinterName: '0',
          Carrier: data.shippingCarrier,
          CarrierNum: data.shipCarrierNum,
          Confirmation: data.signatureRequired,
          Service: data.shippingService,
          PackageName: data.packageName,
          PackageType: data.packageCode,
          ShipPackageNum: data.packageNum,
          ShipServiceNum: data.shipServiceNum,
          Height: data.height,
          Length: data.length,
          Weight: data.weight,
          Width: data.width,
          MasterAccountNum: data.masterAccountNum,
          ProfileNum: data.profileNum,
          InsuredValue: data.insuredValue,
          BillToType: data.shipFromType,
          BillThirdPartyAccount: data.billThirdPartyAccount,
          BillThirdPartyCountry: data.billThirdPartyCountry,
          BillThirdPartyPostalCode: data.billThrdPartyPostalcode,
          CN22: data.cN22
        };
        const res = await postExportShipmentLabelPdf(params);
        // const content = res;
        // const blob = new Blob([content], { type: 'application/pdf' });
        // const fileName = `Shipment#${data.shipmentNum}.pdf`;
        const fileType = res.headers[`content-type`];
        const content = res.data;
        const blob = new Blob([content], { type: fileType });
        const fileName =
          fileType === 'application/pdf'
            ? `Shipment-${data.shipmentNum}.pdf`
            : `Shipment-${data.shipmentNum}.png`;

        if ('download' in document.createElement('a')) {
          const elink = document.createElement('a');
          elink.download = fileName;
          elink.style.display = 'none';
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href);
          document.body.removeChild(elink);
        }
      } catch (error) {
        console.log('error = ', error);
      }
    } else {
      message.error({
        content: `shipment status(${statusKeyValue[data.shippingStatus]
          }) cann't be export.`,
      });
    }
  };

  const handleExportPackingSlip = async (slip: StringKAnyVPair) => {
    try {
      setIsExporting(true);
      const res = await exportShipmentPackingSlip(
        slip.fulfillmentOrderNum,
        slip.shipmentNum,
        nowTime()
      );
      setIsExporting(false);
      const pdfData = new Blob([res], { type: 'application/pdf' });
      const fileURL = window.URL.createObjectURL(pdfData);

      window.open(fileURL, 'preview.pdf');
    } catch (e) {
      setIsExporting(false);
      console.log(`export packing slip error ${e}`);
    }
  };

  const groupFormatItems = (items: any[]) => {
    const arr1: any[] = [];
    const groupArr: any[] = [];
    const expands: any = {};
    items.map((i: any) => {
      if (i.bundleType === 1) {
        const index = arr1.indexOf(i.bundleItemUuid);
        if (index > -1) {
          groupArr[index].nodes.push({
            ...i,
            id: i.fulfillmentOrderLineNum,
            orderQty1: `${i.orderQty} (${i.bundleQty}x${i.orderQty / i.bundleQty
              })`,
          });
        } else {
          arr1.push(i.bundleItemUuid);
          expands[`${i.bundleProductNum}`] = true;
          groupArr.push({
            id: i.bundleItemUuid,
            folder: true,
            sku: i.bundleSKU,
            bundleItemUuid: i.bundleItemUuid,
            upc: i.bundleUPC,
            isbundle: i.bundleQty,
            itemTitle: i.bundleTitle,
            productTitle: i.bundleTitle,
            bundleProductId: i.bundleProductId,
            orderQty1: i.bundleQty,
            nodes: [
              {
                ...i,
                id: i.fulfillmentOrderLineNum,
                orderQty1: `${i.orderQty} (${i.bundleQty}x${i.orderQty / i.bundleQty
                  })`,
              },
            ],
          });
        }
      } else {
        arr1.push(i.sku);
        groupArr.push({
          ...i,
          id: i.fulfillmentOrderLineNum,
          isbundle: '',
          orderQty1: i.orderQty,
        });
      }
      return true;
    });
    setExpandedNodes(expands);
    setLineItems(groupArr);
  };

  useEffect(() => {
    getInfo();
  }, [getInfo]);

  useEffect(() => {
    if (filterValue) {
      const temp = list.filter(
        (i: any) =>
          i.label.toLowerCase().indexOf(filterValue.trim().toLowerCase()) > -1
      );
      setFilterList([...temp]);
    } else {
      setFilterList([...list]);
    }
  }, [filterValue, list]);

  return (
    <>
      {!init ? (
        shipmentInfo ? (
          <Card
            loading={loading}
            style={{ width: '100%', height: '100%' }}
            title={
              <Space>
                {shipmentInfo.shippingStatus !== 32 && <span className="shipment-option-icon-box">
                  <Dropdown
                    disabled={isExporting}
                    overlay={
                      <Menu>
                        <Menu.Item>
                          <Button
                            type="text"
                            style={{ width: '100%', textAlign: 'left' }}
                            icon={<DiffOutlined />}
                            onClick={() => {
                              if (shipmentInfo?.shippingStatus === 1) {
                                handleExportShippingLabel(shipmentInfo);
                              } else {
                                setExportLablePdfVisible(true);
                              }
                            }}
                          >
                            Shipping Label
                          </Button>
                        </Menu.Item>
                        <Menu.Item>
                          <Button
                            type="text"
                            style={{ width: '100%', textAlign: 'left' }}
                            icon={<DiffOutlined />}
                            onClick={() =>
                              handleExportPackingSlip(shipmentInfo)
                            }
                          >
                            Packing Slip
                          </Button>
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={['click']}
                  >
                    <Button
                      size="small"
                      className="hover-danger-button"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <CloudDownloadOutlined />
                      Export PDF
                      <CaretDownOutlined />
                    </Button>
                  </Dropdown>
                </span>
                }

                {shipmentInfo.shippingStatus !== 32 && <span
                  className="shipment-option-icon-box"
                  onClick={() => {
                    setCancelDialogVisible(true);
                  }}
                >
                  <Button
                    disabled={isCancelNotAvaliable(shipmentInfo)}
                    size="small"
                    className="hover-danger-button"
                  >
                    Cancel Shipment
                  </Button>
                </span>}
                {shipmentInfo.shippingStatus !== 1 && shipmentInfo.shippingStatus !== 32 && (
                  <span
                    className="shipment-option-icon-box"
                    onClick={() => {
                      Modal.confirm({
                        title: <span>
                          <div>Do you want to {shipmentInfo.shippingStatus === 8 ? 'unhold' : 'hold'} this shipment?</div>
                          <div>Channel Order ID: {shipmentInfo.channelOrderId}</div>
                          <div>Shipment ID: {shipmentInfo.shipmentNum}</div>
                          <br />
                          <div>{shipmentInfo.shippingStatus === 8 ? 'Unhold Reason' : 'Hold Reason'}: </div>
                        </span>,
                        okText: 'Yes',
                        cancelText: 'No',
                        okType: 'default',
                        okButtonProps: { className: "hover-danger-button" },
                        autoFocusButton: 'cancel',
                        content: (
                          <div style={{ paddingRight: 38 }}>
                            <Input.TextArea
                              rows={2}
                              style={{ marginTop: -2 }}
                              placeholder={
                                shipmentInfo.shippingStatus === 8
                                  ? 'Please input the unhold reason'
                                  : 'Please input the hold reason'
                              }
                              onChange={(event) =>
                                (reason.current = event.target.value)
                              }
                            />
                          </div>
                        ),
                        onCancel() {
                          reason.current = '';
                        },
                        onOk() {
                          if (shipmentInfo.shippingStatus === 8) {
                            handleUnholdShipment();
                          } else {
                            handleHoldShipment();
                          }
                        },
                      });
                    }}
                  >
                    <Button size="small" className="hover-danger-button">
                      {shipmentInfo.shippingStatus === 8
                        ? 'Unhold Shipment'
                        : 'Hold Shipment'}
                    </Button>
                  </span>
                )}
                {shipmentInfo.shippingStatus !== 32 && <span
                  className="shipment-option-icon-box"
                  onClick={handleOpenOptionDialog}
                >
                  <Button
                    disabled={isShipmentEditable(shipmentInfo)}
                    size="small"
                  >
                    <SettingOutlined />
                    Services
                  </Button>
                </span>}
                <span>
                  <Input
                    style={{ width: 150 }}
                    prefix={<FilterOutlined />}
                    size="small"
                    placeholder="Filter"
                    allowClear
                    onChange={(e) => {
                      debounceFn(e.target.value);
                    }}
                  />
                </span>
              </Space>
            }
            bodyStyle={{
              width: '100%',
              height: 'calc(100% - 64px)',
              overflowY: 'auto',
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <Row style={{ marginTop: 5 }}>
              {filterList.length === 0 && <Row style={{ width: '100%' }} justify="center"><Empty /></Row>}
              {filterList.map((item, index) => {
                return showMore ? (
                  <Col span={8} key={item.label}>
                    <Row>
                      <div style={{ width: labelWidth }}>
                        <FormLabel>{item.label}</FormLabel>
                      </div>
                      {item.render}
                    </Row>
                  </Col>
                ) : index <= 8 ? (
                  <Col span={8} key={item.label}>
                    <Row>
                      <div style={{ width: labelWidth }}>
                        <FormLabel>{item.label}</FormLabel>
                      </div>
                      {item.render}
                    </Row>
                  </Col>
                ) : null;
              })}
              {filterList.length > 9 && (
                <Row justify="center" align="middle" style={{ width: '100%' }}>
                  <Button
                    id="toggle_all_shipments"
                    icon={showMore ? <UpOutlined /> : <DownOutlined />}
                    onClick={() => setShowMore((prev) => !prev)}
                    type="link"
                  >
                    {showMore ? 'Collapse' : 'More information...'}
                  </Button>
                </Row>
              )}
            </Row>

            <Card
              bodyStyle={{
                paddingTop: COMMON_PADDING_SPACE,
                paddingBottom: COMMON_PADDING_SPACE,
              }}
              style={{
                marginTop: COMMON_PADDING_SPACE,
                marginBottom: COMMON_PADDING_SPACE,
              }}
            >
              <Row>
                <Col span={12}>
                  <Descriptions
                    title="Ship From"
                    size="small"
                    column={2}
                    labelStyle={{
                      display: 'inline-block',
                      width: 100,
                      textAlign: 'right',
                    }}
                  >
                    <Descriptions.Item
                      label={<FormLabel noColon>Name</FormLabel>}
                    >
                      <CopySpan value={shipmentInfo?.shipFromName} />
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={<FormLabel noColon>Company</FormLabel>}
                    >
                      <CopySpan value={shipmentInfo?.shipFromCompany} />
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={<FormLabel noColon>Tel</FormLabel>}
                    >
                      <CopySpan value={shipmentInfo?.shipFromPhone} />
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={<FormLabel noColon>Address</FormLabel>}
                    >
                      {formatAddress(
                        'row',
                        '',
                        '',
                        shipmentInfo?.shipFromStreet1,
                        shipmentInfo?.shipFromStreet2,
                        shipmentInfo?.shipFromStreet3,
                        shipmentInfo?.shipFromCity,
                        shipmentInfo?.shipFromState,
                        shipmentInfo?.shipFromPostalCode,
                        shipmentInfo?.shipFromCountry
                      )}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
                <Col span={12}>
                  <Descriptions
                    title="Ship To"
                    size="small"
                    column={2}
                    labelStyle={{
                      display: 'inline-block',
                      width: 100,
                      textAlign: 'right',
                    }}
                  >
                    <Descriptions.Item
                      label={<FormLabel noColon>Name</FormLabel>}
                    >
                      <CopySpan value={shipmentInfo?.shipToName} />
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={<FormLabel noColon>Company</FormLabel>}
                    >
                      <CopySpan value={shipmentInfo?.shipToCompany} />
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={<FormLabel noColon>Tel</FormLabel>}
                    >
                      <CopySpan value={shipmentInfo?.shipToDaytimePhone} />
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={<FormLabel noColon>Address</FormLabel>}
                    >
                      {formatAddress(
                        'row',
                        '',
                        '',
                        shipmentInfo?.shipToAddressLine1,
                        shipmentInfo?.shipToAddressLine2,
                        shipmentInfo?.shipToAddressLine3,
                        shipmentInfo?.shipToCity,
                        shipmentInfo?.shipToState,
                        shipmentInfo?.shipToPostalCode,
                        shipmentInfo?.shipToCountry
                      )}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
            </Card>
            <TablesFilter
              dataSource={lineItems}
              columns={getItemColumns(shipmentInfo.shippingStatus === 1)}
              setFilteredData={setFilteredData}
            />
            <DataGridPlus
              name="locationReport"
              idProperty="id"
              loading={loading}
              treeColumn="sku"
              columns={getItemColumns(shipmentInfo.shippingStatus === 1)}
              dataSource={filteredData}
              showColumnMenuTool={false}
              expandedNodes={expandedNodes}
              onExpandedNodesChange={({ expandedNodes: ens }) => {
                setExpandedNodes(ens);
              }}
              pagination={false}
              {...({} as any)}
            />
            {/* <List
                    size="small"
                    loading={loading}
                    dataSource={shipmentInfo?.items || []}
                    renderItem={(item: any) => (
                      <List.Item key={item.sku}>
                        <List.Item.Meta
                          avatar={<Avatar src={item.subGroupCode || 'error'} />}
                          title={
                            <Space>
                              <span>
                                <FormLabel>SKU</FormLabel>
                                <CopySpan value={item.sku} />
                              </span>{' '}
                              <span>
                                <FormLabel>UPC</FormLabel>
                                <CopySpan value={item.sku} />
                              </span>
                            </Space>
                          }
                          description={item.itemTitle}
                        />
                        <div>
                          <Space size="large">
                            <Statistic
                              title="Shipping Qty"
                              value={item.shippingQty}
                            />
                          </Space>
                        </div>
                      </List.Item>
                    )}
                  /> */}
          </Card>
        ) : (
          <Empty />
        )
      ) : (
        <Row
          justify="center"
          align="middle"
          style={{ width: '100%', height: '100%' }}
        >
          <Spin />
        </Row>
      )}
      {exportLablePdfVisible && (
        <ExportLabelPDF
          visible
          onClose={() => {
            setExportLablePdfVisible(false);
          }}
          onRefresh={() => { }}
          shipment={shipmentInfo}
          markVerified={true}
        />
      )}
      {optionDialogVisible && (
        <OptionDialog
          onClose={() => setOptionDialogVisible(false)}
          refreshShipment={() => {
            getInfo();
            hadChanged.current = true;
          }}
          shipment={shipmentInfo}
          visible={optionDialogVisible}
          warehouseNum={shipmentInfo?.warehouseNum}
        />
      )}

      {cancelDialogVisible && (
        <CancelShipmentDialog
          shipmentInfo={shipmentInfo}
          visible={cancelDialogVisible}
          onClose={() => setCancelDialogVisible(false)}
          refreshShipment={() => {
            getInfo();
            hadChanged.current = true;
          }}
        />
      )}
      {
        detailVisible && current && (
          <ProductDetail
            visible={detailVisible}
            onHide={() => setDetailVisible(false)}
            productId={current.productId}
          />
        )
      }
      {bundleModalVisible && current && (
        <BundleModal
          visible
          onHide={() => setBundleModalVisible(false)}
          product={current}
        />
      )}
    </>
  );
};

export default ShipmentDetailDialog;
