/**
 * Warehouse-related service requests are here.
 */
import moment from 'moment';
import { DEFAULT_ISO_TIME_FORMAT } from 'constants/config';
import { convertToUtcTime, string2Date, validateHttpStatus } from 'utils';
import { digitBridgeApi } from './http';

/**
 * Create a warehouse entity.
 */
export const createWarehouseEntity = async (params: {
  [key: string]: any;
}): Promise<any> => {
  const json = JSON.stringify(params);
  const response = await digitBridgeApi.post('/api/warehouses', json);

  return response;
};

/**
 * Delete a warehouse entity according to its id.
 */
export const deleteWarehouseEntity = async (
  warehouseId: string
): Promise<any> => {
  return await digitBridgeApi.delete(
    `/api/warehouses/${warehouseId}`
  );
};

/**
 * Fetch a warehouse entity according to its id.
 */
export const fetchWarehouseEntity = async (
  warehouseId: string
): Promise<any> => {
  return await digitBridgeApi.get(`/api/warehouses/${warehouseId}`);
};

/**
 * Fetch the children of a location in a warehouse.
 *
 * @param {number} warehouseId
 * @param {number} locationId
 */
export const fetchWarehouseLocationChildren = async (
  warehouseId: string,
  locationId: string
): Promise<any> => {
  return await digitBridgeApi.get(
    `/api/warehouses/${warehouseId}/locations/${locationId}/children`
  );
};

/**
 * Fetch the parents(path) of a location in a warehouse.
 *
 * @param {number} warehouseId
 * @param {number} locationId
 */
export const fetchWarehouseLocationParents = async (
  warehouseId: string,
  locationId: string
): Promise<any> => {
  return await digitBridgeApi.get(
    `/api/warehouses/${warehouseId}/locations/${locationId}/parents`
  );
};

/**
 * Fetch the warehouse list.
 */
export const fetchWarehouseList = async (warehouseType?: number): Promise<any> => {
  const params = {
    warehouseType
  }
  return await digitBridgeApi.get('/api/warehouses', { params });
};

export const createWarehouseLocationEntity = async (
  warehouseId: string,
  params: StringKAnyVPair
): Promise<any> => {
  const json = JSON.stringify(params);

  return await digitBridgeApi.post(
    `/api/warehouses/${warehouseId}/locations`,
    json
  );
};

/**
 * Delete a warehouse entity.
 */
export const deleteWarehouseLocationEntity = async (
  warehouseId: string,
  locationId: string
): Promise<any> => {
  return await digitBridgeApi.delete(
    `/api/warehouses/${warehouseId}/locations/${locationId}`
  );
};

export const fetchWarehouseLocationEntity = async (
  warehouseId: string,
  locationId: string
): Promise<any> => {
  return await digitBridgeApi.get(
    `/api/warehouses/${warehouseId}/locations/${locationId}`
  );
};

/**
 * Fetch the location list for a warehouse.
 *
 * @param {string} warehouseId
 * @param {?string} code - code search keywords
 */
export const fetchWarehouseLocationList = async (
  warehouseId: string,
  includeVirtual: boolean,
  code?: string,
  locationType?: number,
  sortBy?: string,   //CreateDate|UpdateDate|LocationCode ASC|DESC
  includePick?: boolean,
): Promise<Array<any>> => {
  const params = code
    ? { includeVirtual, includePick, keyword: code, locationType: locationType ? locationType : 0, sortBy: sortBy ? sortBy : 'UpdateDate DESC' }
    : { includeVirtual, includePick, locationType: locationType ? 0 : locationType, sortBy: sortBy ? sortBy : 'UpdateDate DESC' }


  const list = (await digitBridgeApi.get(
    `/api/warehouses/${warehouseId}/locations`,
    { params }
  )) as any;
  return list.map((item: any) => {
    return {
      id: item.locationNum,
      code: item.locationCode,
      ...item,
      createDate: string2Date(item.createDate),
      updateDate: string2Date(item.updateDate),
    };
  });
};
/**
 * Fetch warehourse address list
 * @param warehouseId
 */
export const fetchWarehouseAddress = async (
  warehouse: WarehouseRow
): Promise<WarehouseAddressRow | undefined> => {
  try {
    const entity = (await digitBridgeApi.get(
      `/api/warehouses/${warehouse.warehouseId}/address`,
      { validateStatus: (status) => validateHttpStatus([404], status) }
    )) as any;
    entity.id = entity.addressNum;
    entity.warehouseId = entity.warehouseNum;
    entity.middleName = entity.middleName || entity.midlleName;
    return entity;
  } catch (e) {
    return undefined;
  }
};
/**
 * Post warehourse address entity
 * @param warehouseId
 * @param entity
 */
export const postWarehouseAddress = async (
  warehouse: WarehouseRow,
  entity: StringKAnyVPair
): Promise<void> => {
  const json = JSON.stringify(entity);
  return await digitBridgeApi.post(
    `/api/warehouses/${warehouse.warehouseId}/address`,
    json
  );
};
/**
 * Patch warehourse address entity
 * @param warehouseId
 * @param entity
 */
export const patchWarehouseAddress = async (
  warehouse: WarehouseRow,
  entity: StringKAnyVPair
): Promise<void> => {
  const json = JSON.stringify(entity);
  return await digitBridgeApi.patch(
    `/api/warehouses/${warehouse.warehouseId}/address`,
    json
  );
};
/**
 * Create warehourse address entity
 * @param warehouse
 * @param entity
 */
export const deleteWarehouseAddress = async (
  warehouse: WarehouseRow
): Promise<void> => {
  return await digitBridgeApi.delete(
    `/api/warehouses/${warehouse.warehouseId}/address`
  );
};

/**
 * Fetch the warehouse list.
 */
export async function fetchWarehouseListPlus(): Promise<Array<WarehouseRow>> {
  // eslint-disable-next-line
  try {
    const list = (await digitBridgeApi.get('/api/warehouses')) as any;
    return list.map((item: any) => {
      return {
        ...item,
        id: item.warehouseNum,
        code: item.warehouseCode,
        name: item.warehouseName,
      };
    });
  } catch (e) {
    /*
    if (e.response.status === 404) {
      return [];
    }*/
    throw e;
  }
}
/**
 * Edit the warehouse location
 * @param warehouseId
 * @param locationId
 * @param code
 */
export const editWarehouseLocation = async (
  warehouseId: string,
  locationId: string,
  code: string
): Promise<any> => {
  // const json = JSON.stringify({LocationCode: code});
  return await digitBridgeApi.patch(
    `/api/warehouses/${warehouseId}/locations/${locationId}`,
    { LocationCode: code }
  );
};
/**
 * Patch the warehouse location
 * @param warehouseId
 * @param locationId
 * @param note
 */
export const patchWarehouseLocation = async (
  warehouseId: string,
  locationId: string,
  note: string
): Promise<boolean> => {
  const json = JSON.stringify({ note });
  await digitBridgeApi.patch(
    `/api/warehouses/${warehouseId}/locations/${locationId}`,
    json
  );
  return true;
};
export const patchWarehouseLocationAspect = async (
  warehouseId: string,
  locationId: string,
  LocationAspectsNum: string
): Promise<boolean> => {
  const json = JSON.stringify({ LocationAspectsNum });
  await digitBridgeApi.patch(
    `/api/warehouses/${warehouseId}/locations/${locationId}`,
    json
  );
  return true;
};
/**
 * Patch the warehouse location zone
 * @param warehouseId
 * @param locationId
 * @param zoneId
 */
export const patchWarehouseLocationZone = async (
  warehouseId: string,
  locationId: string,
  zoneNum: number | null
): Promise<boolean> => {
  const json = JSON.stringify({ zoneNum });
  await digitBridgeApi.patch(
    `/api/warehouses/${warehouseId}/locations/${locationId}`,
    json
  );
  return true;
};

/**
 * Edit the warehouse location
 * @param warehouseId
 * @param params
 */
export const editWarehouse = async (
  warehouseId: string,
  params: Object
): Promise<any> => {
  return await digitBridgeApi.patch(
    `/api/warehouses/${warehouseId}`,
    params
  );
};

/**
 * Fetch cycle counts
 * @param {[key:string]:any} conditons
 * @param {string} sort
 * @returns {Promise<Array<CycleCountRow>>}
 */
export const fetchCycleCounts = async (
  conditions: StringKAnyVPair,
  sort: string
): Promise<Array<CycleCountRow>> => {
  const params = { sort } as any;
  //warehouse
  //const warehouse = conditions['warehouse'];
  //zones
  //const zones = conditions['zones'];
  const { warehouse, zones } = conditions;
  if (zones !== 'all') {
    const ids = zones.map((item: any) => {
      return item.id;
    });
    params['zones'] = ids.join(',');
  }
  //last count date
  const lastCountDateValues = conditions['lastCountDate'];
  if (lastCountDateValues) {
    if (lastCountDateValues === 'never') {
      params['lastCountDate'] = 'never';
    } else {
      if (typeof lastCountDateValues === 'object') {
        const getDate = (t: moment.Moment) => {
          //return t.format('YYYY-MM-DD');
          return convertToUtcTime(t.format(DEFAULT_ISO_TIME_FORMAT));
        };
        const startDate = getDate(lastCountDateValues[0]);
        const endDate = getDate(lastCountDateValues[1]);
        params['lastCountDate'] = `${startDate} ${endDate}`;
      }
    }
  }
  //location type
  const locationTypeValues = conditions['locationType'];
  if (locationTypeValues !== 'all') {
    const ids = locationTypeValues.map((item: any) => {
      return item.id;
    });
    params['locationType'] = ids.join(',');
  }
  //locationOrContainer
  const locationOrContainerValues = conditions['locationOrContainer'];
  if (locationOrContainerValues !== 'all') {
    params['locationOrContainer'] = locationOrContainerValues;
  }
  //sku
  const skuValues = conditions['product'];
  if (skuValues !== 'all') {
    const { type, value } = skuValues;
    params['sku'] = `${type} ${value}`;
  }
  if (conditions['locationCode']) {
    params['locationCode'] = conditions['locationCode']
  }
  const data = (await digitBridgeApi.get(
    `/api/warehouses/${warehouse}/cycleCounts`,
    { params }
  )) as any;
  return data.map((item: any, index: number) => {
    return {
      id: index,
      warehouseId: warehouse,
      ...item,
    };
  });
};

//get all warehouse (only use at account page)
export const getAllWarehouse = async (): Promise<any> => {
  return await digitBridgeApi.get('/api/allWarehouses');
}

export const getWarehouseServiceList = async (warehouseNum: number): Promise<any> => {
  return await digitBridgeApi.get(`/api/warehouse/${warehouseNum}/services`);
}
export const queryLocationsByKeyword = async (warehouseId: string, locationCode: string, includeVirtual: boolean, locationType?: number): Promise<any> => {
  return await digitBridgeApi.get(`/api/warehouses/${warehouseId}/getLocationByCode`, { params: { locationCode, includeVirtual, locationType: locationType ? locationType : 0 } });
}

export const getServicesByWarehouse = async (warehouseNum: number): Promise<any> => {
  return await digitBridgeApi.get(`/api/Warehouse/${warehouseNum}/carrierservices`);

}

export const getStoresByWarehouse = async (warehouseNum: number): Promise<any> => {
  return await digitBridgeApi.get(`/api/Warehouse/${warehouseNum}/channelaccounts`);

}

export const getWarehouseSpecial = async (warehouseId: string, warehouseType: number): Promise<any> => {
  return await digitBridgeApi.get(`/api/warehouses/${warehouseId}/special/${warehouseType}`);
}

export const getSpecialLocations = async (warehouseId: string, warehouseType: number): Promise<any> => {
  return await digitBridgeApi.get(`/api/warehouses/${warehouseId}/special/${warehouseType}/locations`);
}

export const get3rdPaties = async (): Promise<any> => {
  return await digitBridgeApi.get('/api/thirdParties');
}


export const getThirdPartySettingDefs = async (warehouseId: string): Promise<any> => {
  return await digitBridgeApi.get(`/api/warehouses/${warehouseId}/thirdPartySettingDefs`);
}

export const getThirdPartySettings = async (warehouseId: string): Promise<any> => {
  return await digitBridgeApi.get(`/api/warehouses/${warehouseId}/thirdPartySettings`);
}

export const saveThirdPartySettings = async (warehouseId: string, params: { SettingCode: string, SettingValue: any }[]): Promise<any> => {
  const json = JSON.stringify(params);
  return await digitBridgeApi.put(`/api/warehouses/${warehouseId}/thirdPartySettings`, json);
}

/**
 *  Location Aspects
 */
export const getWarehouseAspects = async (warehouseId: string): Promise<any> => {
  return await digitBridgeApi.get(`/api/warehouses/${warehouseId}/locationAspects`);
}

type AddEditAspectParams = {
  LocationAspectsName?: string,
  VolumeLength?: 0,
  VolumeHeight?: 0,
  VolumeWidth?: 0,
  CapacityLength?: 0,
  CapacityHeight?: 0,
  CapacityWidth?: 0,
  MaxLoad?: 0,
  TareWeight?: 0,
  DimensionUnit?: string,
  WeightUnit?: string
}

export const createWarehouseAspects = async (warehouseId: string, params: AddEditAspectParams): Promise<any> => {
  const json = JSON.stringify(params);
  return await digitBridgeApi.post(`/api/warehouses/${warehouseId}/locationAspects`, json);
}

export const editWarehouseAspects = async (warehouseId: string, locationAspectsId: string, params: AddEditAspectParams): Promise<any> => {
  const json = JSON.stringify(params);
  return await digitBridgeApi.patch(`/api/warehouses/${warehouseId}/locationAspects/${locationAspectsId}`, json);
}

export const deleteWarehouseAspects = async (warehouseId: string, locationAspectsId: string): Promise<any> => {
  return await digitBridgeApi.delete(`/api/warehouses/${warehouseId}/locationAspects/${locationAspectsId}`);
}

export const batchWarehouseLocationAspect = async (warehouseId: string, locationAspectsNum: number, locationNums: number[]): Promise<any> => {
  const json = JSON.stringify(locationNums);
  return await digitBridgeApi.post(`/api/warehouses/${warehouseId}/locationAspects/${locationAspectsNum}/bindingLocations`, json);
}

/**
 * Batch delete locations
 */
export const batchDeleteWarehouseLocations = async (warehouseId: string, locations: any): Promise<any> => {
  const json = JSON.stringify(locations)
  return await digitBridgeApi.delete(`/api/warehouses/${warehouseId}/locations`, { data: json });
};

export const  getReturnWarehouseInfo =  async (warehouseNum: number): Promise<any> => {
  return await digitBridgeApi.get(`/api/warehouses/${warehouseNum}/return`);
}
