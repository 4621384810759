import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { Button, Dropdown, Menu } from 'antd';
import {
  // editWarehouseAddress,
  editWarehouseLocation,
} from 'actions/adminActions';
import {
  CaretDownOutlined,
  DeleteOutlined,
  EditOutlined,
  SettingFilled,
} from '@ant-design/icons';
import { deleteIconStyle } from 'components/common/styles';
/**
 * The type definition for ActionCell Properties.
 *
 * @param {WarehouseRow} warehouse
 */
type Props = {
  warehouse: WarehouseRow;
  enableNormal: boolean;
  onWarehouseAddressDialogShow: (warehouse: WarehouseRow) => void;
  onEditWarehouseDialogShow: (warehouse: WarehouseRow) => void;
  onThirdPartySettingDialogShow: (warehouse: WarehouseRow) => void;
  deleteFn: () => void;
  onEditReturnFn: (warehouse: WarehouseRow) => void;
};
// eslint-disable-next-line
export default (props: Props) => {
  // const [loading, setLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  // const [address, setAddress] = useState<WarehouseAddressRow>();
  // const [addressLoaded, setAddressLoaded] = useState(false);
  // const [lockScreen, setLockScreen] = useState(false);
  const {
    warehouse,
    enableNormal,
    deleteFn,
    onWarehouseAddressDialogShow,
    onEditWarehouseDialogShow,
    onThirdPartySettingDialogShow,
    onEditReturnFn
  } = props;
  const dispatch: Dispatch<any> = useDispatch();

  const editLocation = React.useCallback(() => {
    setVisible(false);
    dispatch(editWarehouseLocation(true, warehouse));
  }, [dispatch, warehouse]);
  // const handleVisibleChange = useCallback(() => {
  //   if (visible) {
  //     setVisible(false);
  //   } else {
  //     if (addressLoaded) {
  //       setVisible(true);
  //     } else {
  //       setLoading(true);
  //       fetchWarehouseAddress(warehouse).then((address) => {
  //         setAddress(address);
  //         setAddressLoaded(true);
  //         setLoading(false);
  //         setVisible(true);
  //       });
  //     }
  //   }
  // }, [addressLoaded, warehouse, visible]);
  const renderMenus = () => {
    return (
      <div onMouseLeave={() => setVisible(false)}>
        <Menu>
          <Menu.Item key="5">
            <Button
              type="text"
              style={{ width: '100%', textAlign: 'left' }}
              icon={<EditOutlined />}
              onClick={() => {
                setVisible(false);
                onEditWarehouseDialogShow(warehouse);
              }}
            >
              Edit Warehouse
            </Button>
          </Menu.Item>
          <Menu.Item key="0">
            <Button
              type="text"
              style={{ width: '100%', textAlign: 'left' }}
              icon={<EditOutlined />}
              onClick={() => editLocation()}
            >
              Manage Locations
            </Button>
          </Menu.Item>
          {warehouse.warehouseType === 1 && enableNormal && <Menu.Item key="9">
            <Button
              type="text"
              style={{ width: '100%', textAlign: 'left' }}
              icon={<EditOutlined />}
              onClick={() => onEditReturnFn(warehouse)}
            >
              Return Warehouse
            </Button>
          </Menu.Item>}
          <Menu.Item key="1">
            <Button
              type="text"
              style={{ width: '100%', textAlign: 'left' }}
              icon={<EditOutlined />}
              onClick={() => {
                setVisible(false);
                onWarehouseAddressDialogShow(warehouse);
              }}
            >
              Warehouse Address
            </Button>
          </Menu.Item>

          {warehouse.warehouseType === 5 && <Menu.Item key="6">
            <Button
              type="text"
              style={{ width: '100%', textAlign: 'left' }}
              icon={<EditOutlined />}
              onClick={() => {
                setVisible(false);
                onThirdPartySettingDialogShow(warehouse);
              }}
            >
              Third Party Setting
            </Button>
          </Menu.Item>}

          <Menu.Item key="4">
            <Button
              type="text"
              style={{ width: '100%', textAlign: 'left' }}
              className="hover-danger-button-2"
              icon={<DeleteOutlined style={deleteIconStyle} />}
              onClick={() => deleteFn()}
            >
              Delete Warehouse
            </Button>
          </Menu.Item>
        </Menu>
      </div>
    );
  };

  return (
    <>
      <Dropdown
        onVisibleChange={(visible) => {
          if (!visible) {
            setVisible(visible);
          }
        }}
        overlay={renderMenus()}
        trigger={['click']}
        visible={visible}
      >
        <Button
          icon={<SettingFilled />}
          onClick={() => setVisible(true)}
          // loading={loading}
        >
          <CaretDownOutlined />
        </Button>
      </Dropdown>
      {/* {lockScreen ? (
        <ScreenMask>
          <Loading size={48} />
        </ScreenMask>
      ) : (
        ''
      )} */}
    </>
  );
};
